import React, { FC, useEffect } from "react";
import { Outlet } from "react-router";
import { useParams } from "react-router-dom";
import Stack from "@mui/material/Stack";
import { observer } from "mobx-react-lite";

import { IConstructionSite } from "stores/managerService/constructionSiteStore/types";

import { useFetchWithLoading } from "hooks";
import { useRootStore } from "hooks/useRootStore";
import { useUpdateRouteParams } from "hooks/useUpdateRouteParams";

import AppTour from "../Onboarding";

import Area from "./Area";
import ConstructionSitesPanel from "./ConstructionSitesPanel";
import Map from "./Map";
import { BreadcrumbSteps } from "types";

const ConstructionSite: FC = () => {
  useUpdateRouteParams();

  const { id } = useParams();

  const {
    mapStore: { isAllMapInit },
    constructionSiteStore: {
      setBreadcrumbStep,
      constructionSite,
      createMode,
      getAll,
      _setConstructionSite,
      setIsSliderCardExpanded,
      setCreateMode,
    },
    areaStore: { area },
    jobStore: { job },
    teamStore: { getCurrentUserRoleInCS },
    taskStore,
  } = useRootStore();

  useEffect(() => {
    switch (true) {
      case Boolean(job?.id):
        setBreadcrumbStep(BreadcrumbSteps.jobCard);
        break;
      case Boolean(area?.id):
        setBreadcrumbStep(BreadcrumbSteps.areaCard);
        break;
      case Boolean(constructionSite.id):
        setBreadcrumbStep(BreadcrumbSteps.constructionSiteCard);
        break;
    }
  }, [job?.id, setBreadcrumbStep, area?.id, constructionSite.id]);

  const [fetchConstructionSitesWithLoading, isLoading, isLoaded] = useFetchWithLoading(getAll);
  useEffect(() => {
    if (!isAllMapInit) {
      return;
    }

    fetchConstructionSitesWithLoading();

    return () => {
      _setConstructionSite({} as IConstructionSite);
      setIsSliderCardExpanded(false);
      setCreateMode(false);
    };
  }, [isAllMapInit]);

  useEffect(() => {
    if (!isAllMapInit) {
      return;
    }

    taskStore.setTasks(null);
    if (constructionSite.id) getCurrentUserRoleInCS(constructionSite.id);
  }, [constructionSite.id, isAllMapInit]);

  useEffect(() => {
    const toolsPanel = document.querySelectorAll(".esri-sketch__section")[1];
    if (toolsPanel) {
      const pointTool = toolsPanel.querySelector("calcite-action") as HTMLElement;
      if (createMode) {
        pointTool.style.pointerEvents = "none";
      } else {
        pointTool.style.pointerEvents = "auto";
      }
    }
  }, [createMode]);

  return (
    <>
      <AppTour />
      <Stack direction="column" justifyContent={constructionSite ? "space-between" : "flex-end"} sx={containerStyles}>
        {id && isLoaded && (
          <Area>
            <Outlet />
          </Area>
        )}
        <ConstructionSitesPanel isConstructionSitesLoaded={isLoaded} />
      </Stack>
      <Map />
    </>
  );
};

const containerStyles = {
  height: "calc(100vh - 50px)",
  mt: 2,
  mb: 3,
  mx: 2,
};

export default observer(ConstructionSite);
