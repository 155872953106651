import React, { FC, useCallback } from "react";
import { useTranslation } from "react-i18next";
import RefreshRoundedIcon from "@mui/icons-material/RefreshRounded";
import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";

export interface RefreshStatusProps {
  updateFunc: () => Promise<void>;
  setAngle: React.Dispatch<React.SetStateAction<number>>;
}

const RefreshStatus: FC<RefreshStatusProps> = ({ updateFunc, setAngle }) => {
  const { t } = useTranslation();

  const handleClick = useCallback(async () => {
    setAngle(a => a + 360);
    await updateFunc();
  }, [setAngle, updateFunc]);

  return (
    <Tooltip placement="bottom" title={t("Processings:UpdateStatus")}>
      <Box sx={iconWrapperStyles}>
        <RefreshRoundedIcon onClick={handleClick} sx={{ "&&": { color: "text.primary" } }} />
      </Box>
    </Tooltip>
  );
};

const iconWrapperStyles = {
  overflow: "hidden",
  display: "inline-flex",
  alignItems: "center",
  paddingLeft: "1px",
};

export default RefreshStatus;
