import React, { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { observer } from "mobx-react-lite";

import Breadcrumbs from "common/Breadcrumbs";
import { getExistingSteps } from "common/Breadcrumbs/helpers";
import { ExistingStep } from "common/Breadcrumbs/types";
import { useRootStore } from "hooks/useRootStore";
import { absolutePath, PATHS } from "router/paths";

import { CsBreadcrumbStep } from "./types";

type CardStep = ExistingStep<CsBreadcrumbStep>;

const CardBreadcrumbs = () => {
  const navigate = useNavigate();

  const {
    constructionSiteStore: { constructionSite },
    areaStore: { area },
    jobStore: { job },
    mapStore: { clearAreaAndNested, clearJobAndNested },
  } = useRootStore();

  const steps = useMemo<CardStep[]>(
    () =>
      getExistingSteps([
        constructionSite?.title
          ? {
              title: constructionSite.title,
              stepName: CsBreadcrumbStep.CS_CARD,
              action: () => {
                navigate(absolutePath(PATHS.HOME.CS_CARD(constructionSite.id)));
                clearAreaAndNested();
              },
            }
          : null,
        area
          ? {
              title: area.title,
              stepName: CsBreadcrumbStep.AREA_CARD,
              // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
              action: () => {
                navigate(absolutePath(PATHS.HOME.AREA_CARD(area.id)));
                clearJobAndNested();
              },
            }
          : null,
        job
          ? {
              title: job.title,
              stepName: CsBreadcrumbStep.JOB_CARD,
              action: () => navigate(PATHS.HOME.JOB_CARD(job.id)),
            }
          : null,
      ]),
    [area, clearAreaAndNested, clearJobAndNested, constructionSite.id, constructionSite.title, job, navigate]
  );

  const selectedStepName = useMemo<CsBreadcrumbStep>(() => steps[steps.length - 1]?.stepName, [steps]);

  return <Breadcrumbs<CsBreadcrumbStep> steps={steps} selectedStepName={selectedStepName} />;
};

export default observer(CardBreadcrumbs);
