import React, { FC } from "react";
import Grid from "@mui/material/Grid";

const AppLoader: FC = () => {
  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      sx={{ height: "100vh", backgroundColor: "background.main" }}>
      <img src="/images/Logo.gif" width="60" alt="Preloader" />
    </Grid>
  );
};

export default AppLoader;
