import { useRootStore } from "hooks/useRootStore";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

import { OnboardingSelector } from "../../Onboarding/steps/types";

import CreationForm from "./CreationForm";

const CreateButton: FC = () => {
  const { t } = useTranslation();
  const { jobStore, teamStore, uiStore } = useRootStore();

  const { job } = jobStore;
  const { csPermissions } = teamStore;

  return (
    <Button
      id={OnboardingSelector.ADD_NEW_PROCESSING}
      size="small"
      variant="contained"
      color="secondary"
      fullWidth
      onClick={() => job && uiStore.openModal(<CreationForm jobId={job.id} />)}
      disabled={!csPermissions.creating}>
      <Typography variant="caption">+ {t("Processings:AddNew")}</Typography>
    </Button>
  );
};

export default CreateButton;
