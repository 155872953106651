import { useRootStore } from "hooks/useRootStore";;
import React, { FC, useEffect, useRef, useState } from "react";
import { CellMeasurer, CellMeasurerCache, List } from "react-virtualized";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import { Observer } from "mobx-react-lite";



const HEIGHT = 160;

const UploadsList: FC = () => {
  const { fileStore } = useRootStore();

  const cache = useRef(new CellMeasurerCache({ fixedWidth: true, defaultHeight: 32 }));
  const { uploads } = fileStore;
  const [isOverflow, setIsOverflow] = useState(false);

  useEffect(() => {
    const scrollHeight = document.getElementsByClassName("ReactVirtualized__Grid__innerScrollContainer")[0]
      .clientHeight;

    if (scrollHeight > HEIGHT) {
      setIsOverflow(true);
    } else {
      setIsOverflow(false);
    }
  }, [uploads.length]);

  // const abortUpload = (index: number) => {
  //   store.abortUpload(index);
  // };

  return (
    <Box sx={containerStyles}>
      <List
        style={isOverflow ? { paddingRight: "10px", MozPaddingEnd: "0" } : undefined}
        width={225}
        height={HEIGHT}
        rowHeight={cache.current.rowHeight}
        rowCount={uploads.length}
        deferredMeasurementCache={cache.current}
        rowRenderer={({ key, index, style, parent }) => {
          const upload = uploads[index];
          return (
            <CellMeasurer cache={cache.current} parent={parent} columnIndex={0} rowIndex={index} key={key}>
              <div style={{ ...style, paddingBottom: "10px" }}>
                <Observer>
                  {() => (
                    <Box sx={uploadStyles}>
                      <Box sx={iconWrapperStyles}>
                        {upload.loaded === 100 ? (
                          <CheckRoundedIcon fontSize="small" />
                        ) : (
                          <FileUploadOutlinedIcon fontSize="small" />
                        )}
                        <CircularProgress
                          variant="determinate"
                          sx={{
                            color: "secondary.light",
                            position: "absolute",
                          }}
                          size={32}
                          thickness={2}
                          value={100}
                        />
                        <CircularProgress
                          variant="determinate"
                          sx={{
                            color: "text.primary",
                            position: "absolute",
                          }}
                          size={32}
                          thickness={2}
                          value={upload.loaded}
                        />
                      </Box>
                      <Typography component="div" variant="body1" sx={uploadNameStyles}>
                        {upload.body.name}
                      </Typography>
                    </Box>
                  )}
                </Observer>
              </div>
            </CellMeasurer>
          );
        }}
      />
    </Box>
  );
};

const containerStyles = {
  my: 1.5,
  mx: 0.8,
  px: 0.7,
  height: HEIGHT,
  position: "relative",
  "& div::-webkit-scrollbar": {
    width: "5px",
  },
  "& div::-webkit-scrollbar-track": {
    bgcolor: "secondary.main",
    borderRadius: "5px",
  },
  "& div::-webkit-scrollbar-thumb": {
    bgcolor: "background.paper",
    borderRadius: "5px",
  },
  "& div": {
    scrollbarColor: "#333 #444",
    scrollbarWidth: "thin",
  },
} as const;

const uploadStyles = {
  display: "flex",
  alignItems: "center",
  maxWidth: "100%",
};

const uploadNameStyles = {
  ml: 1,
  width: "100%",
  overflow: "hidden",
  textOverflow: "ellipsis",
} as const;

const iconWrapperStyles = {
  position: "relative",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  minHeight: "32px",
  minWidth: "32px",
} as const;

export default UploadsList;
