import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import Input from "@mui/material/Input";
import { observer } from "mobx-react-lite";
import { BreadcrumbSteps } from "types";

import { useRootStore } from "hooks/useRootStore";

export const SearchField = observer(() => {
  const {
    constructionSiteStore: { breadcrumbStepName },
    areaStore: { filterAreas },
    jobStore: { filterJobs },
    processingStore: { filterProcessings },
  } = useRootStore();
  const { t } = useTranslation();

  const [searchValue, setSearchValue] = useState("");

  const filterMapItems = useCallback(
    (value: string) => {
      switch (breadcrumbStepName) {
        case BreadcrumbSteps.constructionSiteCard:
          filterAreas(value);
          break;
        case BreadcrumbSteps.areaCard:
          filterJobs(value);
          break;
        case BreadcrumbSteps.jobCard:
          filterProcessings(value);
          break;
      }
    },
    [breadcrumbStepName, filterAreas, filterJobs, filterProcessings]
  );

  useEffect(() => {
    filterMapItems(searchValue);
  }, [filterMapItems, searchValue]);

  return (
    <Box
      component="form"
      sx={{
        "& > :not(style)": { m: 1 },
      }}
      noValidate
      autoComplete="off">
      <Input value={searchValue} fullWidth placeholder={t("General:Search")} onChange={e => setSearchValue(e.target.value)} />
    </Box>
  );
});
