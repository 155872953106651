import { useTranslation } from "react-i18next";
import { format, formatDistance, formatDistanceToNow } from "date-fns";
import { de, enUS } from "date-fns/locale";

import { isObject } from "helpers/functions";

const LOCALES: Record<string, Locale> = {
  en: enUS,
  de,
};

type FormatFunction = typeof format | typeof formatDistance | typeof formatDistanceToNow;

export const useFormatDate = <T extends FormatFunction>(formatFunction: T) => {
  const {
    i18n: { language },
  } = useTranslation();

  const locale = LOCALES[language] ?? enUS;
  return (...args: Parameters<typeof formatFunction>) => {
    const lastIndex = args.length - 1;
    const optionsOrMaskOrDate = args[lastIndex];
    if (!(optionsOrMaskOrDate instanceof Date) && isObject(optionsOrMaskOrDate)) {
      const a = optionsOrMaskOrDate as object;
      args[lastIndex] = { ...a, locale };
    } else {
      args.push({ locale });
    }
    // args type must be right for each of formatFunction
    // @ts-expect-error
    return formatFunction(...args);
  };
};
