import { makeAutoObservable } from "mobx";
import { FixPoint, FixPointUpload } from "./types";
import { FixPointService } from "./service";
import RootStore from "stores/rootStore";

export class FixPointStore {
  fixPoints: FixPoint[] = [];

  constructor(private rootStore: RootStore) {
    makeAutoObservable(this);
  }

  get currentProjectId(): string {
    return this.rootStore.constructionSiteStore.constructionSite.id;
  }

  setFixPoints = (fixPoints: FixPoint[]) => {
    this.fixPoints = fixPoints;
  };

  getAllFixPoints = async () => {
    const fixPoints = await FixPointService.getAllFixPoints(this.currentProjectId);
    this.setFixPoints(fixPoints);
  };

  createFixPoint = async (fixPointData: FixPointUpload[]) => {
    await FixPointService.createFixPoint(this.currentProjectId, fixPointData);
    this.getAllFixPoints();
  };

  updateFixPoint = async (fixPointId: string, fixPointData: FixPoint) => {
    const updatedFixPoint = await FixPointService.updateFixPoint(this.currentProjectId, fixPointId, fixPointData);
    this.fixPoints = this.fixPoints.map(fp => (fp.id === fixPointId ? updatedFixPoint : fp));
  };

  deleteFixPoint = async (fixPointId: string) => {
    await FixPointService.deleteFixPoint(this.currentProjectId, fixPointId);
    const filteredPixPoints = this.fixPoints.filter(fp => fp.id !== fixPointId);
    this.setFixPoints(filteredPixPoints);
  };
}
