import React, { FC, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { observer } from "mobx-react-lite";

import { Job, JobStatus } from "stores/managerService/jobStore/types";
import { onboardingStore } from "stores/onboardingStore";

import DateAndUserItemInfoContent from "common/InfoList/DateAndUserItemInfoContent";
import { ItemsList, ListItem } from "common/ItemsList";
import Status from "common/Status";
import { StatusName } from "common/Status/types";
import { useRootStore } from "hooks/useRootStore";
import { absolutePath, PATHS } from "router/paths";

import ActionButtons from "./ActionButtons";

const isFinishedCreating = (j: Job) => j.status === JobStatus.CREATE_FAILED || j.status === JobStatus.CREATE_SUCCESS;

const List: FC = () => {
  const navigate = useNavigate();

  const {
    jobStore: { filteredJobs, job, getAllJobs },
    mapStore: { clearJobAndNested },
  } = useRootStore();
  const { isOnboardingRunning } = onboardingStore;

  const handleSelect = useCallback(
    async (j: Job) => {
      if (j.id === job?.id || j.status !== JobStatus.CREATE_SUCCESS) {
        return;
      }

      clearJobAndNested();
      navigate(absolutePath(PATHS.HOME.JOB_CARD(j.id)));

      if (isOnboardingRunning) {
        onboardingStore.nextStep();
      }
    },
    [clearJobAndNested, isOnboardingRunning, job?.id, navigate]
  );

  return (
    <ItemsList>
      {filteredJobs.map(j => (
        <ListItem
          key={j.id}
          itemId={j.id}
          title={j.title}
          handleSelect={() => handleSelect(j)}
          isCreateOrEditMode={false}
          sx={{
            bgcolor: isFinishedCreating(j) ? "background.paper" : "transparent",
            border: isFinishedCreating(j) ? "none" : "2px dashed #676767",
          }}
          actionButtonsSlot={<ActionButtons job={j} />}
          infoContent={<DateAndUserItemInfoContent userId={j.createdBy} date={new Date(j.createdAt)} />}
          statusSlot={
            j.status === JobStatus.CREATE_SUCCESS || (
              <Status<JobStatus>
                status={j.status}
                statusesMatch={{
                  CREATE_SUCCESS: StatusName.SUCCESS,
                  CREATE_FAIL: StatusName.FAIL,
                  CREATE_IN_PROGRESS: StatusName.IN_PROGRESS,
                }}
                updateFunc={async () => await getAllJobs(j.area)}
              />
            )
          }
        />
      ))}
    </ItemsList>
  );
};

export default observer(List);
