import { TFuncKey } from "react-i18next";
import Collection from "@arcgis/core/core/Collection";
import Geometry from "@arcgis/core/geometry/Geometry";
import * as geometryEngine from "@arcgis/core/geometry/geometryEngine";
import Graphic from "@arcgis/core/Graphic";
import { TOptions } from "i18next";
import { makeAutoObservable } from "mobx";

import i18n from "configs/i18nextConfig";
import { MapStore } from "stores/mapStore";
import RootStore from "stores/rootStore";
import { project } from "@arcgis/core/geometry/projection";
import SpatialReference from "@arcgis/core/geometry/SpatialReference";

enum Errors {
  LOCATION_NOT_SET = "Errors:LocationNotSet",
  TOO_MANY_ELEMENTS = "Errors:TooMany",
  INVALID_GRAPHIC_TYPE = "Errors:InvalidGraphicType",
  BEYOND_BOUNDARY = "Errors:BeyondBoundary",
}

type ValidGeometryType = ReadonlyArray<Geometry["type"]>;
export type ErrorI18KeyAndOptions<T extends object = any> = [TFuncKey, TOptions<T>?];

export interface BoundariesLocalesNames {
  itemLocaleName: string;
  containerLocaleName: string;
}

export interface BoundariesOptions {
  containerGeometry?: Geometry;
  insideGeometry?: Geometry;
}

type CheckFuncReturn = ErrorI18KeyAndOptions | undefined;
type CheckFunc<CheckItem extends Graphic | Collection<Graphic> | BoundariesOptions> = (
  graphic: CheckItem,
  localesNames: BoundariesLocalesNames
) => CheckFuncReturn;

export class CheckGraphics {
  constructor(private readonly rootStore: RootStore, private readonly validGeometryTypes: ValidGeometryType) {
    makeAutoObservable(this);
  }

  checkGraphicsAndGetErrorMessageAndOptions = (
    localesNames: BoundariesLocalesNames,
    boundariesOptions?: BoundariesOptions
  ): ErrorI18KeyAndOptions | undefined => {
    if (this.rootStore.mapStore.selectedGraphic) {
      const res = this.checkIsGraphicTypeInvalid(this.rootStore.mapStore.selectedGraphic, localesNames);
      if (res) {
        return res;
      }
    } else if (this.rootStore.mapStore._graphicsLayer.graphics) {
      const res = this.checkGraphicsCollection(this.rootStore.mapStore._graphicsLayer.graphics, localesNames);
      if (res) {
        return res;
      }
    }

    if (boundariesOptions) {
      return this.checkBoundaries(boundariesOptions, localesNames);
    }
  };

  private readonly checkGraphicsCollection: CheckFunc<Collection<Graphic>> = (graphics, localesNames) => {
    if (graphics.length === 0) {
      return this.getErrorKeyAndOptions(Errors.LOCATION_NOT_SET, localesNames);
    } else if (graphics.length > 1) {
      return this.getErrorKeyAndOptions(Errors.TOO_MANY_ELEMENTS, localesNames);
    }

    return this.checkIsGraphicsCollectionExistsElementsWithInvalidType(graphics, localesNames);
  };

  private readonly checkBoundaries: CheckFunc<BoundariesOptions> = (
    { containerGeometry, insideGeometry },
    localesNames
  ) => {
    if (!containerGeometry) {
      return;
    }

    if (!insideGeometry) {
      return this.getErrorKeyAndOptions(Errors.LOCATION_NOT_SET, localesNames);
    }

    if (containerGeometry.spatialReference.isWGS84) {
      containerGeometry = project(containerGeometry, SpatialReference.WebMercator) as Geometry;
    }

    if (!geometryEngine.contains(containerGeometry, insideGeometry)) {

      return this.getErrorKeyAndOptions(Errors.BEYOND_BOUNDARY, localesNames);
    }
  };

  private readonly checkIsGraphicTypeInvalid: CheckFunc<Graphic> = (graphic, localesNames) => {
    if (this.checkIsInvalidType(graphic)) {
      return this.getErrorKeyAndOptions(Errors.INVALID_GRAPHIC_TYPE, localesNames);
    }
  };

  private readonly checkIsGraphicsCollectionExistsElementsWithInvalidType: CheckFunc<Collection<Graphic>> = (
    graphics: Collection<Graphic>,
    localesNames
  ) => {
    if (graphics.some(this.checkIsInvalidType)) {
      return this.getErrorKeyAndOptions(Errors.INVALID_GRAPHIC_TYPE, localesNames);
    }
  };

  private readonly checkIsInvalidType = (graphic: Graphic): boolean => {
    return !this.validGeometryTypes.includes(graphic.geometry.type);
  };

  private getErrorKeyAndOptions(key: Errors, localesNames: BoundariesLocalesNames): ErrorI18KeyAndOptions {
    return [
      key,
      {
        entityName: i18n.t(localesNames.itemLocaleName).toLowerCase(),
        containerEntityName: i18n.t(localesNames.containerLocaleName).toLowerCase(),
      },
    ];
  }
}
