import { useRootStore } from "hooks/useRootStore";
import { observer } from "mobx-react-lite";
import React, { useCallback, useEffect, useState } from "react";
import { FormControl, Select, MenuItem, Box, Button, Typography, ClickAwayListener } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { useTranslation } from "react-i18next";
import FlipCameraAndroidIcon from "@mui/icons-material/FlipCameraAndroid";
import { IProcessing } from "stores/managerService/processingStore/types";
import Zoom from "@mui/material/Zoom";
import Paper from "@mui/material/Paper";
import VisibilityIcon from "@mui/icons-material/Visibility";
import FolderIcon from "@mui/icons-material/Folder";
import IconButton from "@mui/material/IconButton";
import { useNavigate } from "react-router-dom";
import { PATHS, absolutePath } from "router/paths";

interface LinkedProcessingsFieldProps {
  foreignId: string;
  selectedProcessingsId: string[];
  setSelectedProcessingsId: React.Dispatch<React.SetStateAction<string[]>>;
  isAllowedToEdit: boolean;
}

export const LinkedProcessingsField = observer(
  ({ foreignId, selectedProcessingsId, setSelectedProcessingsId, isAllowedToEdit }: LinkedProcessingsFieldProps) => {
    const [processingSelectorOpen, setProcessingSelectorOpen] = useState(false);
    const { t } = useTranslation();
    const {
      processingStore: { processings, getAllProcessings, setViewerOpen },
    } = useRootStore();

    const handleChange = (e: any) => {
      setSelectedProcessingsId(e.target.value);
    };

    useEffect(() => {
      getAllProcessings(foreignId);
    }, []);

    const selectedProcessings = useCallback(() => {
      return processings.filter(p => selectedProcessingsId.some(sp => sp === p.id));
    }, [selectedProcessingsId, processings]);

    const [checked, setChecked] = useState("");

    async function handleSelectProcessing(processing: IProcessing) {
      setChecked(processing.id);
    }
    async function handleOpenViewer(p: IProcessing) {
      await setViewerOpen(true, p);
    }
    async function handleOpenProcessingFolder(p: IProcessing) {
      window.open(absolutePath(PATHS.HOME.PROCESSING_CARD(p.id)), "_blank");
    }
    function handleClickAway() {
      setChecked("");
    }
    return (
      <Box sx={wrapperStyles}>
        <Box sx={{ display: "flex", flex: 3 }}>
          <FlipCameraAndroidIcon />
          <Typography variant="body1" component="div" fontWeight={600} sx={{ ml: 1.2 }}>
            {t(`Tasks:LinkedProcessings`)}
          </Typography>
        </Box>
        {!processingSelectorOpen ? (
          <ClickAwayListener onClickAway={handleClickAway}>
            <Box sx={{ flex: 4, justifyContent: "center", px: 0.5 }}>
              {selectedProcessings().map((sp, index) => {
                return (
                  <Typography
                    key={sp.id}
                    onClick={async () => {
                      await handleSelectProcessing(sp);
                    }}
                    variant="caption"
                    fontWeight={600}
                    sx={{ ml: 1.2, color: "primary.main", cursor: "pointer", position: "relative" }}>
                    {sp.title}
                    {index !== selectedProcessingsId.length - 1 && ","}
                    <Zoom in={checked === sp.id}>
                      <Paper
                        sx={{
                          position: "absolute",
                          left: 0,
                          bottom: 0,
                          padding: 0.3,
                          zIndex: 10,
                          display: "flex",
                        }}>
                        <IconButton size="small" sx={iconStyles} onClick={async () => await handleOpenViewer(sp)}>
                          <VisibilityIcon />
                        </IconButton>
                        <IconButton
                          size="small"
                          sx={iconStyles}
                          onClick={async () => await handleOpenProcessingFolder(sp)}>
                          <FolderIcon />
                        </IconButton>
                      </Paper>
                    </Zoom>
                  </Typography>
                );
              })}
              <Button
                disabled={!isAllowedToEdit}
                size="small"
                variant="contained"
                color="secondary"
                sx={btnStyles}
                onClick={() => setProcessingSelectorOpen(true)}>
                <EditIcon fontSize="small" />
              </Button>
            </Box>
          </ClickAwayListener>
        ) : (
          <FormControl sx={selectorStyles} size="small">
            <Select
              onClose={() => {
                setProcessingSelectorOpen(false);
              }}
              multiple
              value={selectedProcessingsId}
              onChange={handleChange}
              renderValue={() => (
                <Typography variant="caption" component="div">
                  {selectedProcessings()
                    .map(c => c.title)
                    .join(", ")}
                </Typography>
              )}>
              {processings.map(processing => (
                <MenuItem key={processing.id} value={processing.id}>
                  <Typography variant="caption" component="div">
                    {processing.title}
                  </Typography>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
      </Box>
    );
  }
);

const wrapperStyles = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  minHeight: "32px",
  mt: 0.5,
  boxSizing: "border-box",
};

const iconStyles = {
  color: "text.primary",
  bgcolor: "secondary.main",
  borderRadius: 10,
  "& .MuiSvgIcon-root": {
    transition: "color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
  },
  "&:hover": {
    bgcolor: "secondary.light",
    "& .MuiSvgIcon-root": {
      color: "primary.main",
    },
  },
};

const selectorStyles = {
  px: 0.5,
  flex: 4,
  justifyContent: "center",
  "& .MuiOutlinedInput-root": { pr: 0 },
  "& .MuiSelect-select.MuiInputBase-input": {
    py: 0.8,
    height: "16px",
    minHeight: "16px",
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: "transparent",
  },
};

const btnStyles = {
  ml: 1.5,
  height: "28px",
  width: "28px",
  minWidth: "unset",
  "&:hover": { bgcolor: "rgba(255, 255, 255, 0.1)" },
};
