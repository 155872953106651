import React, { FC, useRef } from "react";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { BoxProps, ClickAwayListener, Popover, SxProps, Theme } from "@mui/material";
import Box from "@mui/material/Box";

const DEFAULT_INFO_ICON = <InfoOutlinedIcon />;

interface ItemInfoProps {
  id: string | number;
  open: boolean;
  setOpen: (open: boolean) => void;
  infoIconSlot?: React.ReactNode;
  infoContent: React.ReactNode;
}

const ItemInfo: FC<ItemInfoProps & BoxProps> = ({
  id,
  open,
  setOpen,
  infoContent,
  infoIconSlot,
  ...props
}) => {
  const iconElementRef = useRef<HTMLDivElement | null>(null);
  const infoIcon = infoIconSlot ?? DEFAULT_INFO_ICON;
  return (
    <>
      <Box ref={iconElementRef} aria-describedby={id} sx={iconContainerStyles} {...props}>
        {infoIcon}
      </Box>
      <ClickAwayListener onClickAway={() => setOpen(false)}>
        <Popover
          id={id}
          anchorEl={iconElementRef.current}
          open={open}
          slotProps={{ root: { style: { pointerEvents: "none" } } }}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          hideBackdrop={true}>
          {infoContent}
        </Popover>
      </ClickAwayListener>
    </>
  );
};

const iconContainerStyles: SxProps<Theme> = {
  display: "flex",
  alignItems: "center",
  "& >*": { cursor: "pointer" },
};

export default ItemInfo;
