import React, { FC, PropsWithChildren, useMemo } from "react";
import { Tooltip, TooltipProps } from "@mui/material";

interface OverflowTooltipProps {
  element: HTMLElement | null;
}

const OverflowTooltip: FC<PropsWithChildren<OverflowTooltipProps & TooltipProps>> = ({
  children,
  element,
  title,
  sx,
  ...props
}) => {
  const isTextOverflow = useMemo(
    () => !!element && (element.offsetHeight < element.scrollHeight || element.offsetWidth < element.scrollWidth),
    [element]
  );

  return (
    <Tooltip
      title={isTextOverflow ? title : ""}
      {...props}
      arrow={false}>
      {children}
    </Tooltip>
  );
};

export default OverflowTooltip;
