import React, { FC, useEffect } from "react";
import { useParams } from "react-router-dom";
import esriConfig from "@arcgis/core/config";
import Box from "@mui/material/Box";
import { observer } from "mobx-react-lite";

import { OnboardingSelector } from "components/Onboarding/steps/types";
import { useRootStore } from "hooks/useRootStore";

import MapItemInfo from "./additionalComponents/MapItemInfo";
import MapPositionInfo from "./additionalComponents/MapItemMenu";
import AdditionalToolbar from "./additionalComponents/Toolbar";

import "./esri-ui.css";

esriConfig.apiKey = `${process.env.REACT_APP_ARCGIS_API_KEY}`;

const Map: FC = () => {
  const {
    mapStore: {
      isSketchInit,
      getCenter,
      rightClickGraphic,
      renderAdditionalToolbar,
      initMap,
      initView,
      setPopupTemplate,
      utils,
    },
    uiStore: { setSnackbarOpen },
    importFileStore: { addGraphicsFromFile },
  } = useRootStore();

  useEffect(() => {
    utils.resetMapInit();
    initMap();
    initView(
      <MapPositionInfo getCenter={getCenter} rightClickGraphic={rightClickGraphic} setSnackbarOpen={setSnackbarOpen} />
    );
    setPopupTemplate(
      <MapPositionInfo getCenter={getCenter} rightClickGraphic={rightClickGraphic} setSnackbarOpen={setSnackbarOpen} />
    );

    return () => {
      utils.resetMapInit();
    };
  }, []);

  useEffect(() => {
    if (isSketchInit) {
      renderAdditionalToolbar(<AdditionalToolbar addGraphicsFromFile={addGraphicsFromFile} />);
    }
  }, [addGraphicsFromFile, isSketchInit, renderAdditionalToolbar]);

  return (
    <>
      <MapItemInfo />
      <Box id={OnboardingSelector.MAP_VIEW_CONTAINER} style={mapStyles} />
    </>
  );
};

const mapStyles = {
  padding: 0,
  margin: 0,
  height: "100%",
  width: "100%",
  position: "absolute",
  top: 0,
} as const;

export default observer(Map);
