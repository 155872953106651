import React, { FC, useEffect } from "react";
import { useMatch, useSearchParams } from "react-router-dom";
import Box from "@mui/material/Box";
import { observer } from "mobx-react-lite";
import { Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import { SEARCH_PARAMS_TASK_ID_KEY } from "stores/mapStore/constants";

import { useRootStore } from "hooks/useRootStore";
import { PATHS } from "router/paths";

import "swiper/css/navigation";

import LoadingSliderItem from "./LoadingSliderItem";
import SliderItem from "./SliderItem";

import "swiper/css";

const Slider: FC = () => {
  const isMatchCsCardPath = useMatch(PATHS.HOME.CS_CARD());
  const [searchParams] = useSearchParams();

  const { constructionSiteStore } = useRootStore();

  const { filteredCS, constructionSite, createMode, setIsSliderCardExpanded } = constructionSiteStore;

  useEffect(() => {
    if (searchParams.get(SEARCH_PARAMS_TASK_ID_KEY) && isMatchCsCardPath) {
      setIsSliderCardExpanded(true);
    }
  }, [setIsSliderCardExpanded, isMatchCsCardPath, searchParams]);

  return (
    <Box sx={wrapperStyles}>
      <Swiper
        slidesPerView="auto"
        spaceBetween={10}
        style={sliderStyles}
        modules={[Navigation]}
        navigation
        centeredSlides
        centeredSlidesBounds
        normalizeSlideIndex={false}
        allowTouchMove={false}
        onSlidesLengthChange={swiper => swiper.slideTo(0)}>
        {createMode && (
          <SwiperSlide key={constructionSite.id} style={sliderSlideStyles}>
            <LoadingSliderItem />
          </SwiperSlide>
        )}
        {filteredCS?.map((el, index) => (
          <SwiperSlide key={el.id} style={sliderSlideStyles}>
            <SliderItem el={el} index={index} />
          </SwiperSlide>
        ))}
      </Swiper>
    </Box>
  );
};

const wrapperStyles = {
  p: 1,
  height: "100%",
  width: "100%",
  minWidth: "0px",
  position: "relative",
  clipPath: "inset(-20px 10px 0px)",
  "& .swiper-button-next, .swiper-button-prev": {
    mx: 0.5,
    bgcolor: "background.default",
    width: "40px",
    height: "40px",
    borderRadius: "50%",
    boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.1)",
    "&:after": {
      transition: "color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
      color: "text.primary",
      fontSize: "18px",
      fontWeight: 700,
    },
    "&:hover": { "&:after": { color: "primary.main" } },
  },
  "& .swiper-button-disabled": {
    display: "none",
  },
} as const;

const sliderStyles = {
  height: "100%",
  position: "static",
  overflow: "visible",
} as const;

const sliderSlideStyles = {
  height: "50px",
  width: "235px",
};

export default observer(Slider);
