import React, { FC, useCallback, useEffect, useState } from "react";
import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";
import { Box, Button } from "@mui/material";
import FileDownloadIcon from "@mui/icons-material/FileDownload";

import { GraphicId } from "stores/mapStore/types";
import { MeasurementType } from "stores/measurementStore/types";
import { useRootStore } from "hooks/useRootStore";

import CloseIconButton from "common/CloseIconButton";

import MeasurementsItemsList from "./MeasurementList";
import MeasurementsSectionsList from "./MeasurementsSectionsList";
import { Id } from "./types";
import { useTranslation } from "react-i18next";
import { LoadingButton } from "@mui/lab";
import { LOCALES } from "./locales";
import { useFetchWithLoading } from "hooks";

const Measurements: FC<Id> = ({ id }) => {
  const {
    jobStore: { job },
    mapStore,
    measurementStore: { exportMeasurements },
  } = useRootStore();
  const [exportMode, setExportMode] = useState(false);
  const { t } = useTranslation();
  const [measurementType, setMeasurementType] = useState<MeasurementType | null>(null);
  const [measurementsForExport, setMeasurementsForExport] = useState<string[]>([]);
  const [invokeExportMeasurements, isLoading] = useFetchWithLoading(exportMeasurements);

  const handleSelectMeasurementForExport = (id: string) => {
    setMeasurementsForExport(prev => {
      if (prev.includes(id)) {
        return prev.filter(i => i !== id);
      } else return [...prev, id];
    });
  };

  const zoomToCurrentJob = useCallback(async () => {
    if (!job?.id) {
      return;
    }

    const currentJobGraphic = mapStore.utils.getGraphicByItemId(job.id);
    currentJobGraphic && (await mapStore.utils.zoomToGeometry(currentJobGraphic.geometry));
  }, [job?.id, mapStore]);

  const handleBack = useCallback(async () => {
    setMeasurementType(null);
    mapStore.utils.removeGraphicsByGraphicType(GraphicId.measurement);

    if (measurementType) {
      await zoomToCurrentJob();
    }
  }, [measurementType, zoomToCurrentJob]);

  async function handleDownloadMeasurements() {
    await invokeExportMeasurements(job!.id, measurementsForExport);
    setMeasurementsForExport([]);
    setExportMode(false);
  }

  useEffect(() => {
    const close = () => {
      handleBack();
    };

    return close;
    // it should be called only after unmounting the component
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box>
      {measurementType ? (
        <>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              mb: 1,
            }}>
            <Box>
              <Button
                sx={{
                  backgroundColor: exportMode ? "secondary.light" : "secondary.main",
                }}
                onClick={() => setExportMode(prev => !prev)}>
                {t(exportMode ? LOCALES.CANCEL : LOCALES.EXPORT)}
              </Button>
              {exportMode && (
                <LoadingButton
                  startIcon={<FileDownloadIcon />}
                  onClick={handleDownloadMeasurements}
                  disabled={!measurementsForExport.length}
                  loading={isLoading}>
                  {t(isLoading ? LOCALES.PREPARING : LOCALES.DOWNLOAD)}
                </LoadingButton>
              )}
            </Box>
            <CloseIconButton onClick={handleBack} icon={<ArrowBackRoundedIcon />} />
          </Box>
          <MeasurementsItemsList
            handleSelectMeasurementForExport={handleSelectMeasurementForExport}
            exportMode={exportMode}
            measurementsForExport={measurementsForExport}
            selectedMeasurementType={measurementType}
            id={id}
          />
        </>
      ) : (
        <MeasurementsSectionsList setMeasurementType={setMeasurementType} />
      )}
    </Box>
  );
};

export default Measurements;
