import React, { FC } from "react";
import ArrowForwardIosRoundedIcon from "@mui/icons-material/ArrowForwardIosRounded";
import { Box, Button, Theme } from "@mui/material";
import { SxProps } from "@mui/material/styles";
import { ExpandProps } from "types";

import { SIDE_BAR_DISPLAY, SIDE_BAR_WIDTH } from "constants/styles";

interface ExpandButtonProps {
  disabled?: boolean;
}

const ExpandButton: FC<ExpandButtonProps & ExpandProps> = ({ open, onOpen, onClose, disabled }) => {
  return (
    <Box sx={open || disabled ? openContainer : expandButtonContainer}>
      <Button disabled={disabled} sx={buttonStyles} onClick={open ? onClose : onOpen}>
        <ArrowForwardIosRoundedIcon sx={{ transform: `rotate(${open ? 180 : 0}deg)` }} />
      </Button>
    </Box>
  );
};

const expandButtonContainer: SxProps<Theme> = theme => ({
  zIndex: 20,
  display: SIDE_BAR_DISPLAY,
  overflow: "visible",
  position: "relative",
  height: "100vh",
  width: 0,
  minWidth: 0,
  transition: theme.transitions.create(["min-width", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
});

const openContainer: SxProps<Theme> = theme => ({
  display: SIDE_BAR_DISPLAY,
  zIndex: 20,
  overflow: "visible",
  position: "relative",
  width: SIDE_BAR_WIDTH,
  minWidth: SIDE_BAR_WIDTH,
  transition: theme.transitions.create(["min-width", "width"], {
    easing: theme.transitions.easing.easeOut,
    duration: theme.transitions.duration.enteringScreen,
  }),
});

const buttonStyles: SxProps<Theme> = theme => ({
  zIndex: 11,
  pl: 5,
  pr: 0.5,
  width: 20,
  height: 70,
  bgcolor: "background.default",
  position: "absolute",
  top: "50%",
  right: -20,
  color: "primary.contrastText",
  borderRadius: 2,
  transition: theme.transitions.create("right", { duration: ".4s" }),
  "&:hover": {
    right: -30,
    color: "primary.main",
    bgcolor: "background.default",
  },
});

export default ExpandButton;
