import { generatePath } from "react-router-dom";
import { makeAutoObservable } from "mobx";
import { BreadcrumbSteps, SortParams } from "types";

import { processings } from "stores/managerService/processingStore/service";
import { Member, TeamRole } from "stores/managerService/teamStore/types";
import team from "stores/managerService/teamStore/service";
import RootStore from "stores/rootStore";

import { LOCALES } from "constants/locales";
import { sortByParameter } from "helpers/functions";

import { GetTeamBy } from "../teamStore/types";

import {
  ICategories,
  IParameters,
  IParamsForInitiateProcessing,
  IProcessing,
  IScripts,
  IUpdatedParams,
  ParameterType,
  Processings,
  ProcessingsForViewer,
  ProcessingStatus,
  ViewerMode,
} from "./types";
import { Job } from "../jobStore/types";
import { pix4dBaseUrl, protocol } from "configs/servicesConfig";

const autoStartProcessing = "autoStartProcessing";

export class ProcessingStore {
  constructor(private readonly rootStore: RootStore) {
    makeAutoObservable(this);
  }

  processings = [] as IProcessing[];
  filteredProcessings = [] as IProcessing[];
  processing: IProcessing | null = null;
  createMode = false;
  editableProcessing: IProcessing | null = null;
  deleteId: string = "";
  categories: null | ICategories[] = null;
  scripts: null | IScripts[] = null;
  script: string | null = null;
  scriptId = 0;
  parameters: IParameters[] | null = null;
  updatedParams = [] as IUpdatedParams[];
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  paramsForInitiateProcessing = {} as IParamsForInitiateProcessing;
  vipointProcessingLaunched: boolean = false;
  processingsForViewer = [] as ProcessingsForViewer[];
  sortParameterForProcessings: SortParams =
    (localStorage.getItem(BreadcrumbSteps.jobCard) as SortParams) || SortParams.dateNew;

  autoStartProcessing: boolean =
    localStorage.getItem(autoStartProcessing) !== "undefined"
      ? JSON.parse(localStorage.getItem(autoStartProcessing)!)
      : false;

  currentView = 0;

  public readonly setCurrentView = (index: number) => {
    this.currentView = index;
  };

  public readonly setAutoStartProcessing = () => {
    this.autoStartProcessing = !this.autoStartProcessing;
    localStorage.setItem(autoStartProcessing, JSON.stringify(this.autoStartProcessing));
  };

  public readonly setSortParameterForProcessings = (parameter: SortParams) => {
    this.sortParameterForProcessings = parameter;
    localStorage.setItem(BreadcrumbSteps.jobCard, parameter);
    this._setProcessings(sortByParameter(this.sortParameterForProcessings, this.processings) as IProcessing[]);
  };

  public readonly setScript = (value: string | null) => {
    this.script = value;
  };

  public readonly _setProcessings = (processings: IProcessing[]) => {
    this.processings = processings;
    this.setFilteredProcessings(processings);
  };

  public readonly _setProcessing = (processing: IProcessing | null): void => {
    this.processing = processing;
  };

  public readonly setCreateMode = (createMode: boolean) => {
    this.createMode = createMode;
  };

  public readonly setEditableProcessing = (editableProcessing: IProcessing | null) => {
    this.editableProcessing = editableProcessing;
  };

  public readonly setDeleteId = (deleteId: string) => {
    this.deleteId = deleteId;
  };

  public readonly setViewerOpen = async (viewerOpen: boolean, item?: Job | IProcessing) => {
    if (viewerOpen) {
      await this.setProcessingForViewer(item!);
    } else {
      this.processingsForViewer = [];
      this.setCurrentView(0);
    }
  };

  public readonly setProcessingForViewer = async (item: Job | IProcessing) => {
    const members = await team.getAll(GetTeamBy.ACL, item.acl);
    const { job } = this.rootStore.jobStore;

    const isSrcExist = this.processingsForViewer.some(src => src.id === item.id);
    const setCurrentView = () => {
      this.setCurrentView(this.processingsForViewer.findIndex(src => src.id === item.id));
    };
    members.forEach((member: Member) => {
      if (member.userId === this.rootStore.authStore.userName) {
        const isTeamManagement =
          member.role === TeamRole.OWNER || member.role === TeamRole.ADMIN || member.role === TeamRole.COORDINATOR;

        if (ViewerMode.projectId in item) {
          const token = isTeamManagement ? item.tokenWrite : item.tokenRead;
          if (isSrcExist) {
            setCurrentView();
          } else {
            this.processingsForViewer?.push({
              title: item.title,
              url: `${protocol}//${pix4dBaseUrl}/dataset/${item.projectId}/?shareToken=${token}`,
              id: item.title,
            });
          }
        } else {
          const token = isTeamManagement ? job!.tokenWrite : job!.tokenRead;

          if (isSrcExist) {
            setCurrentView();
          } else {
            this.processingsForViewer?.push({
              title: item.title,
              url: `${protocol}//${pix4dBaseUrl}/site/${job!.projectGroupId}/dataset/${
                this.processings[0]!.projectId
              }/?shareToken=${token}`,
              id: item.title,
            });
          }
        }
      }
    });
  };

  public readonly removeProcessingFromViewer = (id: string) => {
    this.processingsForViewer = this.processingsForViewer.filter(src => src.id !== id);
    const index = this.currentView > 0 ? this.currentView - 1 : 0;
    this.setCurrentView(index);
  };

  public readonly setVipointProcessingStatus = (value: boolean) => {
    this.vipointProcessingLaunched = value;
  };

  public readonly resetProcessingParams = () => {
    this.parameters = null;
  };

  public readonly getAllProcessings = async (jobId: string) => {
    const response = await processings.getAll(jobId);
    response.forEach((processing: IProcessing) => {
      if (processing.config) {
        processing.config = JSON.parse(processing.config);
      }
    });
    this._setProcessings(sortByParameter(this.sortParameterForProcessings, response) as IProcessing[]);
  };

  public readonly filterProcessings = (value: string) => {
    this.setFilteredProcessings(
      this.processings.filter(processing => processing.title.toLowerCase().includes(value.toLowerCase()))
    );
  };

  public readonly setFilteredProcessings = (value: IProcessing[]) => {
    this.filteredProcessings = value;
  };

  public readonly getProcessing = async (id: string) => {
    const response = await processings.get(id);
    this._setProcessing(response);
  };

  public readonly create = async (title: string, type: string, jobId: string) => {
    await processings.create(title, type, jobId);
    await this.getAllProcessings(jobId);
  };

  public readonly updateTitle = async (id: string, title: string, jobId: string) => {
    await processings.update(id, "title", title);
    await this.getAllProcessings(jobId);
    this.setEditableProcessing(null);
  };

  public readonly updateProcessingData = async (id: string, targetSize: number, template: string, jobId: string) => {
    const params = () => {
      if (targetSize) {
        return JSON.stringify({ template, targetSize });
      } else {
        return JSON.stringify({ template });
      }
    };
    const response = await processings.update(id, "config", params());
    response.payload.config = JSON.parse(response.payload.config);
    this._setProcessing(response.payload);
    await this.getAllProcessings(jobId);
  };

  public readonly delete = async (id: string, jobId: string) => {
    await processings.delete(id);
    await this.getAllProcessings(jobId);
  };

  public readonly resetStore = () => {
    this._setProcessing(null);
    this.setEditableProcessing(null);
    // this.setViewerOpen({ viewerOpen: false });
    this.setCreateMode(false);
    this.setDeleteId("");
  };

  public readonly getCategories = async () => {
    this.categories = await processings.getCategories();
    this.vipointProcessingLaunched = false;
  };

  public readonly getScripts = async (categoryId: string) => {
    this.scripts = await processings.getScripts(categoryId);
  };

  public readonly setParamsForInitiateVipointProcessing = () => {
    this.updatedParams.forEach(e => delete e.defaultValue);
    this.paramsForInitiateProcessing = {
      scriptId: this.scriptId,
      inputFile: this.rootStore.fileStore.pointCloudFileOfProcessing,
      parameters: this.updatedParams,
    };
  };

  public readonly getParameters = async (scriptId: number) => {
    this.vipointProcessingLaunched = false;
    this.scriptId = scriptId;
    const parameters = await processings.getParameters(scriptId);
    this.parameters = parameters.filter(parameter => parameter.isVisible === 1);
    this.updatedParams = [];
    for (const parameter of parameters) {
      this.updatedParams.push({
        selected: false,
        parameterId: parameter.parameterId,
        scriptParametersId: parameter.scriptParametersId,
        value: parameter.defaultValue ? parameter.defaultValue : 0,
        isBoolean: parameter.parameterType === "boolean",
        parameterType: parameter.parameterType,
        defaultValue: parameter.defaultValue,
        aliasBackend: parameter.aliasBackend,
      });
    }
  };

  public readonly setUpdatedParams = (
    id: number,
    value: number | string | boolean | string[],
    parameterType?: string,
    selected?: string[]
  ) => {
    console.log("id", id, "value", value, "parameterType", parameterType, "selected", selected);
    selected &&
      this.updatedParams.forEach(item => {
        if (selected.includes(item.parameterId.toString())) {
          if (parameterType === ParameterType.singleSelect) {
            item.selected = true;
            item.value = 0;
          }
        }
      });
    this.updatedParams.forEach(item => {
      if (item.parameterId === id) {
        item.value = value === true ? 1 : value === false ? 0 : value || item.defaultValue!;
        item.selected = true;
      }
    });

    // this.updatedParams.forEach(e => console.log({ ...e }));
  };

  public readonly initiateProcessing = async (processingId: string) => {
    const currentProcessing = this.processing ?? (await processings.get(processingId));
    const onlyRegisterFiles =
      currentProcessing?.type === Processings.PIX4D_VIEW ||
      currentProcessing?.status === ProcessingStatus.PROCESSING_SUCCESS;

    const shouldLaunchProcessing = () => {
      if (currentProcessing.type !== Processings.VIPOINT && this.autoStartProcessing) {
        return true;
      }

      if (
        (currentProcessing?.status !== ProcessingStatus.CREATE_IN_PROGRESS &&
          currentProcessing?.status !== ProcessingStatus.CREATE_SUCCESS) ||
        currentProcessing.type === Processings.PIX4D_VIEW
      ) {
        return true;
      }

      return false;
    };

    if (shouldLaunchProcessing()) {
      const response = await this.launchProcessing(processingId, onlyRegisterFiles);
      const index = this.processings.findIndex(processing => processing.id === response.payload.id);

      this.processings[index] = response.payload;
      if (this.rootStore.processingStore.processings[index].type === Processings.PIX4D_VIEW) {
        await this.rootStore.fileStore.getOutputs(this.processings[index].id, this.processings[index].acl);
      }
    }
  };

  public readonly launchProcessing = async (processingId: string, onlyRegisterFiles?: boolean) => {
    const response = await processings.launch(processingId, onlyRegisterFiles);
    const payloadProcessing = response.payload;
    this.rootStore.uiStore.setSnackbarOpen(
      LOCALES.PROCESSINGS.PROCESSING_IS_LAUNCHED({ processingTitle: payloadProcessing.title }),
      "success"
    );

    if (this.rootStore.jobStore.isCurrentJobIs(payloadProcessing.job)) {
      this._setProcessing(response.payload);
    }

    return response;
  };

  initialViPointProcessing = async (id: string): Promise<void> => {
    this.setParamsForInitiateVipointProcessing();

    const response = await processings.update(id, "config", JSON.stringify(this.paramsForInitiateProcessing));
    if (response.status === "ok") {
      const response = await this.launchProcessing(id);
      if (response.status === "ok") {
        this.setVipointProcessingStatus(true);
      }
      console.log("viPoint launched", response.status);
      this.resetProcessingParams();
    }
  };

  public readonly generateShareProcessingResultsLink = (processingProjectId: number, shareToken: string) => {
    const pix4dUrl = process.env.REACT_APP_PIX4D_CLOUD;
    return generatePath(`${pix4dUrl}/dataset/${processingProjectId}/map?shareToken=${shareToken}`);
  };
}
