import { useRootStore } from "hooks/useRootStore";;
import React, { FC, useCallback,useState } from "react";
import Cropper, { Area } from "react-easy-crop";
import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Slider from "@mui/material/Slider";
import Typography from "@mui/material/Typography";



const AvatarCropPopup: FC = () => {
  const { t } = useTranslation();
  const { settingsStore } = useRootStore();

  const { imageSrc } = settingsStore;

  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [rotation, setRotation] = useState(0);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<Area>({
    x: 0,
    y: 0,
    width: 0,
    height: 0,
  });
  const [inProgress, setInProgress] = useState(false);

  const onCropComplete = useCallback((croppedArea: Area, croppedAreaPixels: Area) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const createImage = async (url: string) =>
    await new Promise((resolve, reject) => {
      const image = new Image();
      image.addEventListener("load", () => resolve(image));
      image.addEventListener("error", error => reject(error));
      image.src = url;
    });

  const getRadianAngle = (degreeValue: number) => {
    return (degreeValue * Math.PI) / 180;
  };

  const rotateSize = (width: number, height: number, rotation: number) => {
    const rotRad = getRadianAngle(rotation);

    return {
      width: Math.abs(Math.cos(rotRad) * width) + Math.abs(Math.sin(rotRad) * height),
      height: Math.abs(Math.sin(rotRad) * width) + Math.abs(Math.cos(rotRad) * height),
    };
  };

  const getCroppedImg = async (
    imageSrc: string,
    pixelCrop: { x: number; y: number; width: number; height: number },
    rotation = 0,
    flip = { horizontal: false, vertical: false }
  ) => {
    const image = (await createImage(imageSrc)) as HTMLImageElement;
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");

    if (!ctx) {
      return null;
    }

    const rotRad = getRadianAngle(rotation);

    // calculate bounding box of the rotated image
    const { width: bBoxWidth, height: bBoxHeight } = rotateSize(image.width, image.height, rotation);

    // set canvas size to match the bounding box
    canvas.width = bBoxWidth;
    canvas.height = bBoxHeight;

    // translate canvas context to a central location to allow rotating and flipping around the center
    ctx.translate(bBoxWidth / 2, bBoxHeight / 2);
    ctx.rotate(rotRad);
    ctx.scale(flip.horizontal ? -1 : 1, flip.vertical ? -1 : 1);
    ctx.translate(-image.width / 2, -image.height / 2);

    // draw rotated image
    ctx.drawImage(image, 0, 0);

    // croppedAreaPixels values are bounding box relative
    // extract the cropped image using these values
    const data = ctx.getImageData(pixelCrop.x, pixelCrop.y, pixelCrop.width, pixelCrop.height);

    // set canvas width to final desired crop size - this will clear existing context
    canvas.width = pixelCrop.width;
    canvas.height = pixelCrop.height;

    // paste generated rotate image at the top left corner
    ctx.putImageData(data, 0, 0);

    // As a blob
    return await new Promise((resolve, reject) => {
      canvas.toBlob(file => {
        resolve(URL.createObjectURL(file!));
      }, "image/jpeg");
    });
  };

  const uploadCroppedImage = useCallback(async () => {
    setInProgress(true);
    try {
      const croppedImage = await getCroppedImg(imageSrc, croppedAreaPixels, rotation);
      await settingsStore.uploadAvatar(croppedImage as string);
    } catch (e) {
      console.error(e);
      setInProgress(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [croppedAreaPixels, rotation]);

  return (
    <>
      <Typography id="transition-modal-title" variant="h6" component="h2">
        {t("Settings:EditPhoto")}
      </Typography>
      <Box sx={cropperContainerStyles}>
        <Cropper
          image={imageSrc}
          crop={crop}
          zoom={zoom}
          rotation={rotation}
          cropShape="round"
          showGrid={false}
          aspect={1}
          onCropComplete={onCropComplete}
          onCropChange={setCrop}
          onZoomChange={setZoom}
          onRotationChange={setRotation}
        />
      </Box>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Typography variant="overline">{t("Settings:Zoom")}</Typography>
        <Slider
          sx={sliderStyles}
          value={zoom}
          min={1}
          max={3}
          step={0.1}
          aria-labelledby="Zoom"
          onChange={(e, zoom) => setZoom(zoom as number)}
          classes={{ root: "slider" }}
        />
        <Typography variant="overline">{t("Settings:Rotation")}</Typography>
        <Slider
          sx={sliderStyles}
          value={rotation}
          min={0}
          max={360}
          step={1}
          aria-labelledby="Rotation"
          onChange={(e, rotation) => setRotation(rotation as number)}
        />
      </Box>
      <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
        <Button onClick={uploadCroppedImage} disabled={inProgress} variant="contained" sx={{ mt: 1 }}>
          {t("General:Upload")}
        </Button>
      </Box>
    </>
  );
};

const cropperContainerStyles = {
  position: "relative",
  height: 300,
  width: "100%",
  my: 2,
} as const;

const sliderStyles = {
  mx: 2,
  color: "text.primary",
  "& .MuiSlider-thumb:hover": {
    boxShadow: "0px 0px 0px 8px rgb(239 239 239 / 16%)",
  },
  "& .MuiSlider-thumb.Mui-active": {
    boxShadow: "0px 0px 0px 14px rgb(239 239 239 / 16%)",
  },
  "&:last-of-type": {
    mr: 0,
  },
};

export default AvatarCropPopup;
