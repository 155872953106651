import axios from "axios";

import { baseUrl, protocol } from "../../configs/servicesConfig";

interface IAvatarResponse {
  data: { payload: { avatarMini: string; avatarProfileUrl: string } };
}

const userService = {
  get: async (ids: string[]) => {
    const idsToString = ids.join("&username=");
    const response = await axios.get<{ payload: [] }>(
      `${protocol}//${baseUrl}/userService/users?username=${idsToString}`
    );
    return response.data.payload;
  },

  checkIfEmailExists: async (email: string) => {
    const response = await axios.get<{ payload: [{ username: string }] }>(
      `${protocol}//${baseUrl}/userService/users?email=${email}`
    );
    return response.data.payload;
  },

  createUser: async (email: string) => {
    const response = await axios.post(`${protocol}//${baseUrl}/userService/users`, { email });
    return response as {
      data: { payload?: { username: string; email: string } };
    };
  },

  getAvatarAsFile: async (blobUrl: string) => {
    const response = await axios.get<Blob>(blobUrl, { responseType: "blob" });
    return new File([response.data], "Avatar", { type: response.data.type });
  },

  uploadAvatar: async (username: string, avatar: File) => {
    const config = { headers: {} } as {
      headers: { "Content-Type": string };
    };
    config.headers["Content-Type"] = avatar.type;
    const response = await axios.put<File, IAvatarResponse>(
      `${protocol}//${baseUrl}/userService/users/${username}/avatar`,
      avatar,
      config
    );
    return response.data.payload;
  },
};

export default userService;
