import axios, { AxiosResponse } from "axios";
import { ApiResponse, ResponseStatus } from "types";

import { baseUrl, protocol, Service } from "configs/servicesConfig";

import { ExportDxfFileResponse, Measurement, MeasurementPoint } from "./types";

const { MEASUREMENT, DXF_SERVICE } = Service;

export const measurementService = {
  getAllMeasurements: async (foreignId: string): Promise<Measurement[] | null> => {
    const { data } = await axios.get<ApiResponse<{ data: Measurement[] }>>(
      `${protocol}//${baseUrl}/${MEASUREMENT}/measurements/${foreignId}`
    );

    if (data.status === ResponseStatus.ERROR) {
      return null;
    }

    return data.payload.data;
  },

  getOneMeasurement: async (foreignId: string, id: string): Promise<Measurement | null> => {
    const { data } = await axios.get<ApiResponse<Measurement>>(
      `${protocol}//${baseUrl}/${MEASUREMENT}/measurements/${foreignId}/${id}`
    );

    if (data.status === ResponseStatus.ERROR) {
      return null;
    }

    return data.payload;
  },

  createPoint: async (points: Omit<MeasurementPoint, "createdAt" | "updatedAt">[]) => {
    console.log("points", points);
    const response = await axios.post(`${protocol}//${baseUrl}/${MEASUREMENT}/points`, points);
    return response.data;
  },

  getAllPoints: async (measurementId: string): Promise<MeasurementPoint[] | null> => {
    const { data } = await axios.get<ApiResponse<{ data: MeasurementPoint[] }>>(
      `${protocol}//${baseUrl}/${MEASUREMENT}/points/${measurementId}`
    );
    if (data.status === ResponseStatus.ERROR) {
      return null;
    }

    return data.payload.data;
  },

  getOnePoint: async (measurementId: string, pointId: string): Promise<MeasurementPoint | null> => {
    const { data } = await axios.get<ApiResponse<MeasurementPoint>>(
      `${protocol}//${baseUrl}/${MEASUREMENT}/points/${measurementId}/${pointId}`
    );

    if (data.status === ResponseStatus.ERROR) {
      return null;
    }

    return data.payload;
  },

  exportMeasurements: async (jobId: string, measurements: string[]) => {
    const { data } = await axios.post<ApiResponse<ExportDxfFileResponse>>(
      `${protocol}//${baseUrl}/${DXF_SERVICE}/export`,
      { jobId, measurements }
    );
    if (data.status === ResponseStatus.ERROR) {
      return null;
    }
    return data.payload;
  },
};
