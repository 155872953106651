import React, { FC } from "react";
import { Stack, SxProps, Theme, Typography } from "@mui/material";
import { format as dformat } from "date-fns";

import { IFile } from "stores/fileStore/types";

import { HH_MM_DATE_MASK } from "constants/dateTime";
import { formatBytes } from "helpers/functions";
import { useFormatDate } from "hooks/useFormatDate";

interface AttachmentsItemInfoProps {
  file: IFile;
}

const ADDITIONAL_TEXT_COLOR = "#ADADAD";

const AttachmentsItemInfo: FC<AttachmentsItemInfoProps> = ({ file }) => {
  const format = useFormatDate(dformat);

  return (
    <>
      <Stack spacing={0.5} sx={{ ml: 0.5, mr: 1 }}>
        <Typography component="div" variant="body1" sx={fileNameStyles} fontWeight={300}>
          {file.fileMeta.name}
        </Typography>
        <Typography variant="caption" fontWeight={300} sx={{ color: ADDITIONAL_TEXT_COLOR, ml: 0.5 }}>
          {format(new Date(file.createdAt), HH_MM_DATE_MASK)}
        </Typography>
      </Stack>
    </>
  );
};

const fileNameStyles: SxProps<Theme> = {
  width: "100%",
  minWidth: 0,
  overflow: "hidden",
  textOverflow: "ellipsis",
  flex: 1,
  fontSize: "14px",
};

export default AttachmentsItemInfo;
