import { useRootStore } from "hooks/useRootStore";;
import React from "react";
import { observer } from "mobx-react-lite";



import ModalWindow from "./ModalWindow";

const Modals = () => {
  const { uiStore } = useRootStore();
  const { modals } = uiStore;

  return (
    <>
      {modals.map((m, i) => (
        <ModalWindow key={i} {...m} />
      ))}
    </>
  );
};

export default observer(Modals);
