import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ConfirmationDialog: FC<{
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setConfirmed: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({ open, setOpen, setConfirmed }) => {
  const { t } = useTranslation();

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = () => {
    setConfirmed(true);
    setOpen(false);
  };

  return (
    <Dialog open={open} TransitionComponent={Transition} onClose={handleClose}>
      <DialogTitle sx={{ pt: 2.5 }}>{t("General:AreYouSure")}</DialogTitle>
      <DialogContent>
        <DialogContentText color="text">{t("Processings:WithoutDepthData")}</DialogContentText>
      </DialogContent>
      <DialogActions sx={actionsStyles}>
        <Button onClick={handleSubmit} variant="contained">
          {t("General:Confirm")}
        </Button>
        <Button onClick={handleClose} variant="contained" color="secondary">
          {t("General:Cancel")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const actionsStyles = { justifyContent: "flex-start", px: 2.4, pt: 0, pb: 2 };

export default ConfirmationDialog;
