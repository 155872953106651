import React, { FC, useCallback, useContext, useMemo, useState } from "react";
import { SxProps, Theme } from "@mui/material";
import Box from "@mui/material/Box";
import Fade from "@mui/material/Fade";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { observer } from "mobx-react-lite";

import { SymbolStyleType } from "stores/mapStore/types";

import { hoverStyles } from "constants/styles";
import { useRootStore } from "hooks/useRootStore";

import { isOverflowContext } from "./contexts";
import ItemInfo from "./ItemInfo";

interface ListItemProps {
  itemId: string;
  title: string;
  handleSelect: (itemId: string) => Promise<void>;
  isCreateOrEditMode: boolean;
  statusSlot?: React.ReactNode;
  actionButtonsSlot?: React.ReactNode;
  sx?: SxProps<Theme>;
  infoIconSlot?: React.ReactNode;
  infoContent?: React.ReactNode;
}

const ListItem: FC<ListItemProps> = ({
  itemId,
  title,
  handleSelect,
  isCreateOrEditMode,
  statusSlot,
  actionButtonsSlot,
  sx,
  infoIconSlot,
  infoContent,
}) => {
  const { isOverflow } = useContext(isOverflowContext);

  const {
    mapStore: {
      hoveredGraphic,
      utils: { getGraphicByItemId, setSymbolStyle },
    },
  } = useRootStore();

  const [isInfoOpen, setIsInfoOpen] = useState(false);

  const handleHighlightGraphic = useCallback(
    (isHighlight: boolean) => {
      if (isCreateOrEditMode) return;

      const graphic = getGraphicByItemId(itemId);
      const symbolType = isHighlight ? SymbolStyleType.HOVER : SymbolStyleType.UNSELECT;
      graphic && setSymbolStyle(graphic, symbolType);
    },
    [getGraphicByItemId, isCreateOrEditMode, itemId, setSymbolStyle]
  );

  const wrapperStyles = useMemo(
    () => ({ ...elementWrapperStyles(isCreateOrEditMode, isOverflow), ...sx }),
    [isCreateOrEditMode, isOverflow, sx]
  );

  const handleClickInfo = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
    e.preventDefault();
    setIsInfoOpen(o => !o);
  };

  return (
    <Fade in>
      <Paper
        sx={wrapperStyles}
        onMouseOver={() => handleHighlightGraphic(true)}
        onMouseOut={() => handleHighlightGraphic(false)}>
        <Box
          sx={isCreateOrEditMode ? { display: "none" } : hoverStyles(itemId === hoveredGraphic?.attributes.itemId)}
          onClick={async () => await handleSelect(itemId)}
        />
        <Typography
          variant="body1"
          component="div"
          sx={isCreateOrEditMode ? { color: "text.disabled", pr: 1 } : { color: "text.primary", pr: 1 }}>
          {title}
        </Typography>
        <Box sx={slotsContainerStyles}>
          {infoContent && (
            <ItemInfo
              id={itemId}
              open={isInfoOpen}
              setOpen={setIsInfoOpen}
              infoContent={infoContent}
              infoIconSlot={infoIconSlot}
              onClick={handleClickInfo}
            />
          )}
          {statusSlot}
          {actionButtonsSlot}
        </Box>
      </Paper>
    </Fade>
  );
};

const elementWrapperStyles = (isCreateOrEditMode: boolean, isOverflow: boolean) => {
  return {
    mr: isOverflow ? 1 : 0,
    MozMarginEnd: "0",
    my: 0.5,
    p: 1.5,
    minHeight: "70px",
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    wordBreak: "break-all",
    bgcolor: isCreateOrEditMode ? "secondary.main" : "background.paper",
    "& .MuiTypography-root": {
      transition: "color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    },
    "&:first-of-type": {
      mt: 0,
    },
    "&:last-of-type": {
      mb: 0,
    },
  } as const;
};

const slotsContainerStyles: SxProps<Theme> = {
  zIndex: 20,
  display: "flex",
  alignItems: "center",
  gap: "5px",
};

export default observer(ListItem);
