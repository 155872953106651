import React from "react";
import { Box, SxProps, Theme } from "@mui/material";

const BlurBlank = () => {
  return <Box sx={blurBlankStyles} />;
};

const blurBlankStyles: SxProps<Theme> = {
  zIndex: 100,
  position: "absolute",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  backdropFilter: "blur(5px)",
};

export default BlurBlank;
