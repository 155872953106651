import { useRootStore } from "hooks/useRootStore";
import React, { createRef, FC, useEffect, useLayoutEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Paper from "@mui/material/Paper";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import TextField from "@mui/material/TextField";
import { FILE_TYPES } from "stores/fileStore/constants";
import { fileService } from "stores/fileStore/service";
import { processings } from "stores/managerService/processingStore/service";

import { thumbStyles } from "constants/styles";
import { StorageOperationMode, StorageProvider, SubSystem } from "stores/fileStore/types";

const Configuration: FC = () => {
  const { t } = useTranslation();
  const { processingStore, fileStore } = useRootStore();
  const { processing } = processingStore;
  const [loaded, setLoaded] = useState(false);
  const [templates, setTemplates] = useState([]);
  const [template, setTemplate] = useState("");
  const [region, setRegion] = useState("");
  const [bucket, setBucket] = useState("");
  const [targetSize, setTargetSize] = useState("");
  const [inProgress, setInProgress] = useState(false);
  const [isOverflow, setIsOverflow] = useState(false);

  const ref = createRef<HTMLDivElement>();

  useLayoutEffect(() => {
    const node = ref.current;

    if (node && node.clientHeight < node.scrollHeight) {
      setIsOverflow(true);
    } else {
      setIsOverflow(false);
    }
  }, [ref]);

  useEffect(() => {
    getAllTemplates();
  }, []);

  const getAllTemplates = async () => {
    const response = await processings.getTemplates();
    setTemplates(response.templates);
    setRegion(response.region);
    setBucket(response.bucket);
    setLoaded(true);
  };

  const getTemplate = async (e: React.FormEvent) => {
    e.preventDefault();
    setInProgress(true);
    processing && processingStore.updateProcessingData(processing.id, Number(targetSize), template, processing.job);
    // processin
    if (template && processing) {
      const credentials = await fileService.getCredentials(
        SubSystem.VIGRAM,
        StorageProvider.VIGRAM_TEMPLATE,
        processing.id,
        processing.acl,
        StorageOperationMode.GetObject
      );
      const templateFile = await fileService.get(
        region,
        credentials.accessKeyId,
        credentials.secretAccessKey,
        credentials.sessionToken,
        bucket,
        template
      );
      const file = new File([templateFile.Body as Uint8Array], template, {
        type: "application/xml",
      });
      console.log(file);
      fileStore.startUpload({
        files: [file],
        foreignId: processing.id,
        aclId: processing.acl,
        fileType: FILE_TYPES.TEMPLATE,
        projectId: processing.projectId,
      });
    }
  };

  return (
    <>
      {loaded ? (
        <Box component="form" sx={containerStyles} onSubmit={getTemplate}>
          <Box ref={ref} sx={isOverflow ? { ...listWrapperStyles, pr: 1, MozPaddingEnd: "0" } : listWrapperStyles}>
            <FormControl fullWidth>
              <RadioGroup
                defaultValue=""
                name="radio-buttons-group"
                value={template}
                sx={radioGroup}
                onChange={e => {
                  setTemplate((e.target as HTMLInputElement).value);
                }}>
                <Paper elevation={0} sx={templateWrapperStyles}>
                  <FormControlLabel
                    value=""
                    control={<Radio sx={radioBtnStyles} />}
                    label={t("Processings:Standart")}
                  />
                </Paper>
                {templates.map(template => (
                  <Paper elevation={0} sx={templateWrapperStyles} key={template}>
                    <FormControlLabel value={template} control={<Radio sx={radioBtnStyles} />} label={template} />
                  </Paper>
                ))}
              </RadioGroup>
            </FormControl>
          </Box>
          {processing && processing.type.includes("GCP") && (
            <TextField
              id="target-size"
              label="Target size"
              size="small"
              fullWidth
              type="number"
              required
              sx={numberInputStyles}
              value={targetSize}
              onChange={e => setTargetSize(e.target.value)}
              inputProps={{ maxLength: 255, min: "1" }}
            />
          )}
          <Button variant="contained" type="submit" disabled={inProgress} sx={btnStyles}>
            {t("General:Next")}
          </Button>
        </Box>
      ) : (
        <Box sx={loaderWrapperStyles}>
          <CircularProgress size={35} sx={{ color: "text.primary" }} />
        </Box>
      )}
    </>
  );
};

const containerStyles = {
  mt: 1.5,
  height: "calc(100% - 58px)",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
} as const;

const listWrapperStyles = {
  height: "100%",
  flex: 1,
  overflowY: "auto",
  overflowX: "hidden",
  scrollbarColor: "#333 #444",
  scrollbarWidth: "thin",
  "&::-webkit-scrollbar": {
    width: "5px",
  },
  "&::-webkit-scrollbar-track": {
    bgcolor: "secondary.main",
    borderRadius: "5px",
  },
  "&::-webkit-scrollbar-thumb": {
    bgcolor: "background.paper",
    borderRadius: "5px",
  },
} as const;

const numberInputStyles = {
  mt: 1.5,
  bgcolor: "secondary.light",
  "& input::-webkit-outer-spin-button, input::-webkit-inner-spin-button": {
    WebkitAppearance: "none",
  },
  "& input": { p: "7px 14px", MozAppearance: "textfield" },
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: "text.primary",
  },
  "& .MuiInputLabel-root, input": {
    fontFamily: "'futura-pt', Sans-serif",
    fontSize: "18px",
    letterSpacing: "normal",
    color: "text.primary",
    lineHeight: "1.2em",
  },
} as const;

const templateWrapperStyles = {
  bgcolor: "secondary.light",
  borderRadius: "5px",
  px: 1,
  mr: 1,
  "&:last-of-type": {
    mb: 0,
  },
  "& .MuiFormControlLabel-root": { height: "40px" },
  "& .MuiTypography-root": {
    fontFamily: "'futura-pt', Sans-serif",
    fontSize: "18px",
    letterSpacing: "normal",
  },
};

const radioBtnStyles = {
  "&.Mui-checked": { color: "text.primary" },
  "&:hover": {
    bgcolor: "rgba(255, 255, 255, 0.035)",
  },
};

const btnStyles = { mt: 1.5, width: "min-content", alignSelf: "flex-end" };

const loaderWrapperStyles = {
  mt: 1,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  height: "287px",
};

const radioGroup = {
  display: "flex",
  flexFlow: "column",
  gap: 0.5,
  maxHeight: 350,
  ...thumbStyles,
};

export default Configuration;
