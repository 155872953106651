import Graphic from "@arcgis/core/Graphic";
import { makeAutoObservable, runInAction } from "mobx";
import { BreadcrumbSteps, SortParams } from "types";

import { CheckGraphics, ErrorI18KeyAndOptions } from "stores/managerService/helpers/checkGraphics";
import jobs from "stores/managerService/jobStore/service";
import { GraphicId } from "stores/mapStore/types";
import RootStore from "stores/rootStore";

import { LOCALES } from "constants/locales";
import { sortByParameter } from "helpers/functions";

import { Job, JobStatus } from "./types";

const localStorage = window.localStorage;

const CONTAINER_LOCALE_AREA = LOCALES.AREA.AREA;
const CURRENT_LOCALE_JOB = LOCALES.JOB.JOB;

export class JobStore {
  private readonly checkGraphics = new CheckGraphics(this.rootStore, ["polygon", "point"]);

  jobs: Job[] = [];
  filteredJobs: Job[] = [];
  job: Job | null = null;
  createMode = false;
  editMode = false;
  editableJob = "";
  sortParameterForJobs: SortParams =
    (localStorage.getItem(BreadcrumbSteps.areaCard) as SortParams) || SortParams.dateNew;

  constructor(private readonly rootStore: RootStore) {
    makeAutoObservable(this);
  }

  setSortParameterForJobs(parameter: SortParams) {
    this.sortParameterForJobs = parameter;
    localStorage.setItem(BreadcrumbSteps.areaCard, parameter);
    this._setJobs(sortByParameter(this.sortParameterForJobs, this.jobs) as Job[]);
  }

  public readonly _setJobs = (jobs: Job[]) => {
    this.jobs = jobs;
    this.setFilteredJobs(jobs);
  };

  public readonly _setJob = (job: Job | null) => {
    this.job = job;
  };

  setCreateMode(createMode: boolean) {
    this.createMode = createMode;
  }

  setEditMode(editMode: boolean) {
    this.editMode = editMode;
  }

  setEditableJob(editableJob: string) {
    this.editableJob = editableJob;
  }

  startCreatingJob() {
    this.setCreateMode(true);
    this.rootStore.mapStore.initJobGraphic();
  }

  async changeBoundaries(jobId: string, map: string) {
    this.setEditableJob(jobId);

    this.rootStore.mapStore.utils.removeGraphicByItemId(jobId);

    const graphic = JSON.parse(map);
    await this.rootStore.mapStore.addToGraphicsLayer(graphic, jobId);
  }

  public readonly getAllJobs = async (areaId: string) => {
    const response = await jobs.getAll(areaId);
    const withMap = this.rootStore.mapStore.utils.filterExistingMap(response);
    this._setJobs(sortByParameter(this.sortParameterForJobs, withMap) as Job[]);
    this.rootStore.mapStore.initGeometries(withMap, GraphicId.job);
  };

  public readonly filterJobs = (value: string) => {
    this.setFilteredJobs(this.jobs.filter(job => job.title.toLowerCase().includes(value.toLowerCase())));
  };

  setFilteredJobs(value: Job[]) {
    this.filteredJobs = value;
  }

  public readonly getAndSetJob = async (id: string) => {
    const response = await this.getJob(id);
    this._setJob(response);
  };

  public readonly getJob = async (jobId: string): Promise<Job> => {
    return await jobs.getById(jobId);
  };

  async create(title: string, description: string, areaId: string) {
    const graphic = this.rootStore.mapStore.graphicToJSON();
    this.rootStore.mapStore.addAttributes(graphic, { id: GraphicId.job });

    await jobs.create(areaId, title, description, graphic);
    this.setCreateMode(false);
    await this.getAllJobs(areaId);

    this.rootStore.mapStore.utils.clearGraphicLayer();
    this.rootStore.uiStore.closeLastModal();
  }

  async updateGraphic(id: string) {
    const graphic = this.rootStore.mapStore.graphicToJSON();
    const map = JSON.stringify(graphic);
    return await jobs.update(id, "map", map);
  }

  async updateTitle(id: string, title: string) {
    await jobs.update(id, "title", title);
    runInAction(() => {
      this._setJob({ ...this.job!, title });
      this._setJobs(
        this.jobs.map(job => {
          if (job.id === id) return { ...job, title };
          else return job;
        })
      );
    });
  }

  async updateDescription(id: string, description: string) {
    await jobs.update(id, "description", description);
  }

  async delete(id: string, areaId: string, comment: string) {
    await jobs.delete(id, comment);
    this.rootStore.uiStore.closeLastModal();
    await this.getAllJobs(areaId);
  }

  checkJobGraphics = (areaId: string, jobId?: string): ErrorI18KeyAndOptions | undefined => {
    const selectedGeometry = this.rootStore.mapStore.selectedGraphic?.geometry;

    const containerGeometry = this.rootStore.mapStore.utils.getGraphicByItemId(areaId)?.geometry;
    const insideGeometry = jobId
      ? this.rootStore.mapStore.utils.getGraphicByItemId(jobId)?.geometry ?? selectedGeometry
      : selectedGeometry;

    return this.checkGraphics.checkGraphicsAndGetErrorMessageAndOptions(
      {
        itemLocaleName: CURRENT_LOCALE_JOB,
        containerLocaleName: CONTAINER_LOCALE_AREA,
      },
      {
        containerGeometry,
        insideGeometry,
      }
    );
  };

  public isCurrentJobIs = (jobId: string): boolean => {
    return this.job?.id === jobId;
  };

  private getCurrentAreaGraphic(areaId: string): Graphic | undefined {
    return this.rootStore.mapStore._view?.graphics
      .filter(({ attributes }) => attributes?.itemId === areaId)
      .getItemAt(0);
  }

  private getCurrentJobGraphic(): Graphic | undefined {
    return this.rootStore.mapStore._graphicsLayer.graphics.getItemAt(0);
  }
}
