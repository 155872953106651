import Graphic from "@arcgis/core/Graphic";
import { makeAutoObservable } from "mobx";

import { IFile } from "stores/fileStore/types";
import { selectedStylesSymbols } from "stores/mapStore/constants";
import { GraphicId, Position } from "stores/mapStore/types";
import { measurementService } from "stores/measurementStore/service";
import RootStore from "stores/rootStore";

import { FromPointsToGraphic, ImageInfo, Measurement, MeasurementPoint, MeasurementType } from "./types";

// eslint-disable-next-line @typescript-eslint/no-var-requires
const piexif = require("piexifjs");

const myStorage = window.localStorage;

export class MeasurementStore {
  imageInfo: ImageInfo[] | null = null;
  existingPoints: string[] | null = null;
  pointsIsFetching: string | boolean = false;

  measurements: Measurement[] | null = null;
  exportMode = false;

  constructor(private readonly rootStore: RootStore) {
    makeAutoObservable(this);
  }

  setImageInfo = (value: null | ImageInfo[], foreignId: string): void => {
    this.imageInfo = value;
    myStorage.setItem(foreignId, JSON.stringify(value));
  };

  setPointsIsFetching = (value: string | boolean): void => {
    this.pointsIsFetching = value;
  };

  public setMeasurements = (measurements: Measurement[] | null): void => {
    this.measurements = measurements;
  };

  public createPoint = (files: File[], file: IFile[], acl: string) => {
    const points: Omit<MeasurementPoint, "createdAt" | "updatedAt">[] = [];

    const promises: Promise<void>[] = files.map((item, index) => {
      return new Promise<void>((resolve, reject) => {
        if (item.type !== "image/jpeg") {
          resolve();
          return;
        }

        const reader = new FileReader();
        reader.onload = async function (e) {
          try {
            const exifObj = piexif.load(e.target!.result as string);
            const gpsInfo = exifObj.GPS;
            if (!gpsInfo) {
              resolve();
              return;
            }

            const latitude = piexif.GPSHelper.dmsRationalToDeg(
              gpsInfo[piexif.GPSIFD.GPSLatitude],
              gpsInfo[piexif.GPSIFD.GPSLatitudeRef]
            );
            const longitude = piexif.GPSHelper.dmsRationalToDeg(
              gpsInfo[piexif.GPSIFD.GPSLongitude],
              gpsInfo[piexif.GPSIFD.GPSLongitudeRef]
            );

            const alt = gpsInfo[piexif.GPSIFD.GPSAltitude];
            const altRef = gpsInfo[piexif.GPSIFD.GPSAltitudeRef];
            let altitude = alt[0] / alt[1];
            if (altRef === 1) {
              altitude *= -1;
            }

            points.push({
              title: item.name,
              foreignId: file[index].foreignId,
              id: file[index].id,
              acl,
              latitude,
              longitude,
              altitude,
              order: 1,
              accuracyXY: 9,
              accuracyZ: 8,
            });
            resolve();
          } catch (error) {
            console.error("Error processing the image:", error);
            resolve();
          }
        };
        reader.onerror = errorEvent => {
          console.error("FileReader error:", errorEvent);
          resolve();
        };
        reader.readAsDataURL(item);
      });
    });

    Promise.all(promises)
      .then(async () => {
        if (points.length > 0) {
          await measurementService.createPoint(points);
        }
      })
      .catch(error => {
        console.error("Error processing images:", error);
      });
  };

  removeImgCoords = () => {
    this.imageInfo = null;
  };

  public getAllMeasurements = async (foreignId: string): Promise<void> => {
    const measurements = await measurementService.getAllMeasurements(foreignId);
    this.setMeasurements(measurements);
  };

  public getAllPoints = async (measurementId: string): Promise<MeasurementPoint[] | null> => {
    return await measurementService.getAllPoints(measurementId);
  };

  public getOnePoint = async (measurementId: string, pointId: string): Promise<MeasurementPoint | null> => {
    return await measurementService.getOnePoint(measurementId, pointId);
  };

  public async getImagePoints(foreignId: string, id?: string): Promise<ImageInfo[] | null> {
    const cachedPoints = myStorage.getItem(foreignId);
    if (cachedPoints) {
      this.imageInfo = JSON.parse(cachedPoints);
      this.setPointsIsFetching(false);
      return JSON.parse(cachedPoints);
    }
    this.setPointsIsFetching(id ?? foreignId);
    const response = await measurementService.getAllPoints(this.rootStore.processingStore.processing!.id);
    this.setImageInfo(response, foreignId);
    this.setPointsIsFetching(false);

    return response;
  }

  public getMeasurementGraphicByType = (type: MeasurementType, points: MeasurementPoint[]): Graphic => {
    const graphic = this.getPointsGraphicByTypeFunctions[type](points);
    this.rootStore.mapStore.addAttributes(graphic, { id: GraphicId.measurement });
    return graphic;
  };

  private readonly getPointsGraphicByTypeFunctions: Record<MeasurementType, FromPointsToGraphic> = {
    SINGLE_POINT: points => {
      const point = points[0];
      const geometry = this.rootStore.mapStore.getPoint([point.longitude, point.latitude, point.altitude]);
      return new Graphic({ geometry, symbol: selectedStylesSymbols["simple-marker"].select });
    },
    LINE: points => {
      const paths = this.getSortedPositions(points);
      const geometry = this.rootStore.mapStore.getPolyline([paths]);
      return new Graphic({ geometry, symbol: selectedStylesSymbols["simple-fill"].select });
    },
    POLYGON: points => {
      const rings = this.getSortedPositions(points);
      const geometry = this.rootStore.mapStore.getPolygon([rings]);
      return new Graphic({ geometry, symbol: selectedStylesSymbols["simple-fill"].select });
    },
  };

  private readonly getSortedPositions = (points: MeasurementPoint[]): Position[] =>
    points
      .sort((a, b) => {
        if (typeof a.order !== "number" || typeof b.order !== "number") {
          return 0;
        }

        return a.order - b.order;
      })
      .map<Position>(({ longitude, latitude, altitude }) => [longitude, latitude, altitude]);

  public readonly exportMeasurements = async (jobId: string, measurementIds: string[]) => {
    const response = await measurementService.exportMeasurements(jobId, measurementIds);
    if (response?.url) await this.rootStore.fileStore.downloadFileFromURL(response.url);
  };
}
