import { SxProps, Theme } from "@mui/material";

export const thumbStyles = {
  overflowY: "auto",
  scrollbarColor: "#666 #333",
  scrollbarWidth: "thin",
  "&::-webkit-scrollbar": {
    width: "5px",
  },
  "&::-webkit-scrollbar-track": {
    bgcolor: "secondary.main",
    borderRadius: "5px",
  },
  "&::-webkit-scrollbar-thumb": {
    bgcolor: "background.paper",
    borderRadius: "5px",
  },
};

export const inputFileStyles = {
  p: 0.5,
  bgcolor: "transparent",
  border: "1px solid",
  borderRadius: "5px",
  borderColor: "secondary.main",
  display: "flex",
  alignItems: "center",
};

export const iconStyles = {
  transition: "color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
  ml: 0.5,
  "&:hover": { cursor: "pointer", color: "primary.main" },
};

export const SIDE_BAR_DISPLAY = { xs: "none", sm: "block" };
export const SIDE_BAR_WIDTH = 250;

const FORWARD = 0;
const BACKWARD = 180;

export const getExpandRotationStyles: (open: boolean) => SxProps<Theme> = open => () => ({
  transform: `rotate(${open ? BACKWARD : FORWARD}deg)`,
});

export const cardMaxHeightProps = (type?: "jobs" | "processing") => ({
  maxHeight: (type === "processing" && 264) || 300,
});

export const hoveredButtonStyles = {
  cursor: "pointer",
  color: "primary.contrastText",
  "&: hover": {
    color: "primary.main",
  },
};

export const DEFAULT_TEXT_COLOR = "primary.contrastText";
export const HOVERED_TEXT_COLOR = "primary.main";
export const getHoveredTextStyles: (hovered?: boolean) => SxProps = hovered => ({
  cursor: "pointer",
  color: hovered ? HOVERED_TEXT_COLOR : DEFAULT_TEXT_COLOR,
  "&:hover": {
    color: HOVERED_TEXT_COLOR,
  },
});

export const hoverStyles = (hovered?: boolean) => {
  const color = hovered && {
    "& + .MuiTypography-root": {
      color: "primary.main",
    },
  };
  return {
    position: "absolute",
    height: "100%",
    width: "100%",
    ml: -1.5,
    ...color,
    "&:hover": {
      cursor: "pointer",
      "& + .MuiTypography-root": {
        color: "primary.main",
      },
    },
  };
};

export const tabsIndicatorStyles = {
  borderRadius: "8px",
  bgcolor: "background.paper",
  height: "calc(100% - 6px)",
  margin: "3px",
  width: "100%",
  // maxWidth: "calc(100% - 6px)",
};

export const tabsStyles = {
  borderRadius: "10px",
  minHeight: "38px",
  bgcolor: "secondary.main",
  mt: 1.5,
  flexShrink: 0,
  width: "100%",
  "& .MuiButtonBase-root": {
    zIndex: 2,
    minHeight: "38px",
    color: "text.primary",
    textTransform: "none",
    fontSize: "16px",
    fontFamily: "Roboto",
    letterSpacing: 0,
    p: 0,
    "&.Mui-selected": {
      color: "text.primary",
    },
  },
  "& .MuiTabs-indicator": tabsIndicatorStyles,
};

export const tabStylesWithIndicator = {
  ...tabsStyles,
  "& .MuiTabs-indicator": {
    ...tabsIndicatorStyles,
    maxWidth: "calc(50% - 6px)",
  },
};
