import { useRootStore } from "hooks/useRootStore";;
import React, { FC, useEffect } from "react";
import { TextFieldProps } from "@mui/material";
import TextField from "@mui/material/TextField";



interface CardEditableTitleProps {
  title: string;
  setTitle?: (value: string) => void;
  onFinish?: () => void;
  disabled?: boolean;
}

const CardEditableTitle: FC<CardEditableTitleProps & TextFieldProps> = ({
  title,
  setTitle,
  onFinish,
  disabled,
  ...props
}) => {
  const { jobStore } = useRootStore();

  const { job } = jobStore;
  useEffect(() => {
    if (job && job?.title !== title) {
      setTitle?.(jobStore.job?.title ?? "");
    }
  }, [job]);

  return (
    <TextField
      size="small"
      fullWidth
      sx={editableTitleStyles}
      value={title}
      onChange={e => setTitle?.(e.target.value)}
      onBlur={onFinish}
      inputProps={{ maxLength: 255 }}
      disabled={disabled}
      {...props}
    />
  );
};

const editableTitleStyles = {
  "& .MuiInputBase-input": {
    fontFamily: "'futura-pt-bold', Sans-serif",
    fontSize: "22px",
    lineHeight: 1,
    padding: "4px 5px",
    textOverflow: "ellipsis",
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: "transparent",
  },
  "& .Mui-disabled": {
    color: "#fff!important",
    WebkitTextFillColor: "#fff!important",
  },
};

export default CardEditableTitle;
