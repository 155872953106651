import { StringMap, TFuncBodyWithOptions } from "../types";

export const LOCALES = {
  GENERAL: {
    DESCRIPTION: "General:Description",
    EDIT: "General:Edit",
    TITLE: "General:Title",
    CANCEL: "General:Cancel",
    CONFIRM: "General:Confirm",
    CONFIRMATION_QUESTION: "General:ConfirmationQuestion",
    YOU: "General:You",
    SAVE: "General:Save",
    SUCCESSFULLY_COPIED: "General:SuccessfullyCopied",
  },
  TASKS: {
    TASKS: "Tasks:Tasks",
    ATTACHMENTS: "Tasks:Attachments",
    COMMENTS: "Tasks:Comments",
    SEARCH_BY_TITLE: "Tasks:SearchByTitle",
    CREATED_BY_TITLE: "Tasks:CreatedByTitle",
    CANCEl: "Tasks:Cancel",
    EXPORT: "Tasks:Export",
    PREPARING: "Tasks:Preparing",
    DOWNLOAD: "Tasks:Download",
    CREATED_BY: (keys: TasksCreatedByKeys): TFuncBodyWithOptions => ["Tasks:CreatedBy", undefined, keys],
    DUE_DATE: "Tasks:DueDate",
    CREATED_AT: "Tasks:CreatedAt",
    PRIORITY: "Tasks:Priority",
    CREATED_AT_FROM: "Tasks:CreatedAtFrom",
    CREATED_AT_TO: "Tasks:CreatedAtTo",
    DUE_DATE_FROM: "Tasks:DueDateFrom",
    DUE_DATE_TO: "Tasks:DueDateTo",
    IS_CREATOR: "Tasks:IsCreator",
    IS_ASSIGNED: "Tasks:IsAssigned",
    IS_REVIEWER: "Tasks:IsReviewer",
    PROCESS_TYPE: {
      IDNEX: "Tasks:ProcessType",
      APPROVAL: "Tasks:APPROVAL",
      MEASUREMENT_ACCOUNTING: "Tasks:MEASUREMENT_ACCOUNTING",
      CONSTRUCTION_REPORT: "Tasks:CONSTRUCTION_REPORT",
      DEFECT_MANAGEMENT: "Tasks:DEFECT_MANAGEMENT",
      INCOMING_INSPECTION: "Tasks:INCOMING_INSPECTION",
      CONDITION_ASSESSMENT: "Tasks:CONDITION_ASSESSMENT",
    },
    EXPORT_TYPE: {
      "BCF_3.0": "Tasks:ExportType:BCF_3.0",
      JSON: "Tasks:ExportType:JSON",
      CSV: "Tasks:ExportType:CSV",
    },
  },
  MEASUREMENTS: {
    MEASUREMENT_PLURAL: "Measurements:Measurement_plural",
  },
  PROCESSINGS: {
    PROCESSINGS: "Processings:Processings",
    PROCESSING_IS_LAUNCHED: (keys: ProcessingIsLaunchedKeys): TFuncBodyWithOptions => [
      "Processings:ProcessingIsLaunched",
      undefined,
      keys,
    ],
  },
  JOB: {
    JOB: "Job:Job",
    JOB_plural: "Job:Jobs",
  },
  CONSTRUCTION_SITE: {
    SINGULAR: "ConstructionSite:Singular",
    SHORT: "ConstructionSite:Short",
    DETERMINATE_LOCATION: "ConstructionSite:DetermineLocation",
    MAX_CS_TITLE_LENGTH_ERROR: "ConstructionSite:MaxCSTitleLengthError",
  },
  AREA: {
    AREA: "Area:Area",
  },
  ERRORS: {
    NOT_ACCESS: "Errors:NotAccess",
    SOME_ERROR: "Errors:SomeError",
    ELEMENT_RECYCLED: "Errors:ElementRecycled",
    NOT_FOUND: "Errors:NotFound",
  },
  SIDEBAR: {
    TASKS: "Sidebar:Tasks",
    TEAMS: "Sidebar:Teams",
    CONSTRUCTION_SITE: "Sidebar:ConstructionSite",
    SETTINGS: "Sidebar:Settings",
    HELP: "Sidebar:Help",
    METRICS: "Sidebar:Metrics",
  },
} as const;

interface ProcessingIsLaunchedKeys extends StringMap {
  processingTitle: string;
}

interface TasksCreatedByKeys extends StringMap {
  name: string;
}
