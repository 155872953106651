import { useRootStore } from "hooks/useRootStore";
import React, { PropsWithChildren, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";

import { Processings, ProcessingStatus } from "stores/managerService/processingStore/types";

import { tabsStyles, tabStylesWithIndicator } from "constants/styles";

import Inputs from "./Inputs";
import Outputs from "./Outputs";

interface TabPanelProps {
  index: number;
  value: number;
}

export const TabPanel: React.FC<PropsWithChildren<TabPanelProps>> = ({ children, value, index }) => {
  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      sx={tabpanelStyles}>
      {value === index && children}
    </Box>
  );
};

const List: React.FC = () => {
  const { t } = useTranslation();
  const { processingStore, constructionSiteStore, measurementStore } = useRootStore();

  const { processing } = processingStore;

  const isResultExists =
    processing &&
    (processing.status === ProcessingStatus.PROCESSING_SUCCESS ||
      processing.status === ProcessingStatus.PROCESSING_FAIL);

  const [tabIndex, setTabIndex] = useState(isResultExists ? 1 : 0);

  useEffect(() => {
    const condition =
      processing && processing.type !== Processings.PIX4D_VIEW && processing.type !== Processings.VIPOINT;
    return () => {
      if (condition) {
        constructionSiteStore.removeCSIcons();
        measurementStore.removeImgCoords();
      }
    };
  }, []);

  return (
    <Box>
      <Tabs
        value={tabIndex}
        onChange={(e, i) => setTabIndex(i)}
        aria-label="tabs"
        variant="fullWidth"
        sx={isResultExists ? tabStylesWithIndicator : tabsStyles}>
        <Tab label={t("Processings:Inputs")} disableRipple />
        <Tab label={t("Processings:Results")} disableRipple />
      </Tabs>
      <TabPanel value={tabIndex} index={0}>
        <Inputs />
      </TabPanel>
      <TabPanel value={tabIndex} index={1}>
        <Outputs />
      </TabPanel>
    </Box>
  );
};

const tabpanelStyles = { overflow: "hidden", display: "flex" } as const;

export default List;
