import { useRootStore } from "hooks/useRootStore";;
import React, { FC, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Outlet, useLocation, useNavigate } from "react-router";
import LogoutRoundedIcon from "@mui/icons-material/LogoutRounded";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { observer } from "mobx-react-lite";

import { MenuItemId } from "stores/settingStore/types";

import { PATHS } from "router/paths";

import NewUserAlert from "./NewUserAlert";

export const Settings: FC = observer(() => {
  const { t } = useTranslation();
  const { authStore, uiStore } = useRootStore();

  const navigate = useNavigate();
  const menuItems = [
    { id: MenuItemId.USER, title: `${t("Settings:UserProfile")}` },
    { id: MenuItemId.COMPANY, title: `${t("Settings:CompanyProfile")}` },
    { id: MenuItemId.ACCOUNT, title: `${t("Settings:Account")}` },
    { id: MenuItemId.NOTIFICATIONS, title: `${t("Settings:Notifications")}` },
  ];

  const { userAttributes, newUser } = authStore;

  const handleNavigate = (menuItem: MenuItemId) => {
    if (menuItem !== MenuItemId.USER) {
      navigate(`/settings/${PATHS.SETTINGS[menuItem]}`);
    } else {
      navigate("/settings");
    }
  };

  useEffect(() => {
    if (userAttributes && newUser && userAttributes.some(attr => attr.Name === "family_name")) {
      navigate(`/settings/${PATHS.SETTINGS[MenuItemId.COMPANY]}`);
    }
  }, [userAttributes, newUser]);

  const handleLogout = () => {
    uiStore.setConfirmModalOpen({
      children: <>{t("Auth:ConfirmLogoutQuestion")}</>,
      onConfirm: authStore.logout,
      confirmText: "Auth:Logout",
    });
  };

  const getMenuItemSelected = (id: MenuItemId) => {
    const pathname = useLocation().pathname;
    return (pathname === "/settings" && id === MenuItemId.USER) || pathname.includes(id.toLowerCase());
  };

  return (
    <Paper sx={wrapperStyles}>
      <MenuList>
        {menuItems.map(item => (
          <MenuItem
            key={item.id}
            selected={getMenuItemSelected(item.id)}
            onClick={() => handleNavigate(item.id)}
            sx={menuItemStyles}
            disableRipple>
            <Typography variant="subtitle1">{item.title}</Typography>
          </MenuItem>
        ))}
        <Divider sx={{ borderColor: "background.default", ml: "16px" }} />
        <Box sx={logoutStyles} onClick={handleLogout}>
          <Typography variant="subtitle1">{t("Auth:Logout")}</Typography>
          <LogoutRoundedIcon fontSize="small" sx={{ ml: 0.5 }} />
        </Box>
      </MenuList>
      <Box sx={{ width: "100%", ml: 6 }}>
        <Outlet />
      </Box>
      <NewUserAlert />
    </Paper>
  );
});

const wrapperStyles = {
  mt: 4,
  mr: 2,
  ml: 4,
  height: "100%",
  display: "flex",
  bgcolor: "transparent",
};

const menuItemStyles = {
  mb: 1,
  position: "relative",
  "& .MuiTypography-root": {
    color: "text.primary",
    lineHeight: 1.2,
    transition: "all 0.3s",
  },
  "&.Mui-selected": {
    backgroundColor: "transparent",
    "& .MuiTypography-root": { color: "primary.main" },
    ":hover": {
      backgroundColor: "transparent",
    },
    ":before": {
      content: "''",
      position: "absolute",
      top: 0,
      left: 0,
      height: "100%",
      borderLeft: "2px solid",
      borderColor: "primary.main",
    },
  },
  ":hover": {
    backgroundColor: "transparent",
    "& .MuiTypography-root": { color: "primary.main" },
  },
} as const;

const logoutStyles = {
  display: "flex",
  alignItems: "center",
  ml: "16px",
  pt: "6px",
  cursor: "pointer",
  "& .MuiTypography-root, .MuiSvgIcon-root": {
    transition: "color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
  },
  "&:hover": {
    "& .MuiTypography-root": {
      color: "primary.main",
    },
    "& .MuiSvgIcon-root": {
      color: "primary.main",
    },
  },
};
