import { action, makeObservable, observable } from "mobx";

class OnboardStore {
  @observable
  isRun: boolean = false;

  @observable
  stepIndex: number | null = null;

  @observable
  isOnboardingRunning: boolean = false;

  @observable
  isLastStep: boolean = false;

  @action.bound
  nextStep(): void {
    if (this.isLastStep) {
      this.finishTour();
    } else {
      this.stepIndex! += 1;
    }
  }

  startTour = (): void => {
    this.setIsRun(true);
    this.setStepIndex(0);
    this.setIsOnboardingRunning(true);
    this.setIsLastStep(false);
  };

  finishTour(): void {
    this.setIsRun(false);
    this.setStepIndex(null);
    this.setIsOnboardingRunning(false);
    this.setIsLastStep(true);
  }

  @action.bound
  private setIsRun(isRun: boolean): void {
    this.isRun = isRun;
  }

  @action.bound
  setIsLastStep(isLastStep: boolean): void {
    this.isLastStep = isLastStep;
  }

  @action.bound
  private setStepIndex(stepIndex: number | null): void {
    this.stepIndex = stepIndex;
  }

  @action.bound
  private setIsOnboardingRunning(isOnboardingRunning: boolean): void {
    this.isOnboardingRunning = isOnboardingRunning;
  }

  constructor() {
    makeObservable(this);
  }
}

export const onboardingStore = new OnboardStore();
