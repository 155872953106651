import React from "react";
import { Link, SxProps, Theme, Typography } from "@mui/material";

import { ExistingStep } from "./types";

export interface BreadcrumbActions<T> {
  setStepName?: (stepName: T) => void;
  selectedStepName: T;
}

interface Breadcrumb<T> {
  step: ExistingStep<T>;
}

function Breadcrumb<T>({ step, setStepName, selectedStepName }: Breadcrumb<T> & BreadcrumbActions<T>) {
  const handleSelect = () => {
    setStepName?.(step.stepName);
    step.action?.();
  };

  return (
    <Link
      underline={"hover"}
      onClick={handleSelect}
      sx={breadcrumbTextStyles}
      color={selectedStepName === step.stepName ? "primary.contrastText" : "secondary.light"}>
      <Typography noWrap>{step.title}</Typography>
    </Link>
  );
}

const breadcrumbTextStyles: SxProps<Theme> = {
  display: "block",
  cursor: "pointer",
  maxWidth: "120px",
};

export default Breadcrumb;
