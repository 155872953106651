import React, { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Stack, SxProps, Theme } from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { format as dformat, formatDistanceToNow as dformatDistanceToNow } from "date-fns";
import { observer } from "mobx-react-lite";

import { Member } from "stores/managerService/teamStore/types";
import { CommentType, Comment } from "stores/taskStore/types";

import { getFullName } from "components/ConstructionSite/Team";
import { useFormatDate } from "hooks/useFormatDate";
import { useRootStore } from "hooks/useRootStore";

import { UserChangeSystemComments } from "./types";
import UserAvatar from "./UserAvatar";
import { SystemCommentCodes } from "..";

interface CommentsListItemProps {
  taskId: string;
  comment: Comment;
  author: Member;
}

const AVATAR = "avatar";
const TITLE = "title";
const BODY = "body";

const CommentsListItem: FC<CommentsListItemProps> = ({ taskId, comment, author }) => {
  const { t } = useTranslation();
  const pictureMini = useMemo(() => author["custom:picture-mini"], [author]);
  const {
    teamStore: { constructionSiteTeamMembersMap },
  } = useRootStore();

  const format = useFormatDate(dformat);
  const formatDistanceToNow = useFormatDate(dformatDistanceToNow);

  const tt = (commentString: string) => {
    const commentData = JSON.parse(commentString) as { code: string; params: { [key: string]: string } };
    const { code, params } = commentData;

    let translatedParams;
    const commentCode = code.split(":")[1];

    if (Object.values(UserChangeSystemComments).some(item => item === commentCode)) {
      translatedParams = Object.entries(params).reduce((acc, [key, value]) => {
        try {
          const fullName = getFullName(constructionSiteTeamMembersMap[value]);
          return { ...acc, [key]: fullName };
        } catch (error) {
          console.error("getFullName error");
          return acc;
        }
      }, {});
    } else if ([SystemCommentCodes.DueDateChange, SystemCommentCodes.DueDateSet].some(item => item === commentCode)) {
      translatedParams = Object.entries(params).reduce((acc, [key, value]) => {
        try {
          const formattedValue = format(new Date(value), "MMM d, yyyy");
          return { ...acc, [key]: formattedValue };
        } catch (error) {
          console.error("wrong date format");
          return acc;
        }
      }, {});
    } else if (
      [
        SystemCommentCodes.ProcessTypeChange,
        SystemCommentCodes.StatusChange,
        SystemCommentCodes.TitleChange,
        SystemCommentCodes.DescriptionChange,
        SystemCommentCodes.DescriptionSet,
        SystemCommentCodes.PriorityChange,
      ].some(item => item === commentCode)
    ) {
      translatedParams = Object.entries(params).reduce((acc, [key, value]) => {
        try {
          const translatedValue = t(`Tasks:${value}`);
          return { ...acc, [key]: translatedValue };
        } catch (error) {
          console.error("translation error");
          return acc;
        }
      }, {});
    }

    return t(code, translatedParams);
  };

  const formatTimeAgo = (date: number) => {
    const formattedDate = new Date(date);
    const now = new Date();
    const diffInMilliseconds = now.getTime() - formattedDate.getTime();

    if (diffInMilliseconds < 24 * 60 * 60 * 1000) {
      return formatDistanceToNow(formattedDate) + " ago";
    } else {
      return (
        format(formattedDate, "MMM d, yyyy H:mm") + (now.getFullYear() !== formattedDate.getFullYear() ? ", yyyy" : "")
      );
    }
  };

  if (comment.commentType === CommentType.SYSTEM_CREATED) {
    return (
      <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "flex-start", marginTop: 1 }}>
        <Typography
          style={{ overflowWrap: "break-word", wordBreak: "break-all", flex: 5 }}
          variant="body1"
          component="div"
          fontSize="14px"
          fontWeight={600}>
          {`${getFullName(author)} ${tt(comment.content)}`}
        </Typography>
        <Typography
          sx={{
            flex: 1,
            alignItems: "flex-end",
            justifyContent: "flex-end",
            textAlign: "end",
          }}
          variant="caption"
          component="div"
          fontSize="12px"
          fontWeight={400}>
          {formatTimeAgo(comment.createdAt)}
        </Typography>
      </Box>
    );
  }
  return (
    <Box sx={wrapperStyles}>
      <Box sx={avatarStyles}>
        <UserAvatar givenName={author.given_name} familyName={author.family_name} pictureMini={pictureMini} />
      </Box>
      <Box sx={titleStyles}>
        <Stack display={"flex"} alignItems={"center"} direction={"row"} spacing={0.5}>
          <Typography variant="body1" component="div" fontSize="14px" fontWeight={600} color="primary">
            {/* eslint-disable-next-line @typescript-eslint/restrict-template-expressions */}
            {`${author.given_name} ${author.family_name}`}
          </Typography>
        </Stack>
        <Typography sx={{ marginLeft: "auto" }} variant="caption" component="div" fontSize="12px" fontWeight={400}>
          {formatTimeAgo(comment.createdAt)}
        </Typography>
      </Box>
      <Typography sx={bodyStyles} variant="body1" component="div">
        {comment.content}
      </Typography>
    </Box>
  );
};

const wrapperStyles: SxProps<Theme> = {
  display: "grid",
  gridTemplateColumns: "35px auto",
  gridTemplateRows: "35px auto",
  gridTemplateAreas: `
    "${AVATAR} ${TITLE}"
    ". ${BODY}"
  `,
  gap: 0.5,
  my: 1.5,
  width: "100%",
};

const avatarStyles: SxProps<Theme> = {
  gridArea: AVATAR,
};

const titleStyles: SxProps<Theme> = {
  gridArea: TITLE,
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
};

const bodyStyles: SxProps<Theme> = {
  gridArea: BODY,
  fontSize: "14px",
  display: "block",
  overflowWrap: "break-word",
  wordBreak: "break-all",
  backgroundColor: "secondary.dark",
  borderRadius: "0 10px 10px 10px",
  padding: 0.5,
};

export default observer(CommentsListItem);
