import { useRootStore } from "hooks/useRootStore";;
import React, { FC, useState } from "react";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import FormatShapesIcon from "@mui/icons-material/FormatShapes";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import Box from "@mui/material/Box";
import Fab from "@mui/material/Fab";
import IconButton from "@mui/material/IconButton";
import Zoom from "@mui/material/Zoom";

import { IArea } from "stores/managerService/areaStore";

import DeletePopupForm from "../DeletePopupForm";

const ActionButtons: FC<{ area: IArea }> = ({ area }) => {
  const { areaStore, constructionSiteStore, uiStore, jobStore } = useRootStore();

  const { createMode, editMode } = areaStore;
  const { editableConstructionSite } = constructionSiteStore;

  const [actionButtons, setActionButtons] = useState("");
  const [scrollTop, setScrollTop] = useState(0);

  const changeBoundaries = (areaId: string, map: string) => {
    setActionButtons("");
    areaStore.setEditMode(true);
    jobStore.getAllJobs(areaId);
    areaStore.changeBoundaries(areaId, map);
  };

  return (
    <Box sx={{ position: "relative" }}>
      <IconButton
        sx={createMode || editMode ? { ...iconStyles, pointerEvents: "none", opacity: 0.5 } : iconStyles}
        onClick={() => {
          setActionButtons(area.id);
          setScrollTop(document.getElementById("areas")!.scrollTop);
        }}>
        <MoreHorizIcon />
      </IconButton>
      <Box sx={buttonsWrapperStyles(actionButtons === area.id, scrollTop)} onMouseLeave={() => setActionButtons("")}>
        <Zoom in={actionButtons === area.id}>
          <Fab
            size="small"
            aria-label="edit"
            sx={{ mb: 1, color: "background.default" }}
            onClick={() => changeBoundaries(area.id, area.map)}
            disabled={!!editableConstructionSite}>
            <FormatShapesIcon />
          </Fab>
        </Zoom>
        <Zoom in={actionButtons === area.id} style={{ transitionDelay: "100ms" }}>
          <Fab
            size="small"
            aria-label="delete"
            sx={{ color: "background.default" }}
            onClick={() =>
              uiStore.openModal(<DeletePopupForm areaId={area.id} constructionSiteId={area.constructionSite} />)
            }>
            <DeleteOutlinedIcon />
          </Fab>
        </Zoom>
      </Box>
    </Box>
  );
};

const iconStyles = {
  color: "text.primary",
  bgcolor: "background.default",
  "& .MuiSvgIcon-root": {
    transition: "color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
  },
  "&:hover": {
    bgcolor: "background.default",
    "& .MuiSvgIcon-root": {
      color: "primary.main",
    },
  },
};

const buttonsWrapperStyles = (selected: boolean, scrollTop: number) => {
  return {
    pl: selected ? 4 : 0,
    ml: selected ? 0 : 4,
    zIndex: selected ? 10 : -1,
    transition: "z-index 0.24s",
    mt: `calc(-64px - ${scrollTop}px)`,
    position: "fixed",
    display: "flex",
    flexDirection: "column",
  } as const;
};

export default ActionButtons;
