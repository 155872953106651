import { useRootStore } from "hooks/useRootStore";;
import React, { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import SubjectRoundedIcon from "@mui/icons-material/SubjectRounded";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import { TeamRole } from "stores/managerService/teamStore/types";



const Description: FC = () => {
  const { t } = useTranslation();
  const { teamStore, constructionSiteStore } = useRootStore();

  const { csPermissions } = teamStore;
  const { constructionSite } = constructionSiteStore;
  const [description, setDescription] = useState(constructionSite.description ? constructionSite.description : "");

  const finishEditing = () => {
    constructionSiteStore.updateConstructionSite(constructionSite.id, "description", description);
  };

  return (
    <Box sx={{ flex: "50%" }}>
      <Box sx={wrapperStyles}>
        <SubjectRoundedIcon />
        <Typography variant="body1" component="div" fontWeight={600} sx={{ ml: 0.8 }}>
          {t("General:Description")}
        </Typography>
      </Box>
      <TextField
        size="small"
        fullWidth
        multiline
        rows={3}
        sx={editableDescriptionStyles}
        value={description}
        onChange={e => setDescription(e.target.value)}
        onBlur={finishEditing}
        inputProps={{ maxLength: 255 }}
        placeholder={t("General:TypeHere")}
        disabled={!csPermissions.creating}>
        {constructionSite.description}
      </TextField>
    </Box>
  );
};

const wrapperStyles = {
  display: "flex",
  alignItems: "center",
  mb: 1,
  ml: "-3px",
};

const editableDescriptionStyles = {
  mb: 1,
  ml: "-5px",
  "& .MuiInputBase-root": {
    padding: "4px 5px",
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: "transparent",
  },
};

export default Description;
