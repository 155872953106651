import React, { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import AssignmentTurnedInOutlinedIcon from "@mui/icons-material/AssignmentTurnedInOutlined";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { observer } from "mobx-react-lite";

import { Task } from "stores/taskStore/types";

import { Loader } from "common/Loader";
import { NoDataMessage } from "common/NoDataMessage";
import AddTaskPopup from "common/AddTaskPopup";
import { useFetchWithLoading } from "hooks";
import { useOpenTaskDetails } from "hooks/useOpenTaskDetails";
import { useRootStore } from "hooks/useRootStore";

import List from "./List";

interface TasksProps {
  foreignId: string;
  acl: string;
  constructionSiteId?: string;
  displaySectionName?: boolean;
  noTasksMessage?: string;
}

const Tasks: FC<TasksProps> = ({ foreignId, acl, constructionSiteId, displaySectionName, noTasksMessage }) => {
  const { t } = useTranslation();

  const {
    taskStore,
    teamStore,
    uiStore: { openModal },
    constructionSiteStore: { constructionSite },
  } = useRootStore();
  const { tasks } = taskStore;
  const { csPermissions } = teamStore;
  const [fetchTasksWithLoading, isLoading] = useFetchWithLoading(
    async () => await taskStore.getAllTasks({ constructionSiteId, acl })
  );
  const [tasksByForeignId, setTasksByForeignId] = useState<Task[] | null>(null);
  useOpenTaskDetails(tasksByForeignId);

  useEffect(() => {
    setTasksByForeignId(null);
    fetchTasksWithLoading();
  }, [acl]);

  useEffect(() => {
    if (tasks) {
      const filteredTasks = tasks.filter(task => task.foreignId === foreignId);
      setTasksByForeignId(filteredTasks);
    }
  }, [tasks, foreignId]);

  return (
    <Box>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        {displaySectionName && (
          <>
            <AssignmentTurnedInOutlinedIcon />
            <Typography variant="body1" component="div" fontWeight={600} sx={{ ml: 1, mr: 2 }}>
              {t("Tasks:Tasks")}
            </Typography>
          </>
        )}
        <Button
          size="small"
          color="secondary"
          variant="contained"
          fullWidth
          onClick={() =>
            openModal(
              <AddTaskPopup
                foreignId={foreignId}
                acl={acl}
                constructionSiteId={constructionSite.id}
                status="PENDING"
              />
            )
          }
          disabled={!csPermissions.creating}>
          <Typography variant="caption">+ {t("Tasks:AddNew")}</Typography>
        </Button>
      </Box>
      {isLoading ? (
        <Loader />
      ) : tasksByForeignId?.length ? (
        <List tasks={tasksByForeignId} />
      ) : (
        <NoDataMessage message={t("Tasks:NoTasks")} />
      )}
    </Box>
  );
};

export default observer(Tasks);
