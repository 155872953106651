import React, { FC, useState } from "react";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { SxProps, TextField, TextFieldProps, Theme } from "@mui/material";

import { hoveredButtonStyles } from "constants/styles";

interface ClearingTextFieldProps {
  onClear?: () => void;
}

const ClearingTextField: FC<ClearingTextFieldProps & TextFieldProps> = ({ onClear, value, ...props }) => {
  const [hovered, setHovered] = useState(false);

  return (
    <TextField
      {...props}
      value={value}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      InputProps={{
        endAdornment: hovered && onClear && value ? <CloseRoundedIcon sx={buttonStyles} onClick={onClear} /> : null,
      }}
    />
  );
};

const buttonStyles: SxProps<Theme> = {
  ...hoveredButtonStyles,
};

export default ClearingTextField;
