import { useRootStore } from "hooks/useRootStore";;
import React, { useCallback } from "react";
import FormatShapesIcon from "@mui/icons-material/FormatShapes";
import { Box, SxProps, Theme } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { observer } from "mobx-react-lite";



const ChangeBoundariesButton = () => {
  const { constructionSiteStore, areaStore, jobStore } = useRootStore();

  const { constructionSite } = constructionSiteStore;
  // const { area } = areaStore;
  // const { job } = jobStore;

  const handleChangeBoundaries = useCallback(async () => {
    // if (job) {
    //   jobStore.changeBoundaries(job.id);
    // }
    await constructionSiteStore.changeBoundaries(constructionSite.id, constructionSite.map, 0);
  }, [constructionSite.id, constructionSite.map]);

  return (
    <Box sx={buttonContainerStyles}>
      <IconButton size="small" sx={iconStyles} color="secondary" onClick={handleChangeBoundaries}>
        <FormatShapesIcon />
      </IconButton>
    </Box>
  );
};

const buttonContainerStyles: SxProps<Theme> = {
  justifyContent: "center",
  alignItems: "center",
};

const iconStyles: SxProps<Theme> = {
  color: "text.primary",
  bgcolor: "background.paper",
  borderRadius: 10,
  "& .MuiSvgIcon-root": {
    transition: "color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
  },
  "&:hover": {
    bgcolor: "background.paper",
    "& .MuiSvgIcon-root": {
      color: "primary.main",
    },
  },
};

export default observer(ChangeBoundariesButton);
