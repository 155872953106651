import React, { FC, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams, useSearchParams } from "react-router-dom";
import { Box, Fade } from "@mui/material";
import { observer } from "mobx-react-lite";

import { TeamRole } from "stores/managerService/teamStore/types";
import { SEARCH_PARAMS_TASK_ID_KEY } from "stores/mapStore/constants";

import Attachments from "common/Attachments";
import ButtonsList from "common/ButtonsList";
import CardEditableTitle from "common/Card/CardEditableTitle";
import Description from "common/Description";
import Measurements from "common/Measurements";
import Processings from "components/ConstructionSite/Processings";
import ProcessingFiles from "components/ConstructionSite/Processings/ProcessingFiles";
import Tasks from "components/ConstructionSite/Tasks";
import { LOCALES } from "constants/locales";
import useFetchAreaAndJobs from "hooks/manager/useFetchAreaAndJobs";
import useFetchConstructionSiteAndAreas from "hooks/manager/useFetchConstructionSiteAndAreas";
import useFetchJob from "hooks/manager/useFetchJobAndProcessings";
import { useRootStore } from "hooks/useRootStore";

import { jobCardButtonsTitles } from "./constsnts";

const Card: FC = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const [searchParams] = useSearchParams();

  const { teamStore, constructionSiteStore, jobStore, processingStore, areaStore } = useRootStore();

  const { csPermissions } = teamStore;
  const { isCardOpen } = constructionSiteStore;
  const { area } = areaStore;
  const { job, updateDescription } = jobStore;
  const { processing } = processingStore;

  useFetchConstructionSiteAndAreas(area?.constructionSite);
  useFetchAreaAndJobs(job?.area);
  const [isLoading] = useFetchJob(id);
  const [title, setTitle] = useState(job?.title ?? "");

  const [selectedButtonTitle, setSelectedButtonTitle] = useState(
    searchParams.get(SEARCH_PARAMS_TASK_ID_KEY) ? jobCardButtonsTitles[3] : jobCardButtonsTitles[4] // tasks : processings
  );

  const finishEditing = async () => {
    if (title) {
      await jobStore.updateTitle(job!.id, title);
    } else {
      setTitle(job!.title);
    }
  };

  const sectionsComponents = useMemo<Record<string, JSX.Element> | null>(
    () =>
      job
        ? {
            [LOCALES.GENERAL.DESCRIPTION]: (
              <Description
                description={job.description}
                onUpdateDescription={async d => await updateDescription(job.id, d)}></Description>
            ),
            [LOCALES.TASKS.ATTACHMENTS]: <Attachments id={job.id} acl={job.acl} buttonTitle={t("General:Add")} />,
            [LOCALES.MEASUREMENTS.MEASUREMENT_PLURAL]: <Measurements id={job.id} />,
            [LOCALES.TASKS.TASKS]: <Tasks foreignId={job.id} acl={job.acl} />,
            [LOCALES.PROCESSINGS.PROCESSINGS]: <Processings jobId={job.id} isLoading={isLoading} />,
          }
        : null,
    [job, t, updateDescription, isLoading]
  );

  const SectionComponent = useMemo(
    () => sectionsComponents?.[selectedButtonTitle],
    [sectionsComponents, selectedButtonTitle]
  );

  return (
    <>
      {processing ? (
        <CardEditableTitle title={processing.title} disabled />
      ) : (
        <CardEditableTitle
          title={title}
          setTitle={setTitle}
          onFinish={finishEditing}
          disabled={!isCardOpen || !csPermissions.creating}
        />
      )}
      <Fade in>
        <Box sx={innerContainerStyles}>
          {job && !processing && (
            <ButtonsList
              buttonsTitles={jobCardButtonsTitles}
              selectedButtonTitle={selectedButtonTitle}
              setButtonTitle={setSelectedButtonTitle}
            />
          )}
          <Box sx={{ mt: 1 }}>
            {processing && <ProcessingFiles />}
            {SectionComponent}
          </Box>
        </Box>
      </Fade>
    </>
  );
};

const innerContainerStyles = {
  display: "flex",
  flexDirection: "column",
} as const;

export default observer(Card);
