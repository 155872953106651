import { useRootStore } from "hooks/useRootStore";
import React, { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";

import { ErrorI18KeyAndOptions } from "stores/managerService/helpers/checkGraphics";

import CreatePopupForm from "./CreatePopupForm";

const CreateMode: FC<{ constructionSiteId: string }> = ({ constructionSiteId }) => {
  const { t } = useTranslation();
  const { areaStore, uiStore, mapStore } = useRootStore();

  const { checkAreaGraphics, editableArea } = areaStore;

  const [error, setError] = useState<ErrorI18KeyAndOptions>();

  const handleSubmit = async () => {
    const errorAndMessage = checkAreaGraphics(constructionSiteId, editableArea);

    setError(errorAndMessage);
    if (errorAndMessage) {
      return;
    }
    uiStore.openModal(<CreatePopupForm constructionSiteId={constructionSiteId} />);
  };

  const handleCancel = () => {
    setError(undefined);
    areaStore.setCreateMode(false);
    mapStore.utils.clearGraphicLayer();
  };

  return (
    <Box sx={{ mt: 1, flexShrink: 0 }}>
      <Paper sx={styles}>
        <CircularProgress sx={{ color: "text.primary" }} size={35} />
      </Paper>
      <Typography variant="body1" sx={{ mt: 2 }}>
        {t("Area:DetermineLocation")}
      </Typography>
      {error && (
        <Typography variant="caption" component="div" color="error" sx={{ mt: 0.5, mb: 0.25 }}>
          {t(...error)}
        </Typography>
      )}
      <Button variant="contained" sx={{ mt: 1.75, mr: 1, mb: 1 }} onClick={handleSubmit}>
        {t("General:Save")}
      </Button>
      <Button variant="contained" color="secondary" sx={{ mt: 1.75, mb: 1 }} onClick={handleCancel}>
        {t("General:Cancel")}
      </Button>
    </Box>
  );
};

const styles = {
  height: 66,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

export default CreateMode;
