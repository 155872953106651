import React from "react";
import { Paper } from "@mui/material";

import { Loader } from "common/Loader";

import { elementStyles } from "./SliderItem";

const LoadingSliderItem = () => {
  return (
    <Paper sx={elementStyles} elevation={0}>
      <Loader size={"md"} />
    </Paper>
  );
};

export default LoadingSliderItem;
