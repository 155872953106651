import axios from "axios";
import { baseUrl, protocol, Service } from "configs/servicesConfig";
import { IArea } from "./index";

const { PROJECT } = Service;

const areas = {
  getAll: async (constructionSite: string): Promise<IArea[]> => {
    const response = await axios.get<{ payload: [] }>(
      `${protocol}//${baseUrl}/${PROJECT}/areas?constructionSite=${constructionSite}`
    );
    return response.data.payload;
  },

  get: async (id: string) => {
    const response = await axios.get<{ payload: IArea }>(`${protocol}//${baseUrl}/${PROJECT}/areas/${id}`);

    if (response.data) {
      return response.data.payload;
    } else {
      // eslint-disable-next-line @typescript-eslint/no-throw-literal
      throw response;
    }
  },

  createArea: async (
    constructionSite: string,
    title: string,
    description: string,
    isProtected: boolean,
    graphic: JSON
  ) => {
    const map = JSON.stringify(graphic);
    const response = await axios.post(`${protocol}//${baseUrl}/${PROJECT}/areas`, {
      constructionSite,
      title,
      isProtected,
      map,
      ...(description && { description }),
    });

    return response as { data: { payload?: IArea } };
  },

  update: async (id: string, name: string, value: string) => {
    const response = await axios.put(`${protocol}//${baseUrl}/${PROJECT}/areas/${id}`, {
      [name]: value,
    });
    if (response.data.status === "ok") {
      return response as { data: { payload?: IArea } };
    }
  },

  delete: async (id: string, recycledComment: string) => {
    const response = await axios.delete(`${protocol}//${baseUrl}/${PROJECT}/areas/${id}/recycle`, {
      data: { recycledComment },
    });
    return response;
  },
};

export default areas;
