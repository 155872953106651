import { object, Schema } from "yup";

import { stringRules } from "constants/defaultValidatingRules";

export interface DescriptionSchema {
  description: string;
}
export const descriptionSchema: Schema<DescriptionSchema> = object({
  description: stringRules,
});
