import { makeAutoObservable } from "mobx";

import RootStore from "stores/rootStore";

import { NotificationTopic } from "./constants";
import { emailService } from "./service";
import { Unsubscribes } from "./types";

export class EmailStore {
  unsubscribes: Unsubscribes[] | null = null;
  unsubscribesByUserIdAndTopicName = null;

  constructor(private readonly rootStore: RootStore) {
    makeAutoObservable(this);
  }

  get userId(): string {
    return this.rootStore.authStore.userName;
  }

  public setUnsubscribes = (value: Unsubscribes[]) => {
    this.unsubscribes = value;
  };

  public getUnsubscribes = async () => {
    this.setUnsubscribes(await emailService.getUnsubscribes(this.userId));
  };

  public addUnsubscribes = async (topicName: NotificationTopic) => {
    const response = await emailService.addUnsubscribes(this.userId, topicName);
    if (response.body === "successfully") {
      this.setUnsubscribes([...this.unsubscribes!, { topicName, userId: this.userId }]);
    }
  };

  public deleteUnsubscribes = async (topicName: NotificationTopic) => {
    const response = await emailService.deleteUnsubscribes(this.userId, topicName);
    if (response.body === "successfully") {
      this.setUnsubscribes(this.unsubscribes?.filter(un => un.topicName !== topicName)!);
    }
  };
}
