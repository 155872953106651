import React, { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Stack } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { format } from "date-fns";

import { SearchByUserIdsItem } from "stores/userStore/types";

import { LOCALES } from "constants/locales";
import { getFullName } from "helpers/functions";
import { useRootStore } from "hooks/useRootStore";

import { InfoListItem } from "./index";

interface DateAndUserItemInfoContentProps {
  date: Date;
  userId: string;
}

const DateAndUserItemInfoContent: FC<DateAndUserItemInfoContentProps> = ({ date, userId }) => {
  const { t } = useTranslation();
  const {
    usersStore: { getUserById },
  } = useRootStore();

  const [user, setUser] = useState<SearchByUserIdsItem | null>(null);

  useEffect(() => {
    const fetchUser = async () => {
      const res = await getUserById(userId);
      setUser(res);
    };
    fetchUser();
  }, [getUserById, userId]);

  return (
    <Stack spacing={0.7} p={1}>
      <InfoListItem
        title={t(LOCALES.TASKS.CREATED_BY_TITLE)}
        content={
          user !== null ? (
            getFullName(user.given_name, user.family_name)
          ) : (
            <CircularProgress size={20} />
          )
        }
      />
      <InfoListItem
        title={t(LOCALES.TASKS.CREATED_AT)}
        content={format(date, "dd/MM/yyyy HH:mm")}
      />
    </Stack>
  );
};

export default DateAndUserItemInfoContent;
