import React from "react";
import { Select, MenuItem, FormControl, InputLabel, Typography, Box } from "@mui/material";
import FlagRoundedIcon from "@mui/icons-material/FlagRounded";
import { TaskPriority } from "stores/taskStore/types";
import { useTranslation } from "react-i18next";

interface PriorityFieldProps {
  priority: TaskPriority;
  setPriority: (value: TaskPriority) => void;
  isAllowedToEdit: boolean;
}

const selectorStyles = {
  ml: 1.5,
  maxWidth: "100%",
  "& .MuiOutlinedInput-root": { pr: 0 },
  "& .MuiSelect-select.MuiInputBase-input": {
    py: 0.8,
    height: "16px",
    minHeight: "16px",
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: "transparent",
  },
};

export const PriorityField: React.FC<PriorityFieldProps> = ({ priority, setPriority, isAllowedToEdit }) => {
  const { t } = useTranslation();
  const [prioritySelectorOpen, setPrioritySelectorOpen] = React.useState(false);

  return (
    <Box sx={{ display: "flex", alignItems: "center", height: "32px" }}>
      <FlagRoundedIcon />
      <Typography variant="body1" component="div" fontWeight={600} sx={{ ml: 1.2 }}>
        {t("Tasks:Priority")}
      </Typography>
      <FormControl
        disabled={!isAllowedToEdit}
        size="small"
        sx={{
          ...selectorStyles,
          ...(prioritySelectorOpen && { width: "140px" }),
        }}
        focused={prioritySelectorOpen}>
        <Select
          value={priority}
          onOpen={() => setPrioritySelectorOpen(true)}
          onClose={() => setPrioritySelectorOpen(false)}
          onChange={e => setPriority(e.target.value as TaskPriority)}>
          {Object.values(TaskPriority).map(priority => (
            <MenuItem key={priority} sx={prioritySelectorOpen ? { width: "140px" } : undefined} value={priority}>
              <Typography variant="caption" component="div">
                {t(`Tasks:${priority}`)}
              </Typography>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};
