import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import ClearRoundedIcon from "@mui/icons-material/ClearRounded";
import { FormControl, MenuItem, Select, SxProps, Typography } from "@mui/material";

import { ProcessType } from "stores/taskStore/types";

interface ProcessTypesSelectProps {
  processType: ProcessType | "" | undefined;
  setProcessType: (p: ProcessType | null) => void;
  disabled: boolean;
  sx?: SxProps;
  label?: string;
  clearable?: boolean;
}

const ProcessTypesSelect: FC<ProcessTypesSelectProps> = ({
  processType,
  setProcessType,
  disabled,
  sx,
  label,
  clearable,
}) => {
  const { t } = useTranslation();

  return (
    <FormControl sx={sx} size="small" disabled={disabled}>
      <Select
        value={processType}
        onChange={e => setProcessType(e.target.value as ProcessType)}
        label={label}
        endAdornment={
          clearable && !!processType && <ClearRoundedIcon onClick={() => setProcessType(null)} />
        }>
        {Object.values(ProcessType)?.map(pt => (
          <MenuItem value={pt} key={pt}>
            <Typography variant="caption" component="div">
              {t(`Tasks:${pt}`)}
            </Typography>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default ProcessTypesSelect;
