import { useRootStore } from "hooks/useRootStore";
import React, { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import CloseIcon from "@mui/icons-material/Close";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Grow } from "@mui/material";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { observer } from "mobx-react-lite";

import { thumbStyles } from "constants/styles";

const Viewer: FC = () => {
  const { t } = useTranslation();
  const { processingStore } = useRootStore();

  const { processingsForViewer, currentView, setCurrentView, removeProcessingFromViewer } = processingStore;
  const [isMenuVisible, toggleMenuVisible] = useState(false);
  const [showModal, setShowModal] = useState(true);
  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
    processingsForViewer.length > 0 && setCurrentView(processingsForViewer.length - 1);
    toggleMenuVisible(false);
  }, [processingsForViewer.length]);

  const toggleExpand = () => {
    !showModal ? setExpanded(true) : setExpanded(prev => !prev);
    setShowModal(true);
  };

  const handleCurrentView = (index: number) => {
    setCurrentView(index);
    toggleMenuVisible(false);
  };

  const Buttons: FC<{ hidden?: boolean }> = ({ hidden }) => {
    return (
      <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: 80 }}>
        <ExpandLessIcon
          sx={{ ...iconStyles, rotate: hidden ? "180deg" : 0 }}
          onClick={() => setShowModal(prev => !prev)}
        />
        <FullscreenIcon sx={iconStyles} onClick={toggleExpand} />
        <CloseIcon onClick={() => processingStore.setViewerOpen(false)} sx={iconStyles} />
      </Box>
    );
  };

  return (
    <>
      {processingsForViewer?.length > 0 && (
        <Box sx={wrapperStyles(expanded, !showModal)}>
          <Box sx={shortcutMenuStyles}>
            <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
              <MenuOpenIcon onClick={() => toggleMenuVisible(prev => !prev)} sx={iconStyles} />
              <Typography sx={{ ml: 1 }}>{t("Processings:Processings")}</Typography>
            </Box>
            <Grow in={isMenuVisible}>
              <Paper sx={paperStyles} elevation={4}>
                {processingsForViewer.map((view, index) => (
                  <Box key={view.id} sx={processingsStyles(currentView, index)}>
                    <Typography
                      sx={{
                        cursor: "pointer",
                      }}
                      onClick={() => handleCurrentView(index)}>
                      {view.title}
                    </Typography>
                    <CloseIcon onClick={() => removeProcessingFromViewer(view.id)} sx={iconStyles} />
                  </Box>
                ))}
              </Paper>
            </Grow>
            <ViewerIcon />
            <Buttons hidden={showModal} />
          </Box>
          <iframe
            style={{ border: 0 }}
            title="viewer"
            src={processingsForViewer[currentView].url}
            referrerPolicy="strict-origin-when-cross-origin"
            width="100%"
            height="100%"
            allowFullScreen
          />
        </Box>
      )}
      {processingsForViewer?.length > 0 && (
        <Box sx={viewerBtnStyle(showModal)}>
          <ViewerIcon />
          <Buttons />
        </Box>
      )}
    </>
  );
};

const ViewerIcon = () => {
  const { t } = useTranslation();

  return (
    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mr: 0.5 }}>
      <VisibilityIcon sx={{ mr: 0.5 }} />
      <Typography>{t("Processings:Viewer")}</Typography>
    </Box>
  );
};

const shortcutMenuStyles = {
  width: "100%",
  height: "28px",
  backgroundColor: "background.default",
  display: "flex",
  justifyContent: "space-between",
};

const paperStyles = {
  backgroundColor: "background.default",
  m: 1,
  position: "absolute",
  top: 20,
  padding: 0.5,
  zIndex: 10,
  minWidth: 150,
  maxHeight: 200,
  ...thumbStyles,
};

const iconStyles = {
  cursor: "pointer",
  "&:hover": {
    color: "primary.main",
  },
  "&.Mui-disabled": {
    bgcolor: "secondary.main",
    "& .MuiSvgIcon-root": {
      opacity: 0.2,
    },
  },
};

const processingsStyles = (currentView: number, index: number) => ({
  backgroundColor: currentView == index ? "background.paper" : "secondary.dark",
  mb: 0.5,
  borderRadius: 1,
  padding: 0.5,
  display: "flex",
  justifyContent: "space-between",
});

const viewerBtnStyle = (expanded: boolean) => ({
  position: "fixed",
  bottom: expanded ? -30 : 120,
  right: expanded ? -150 : 20,
  backgroundColor: "#000",
  display: "flex",
  justifyContent: "space-between",
  borderRadius: 2,
  padding: 0.3,
});

const wrapperStyles = (expanded: boolean, hidden: boolean) => ({
  height: hidden ? 0 : expanded ? window.innerHeight - 25 : "50%",
  width: hidden ? 0 : expanded ? window.innerWidth : "40%",
  position: "fixed",
  bottom: 25,
  right: 0,
  transition: "height 0.5s, width 0.5s",
  zIndex: 999999,
});

export default observer(Viewer);
