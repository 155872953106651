import React, { FC, useCallback } from "react";
import SearchIcon from "@mui/icons-material/Search";
import { Box } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { observer } from "mobx-react-lite";

import { useRootStore } from "hooks/useRootStore";

import { iconStyles } from "../styles";

export const SearchButton: FC = observer(() => {
  const {
    uiStore: { setIsSearchFieldVisible, isSearchFieldVisible },
  } = useRootStore();

  const handleShowSearchField = useCallback(() => {
    setIsSearchFieldVisible(!isSearchFieldVisible);
  }, [isSearchFieldVisible, setIsSearchFieldVisible]);

  return (
    <Box sx={{ justifyContent: "center", alignItems: "center" }}>
      <IconButton size="small" sx={iconStyles} color="secondary" onClick={handleShowSearchField}>
        <SearchIcon />
      </IconButton>
    </Box>
  );
});
