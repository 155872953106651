import React, { FC, useMemo } from "react";
import { Box, MenuItem, MenuList, Typography } from "@mui/material";
import { observer } from "mobx-react-lite";

import { MapStore } from "stores/mapStore";
import { UIStore } from "stores/uiStore";

import { clipboardCopyPosition } from "helpers/functions";

interface MapItemMenuProps {
  getCenter: MapStore["getCenter"];
  rightClickGraphic: MapStore["rightClickGraphic"];
  setSnackbarOpen: UIStore["setSnackbarOpen"];
}

// you shouldn`t use `useRootStore` here because it`s render not into the main app container
const MapItemMenu: FC<MapItemMenuProps> = ({ getCenter, rightClickGraphic, setSnackbarOpen }) => {
  const position = useMemo(() => rightClickGraphic && getCenter(rightClickGraphic), [getCenter, rightClickGraphic]);

  if (!position) {
    return null;
  }

  const handleCopyPosition = async () => {
    await clipboardCopyPosition(position);
    setSnackbarOpen("General:SuccessfullyCopied", "info");
  };

  return (
    <Box>
      <MenuList sx={{ mt: 0, pt: 0 }}>
        <MenuItem sx={{ mt: 0, pt: 0, "&:hover": { backgroundColor: "#333" } }} onClick={handleCopyPosition}>
          <Typography sx={{ mt: "15px" }} component={"span"} variant={"caption"}>
            {position[0]}, {position[1]}
          </Typography>
        </MenuItem>
      </MenuList>
    </Box>
  );
};

export default observer(MapItemMenu);
