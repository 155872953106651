import axios from "axios";

import { clientId } from "configs/servicesConfig";

import { useRootStore } from "hooks/useRootStore";

export const useAxiosInterceptorConfig = () => {
  const rootStore = useRootStore();

  axios.interceptors.request.use(
    async axiosConfig => {
      const user = localStorage.getItem(`CognitoIdentityServiceProvider.${clientId}.LastAuthUser`);
      const token = localStorage.getItem(`CognitoIdentityServiceProvider.${clientId}.${user}.idToken`);
      if (axiosConfig.headers) {
        axiosConfig.headers.Authorization = `Bearer ${token}`;
      }
      return axiosConfig;
    },
    async error => {
      await Promise.reject(error);
    },
  );

  axios.interceptors.response.use(
    response => {
      return response;
    },
    async error => {
      const originalRequest = error.config;
      console.log(error.response);
      if (error.response?.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;
        const accessToken = await rootStore.authStore.refreshToken();
        originalRequest.headers.Authorization = `Bearer ${accessToken}`;
        return await axios(originalRequest);
      }

      return error;
    }
  );
};
