import React, { FC } from "react";
import { Stack, TypographyVariant } from "@mui/material";
import Typography from "@mui/material/Typography";

const DEFAULT_TYPOGRAPHY_VARIANT: TypographyVariant = "body1";

interface InfoListItemProps {
  title: string;
  content: React.ReactNode;
  typographyVariant?: TypographyVariant;
}

const InfoListItem: FC<InfoListItemProps> = ({ title, content, typographyVariant }) => {
  const variant = typographyVariant ?? DEFAULT_TYPOGRAPHY_VARIANT;
  const contentIsTypography = typeof content === "string" || typeof content === "number";

  return (
    <Stack direction={"row"} spacing={1}>
      <Typography variant={variant} fontWeight={"bold"}>
        {title}:
      </Typography>
      {contentIsTypography ? <Typography variant={variant}>{content}</Typography> : content}
    </Stack>
  );
};

export default InfoListItem;
