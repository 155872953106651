import CSVFileValidator from "csv-file-validator";

import { FILE_TYPES } from "stores/fileStore/constants";
import { FixPointUpload } from "stores/fixPointStore/types";
import RootStore from "stores/rootStore";

const requiredError = (e: string): string => {
  console.error("requiredError", e);
  return e;
};
const uniqueError = (e: string): string => {
  console.error("uniqueError", e);
  return e;
};
const validateError = (e: string): string => {
  console.error("validateError", e);
  return e;
};
const headers: string[] = [
  "name",
  "lat",
  "lon",
  "altitude(ell)",
  "horizontalaccuracy",
  "vertivalaccuracy",
  "verticalaccuracy",
  "east",
  "north",
  "height(projectsystem)",
  "horizontalsystem",
  "veritcalsystem",
  "höhenkorrektur",
  "duration",
];
const isCoordsValid = function (coord: string) {
  const reqExp = /^\d+\.\d+$/;
  const modifiedCoord = coord.replace(/\s+/g, "").toLowerCase();
  return reqExp.test(coord) || headers.includes(modifiedCoord);
};
const isNameValid = (name: string) => {
  return !!name.length;
};
const isHorizontalSystemValid = (hs: string) => {
  const regExp = /^\d+$/;
  return hs === "UTM" || hs === "GK" || regExp.test(hs) || hs === "horizontal system";
};
const isVerticalSystemValid = (vs: string) => {
  return !!vs.length || vs === "vertical system";
};

const completely = {
  headers: [
    {
      name: "name",
      inputName: "name",
      required: true,
      requiredError,
      unique: true,
      uniqueError,
      validate: isNameValid,
      validateError,
    },
    {
      name: "coord1",
      inputName: "coord1",
      required: true,
      requiredError,
      validate: isCoordsValid,
      validateError,
    },
    {
      name: "coord2",
      inputName: "coord2",
      required: true,
      requiredError,
      validate: isCoordsValid,
      validateError,
    },
    {
      name: "coord3",
      inputName: "coord3",
      required: true,
      requiredError,
      validate: isCoordsValid,
      validateError,
    },
    {
      name: "coord4",
      inputName: "coord4",
      required: true,
      requiredError,
      validate: isCoordsValid,
      validateError,
    },
    {
      name: "coord5",
      inputName: "coord5",
      required: true,
      requiredError,
      validate: isCoordsValid,
      validateError,
    },
    {
      name: "coord6",
      inputName: "coord6",
      required: true,
      requiredError,
      validate: isCoordsValid,
      validateError,
    },
    {
      name: "coord7",
      inputName: "coord7",
      required: true,
      requiredError,
      validate: isCoordsValid,
      validateError,
    },
    {
      name: "coord8",
      inputName: "coord8",
      required: true,
      requiredError,
      validate: isCoordsValid,
      validateError,
    },
    {
      name: "horizontalSystem",
      inputName: "horizontalSystem",
      required: true,
      requiredError,
      validate: isHorizontalSystemValid,
      validateError,
    },
    {
      name: "verticallSystem",
      inputName: "verticalSystem",
      required: true,
      requiredError,
      validate: isVerticalSystemValid,
      validateError,
    },
    {
      name: "heightCorrection",
      inputName: "heightCorrection",
      required: true,
      requiredError,
      validate: isCoordsValid,
      validateError,
    },
    {
      name: "duration",
      inputName: "duration",
      required: true,
      requiredError,
      validate: isCoordsValid,
      validateError,
    },
  ],
  isHeaderNameOptional: true,
};
const xyz = {
  headers: [
    {
      name: "name",
      inputName: "name",
      required: true,
      requiredError,
      unique: true,
      uniqueError,
      validate: isNameValid,
      validateError,
    },
    {
      name: "coord1",
      inputName: "coord1",
      required: true,
      requiredError,
      validate: isCoordsValid,
      validateError,
    },
    {
      name: "coord2",
      inputName: "coord2",
      required: true,
      requiredError,
      validate: isCoordsValid,
      validateError,
    },
    {
      name: "coord3",
      inputName: "coord3",
      required: true,
      requiredError,
      validate: isCoordsValid,
      validateError,
    },
  ],
  isHeaderNameOptional: true,
};
const inShortWithAccuracies = {
  headers: [
    {
      name: "name",
      inputName: "name",
      required: true,
      requiredError,
      unique: true,
      uniqueError,
      validate: isNameValid,
      validateError,
    },
    {
      name: "coord1",
      inputName: "coord1",
      required: true,
      requiredError,
      validate: isCoordsValid,
      validateError,
    },
    {
      name: "coord2",
      inputName: "coord2",
      required: true,
      requiredError,
      validate: isCoordsValid,
      validateError,
    },
    {
      name: "coord3",
      inputName: "coord3",
      required: true,
      requiredError,
      validate: isCoordsValid,
      validateError,
    },
    {
      name: "coord4",
      inputName: "coord4",
      required: true,
      requiredError,
      validate: isCoordsValid,
      validateError,
    },
    {
      name: "coord5",
      inputName: "coord5",
      required: true,
      requiredError,
      validate: isCoordsValid,
      validateError,
    },
  ],
  isHeaderNameOptional: true,
};

function csvToArray(str: string, delimiter = ",") {
  const headers = str.slice(0, str.indexOf("\n")).split(delimiter);
  return headers.length;
}
export const CSVValidator = async (files: FileList, rootStore: RootStore) => {
  const file = files[0];
  const reader = new FileReader();
  reader.onload = async e => {
    const text: string | ArrayBuffer = e.target!.result!;
    const headersLength = typeof text === "string" && csvToArray(text);

    CSVFileValidator(
      file,
      (headersLength === 4 && xyz) || (headersLength === 6 && inShortWithAccuracies) || completely
    ).then(csvData => {
      if (csvData.inValidData.length > 0) rootStore.uiStore.setSnackbarOpen("Files:ErrorCSV", "error");
      else {
        rootStore.fileStore.setFilesFromInputs(Array.from(files), FILE_TYPES.GCP);
        rootStore.uiStore.setSnackbarClose();
      }
    });
  };
  reader.readAsText(file);
};


const isNumber = (value: string): boolean => !isNaN(Number(value));

const CSVConfig = {
  headers: [
    { name: "Name", inputName: "name", required: true },
    { name: "X", inputName: "x", required: true },
    { name: "Y", inputName: "y", required: true },
    { name: "Z", inputName: "z", required: true },
  ],
  isHeaderNameOptional: true,
};

export async function validateFixPointCsv(file: File): Promise<FixPointUpload[]> {
  return CSVFileValidator(file, CSVConfig)
    .then(csvData => {
      const fixPoints = csvData.data as FixPointUpload[];
      for (const fixPoint of fixPoints) {
        if (!isNumber(fixPoint.x.toString()) || !isNumber(fixPoint.y.toString()) || !isNumber(fixPoint.z.toString())) {
          throw new Error("Invalid format: X, Y, and Z must be numbers.");
        }
      }
      return fixPoints;
    })
    .catch(error => {
      console.error(error);
      throw new Error("Invalid CSV format: " + error.message);
    });
}
