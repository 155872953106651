import React, { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import TuneRoundedIcon from "@mui/icons-material/TuneRounded";
import { Badge, Button, Stack } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import TextField from "@mui/material/TextField";

import { Filters, TaskStore } from "stores/taskStore";
import { ProcessType, TaskPriority } from "stores/taskStore/types";

import { LOCALES } from "constants/locales";

import RangeDatePicker from "./RangeDatePicker";

interface TasksFiltersProps {
  filters: Filters;
  setFilter: TaskStore["setFilter"];
  resetFilters: () => void;
}

interface Option<T> {
  id: T;
  label: string;
}

const TasksFilters: FC<TasksFiltersProps> = ({ filters, setFilter, resetFilters }) => {
  const { t } = useTranslation();

  const isHaveFilters = Object.values(filters).some(v => v !== null && v !== false);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const processTypeOptions = useMemo<Array<Option<ProcessType>>>(
    () => Object.values(ProcessType)?.map(pt => ({ id: pt, label: t(`Tasks:${pt}`) })),
    [t]
  );
  const priorityOptions = useMemo<Array<Option<TaskPriority>>>(
    () => Object.values(TaskPriority).map(priority => ({ id: priority, label: t(`Tasks:${priority}`) })),
    [t]
  );

  const ariaLabelledby = "open-filters-button";

  return (
    <>
      <Badge variant={"dot"} color={"error"} invisible={!isHaveFilters}>
        <IconButton
          id={ariaLabelledby}
          aria-controls={open ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}>
          <TuneRoundedIcon sx={{ color: "#fff" }} />
        </IconButton>
      </Badge>
      <Menu
        id={ariaLabelledby}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}>
        <Stack spacing={0.5} p={1} maxWidth={370}>
          <Autocomplete
            size={"small"}
            sx={autocompleteStyles}
            value={processTypeOptions.find(o => o.id === filters.processType) ?? null}
            onChange={(_, value) => setFilter("processType", value?.id ?? null)}
            renderInput={params => <TextField label={t(LOCALES.TASKS.PROCESS_TYPE.IDNEX)} {...params} />}
            options={processTypeOptions}
          />
          <Autocomplete
            size={"small"}
            sx={autocompleteStyles}
            value={priorityOptions.find(o => o.id === filters.priority) ?? null}
            onChange={(_, value) => setFilter("priority", value?.id ?? null)}
            renderInput={params => <TextField label={t(LOCALES.TASKS.PRIORITY)} {...params} />}
            options={priorityOptions}
          />
          <RangeDatePicker
            startDateValue={filters.createdAtDateStart}
            endDateValue={filters.createdAtDateEnd}
            setStartDateValue={d => setFilter("createdAtDateStart", d)}
            setEndDateValue={d => setFilter("createdAtDateEnd", d)}
            startLabel={t(LOCALES.TASKS.CREATED_AT_FROM)}
            endLabel={t(LOCALES.TASKS.CREATED_AT_TO)}
          />
          <RangeDatePicker
            startDateValue={filters.dueDateAtDateStart}
            endDateValue={filters.dueDateAtDateEnd}
            setStartDateValue={d => setFilter("dueDateAtDateStart", d)}
            setEndDateValue={d => setFilter("dueDateAtDateEnd", d)}
            startLabel={t(LOCALES.TASKS.DUE_DATE_FROM)}
            endLabel={t(LOCALES.TASKS.DUE_DATE_TO)}
          />
          <FormControlLabel
            control={<Checkbox checked={filters.isCreator} onChange={e => setFilter("isCreator", e.target.checked)} />}
            label={t(LOCALES.TASKS.IS_CREATOR)}
          />
          <FormControlLabel
            control={
              <Checkbox checked={filters.isAssigned} onChange={e => setFilter("isAssigned", e.target.checked)} />
            }
            label={t(LOCALES.TASKS.IS_ASSIGNED)}
          />
          <FormControlLabel
            control={
              <Checkbox checked={filters.isReviewer} onChange={e => setFilter("isReviewer", e.target.checked)} />
            }
            label={t(LOCALES.TASKS.IS_REVIEWER)}
          />
          <Box width={"100%"} display={"flex"} justifyContent={"flex-end"}>
            <Button onClick={resetFilters}>Reset</Button>
          </Box>
        </Stack>
      </Menu>
    </>
  );
};

const autocompleteStyles = { width: "100%", "& .MuiSvgIcon-root": { color: "#fff" } };

export default TasksFilters;
