import React, { FC, useEffect, useState } from "react";
import { Portal, SxProps, Theme } from "@mui/material";
import Box from "@mui/material/Box";
import { observer } from "mobx-react-lite";
import useResizeObserver from "use-resize-observer";

import { CARD_CONTAINER_ID } from "components/Tasks/TaskDetails/constants";
import { thumbStyles } from "constants/styles";

import CommentsList from "./CommentsList";
import WriteComment from "./WriteComment";
import { Task } from "stores/taskStore/types";

interface CommentsProps {
  task: Task;
}

const Comments: FC<CommentsProps> = ({ task }) => {
  const { ref, height } = useResizeObserver();

  const [cardContainer, setCardContainer] = useState<HTMLElement | null>(null);

  useEffect(() => {
    const a = document.getElementById(CARD_CONTAINER_ID);
    setCardContainer(a);
  }, []);

  return (
    <Box sx={wrapperStyles}>
      <Box sx={commentsContainerStyles(height ?? 0)}>
        <CommentsList taskId={task.id} />
      </Box>
      {cardContainer && (
        <Portal container={cardContainer}>
          <Box sx={writeCommentContainer} ref={ref}>
            <WriteComment task={task} />
          </Box>
        </Portal>
      )}
    </Box>
  );
};

const wrapperStyles: SxProps<Theme> = {
  position: "relative",
  display: "flex",
  flexFlow: "column",
  width: "100%",
  // flex: 1,
};

const commentsContainerStyles: (height: number) => SxProps<Theme> = height => ({
  // height: "30vh",
  paddingBottom: `${height}px`,
  pr: 1,
  ...thumbStyles,
});

const writeCommentContainer: SxProps<Theme> = {
  position: "absolute",
  bottom: 10,
  pt: 0.5,
  backgroundColor: "background.default",
  width: "94%",
};

export default observer(Comments);
