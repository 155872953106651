import React, { FC, PropsWithChildren, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import Box from "@mui/material/Box";
import { observer } from "mobx-react-lite";

import { GraphicId } from "stores/mapStore/types";
import { useRootStore } from "hooks/useRootStore";

import CardExpandingContainer from "common/Card/CardExpandingContainer";
import { PATHS } from "router/paths";

import CardBreadcrumbs from "./CardBreadcrumbs";
import { POINT_TOOL_QUERY_SELECTOR } from "./constants";

const Area: FC<PropsWithChildren> = ({ children }) => {
  const navigate = useNavigate();

  const { constructionSiteStore, jobStore, processingStore, areaStore, mapStore } = useRootStore();

  const {
    isAllMapInit,
    utils: { clearGraphicLayer, removeGraphicsByGraphicType },
  } = mapStore;
  const { setIsCardOpen, isCardOpen } = constructionSiteStore;
  const { area, createMode, editMode } = areaStore;
  const { processing } = processingStore;
  const { _setJobs } = jobStore;

  const handleClose = async () => {
    navigate(PATHS.HOME.INDEX);
    await mapStore.clearCSAndNestedAndFetchAllCS();
  };

  const handleBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    const toolsPanel = document.querySelectorAll(".esri-sketch__section")[1];
    if (toolsPanel) {
      const pointTool = toolsPanel.querySelector<HTMLElement>(POINT_TOOL_QUERY_SELECTOR);

      if (!pointTool) {
        return;
      }

      if (createMode || editMode) {
        pointTool.style.pointerEvents = "none";
      } else {
        pointTool.style.pointerEvents = "auto";
      }
    }
  }, [createMode, editMode]);

  useEffect(() => {
    if (isAllMapInit) {
      _setJobs([]);
      clearGraphicLayer();
      removeGraphicsByGraphicType(GraphicId.job);
    }
  }, [isAllMapInit, area?.id, _setJobs, clearGraphicLayer, removeGraphicsByGraphicType]);

  return (
    <CardExpandingContainer open={isCardOpen} onOpen={() => setIsCardOpen(true)} onClose={() => setIsCardOpen(false)}>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <CardBreadcrumbs />
        {processing ? (
          <ArrowBackRoundedIcon sx={{ cursor: "pointer" }} onClick={handleBack} />
        ) : (
          <CloseRoundedIcon sx={{ cursor: "pointer" }} onClick={handleClose} />
        )}
      </Box>
      {children}
    </CardExpandingContainer>
  );
};

export default observer(Area);
