import { LOCALES } from "constants/locales";
import { PATHS } from "router/paths";

import { HandleFunction, HandlingResponseStatus } from "./types";

export const statusErrorsHandlers: Record<number, HandleFunction> = {
  403: (id, setSnackbarOpen, navigate) => {
    console.warn(`Element with \`id\` ${id} is forbidden`);
    setSnackbarOpen(LOCALES.ERRORS.NOT_ACCESS, "warning");
    navigate(PATHS.HOME.INDEX);
  },
  404: (id, setSnackbarOpen, navigate) => {
    console.warn(`Element with \`id\` ${id} not found`);
    setSnackbarOpen(LOCALES.ERRORS.NOT_FOUND, "warning");
    navigate(PATHS.HOME.INDEX);
  },
};
