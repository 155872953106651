import React, { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import { Box, Typography } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { observer } from "mobx-react-lite";

import { IParameters } from "stores/managerService/processingStore/types";

import { useRootStore } from "hooks/useRootStore";

export const SingleSelect: FC<{ parameter: IParameters }> = observer(({ parameter }) => {
  const { t } = useTranslation();
  const { processingStore } = useRootStore();
  const [value, setValue] = useState("");
  const { parameterType, parameterId, dropDownValues } = parameter;

  useEffect(() => {
    processingStore.setUpdatedParams(Number(value), true, parameterType, Object.keys(dropDownValues));
  }, [value]);

  return (
    <Box key={parameterId}>
      <Typography>{t(`Processings:Parameters:${parameterId}:description`)}</Typography>
      <FormControl sx={{ my: 1 }} fullWidth required>
        <InputLabel>{t(`Processings:Parameters:${parameterId}:parameterName`)}</InputLabel>
        <Select
          value={value}
          label={t(`Processings:Parameters:${parameterId}:parameterName`)}
          defaultValue=""
          onChange={e => {
            setValue(e.target.value);
          }}
          IconComponent={KeyboardArrowDownRoundedIcon}>
          {Object.keys(dropDownValues).map(e => (
            <MenuItem value={e} key={e}>
              {t(`Processings:Parameters:${e}:parameterName`)}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
});
