import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { observer } from "mobx-react-lite";

import Email from "./Email";
import Password from "./Password";

export const Account = observer(() => {
  const { t } = useTranslation();
  return (
    <Box>
      <Typography variant="h6" component="div">
        {t("Settings:Account")}
      </Typography>
      <Email />
      <Password />
      {/* <StartOnboarding /> */}
    </Box>
  );
});
