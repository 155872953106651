import React, { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { Box, Checkbox, FormControlLabel } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import Typography from "@mui/material/Typography";
import { observer } from "mobx-react-lite";

import { IParameters } from "stores/managerService/processingStore/types";

import { useRootStore } from "hooks/useRootStore";

export const MultipleSelect: FC<{ parameter: IParameters }> = observer(({ parameter }) => {
  const { t } = useTranslation();
  const { processingStore } = useRootStore();
  const [value, setValue] = useState<{ [key: string]: boolean }>({});
  const { parameterId, dropDownValues } = parameter;

  return (
    <Box sx={{ mt: 1 }}>
      <Typography variant="body1" sx={{ mb: 0.25 }}>
        {t(`Processings:Parameters:${parameterId}:description`)}
      </Typography>
      <FormControl sx={{ ml: 0.5 }}>
        {Object.keys(dropDownValues).map(i => (
          <FormControlLabel
            key={i}
            label={t(`Processings:Parameters:${i}:parameterName`)}
            control={
              <Checkbox
                checked={value[i] || false}
                onChange={e => {
                  const {
                    target: { checked },
                  } = e;
                  setValue(prev => ({
                    ...prev,
                    [i]: checked,
                  }));
                  processingStore.setUpdatedParams(Number(i), checked);
                }}
              />
            }
          />
        ))}
      </FormControl>
    </Box>
  );
});
