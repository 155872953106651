import { makeAutoObservable, observe } from "mobx";

import RootStore from "stores/rootStore";

import { LOCALES } from "constants/locales";
import { getUndefinedSingleOrMultiple } from "helpers/functions";

import * as tasks from "./service";
import { Task, TaskExportType } from "./types";

type TaskId = Task["id"];
type TasksObject = Record<TaskId, Task>;

export class TasksExportStore {
  public exportMode: boolean = false;
  private tasksForExportMap: TasksObject = {};
  public get tasksForExport(): Task[] {
    return Object.values(this.tasksForExportMap);
  }

  public readonly setExportMode = (isExport: boolean) => {
    this.exportMode = isExport;
  };

  public readonly toggleExportMode = () => {
    this.setExportMode(!this.exportMode);
  };

  public readonly addExportTask = (task: Task): void => {
    this.tasksForExportMap[task.id] = task;
  };

  public readonly deleteExportTask = (taskId: string): void => {
    try {
      // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
      delete this.tasksForExportMap[taskId];
    } catch (e: any) {
      this.rootStore.uiStore.setSnackbarOpen(LOCALES.ERRORS.SOME_ERROR);
      console.error(e.message);
    }
  };

  public readonly setTasksForExport = (tasks: TasksObject) => {
    this.tasksForExportMap = tasks;
  };

  public readonly toggleExportTask = (task: Task) => {
    if (this.isSelected(task.id)) {
      this.deleteExportTask(task.id);
    } else {
      this.addExportTask(task);
    }
  };

  public readonly clearTasksForExport = () => {
    this.setTasksForExport({});
  };

  public readonly disableExportMode = () => {
    this.setExportMode(false);
    this.clearTasksForExport();
  };

  public readonly isSelected = (taskId: string): boolean => {
    return this.tasksForExportMap[taskId] !== undefined;
  };

  constructor(private readonly rootStore: RootStore) {
    makeAutoObservable(this);
    this.exportTasks = this.exportTasks.bind(this);
    this.observeChangingExportMode();
  }

  public exportTasks(exportType: TaskExportType.BCF): Promise<void>;
  public exportTasks(exportType: TaskExportType.JSON | TaskExportType.CSV, csId: string): Promise<void>;
  public async exportTasks(exportType: TaskExportType, csId?: string): Promise<void> {
    switch (exportType) {
      case TaskExportType.BCF: {
        await this.bcfExport(this.tasksForExport);
        break;
      }
      case TaskExportType.JSON: {
        // the second signature of the method has a required "csId" param
        await this.jsonExport(csId!, this.tasksForExport);
        break;
      }
      case TaskExportType.CSV: {
        // the second signature of the method has a required "csId" param
        await this.csvExport(csId!, this.tasksForExport);
        break;
      }
    }

    this.clearTasksForExport();
  }

  // todo: make the method private
  public readonly bcfExport = async (tasksForExport: Task[]): Promise<void> => {
    const response = await tasks.bcfExport(tasksForExport);
    await this.rootStore.fileStore.downloadFileFromURL(response.payload!.downloadLink);
    this.resetExportMode();
  };

  private readonly jsonExport = async (csId: string, tasksForExport: Task[]): Promise<void> => {
    const _tasksForExport = getUndefinedSingleOrMultiple(tasksForExport.map(t => t.id));
    const res = await tasks.jsonOrCsvExport(TaskExportType.JSON, csId, _tasksForExport);
    const url = this.rootStore.fileStore.createBlobDownloadLink(res.toString());
    const cs = await this.rootStore.constructionSiteStore.getConstructionSite(csId);
    this.rootStore.fileStore.downloadBlobFile(url, "json", cs?.title ?? csId);
    this.resetExportMode();
  };

  private readonly csvExport = async (csId: string, tasksForExport: Task[]): Promise<void> => {
    const _tasksForExport = getUndefinedSingleOrMultiple(tasksForExport.map(t => t.id));
    const res = await tasks.jsonOrCsvExport(TaskExportType.CSV, csId, _tasksForExport);
    const url = this.rootStore.fileStore.createBlobDownloadLink(res.toString());
    const cs = await this.rootStore.constructionSiteStore.getConstructionSite(csId);
    this.rootStore.fileStore.downloadBlobFile(url, "csv", cs?.title ?? csId);
    this.resetExportMode();
  };

  private readonly resetExportMode = (): void => {
    this.exportMode = false;
    this.clearTasksForExport();
  };

  // observers
  private readonly observeChangingExportMode = () => {
    observe(this, "exportMode", change => {
      if (change.newValue === false) {
        this.resetExportMode();
      }
    });
  };
}
