import { Member } from "stores/managerService/teamStore/types";

export type MembersMap = Record<MemberId, Member>;

type MemberId = string;

export enum UserChangeSystemComments {
  AssigneeOrganizationChange = "AssigneeOrganizationChange",
  ReviewerOrganizationChange = "ReviewerOrganizationChange",
  AssigneeOrganizationSet = "AssigneeOrganizationSet",
  ReviewerOrganizationSet = "ReviewerOrganizationSet",
  AssigneeChange = "AssigneeChange",
  ReviewerChange = "ReviewerChange",
  AssigneeSet = "AssigneeSet",
  ReviewerSet = "ReviewerSet",
}
