import { useCallback, useRef, useState } from "react";

import { FetchingState } from "./types";

type FetchFun<T> = () => Promise<T>;

/**
 * @param fetchFun
 * @returns {[FetchFun<T>, isLoadingInProgress, isLoaded]}
 */
const useFetchWithLoading = <T extends (...args: any) => Promise<any>>(fetchFun: T): [T, ...FetchingState] => {
  const [isLoading, setIsLoading] = useState(false);
  const isLoadedRef = useRef(false);

  const fetchWithLoading = useCallback(
    async (...args: Parameters<T>) => {
      setIsLoading(true);
      const res = await fetchFun(...args);
      isLoadedRef.current = true;
      setIsLoading(false);

      return res;
    },
    [fetchFun]
  );

  return [fetchWithLoading as T, isLoading, isLoadedRef.current];
};

export default useFetchWithLoading;
