import React, { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import SubjectRoundedIcon from "@mui/icons-material/SubjectRounded";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import { useRootStore } from "hooks/useRootStore";

const Description: FC = () => {
  const { t } = useTranslation();
  const { areaStore, teamStore } = useRootStore();

  const { area } = areaStore;

  const [description, setDescription] = useState(area?.description || "");
  const [expanded, setExpanded] = useState(false);
  const finishEditing = async () => {
    if (!area?.id) {
      return;
    }
    await areaStore.updateDescription(area.id, description);
  };
  const { csPermissions } = teamStore;

  useEffect(() => {
    if (description !== area?.description) {
      setDescription(area?.description ?? "");
    }
  }, [area, description]);

  return (
    <Box sx={{ flexShrink: 0 }}>
      <Box sx={wrapperStyles}>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <SubjectRoundedIcon />
          <Typography variant="body1" component="div" fontWeight={600} sx={{ ml: 0.8 }}>
            {t("General:Description")}
          </Typography>
        </Box>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Button
            size="small"
            variant="contained"
            color="secondary"
            sx={{ p: 0, ml: "8px", minWidth: "0px", minHeight: "0px" }}
            onClick={() => setExpanded(!expanded)}>
            <KeyboardArrowDownRoundedIcon
              sx={
                expanded
                  ? {
                      transform: "rotate(180deg)",
                      transition: "transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                    }
                  : {
                      transform: "rotate(0deg)",
                      transition: "transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                    }
              }
            />
          </Button>
        </Box>
      </Box>
      {expanded && (
        <TextField
          size="small"
          fullWidth
          multiline
          sx={editableDescriptionStyles}
          value={description}
          onChange={e => setDescription(e.target.value)}
          onBlur={finishEditing}
          inputProps={{ maxLength: 255 }}
          placeholder={t("General:TypeHere")}
          disabled={!csPermissions.creating}>
          {area?.description}
        </TextField>
      )}
    </Box>
  );
};

const wrapperStyles = {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  my: 1,
  ml: "-3px",
};

const editableDescriptionStyles = {
  ml: "-5px",
  "& .MuiInputBase-root": {
    padding: "4px 5px",
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: "transparent",
  },
};

export default Description;
