import React, { FC } from "react";
import { Box, BoxProps, SxProps, Theme } from "@mui/material";

const PRIMARY_COLOR = "#2b2b2b";
const SECONDARY_COLOR = "#202020";
const FILL_COLOR = "#9f9f9f";

interface ToolbarItemContainerProps {
  width?: string | number;
}

const ToolbarItemContainer: FC<ToolbarItemContainerProps & BoxProps> = ({ children, width, ...props }) => {
  return (
    <Box {...props} sx={getContainerStyles(width)}>
      {children}
    </Box>
  );
};

const getContainerStyles: (width?: string | number) => SxProps<Theme> = width => ({
  position: "relative",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: width === undefined ? 32 : "auto",
  height: 32,
  bgcolor: PRIMARY_COLOR,
  color: FILL_COLOR,
  fill: FILL_COLOR,
  cursor: "pointer",
  "&:hover": { bgcolor: SECONDARY_COLOR },
});

export default ToolbarItemContainer;
