import { FC, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { observer } from "mobx-react-lite";

import { Task } from "stores/taskStore/types";

import { thumbStyles } from "constants/styles";
import { useRootStore } from "hooks/useRootStore";

import EmptyCard from "./Card/EmptyCard";
import Card from "./Card";

const Tasks: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    authStore,
    constructionSiteStore: { constructionSites, getAll },
    taskStore: { getAllByAssignedTo, tasksByAssignedTo, todayTasks, overdueTasks, recentlyAssignedTasks },
    teamStore: { setConstructionSiteTeamMembers },
  } = useRootStore();

  const { userName } = authStore;

  useEffect(() => {
    !constructionSites && getAll();
  }, [constructionSites, getAll]);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    getAllByAssignedTo(userName);
    return () => setConstructionSiteTeamMembers(null);
  }, [getAllByAssignedTo, setConstructionSiteTeamMembers, userName]);

  const openConstructionSiteTasks = (constructionSiteId: string) => {
    navigate(`${constructionSiteId}`);
  };

  return (
    <Box sx={containerStyles}>
      <Header text="Tasks:Today" />
      {tasksByAssignedTo ? (
        <Grid container spacing={1}>
          {todayTasks && todayTasks.length > 0 ? (
            todayTasks.map(task => <TaskList key={task.id} task={task} />)
          ) : (
            <TaskList empty />
          )}
        </Grid>
      ) : (
        <ConfiguredCircularProgress />
      )}
      <Header text="Tasks:Overdue" marginTop />
      {tasksByAssignedTo ? (
        <Grid container spacing={1}>
          {overdueTasks && overdueTasks.length > 0 ? (
            overdueTasks?.map(task => <TaskList key={task.id} task={task} />)
          ) : (
            <TaskList empty />
          )}
        </Grid>
      ) : (
        <ConfiguredCircularProgress />
      )}
      <Header text="Tasks:RecentlyAssigned" marginTop />
      {tasksByAssignedTo ? (
        <Grid container spacing={1}>
          {recentlyAssignedTasks ? (
            recentlyAssignedTasks.slice(0, 8).map(task => <TaskList key={task.id} task={task} />)
          ) : (
            <TaskList empty />
          )}
        </Grid>
      ) : (
        <ConfiguredCircularProgress />
      )}
      <Header text="ConstructionSite:Plural" marginTop />
      {constructionSites ? (
        <>
          {constructionSites?.length === 0 ? (
            <Typography variant="body1" component="div" sx={{ mb: 2 }}>
              {t("ConstructionSite:NoConstructionSites")}
            </Typography>
          ) : (
            <Grid container spacing={1} sx={{ mb: 2 }}>
              {constructionSites?.map(constructionSite => (
                <Grid item key={constructionSite.id} xs={3}>
                  <Button
                    component="div"
                    variant="contained"
                    sx={itemStyles}
                    onClick={() => openConstructionSiteTasks(constructionSite.id)}>
                    <Typography variant="body1" component="div">
                      {constructionSite.title}
                    </Typography>
                  </Button>
                </Grid>
              ))}
            </Grid>
          )}
        </>
      ) : (
        <ConfiguredCircularProgress />
      )}
    </Box>
  );
};

const Header: FC<{ text: string; marginTop?: boolean }> = ({ text, marginTop }) => {
  const { t } = useTranslation();
  return (
    <Typography variant="h5" component="div" sx={{ mt: marginTop ? 4 : 0, mb: 2 }}>
      {t(`${text}`)}
    </Typography>
  );
};

const TaskList: FC<{ task?: Task; empty?: boolean }> = ({ task, empty }) => {
  return (
    <Grid sx={{ width: 275, minWidth: 275, height: 207 }} item xs={3}>
      {empty || !task ? <EmptyCard /> : <Card task={task} />}
    </Grid>
  );
};

const ConfiguredCircularProgress: FC = () => <CircularProgress size={30} sx={{ color: "text.primary" }} />;

const containerStyles = {
  pt: 2,
  pr: 2,
  pl: 4,
  overflow: "auto",
  ...thumbStyles,
};

const itemStyles = {
  p: 2,
  width: "100%",
  height: "100%",
  borderRadius: "10px",
  bgcolor: "secondary.light",
  textAlign: "center",
  cursor: "pointer",
  "&:hover": { bgcolor: "rgba(255, 255, 255, 0.2)" },
  "& .MuiTypography-root": {
    textTransform: "none",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
} as const;

export default observer(Tasks);
