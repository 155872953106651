import React, { FC } from "react";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import IconButton from "@mui/material/IconButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

interface AttachmentsItemActions {
  anchorEl: null | HTMLElement;
  open: boolean;
  handleMenuButtonClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  handleClose: () => void;
  actions: Action[];
  disabled: boolean;
}

interface Action {
  func: () => void;
  icon: React.ReactNode;
  title: string;
  disabled?: boolean;
}

const AttachmentsItemActions: FC<AttachmentsItemActions> = ({
  anchorEl,
  open,
  handleMenuButtonClick,
  handleClose,
  actions,
  disabled,
}) => {
  return (
    <>
      <IconButton
        id="attachments-button"
        aria-controls={open ? "attachments-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open && "true"}
        onClick={handleMenuButtonClick}
        sx={iconStyles}
        disabled={disabled}>
        <MoreHorizIcon fontSize="small" />
      </IconButton>
      <Menu
        id="attachments-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "attachments-button",
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        PaperProps={{
          elevation: 1,
          sx: menuStyles,
        }}>
        {actions.map(({ func, icon, title, disabled }) => (
          <MenuItem key={title} sx={menuItemStyles} onClick={func} disabled={disabled}>
            <ListItemIcon>{icon}</ListItemIcon>
            <ListItemText>{title}</ListItemText>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

const menuStyles = {
  minWidth: "126px",
  borderRadius: "5px",
  bgcolor: "background.default",
};

const menuItemStyles = {
  "& .MuiSvgIcon-root": { color: "text.primary" },
  "& .MuiTypography-root": { fontSize: "15px" },
};

const iconStyles = {
  ml: 1,
  bgcolor: "secondary.light",
  color: "text.primary",
  "& .MuiSvgIcon-root": {
    color: "text.primary",
    transition: "color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
  },
  "&:hover": {
    bgcolor: "secondary.light",
    "& .MuiSvgIcon-root": {
      color: "primary.main",
    },
  },
  "&.Mui-disabled": {
    bgcolor: "secondary.main",
    "& .MuiSvgIcon-root": {
      opacity: 0.2,
    },
  },
};

export default AttachmentsItemActions;
