import React, { FC } from "react";
import { Box } from "@mui/material";
import { observer } from "mobx-react-lite";
import { BreadcrumbSteps } from "types";

import { useRootStore } from "hooks/useRootStore";

import { MembersButton } from "./Members/MembersButton";
import { SearchButton } from "./Search/SearchButton";
import { SearchField } from "./Search/SearchField";
import { SortButton } from "./Sort/SortButton";
import ChangeBoundariesButton from "./ChangeBoundariesButton";

export const DisplayOptions: FC<{ children: React.ReactNode }> = observer(({ children }) => {
  const { constructionSiteStore, areaStore, jobStore, processingStore, uiStore } = useRootStore();

  const { breadcrumbStepName } = constructionSiteStore;
  const { area, areas } = areaStore;
  const { job, jobs } = jobStore;
  const { processings } = processingStore;
  const { isSearchFieldVisible } = uiStore;

  function getSeacrhButtonIsVisible() {
    switch (breadcrumbStepName) {
      case BreadcrumbSteps.constructionSiteCard:
        return !!areas.length;
      case BreadcrumbSteps.areaCard:
        return !!jobs.length;
      case BreadcrumbSteps.jobCard:
        return !!processings.length;
    }
  }

  function getMembersButtonIsVisible() {
    return breadcrumbStepName === BreadcrumbSteps.areaCard && area?.isProtected;
  }

  return (
    <Box sx={{ transition: "0.5s" }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          height: "50px",
          alignItems: "center",
        }}>
        <Box sx={{ flex: getSeacrhButtonIsVisible() ? 7 : 9 }}>{children}</Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-around",
            flex: getSeacrhButtonIsVisible() ? 2 : 1,
            pl: 1,
            gap: 0.5,
          }}>
          {getSeacrhButtonIsVisible() && <SearchButton />}
          {getMembersButtonIsVisible() && <MembersButton />}
          {(area ?? job) && <ChangeBoundariesButton />}
          <SortButton />
        </Box>
      </Box>
      {isSearchFieldVisible && <SearchField />}
    </Box>
  );
});
