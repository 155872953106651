import React, { FC } from "react";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { NotificationTopic } from "stores/emailStore/constants";
import { useRootStore } from "../../../hooks/useRootStore";
import { useTranslation } from "react-i18next";
import { useFetchWithLoading } from "../../../hooks";

interface NotificationProps {
  topicName: NotificationTopic;
}

const Notification: FC<NotificationProps> = ({ topicName }) => {
  const { t } = useTranslation();
  const { emailStore } = useRootStore();

  const { unsubscribes } = emailStore;

  const [fetchWithLoading, isLoading] = useFetchWithLoading((fnc: () => Promise<unknown>) => fnc());
  const handleChange = async (e: React.ChangeEvent<HTMLInputElement>, topicName: NotificationTopic) => {
    const {
      target: { checked },
    } = e;
    const customFetch = async (fnc: () => Promise<unknown>) => {
      await fetchWithLoading(fnc);
    };
    if (checked) {
      await customFetch(async () => await emailStore.deleteUnsubscribes(topicName));
    } else {
      await customFetch(async () => await emailStore.addUnsubscribes(topicName));
    }
  };

  if (!unsubscribes) {
    return null;
  }

  return (
    <FormControlLabel
      sx={formStyle}
      key={topicName}
      control={
        <Checkbox
          disabled={isLoading}
          checked={!unsubscribes.some(un => un.topicName === topicName)}
          onChange={async e => await handleChange(e, topicName)}
        />
      }
      label={t(`Settings:${topicName}`)}
    />
  );
};

const formStyle = { backgroundColor: "background.paper", mb: 1, pr: 2, borderRadius: "0px 20px 20px 20px" };

export default Notification;
