import Graphic from "@arcgis/core/Graphic";
import { kml } from "@tmcw/togeojson";
import { Feature, Geometry, GeometryCollection, MultiPoint, Point, Polygon } from "geojson";
import { action, makeObservable } from "mobx";
import { DOMParser } from "xmldom";

import { FileExt } from "stores/fileStore/types";
import { DEFAULT_ZOOM, markerSymbol, polygonSymbol } from "stores/mapStore/constants";
import { Position } from "stores/mapStore/types";
import RootStore from "stores/rootStore";

import { getFileExt, getFileName } from "helpers/functions";
import ArcGisGeometry from "@arcgis/core/geometry/Geometry";
import SpatialReference from "@arcgis/core/geometry/SpatialReference";
import ArcGisPolygon from "@arcgis/core/geometry/Polygon";

// const { DxfParser, toGeojson } = require("dxf2json");

type GraphicsFromKml = Graphic;
type GetGraphicFromFile = (file: File) => Promise<GraphicsFromKml[]>;
type GetGraphicByGeometry<T extends Geometry = Geometry> = (
  geoGeometry: T
) => Array<GraphicsFromKml | GraphicsFromKml[]>;

// type SupportedFilesExts = Extract<"dxf" | "kml", FileExts>;
type SupportedFilesExts = Extract<FileExt.kml, FileExt>;

export class ImportFilesStore {
  // private _dxfParser = new DxfParser();

  constructor(private readonly rootStore: RootStore) {
    makeObservable(this);
  }

  @action
  public addGraphicsFromFile = async (file: File): Promise<void> => {
    try {
      this.rootStore.mapStore.utils.clearGraphicLayer();

      const fileExt = getFileExt(file);
      const getGraphics = this.graphicFromFileFunctions[fileExt as SupportedFilesExts];
      if (!fileExt || !getGraphics) {
        throw new Error();
      }

      const graphic = await getGraphics(file);
      type ExtendedGeometry = ArcGisGeometry & { rings?: any };

      // we need this action after that we've changed spacialReference in the project
      let validGraphic = graphic.map(graphic => {
        const geometry: ExtendedGeometry = graphic.geometry;

        const polygon = new ArcGisPolygon({
          spatialReference: SpatialReference.WGS84,
          rings: geometry.rings,
        });

        return new Graphic({
          geometry: polygon,
          symbol: polygonSymbol,
        });
      });

      if (this.checkIsFileExistsEmptyGraphic(validGraphic)) {
        validGraphic = this.filterGraphics(validGraphic);
        if (validGraphic.length > 0) {
          this.showGraphicsInFileWarning();
        } else {
          throw new Error();
        }
      }

      this.rootStore.mapStore.utils.clearGraphicLayer();
      await this.rootStore.mapStore.addGraphicAndUpdate(validGraphic);

      const defaultSelectedGraphic = validGraphic[0];
      await this.rootStore.mapStore.utils.zoomToGeometry(defaultSelectedGraphic.geometry);

      this.rootStore.mapStore.setKmlFileName(getFileName(file));
      this.rootStore.uiStore.setSnackbarClose();
    } catch (e) {
      this.showErrorMessage();
    }
  };

  // private getGraphicFromDxfFile: GetGraphicFromFile = async (dxfFile) => {
  //   const fileText = await dxfFile.text();
  //   const parsedDxfJson = await this._dxfParser.parseContent(fileText);
  //   const geojson = toGeojson(parsedDxfJson);
  //
  //   return this.getGraphicsFromFeatures(geojson.features);
  // }

  private readonly getGraphicFromKmlFile: GetGraphicFromFile = async kmlFile => {
    const xml = await kmlFile.text();
    const kmlDocument = new DOMParser().parseFromString(xml);
    const geojson = kml(kmlDocument);

    return this.getGraphicsFromFeatures(geojson.features);
  };

  private readonly graphicFromFileFunctions: Record<SupportedFilesExts, GetGraphicFromFile> = {
    // dxf: this.getGraphicFromDxfFile,
    kml: this.getGraphicFromKmlFile,
  };

  private getGraphicsFromFeatures(features: Array<Feature<Geometry | null>>): GraphicsFromKml[] {
    return features
      .filter(f => f.geometry !== null)
      .map(f => this.getGraphicByGeoInfo(f.geometry as Geometry))
      .flat(2);
  }

  private readonly getGraphicByGeoInfo: GetGraphicByGeometry = geoGeometry => {
    return this.functionsForGettingGraphicByGeometryType[geoGeometry.type](geoGeometry);
  };

  private readonly functionsForGettingGraphicByGeometryType: Record<
    Geometry["type"],
    GetGraphicByGeometry<any>
  > = {
    GeometryCollection: (geoGeometry: GeometryCollection) => {
      // because of recursion, typescript thinks that type may be is similar to GraphicsFromKml[][]...[]
      // but this recursion has only one cycle
      return geoGeometry.geometries.map(this.getGraphicByGeoInfo) as Array<
        GraphicsFromKml | GraphicsFromKml[]
      >;
    },
    Point: (geoGeometry: Point) => {
      const geometry = this.rootStore.mapStore.getPoint(
        geoGeometry.coordinates as Position // wrong type of coordinates in the lib - number[]
      );
      return [new Graphic({ geometry, symbol: markerSymbol })];
    },
    MultiPoint: (geoGeometry: MultiPoint) => {
      const geometries = geoGeometry.coordinates.map(p =>
        this.rootStore.mapStore.getPoint(p as Position)
      );
      return geometries.map(geometry => new Graphic({ geometry }));
    },
    Polygon: (geoGeometry: Polygon) => {
      const geometries = geoGeometry.coordinates.map(c => this.rootStore.mapStore.getPolygon([c]));
      return geometries.map(geometry => new Graphic({ geometry, symbol: polygonSymbol }));
    },
    LineString: () => [new Graphic()],
    MultiLineString: () => [new Graphic()],
    MultiPolygon: () => [new Graphic()],
  };

  private filterGraphics(graphics: GraphicsFromKml[]): GraphicsFromKml[] {
    return graphics.filter(g => g.geometry !== null);
  }

  private checkIsFileExistsEmptyGraphic(graphic: GraphicsFromKml[]): boolean {
    return graphic.some(g => g.geometry === null);
  }

  private showGraphicsInFileWarning(): void {
    this.rootStore.uiStore.setSnackbarOpen("General:WrongGraphicsInFile", "warning");
    console.warn(
      "The .kml file exists one of not supported graphic type: 'LineString', 'MultiLineString', 'MultiPolygon'"
    );
  }

  private showErrorMessage(): void {
    this.rootStore.uiStore.setSnackbarOpen("General:WrongKmlFile");
    console.warn("Wrong .kml parsing");
  }
}
