import React, { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import Fade from "@mui/material/Fade";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { observer } from "mobx-react-lite";

import { FileName } from "stores/fileStore/types";
import { Processings, PROCESSINGS_FILES_TYPES } from "stores/managerService/processingStore/types";
import { useRootStore } from "hooks/useRootStore";

import ChooseButton from "../Buttons/ChooseButton";

import ConfirmationDialog from "./ConfirmationDialog";
import { ReuseFilesVipoint } from "./ReuseFilesVipoint";

const FileSelection: FC = () => {
  const { t } = useTranslation();
  const { processingStore, fileStore } = useRootStore();

  const { processing, autoStartProcessing } = processingStore;
  const { filesFromInputs, selectedPointCloudForReuse } = fileStore;
  const [error, setError] = useState("");
  const [disabled, setDisabled] = useState(true);
  const [inProgress, setInProgress] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [isConfirmed, setConfirmed] = useState(false);
  const isVipoint = processing?.type === Processings.VIPOINT;

  useEffect(() => {
    return () => {
      fileStore.resetInfoPanel();
    };
    // eslint-disable-next-line
  }, [processing]);

  useEffect(() => {
    isConfirmed && startUpload();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isConfirmed]);

  useEffect(() => {
    if (processing?.type === Processings.PIX4D_GCP) {
      filesFromInputs.length === PROCESSINGS_FILES_TYPES[processing.type].length
        ? setDisabled(false)
        : setDisabled(true);
    } else filesFromInputs.length > 0 && !isVipoint ? setDisabled(false) : setDisabled(true);

    if (selectedPointCloudForReuse.length) {
      setDisabled(false);
    }
  }, [filesFromInputs.length, processing, selectedPointCloudForReuse, isVipoint]);

  const checkDepth = () => {
    setError("");
    const isDepth = filesFromInputs.some(
      el => el.fileType.name === FileName.DEPTHMAP || el.fileType.name === FileName.CONFIDENCE
    );
    if (isDepth) {
      const length = filesFromInputs.map(el => {
        return el.files.length;
      });
      const isEqual = length.every(el => el === length[0]);
      if (!processing || !isEqual || filesFromInputs.length !== PROCESSINGS_FILES_TYPES[processing.type].length) {
        setError(t("Files:RatioError"));
        return false;
      } else {
        return true;
      }
    } else {
      setOpenDialog(true);
    }
  };

  const startUpload = () => {
    if (processing?.type === Processings.PIX4D_DEPTH && !isConfirmed && !checkDepth()) return;
    if (isVipoint) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      fileStore.reuseFiles();
    }
    setInProgress(true);
    fileStore.resetInfoPanel();

    filesFromInputs.forEach((input, index) => {
      if (processing?.type === Processings.PIX4D_DEPTH) {
        fileStore.setUuidForRtkFiles(input.files);
      }
      processing &&
        fileStore.startUpload({
          files: input.files,
          foreignId: processing.id,
          aclId: processing.acl,
          fileType: input.fileType,
          projectId: processing.projectId,
          processingType: processing.type,
        });
    });
  };
  return (
    <>
      <Box sx={containerStyles}>
        <div>
          {processing &&
            PROCESSINGS_FILES_TYPES[processing.type]?.map(fileType => (
              <Fade in key={fileType.name}>
                <Paper elevation={0} sx={itemWrapperStyles}>
                  <Box sx={leftStyles}>
                    <Typography variant="body1" component="div" fontWeight={500}>
                      {t(`Files:${fileType.name}`)}
                    </Typography>
                    <Typography variant="overline" component="div" fontWeight={300} sx={fileTypeStyles} noWrap>
                      {typeof fileType.ext === "object" ? fileType.ext.join(", ") : fileType.ext}
                    </Typography>
                  </Box>
                  <ChooseButton fileType={fileType} />
                </Paper>
              </Fade>
            ))}
          {isVipoint && <ReuseFilesVipoint />}
          {error && (
            <Typography variant="body1" component="div" color="error">
              {error}
            </Typography>
          )}
        </div>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}>
            {!isVipoint && (
              <>
                <Typography sx={fileTypeStyles}>{t("Processings:AutoStart")}</Typography>
                <Checkbox
                  size="small"
                  checked={autoStartProcessing}
                  onChange={() => processingStore.setAutoStartProcessing()}
                />
              </>
            )}
          </Box>
          <Button
            variant="contained"
            onClick={startUpload}
            disabled={disabled || inProgress}
            sx={{ justifySelf: "flex-end", alignSelf: "flex-end" }}>
            {t(`Files:${(isVipoint && filesFromInputs.length === 0 && "Select") || "StartUpload"}`)}
          </Button>
        </Box>
      </Box>
      <ConfirmationDialog open={openDialog} setOpen={setOpenDialog} setConfirmed={setConfirmed} />
    </>
  );
};

const containerStyles = {
  mt: 1.5,
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  height: "calc(100% - 58px)",
} as const;

const itemWrapperStyles = {
  mb: 0.5,
  height: "50px",
  borderRadius: "5px",
  bgcolor: "secondary.main",
  display: "flex",
  alignItems: "center",
};

const leftStyles = {
  pl: 1.5,
  width: "43%",
  height: "100%",
  display: "flex",
  alignItems: "center",
  bgcolor: "secondary.light",
  borderRadius: "5px 0 0 5px",
};

const fileTypeStyles = {
  ml: 1,
  fontSize: "14px",
  lineHeight: "16px",
  color: "#ADADAD",
};

export default observer(FileSelection);
