import { MapItem } from "stores/mapStore/types";

export interface Job extends MapItem {
  projectGroupId: number;
  area: string;
  status: JobStatus;
  tokenRead: string;
  tokenWrite: string;
}

export const enum JobStatus {
  CREATE_SUCCESS = "CREATE_SUCCESS",
  CREATE_FAILED = "CREATE_FAIL",
  CREATE_IN_PROGRESS = "CREATE_IN_PROGRESS",
}
