import { useNavigate } from "react-router-dom";

import RootStore from "stores/rootStore";

export let rootStore: RootStore;

export const useRootStore = (): RootStore => {
  const navigate = useNavigate();

  if (!rootStore) {
    rootStore = new RootStore(navigate);
  }

  return rootStore;
};
