import { useRootStore } from "hooks/useRootStore";;
import React, { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import ClearRoundedIcon from "@mui/icons-material/ClearRounded";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { observer } from "mobx-react-lite";



import UploadsList from "./UploadsList";

const UploadProgress: FC = () => {
  const { t } = useTranslation();
  const { fileStore } = useRootStore();

  const location = useLocation();
  const { uploads, progress, isFullyUploaded } = fileStore;
  const [expanded, setExpanded] = useState(true);

  useEffect(() => {
    window.onbeforeunload = () => {};
    if (progress !== 100 && uploads.length > 0) {
      window.onbeforeunload = () => {
        return t("Files:ActiveUploadsWarning");
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [progress]);

  const handleClose = () => {
    setExpanded(true);
    fileStore.clearUploads();
  };

  return (
    <>
      {uploads.length > 0 && (
        <Paper sx={location.pathname.split("/")[1] ? containerStyles : { ...containerStyles, bottom: "116px" }}>
          <Box sx={topWrapperStyles}>
            <Typography
              variant="subtitle2"
              component="div"
              sx={{
                fontFamily: "'futura-pt', Sans-serif",
              }}>
              {progress === 100 ? t("Files:UploadComplete") : t("Files:Uploading")}
            </Typography>
            {progress === 100 && isFullyUploaded ? (
              <ClearRoundedIcon sx={topIconStyles} onClick={handleClose} fontSize="small" />
            ) : (
              <KeyboardArrowDownRoundedIcon
                fontSize="small"
                sx={
                  expanded
                    ? topIconStyles
                    : {
                        ...topIconStyles,
                        transform: "rotate(180deg)",
                      }
                }
                onClick={() => setExpanded(!expanded)}
              />
            )}
          </Box>
          <LinearProgress variant="determinate" color="success" value={progress} sx={{ bgcolor: "secondary.light" }} />
          {expanded && <UploadsList />}
        </Paper>
      )}
    </>
  );
};

const containerStyles = {
  zIndex: "9999",
  position: "fixed",
  right: "15px",
  bottom: "15px",
  width: "255px",
  bgcolor: "background.default",
  overflow: "hidden",
} as const;

const topWrapperStyles = {
  py: 0.5,
  px: 1.5,
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
};

const topIconStyles = {
  transition: "opacity 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
  transform: "rotate(0deg)",
  "&:hover": {
    opacity: 0.6,
    cursor: "pointer",
  },
};

export default observer(UploadProgress);
