import { FILE_TYPES } from "stores/fileStore/constants";
import { FileType, IFile } from "stores/fileStore/types";

export enum Processings {
  PIX4D_VIEW = "PIX4D_VIEW",
  PIX4D_COMMON = "PIX4D_COMMON",
  PIX4D_DEPTH = "PIX4D_DEPTH",
  PIX4D_GCP = "PIX4D_GCP",
  VIPOINT = "VIPOINT",
  VIPOINT_OUTPUT = "VIPOINT_OUTPUT",
  AGISOFT = "AGISOFT",
}

export const PROCESSINGS = Object.values(Processings);

export const PROCESSINGS_FILES_TYPES: Record<Processings, FileType[]> = {
  PIX4D_VIEW: [
    FILE_TYPES.ORTHOMOSAIC,
    FILE_TYPES.DSM,
    FILE_TYPES.POINT_CLOUD,
    FILE_TYPES.MESH_OBJ_ZIP,
    FILE_TYPES.NDVI,
    FILE_TYPES.IFC,
  ],
  PIX4D_COMMON: [FILE_TYPES.IMAGE],
  PIX4D_DEPTH: [FILE_TYPES.IMAGE, FILE_TYPES.DEPTHMAP, FILE_TYPES.CONFIDENCE],
  PIX4D_GCP: [FILE_TYPES.IMAGE, FILE_TYPES.GCP],
  VIPOINT: [FILE_TYPES.DXF, FILE_TYPES.VP_CSV],
  VIPOINT_OUTPUT: [FILE_TYPES.POINT_CLOUD, FILE_TYPES.DXF_OUTPUT],
  AGISOFT: [FILE_TYPES.AGISOFT_IMAGE, FILE_TYPES.DEPTHMAP, FILE_TYPES.CONFIDENCE],
};

export enum ProcessingStatus {
  CREATE_SUCCESS = "CREATE_SUCCESS",
  PROCESSING_PENDING = "PROCESSING_PENDING",
  PROCESSING_SUCCESS = "PROCESSING_SUCCESS",
  PROCESSING_FAIL = "PROCESSING_FAIL",
  PROCESSING_IN_PROGRESS = "PROCESSING_IN_PROGRESS",
  CREATE_IN_PROGRESS = "CREATE_IN_PROGRESS",
}

export enum ViewerMode {
  projectGroupId = "projectGroupId",
  projectId = "projectId",
}

interface Template {
  template: string;
  targetSize: number;
}
export type ProcessingConfig = Template & IParamsForInitiateProcessing;

export interface IProcessing {
  id: string;
  title: string;
  type: Processings;
  acl: string;
  job: string;
  projectId: number;
  tokenRead: string;
  tokenWrite: string;
  status: ProcessingStatus;
  config?: any;
  createdAt: string;
}
export interface ICategories {
  name: string;
  categoryId: string;
}

export interface IScripts {
  alias: string;
  scriptId: string;
  description: string;
}

export interface DropDownValues {
  [key: string]: string;
}

export enum ParameterType {
  singleSelect = "singleSelect",
  float = "float",
  boolean = "boolean",
  multipleSelect = "multipleSelect",
  dxfFile = "dxfFile",
  csvFile = "csvFile",
  multipleLasFile = "multipleLasFile",
}
export interface IParameters {
  parameterId: number;
  aliasBackend: string;
  parameterName: string;
  scriptParametersId: string;
  turnOn: string;
  turnOff: string;
  dropDownValues: DropDownValues | string;
  frontendOrder: string;
  isVisible: number;
  description: string;
  minValue: string;
  maxValue: string;
  defaultValue: number;
  parameterType: ParameterType;
}

export interface IUpdatedParams {
  parameterId: number;
  scriptParametersId: string;
  selected: boolean;
  value: number | string | boolean | string[];
  isBoolean: boolean;
  parameterType: string;
  defaultValue?: number;
  aliasBackend: string;
}
export interface IParamsForInitiateProcessing {
  inputFile: IFile;
  parameters: IUpdatedParams[];
  scriptId: number;
}

export interface IDataForViewer {
  projectGroupId?: string;
  projectId?: number;
  token: string;
}

export interface ProcessingsForViewer {
  title: string;
  url: string;
  id: string;
}
