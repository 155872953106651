import { useRootStore } from "hooks/useRootStore";;
import React, { FC, useEffect,useState } from "react";
import { useTranslation } from "react-i18next";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";



const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const NewUserAlert: FC = () => {
  const { t } = useTranslation();
  const { authStore } = useRootStore();

  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (authStore.newUser) {
      setOpen(true);
    }
  }, []);

  return (
    <Dialog open={open} TransitionComponent={Transition} onClose={handleClose}>
      <DialogTitle sx={{ pt: 2.5 }}>{t("Settings:Welcome")}</DialogTitle>
      <DialogContent>
        <DialogContentText color="text">{t("Settings:NewUserAlert")}</DialogContentText>
      </DialogContent>
    </Dialog>
  );
};

export default NewUserAlert;
