import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import AccessTimeRoundedIcon from "@mui/icons-material/AccessTimeRounded";
import AttachmentIcon from "@mui/icons-material/Attachment";
import DeleteOutlineRoundedIcon from "@mui/icons-material/DeleteOutlineRounded";
import PersonOutlineRoundedIcon from "@mui/icons-material/PersonOutlineRounded";
import { Stack, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import { observer } from "mobx-react-lite";

import { IArea } from "stores/managerService/areaStore";
import areas from "stores/managerService/areaStore/service";
import { IConstructionSite } from "stores/managerService/constructionSiteStore/types";
import jobs from "stores/managerService/jobStore/service";
import { Job } from "stores/managerService/jobStore/types";
import {
  CommentType,
  ProcessType,
  Task,
  TaskForeignType,
  TaskParamsForUpdate,
  TaskPriority,
  TaskStatus,
} from "stores/taskStore/types";

import { LOCALES } from "constants/locales";
import { thumbStyles } from "constants/styles";
import { useRootStore } from "hooks/useRootStore";
import { absolutePath } from "router/paths";

import DescriptionField from "../Fields/DescriptionField";
import DueDateField from "../Fields/DueDateField";
import { LinkedProcessingsField } from "../Fields/LinkedProcessingsField";
import { ProcessTypesField } from "../Fields/ProcessTypesField/ProcessTypesField";
import SelectUserField, { SelectType } from "../Fields/SelectUserField";
import StatusField from "../Fields/StatusField";
import { useIsAllowedToDeleteOrEdit } from "../utils/useIsAllowedToDeleteOrEdit";

import { belongToPaths, CARD_CONTAINER_ID } from "./constants";
import Tabs from "./Tabs";
import { UserChangeSystemComments } from "./Comments/types";
import { format } from "date-fns";
import { useFormatDate } from "hooks/useFormatDate";
import { PriorityField } from "../Fields/PriorityField";

interface TaskDetailsPopupProps {
  task: Task;
}

export enum SystemCommentCodes {
  StatusChange = "StatusChange",
  ProcessTypeChange = "ProcessTypeChange",
  DueDateChange = "DueDateChange",
  DueDateSet = "DueDateSet",
  DescriptionChange = "DescriptionChange",
  DescriptionSet = "DescriptionSet",
  TitleChange = "TitleChange",
  PriorityChange = "PriorityChange",
}

interface CreateSystemCommentParams {
  changeCode: string;
  newParam: string | Date;
  oldParam?: string | undefined | Date;
  setCode?: string;
}

const TaskDetailsPopup: FC<TaskDetailsPopupProps> = ({ task }) => {
  const { t } = useTranslation();
  const { teamStore, authStore, taskStore, uiStore, constructionSiteStore } = useRootStore();
  const { constructionSites } = constructionSiteStore;
  const { constructionSiteTeamMembersMap } = teamStore;

  const [title, setTitle] = useState(task.title);
  const [status, setStatus] = useState<TaskStatus>(task.status);
  const [assignee, setAssignee] = useState(task.assignedTo);
  const [reviewBy, setReviewBy] = useState(task.reviewBy);
  const [dueDate, setDueDate] = useState<Date | undefined | null>(task.dueDate);
  const [description, setDescription] = useState(task.description ? task.description : "");
  const [processType, setProcessType] = useState<ProcessType>(task.processType || ProcessType.APPROVAL);
  const currentUserId = authStore.cognitoUser?.getUsername();
  const [linkedProcessings, setLinkedProcessings] = useState<string[]>(task.processings ?? []);
  const [priority, setPriority] = useState(task.priority ?? TaskPriority.NORMAL);
  const [assigneeOrganization, setAssigneeOrganization] = useState(task.assigneeOrganization);
  const [reviewerOrganization, setReviewerOrganization] = useState(task.reviewerOrganization);

  const isAllowedToDeleteOrEdit = useIsAllowedToDeleteOrEdit(task);
  const isTaskCompleted = task.status === TaskStatus.COMPLETED;
  const formatDate = useFormatDate(format);

  const createSystemComment = ({ changeCode, newParam, oldParam, setCode }: CreateSystemCommentParams) => {
    let systemComment;

    if (oldParam) {
      systemComment = {
        code: `Tasks:${changeCode}`,
        params: {
          old: oldParam,
          new: newParam,
        },
      };
    } else {
      systemComment = {
        code: `Tasks:${setCode}`,
        params: {
          new: newParam,
        },
      };
    }
    const systemCommentString = JSON.stringify(systemComment);

    taskStore.createComment(task.id, task.acl, systemCommentString, CommentType.SYSTEM_CREATED);
  };

  useEffect(() => {
    if (status !== task.status) {
      console.log("vxcvcvxxcv", status, task.status);
      taskStore.updateTask(task.foreignId, task.id, TaskParamsForUpdate.status, status);
      createSystemComment({
        changeCode: SystemCommentCodes.StatusChange,
        oldParam: task.status,
        newParam: status,
      });
    }
  }, [status]);

  const shouldDisplayAprroveBtn = useCallback(() => {
    const isReviewStatus = task.status === TaskStatus.IN_REVIEW;
    const isCurrentUserReviewer = task.reviewBy && currentUserId === task.reviewBy;
    const isCurrentUserCreatorAndNoReviewer = !task.reviewBy && currentUserId === task.createdBy;

    return isReviewStatus && (isCurrentUserReviewer || isCurrentUserCreatorAndNoReviewer);
  }, [currentUserId, task.createdBy, task.reviewBy, task.status]);

  useEffect(() => {
    if (assignee && assignee !== task.assignedTo) {
      taskStore.updateTask(task.foreignId, task.id, TaskParamsForUpdate.assignedTo, assignee);
      createSystemComment({
        changeCode: UserChangeSystemComments.AssigneeChange,
        newParam: assignee,
        oldParam: task.assignedTo,
        setCode: UserChangeSystemComments.AssigneeSet,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assignee]);

  useEffect(() => {
    if (dueDate && dueDate !== task.dueDate) {
      taskStore.updateTask(task.foreignId, task.id, TaskParamsForUpdate.dueDate, dueDate.getTime(), dueDate);
      createSystemComment({
        changeCode: SystemCommentCodes.DueDateChange,
        setCode: SystemCommentCodes.DueDateSet,
        oldParam: task.dueDate,
        newParam: dueDate,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dueDate]);

  useEffect(() => {
    if (processType !== task.processType) {
      taskStore.updateTask(task.foreignId, task.id, TaskParamsForUpdate.processType, processType);
      createSystemComment({
        changeCode: SystemCommentCodes.ProcessTypeChange,
        newParam: processType,
        oldParam: task.processType,
      });
    }
  }, [processType]);

  useEffect(() => {
    if (reviewBy && reviewBy !== task.reviewBy) {
      taskStore.updateTask(task.foreignId, task.id, TaskParamsForUpdate.reviewBy, reviewBy);
      createSystemComment({
        changeCode: UserChangeSystemComments.ReviewerChange,
        newParam: reviewBy,
        oldParam: task.reviewBy,
        setCode: UserChangeSystemComments.ReviewerSet,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reviewBy, task.reviewBy]);

  useEffect(() => {
    if (reviewerOrganization && reviewerOrganization !== task.reviewerOrganization) {
      taskStore.updateTask(task.foreignId, task.id, TaskParamsForUpdate.reviewerOrganization, reviewerOrganization);

      createSystemComment({
        changeCode: UserChangeSystemComments.ReviewerOrganizationChange,
        newParam: reviewerOrganization,
        setCode: UserChangeSystemComments.ReviewerOrganizationSet,
        oldParam: task.reviewerOrganization,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reviewerOrganization]);

  useEffect(() => {
    if (assigneeOrganization && assigneeOrganization !== task.assigneeOrganization) {
      taskStore.updateTask(task.foreignId, task.id, TaskParamsForUpdate.assigneeOrganization, assigneeOrganization);
      createSystemComment({
        changeCode: UserChangeSystemComments.AssigneeOrganizationChange,
        newParam: assigneeOrganization,
        setCode: UserChangeSystemComments.AssigneeOrganizationSet,
        oldParam: task.assigneeOrganization,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assigneeOrganization]);

  useEffect(() => {
    if (task.processings?.length !== linkedProcessings.length)
      taskStore.updateTask(task.foreignId, task.id, TaskParamsForUpdate.processings, linkedProcessings);
  }, [linkedProcessings]);

  useEffect(() => {
    if (priority !== task.priority) {
      taskStore.updateTask(task.foreignId, task.id, TaskParamsForUpdate.priority, priority);
      createSystemComment({
        changeCode: SystemCommentCodes.PriorityChange,
        newParam: priority,
        oldParam: task.priority || TaskPriority.NORMAL,
      });
    }
  }, [priority]);

  const finishEditingTitle = async () => {
    if (title !== task.title) {
      createSystemComment({
        changeCode: SystemCommentCodes.TitleChange,
        oldParam: task.title.trim(),
        newParam: title.trim(),
      });
      await taskStore.updateTask(task.foreignId, task.id, TaskParamsForUpdate.title, title);
    } else {
      setTitle(task.title);
    }
  };

  const finishEditingDescription = async () => {
    if (description !== task.description) {
      createSystemComment({
        changeCode: SystemCommentCodes.DescriptionChange,
        setCode: SystemCommentCodes.DescriptionSet,
        oldParam: task.description,
        newParam: description,
      });
      await taskStore.updateTask(task.foreignId, task.id, TaskParamsForUpdate.description, description);
    }
  };

  const approveTaskFnc = async () => {
    // const response = await taskStore.createComment(task.id, task.acl, "Task approved");
    // if (response.status === "ok") {
    await taskStore.updateTask(task.foreignId, task.id, TaskParamsForUpdate.approvedAt, Date.now());
    setStatus(TaskStatus.COMPLETED);
    // }
  };

  const rejectTaskFnc = async () => {
    // const response = await taskStore.createComment(task.id, task.acl, "Task rejected");
    // if (response.status === "ok") {
    setStatus(TaskStatus.IN_PROGRESS);
    // }
  };

  const handleConfirmTaskDeletion = async () => {
    await taskStore.deleteTask(task.foreignId, task.id);
    uiStore.clearModals();
  };

  const handleDeleteTask = async () => {
    uiStore.setConfirmModalOpen({ onConfirm: handleConfirmTaskDeletion });
  };

  const createdByMember = useMemo(
    () => constructionSiteTeamMembersMap[task.createdBy],
    [task.createdBy, constructionSiteTeamMembersMap]
  );

  const createdByFullName = useMemo(
    () => `${createdByMember?.given_name ?? "name"} ${createdByMember?.family_name ?? "family"}`,
    [createdByMember?.family_name, createdByMember?.given_name]
  );

  const [belongsTo, setBelongsTo] = useState<IConstructionSite | IArea | Job>();

  const shouldDisplayLinkedProcessings = task.foreignType === TaskForeignType.JOB;

  const handleSelectBelongsTo = (item: IConstructionSite | IArea | Job) => {
    const path = belongToPaths[task.foreignType];
    window.open(absolutePath(path(item.id)), "_blank");
  };

  useEffect(() => {
    const fetchData = async () => {
      switch (task.foreignType) {
        case TaskForeignType.CONSTRUCTION_SITE: {
          const constructionSite = constructionSites?.find(cs => cs.id === task.constructionSite);
          if (constructionSite) {
            setBelongsTo(constructionSite);
          }
          break;
        }
        case TaskForeignType.AREA: {
          const response = await areas.get(task.foreignId);
          setBelongsTo(response);
          break;
        }
        case TaskForeignType.JOB: {
          const response = await jobs.getById(task.foreignId);
          setBelongsTo(response);
          break;
        }
      }
    };

    fetchData();
  }, [task, constructionSites, areas, jobs]);

  return (
    <Box sx={{ height: "80vh", ...thumbStyles }}>
      <Stack direction={"row"} alignItems={"center"} spacing={0.5}>
        <Typography variant={"h5"} sx={{ color: "text.disabled" }}>
          #{task.index}
        </Typography>
        <TextField
          required
          size="small"
          fullWidth
          multiline
          value={title}
          disabled={!isAllowedToDeleteOrEdit}
          onChange={e => setTitle(e.target.value)}
          onBlur={finishEditingTitle}
          inputProps={{ maxLength: 255 }}
          sx={titleStyles}
        />
      </Stack>
      <Divider
        sx={{
          mx: -1.5,
          mt: 1,
        }}
      />
      <Box id={CARD_CONTAINER_ID} sx={wrapperStyles}>
        {belongsTo && (
          <Box sx={statusWrapperStyles}>
            <AttachmentIcon />
            <Typography variant="caption" component="span" fontWeight={600} sx={{ ml: 1.2 }}>
              {task.foreignType === TaskForeignType.AREA
                ? "Area:"
                : task.foreignType === TaskForeignType.JOB
                ? "Job:"
                : "Construction site:"}
            </Typography>
            <Typography
              key={belongsTo.id}
              onClick={async () => {
                await handleSelectBelongsTo(belongsTo);
              }}
              variant="caption"
              fontWeight={600}
              sx={{ ml: 1.2, color: "primary.main", cursor: "pointer", position: "relative" }}>
              {belongsTo.title}
            </Typography>
          </Box>
        )}
        <Box sx={statusWrapperStyles}>
          <StatusField status={status} setStatus={setStatus} isAllowedToEdit={isAllowedToDeleteOrEdit} />
          {shouldDisplayAprroveBtn() && (
            <Box sx={approveWrapperStyles}>
              <Button size="small" variant="contained" color="success" sx={approvebtnStyles} onClick={approveTaskFnc}>
                {t("Tasks:Approve")}
              </Button>
              <Button size="small" variant="contained" color="primary" sx={approvebtnStyles} onClick={rejectTaskFnc}>
                {t("Tasks:Cancel")}
              </Button>
            </Box>
          )}
          <Tooltip title={t("Tasks:DeleteTask")}>
            <Button
              disabled={!isAllowedToDeleteOrEdit || task.status !== TaskStatus.PENDING}
              size="small"
              variant="contained"
              color="secondary"
              sx={btnStyles}
              onClick={handleDeleteTask}>
              <DeleteOutlineRoundedIcon fontSize="small" />
            </Button>
          </Tooltip>
        </Box>
        <PriorityField priority={priority} setPriority={setPriority} isAllowedToEdit={isAllowedToDeleteOrEdit} />
        <ProcessTypesField
          processType={processType}
          setProcessType={p => setProcessType(p ?? ProcessType.APPROVAL)}
          isAllowedToEdit={isAllowedToDeleteOrEdit}
        />
        {createdByMember && (
          <Box sx={statusWrapperStyles}>
            <PersonOutlineRoundedIcon />
            <Typography variant="body1" component="span" fontWeight={600} sx={{ ml: 1.2 }}>
              {t(...LOCALES.TASKS.CREATED_BY({ name: "" }))}
            </Typography>
            <Typography variant="caption" fontWeight={600} sx={{ ml: 1.2 }}>
              {createdByFullName}
            </Typography>
          </Box>
        )}
        <DescriptionField
          isAllowedToEdit={isAllowedToDeleteOrEdit}
          description={description}
          setDescription={setDescription}
          onBlur={finishEditingDescription}
        />
        <Box sx={statusWrapperStyles}>
          <AccessTimeRoundedIcon />
          <Typography variant="body1" component="span" fontWeight={600} sx={{ ml: 1.2 }}>
            {t(LOCALES.TASKS.CREATED_AT)}
          </Typography>
          <Typography variant="caption" fontWeight={600} sx={{ ml: 1.2 }}>
            {formatDate(task.createdAt, "HH:mm, d MMMM yyyy")}
          </Typography>
        </Box>
        <DueDateField dueDate={dueDate} setDueDate={setDueDate} isAllowedToEdit={isAllowedToDeleteOrEdit} />
        <SelectUserField
          acl={task.acl}
          user={assigneeOrganization}
          setUser={setAssigneeOrganization}
          selectType={SelectType.ASSIGNEE_ORGANIZATION}
          isAllowedToEdit={!isTaskCompleted}
        />
        <SelectUserField
          acl={task.acl}
          user={assignee}
          setUser={setAssignee}
          selectType={SelectType.ASSIGNEE}
          isAllowedToEdit={!isTaskCompleted}
        />
        <SelectUserField
          acl={task.acl}
          user={reviewerOrganization}
          setUser={setReviewerOrganization}
          selectType={SelectType.REVIEWER_ORGANIZATION}
          isAllowedToEdit={!isTaskCompleted}
        />
        <SelectUserField
          acl={task.acl}
          user={reviewBy}
          setUser={setReviewBy}
          selectType={SelectType.REVIEWER}
          isAllowedToEdit={isAllowedToDeleteOrEdit}
        />
        {shouldDisplayLinkedProcessings && (
          <LinkedProcessingsField
            foreignId={task.foreignId}
            selectedProcessingsId={linkedProcessings}
            setSelectedProcessingsId={setLinkedProcessings}
            isAllowedToEdit={isAllowedToDeleteOrEdit}
          />
        )}
      </Box>
      <Tabs task={task} />
    </Box>
  );
};

const wrapperStyles = {
  padding: "0 5px 0 5px",
  overflow: "hidden",
};

const statusWrapperStyles = {
  display: "flex",
  alignItems: "center",
  height: "32px",
};
const approveWrapperStyles = {
  display: "flex",
  alignItems: "center",
  height: "32px",
  width: "180px",
  justifyContent: "space-between",
};
const titleStyles = {
  mt: "-10px",
  ml: "-5px",
  width: "calc(100% + 10px)",
  "& .MuiOutlinedInput-root": {
    px: "5px",
    py: 1,
  },
  "& .MuiInputBase-input": {
    fontFamily: "'futura-pt-bold', Sans-serif",
    fontSize: "22px",
    lineHeight: "30px",
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: "transparent",
  },
} as const;

const btnStyles = {
  ml: "auto",
  height: "28px",
  width: "28px",
  minWidth: "unset",
  "&:hover": { bgcolor: "rgba(255, 255, 255, 0.1)" },
};

const approvebtnStyles = {
  ml: "auto",
  height: "28px",
  width: "85px",
  minWidth: "unset",
  backgroundColor: "background: #FF971E",
  "&:hover": { bgcolor: "rgba(255, 255, 255, 0.1)" },
};

export default observer(TaskDetailsPopup);
