import axios from "axios";

import { baseUrl, protocol, Service } from "../../../configs/servicesConfig";
import { GetTeamBy } from "./types";

const { PROJECT } = Service;

const team = {
  getAll: async (getBy: GetTeamBy, id: string) => {
    const response = await axios.get<{ payload: [] }>(`${protocol}//${baseUrl}/${PROJECT}/actors?${getBy}=${id}`);
    return response.data.payload;
  },

  invite: async (userId: string, role: string, resource: string) => {
    const response = await axios.post(`${protocol}//${baseUrl}/${PROJECT}/actors`, {
      userId,
      resource,
      role,
    });
    return response;
  },

  set: async (id: string, role: string) => {
    const response = await axios.patch(`${protocol}//${baseUrl}/${PROJECT}/actors/${id}`, { role });
    return response as { data: { status?: string } };
  },

  delete: async (id: string) => {
    const response = await axios.delete(`${protocol}//${baseUrl}/${PROJECT}/actors/${id}`);
    return response;
  },
};

export default team;
