import React, { FC } from "react";
import ClearRoundedIcon from "@mui/icons-material/ClearRounded";
import { SxProps, Theme } from "@mui/material";
import TextField from "@mui/material/TextField";
import { DateTimePicker } from "@mui/x-date-pickers";

interface DateRangeItemSelectorProps {
  value: Date | null;
  setValue: (value: Date | null) => void;
  label?: string;
}

const DateRangeItemSelector: FC<DateRangeItemSelectorProps> = ({ value, setValue, label }) => {
  return (
    <DateTimePicker
      onChange={setValue}
      value={value}
      renderInput={params => (
        <TextField
          size={"small"}
          {...params}
          label={label}
          InputProps={{
            ...params.InputProps,
            endAdornment:
              value !== null ? (
                <ClearRoundedIcon sx={clearIconStyles} onClick={() => setValue(null)} />
              ) : (
                params.InputProps?.endAdornment
              ),
          }}
        />
      )}
    />
  );
};

const clearIconStyles: SxProps<Theme> = {
  cursor: "pointer",
};

export default DateRangeItemSelector;
