import { useRootStore } from "hooks/useRootStore";
import React, { FC, FormEvent, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { observer } from "mobx-react-lite";

import { Member, TeamRole } from "stores/managerService/teamStore/types";
import { getFullName } from "components/ConstructionSite/Team";

const AddMemberPopup: FC<{ constructionSiteId: string; areaId?: string; currentUserRole: TeamRole }> = ({
  constructionSiteId,
  areaId,
  currentUserRole,
}) => {
  const { t } = useTranslation();
  const { teamStore } = useRootStore();

  const [email, setEmail] = useState("");
  const [role, setRole] = useState(TeamRole.MEMBER);
  const [inProgress, setInProgress] = useState(false);
  const { inviteUserToProtectedArea, inviteUserToCS, constructionSiteTeamMembers, protectedAreaMembers } = teamStore;

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    setInProgress(true);
    if (areaId) {
      await inviteUserToProtectedArea(email, role, areaId);
    } else {
      await inviteUserToCS(email, role, constructionSiteId);
    }
    setInProgress(false);
  };

  const getMembersForInvite = useCallback((): Member[] | undefined => {
    const membersToExclude = new Set(protectedAreaMembers?.map(pam => pam.userId));
    return constructionSiteTeamMembers?.filter(csm => !membersToExclude?.has(csm.userId));
  }, [constructionSiteTeamMembers, protectedAreaMembers]);

  const isAdmin = currentUserRole === TeamRole.OWNER || currentUserRole === TeamRole.ADMIN;

  const roleMenuItems = [
    { role: TeamRole.ADMIN, show: isAdmin },
    { role: TeamRole.COORDINATOR, show: isAdmin },
    { role: TeamRole.MEMBER, show: true },
    { role: TeamRole.GUEST, show: true },
  ];

  return (
    <form onSubmit={handleSubmit}>
      <Typography id="transition-modal-title" variant="h5" component="h2" textAlign="center" sx={{ mb: 2 }}>
        {t("Team:AddMember")}
      </Typography>
      <Divider sx={{ mx: -1.5, mb: 0.5 }} />
      {areaId ? (
        <FormControl sx={{ mb: 2 }} fullWidth required>
          <InputLabel id="selectUser">{t("Team:SelectUser")}</InputLabel>
          <Select
            labelId="selectUser"
            value={email}
            disabled={!getMembersForInvite()?.length}
            label={t("Team:SelectUser")}
            onChange={e => setEmail(e.target.value)}>
            {getMembersForInvite()?.map(member => (
              <MenuItem key={member.id} value={member.email}>
                {getFullName(member)}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      ) : (
        <TextField
          id="email"
          type="email"
          label={t("General:Email")}
          value={email}
          onChange={e => setEmail(e.target.value)}
          fullWidth
          sx={{ my: 2 }}
          required
        />
      )}
      <FormControl sx={{ mb: 2 }} fullWidth required>
        <InputLabel id="label">{t("Team:Role")}</InputLabel>
        <Select
          labelId="label"
          label={t("Team:Role") + "*"}
          value={role}
          onChange={e => setRole(e.target.value as TeamRole)}>
          {roleMenuItems
            .filter(({ show }) => show)
            .map(({ role }) => (
              <MenuItem key={role} value={role}>
                {t(`Team:${role}`)}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
      <Button color="secondary" variant="contained" fullWidth type="submit" disabled={inProgress}>
        {t("General:Submit")}
      </Button>
    </form>
  );
};

export default observer(AddMemberPopup);
