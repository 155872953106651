import { useRootStore } from "hooks/useRootStore";;
import React, { FC, useEffect, useRef, useState } from "react";
import { CellMeasurer, CellMeasurerCache, List as VirtualizedList } from "react-virtualized";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Fade from "@mui/material/Fade";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { observer } from "mobx-react-lite";

import { formatBytes } from "helpers/functions";

const HEIGHT = 180;

const List: FC = () => {
  const { fileStore } = useRootStore();

  const cache = useRef(new CellMeasurerCache({ fixedWidth: true, defaultHeight: 62 }));

  const {
    filesFromInputs,
    infoPanel: { fileType },
  } = fileStore;

  const [isOverflow, setIsOverflow] = useState(false);
  const [files, setFiles] = useState([] as File[]);

  useEffect(() => {
    if (!fileType) {
      return;
    }

    const filteredFiles = filesFromInputs.filter(input => input.fileType.name === fileType.name)[0].files;
    setFiles(filteredFiles);
  }, [filesFromInputs, name]);
console.log(files)
  useEffect(() => {
    if (files.length > 0) {
      const scrollHeight = document.getElementsByClassName("ReactVirtualized__Grid__innerScrollContainer")[0]
        .clientHeight;

      if (scrollHeight > HEIGHT) {
        setIsOverflow(true);
      } else {
        setIsOverflow(false);
      }
    }
  }, [files.length]);

  if (!fileType) {
    return null;
  }

  return (
    <Box sx={containerStyles}>
      <VirtualizedList
        style={isOverflow ? { paddingRight: "10px", MozPaddingEnd: "0" } : undefined}
        width={400}
        height={HEIGHT}
        rowHeight={cache.current.rowHeight}
        rowCount={files.length}
        deferredMeasurementCache={cache.current}
        rowRenderer={({ key, index, style, parent }) => {
          const file = files[index];
          return (
            <CellMeasurer cache={cache.current} parent={parent} columnIndex={0} rowIndex={index} key={key}>
              <div style={{ ...style, paddingBottom: "10px" }}>
                <Fade in>
                  <Paper sx={itemWrapperStyles} elevation={0}>
                    <Typography component="div" variant="body1" sx={fileNameStyles} fontWeight={300}>
                      {file.name}
                    </Typography>
                    <Typography variant="caption" fontWeight={300} sx={{ mr: 1, color: "#ADADAD" }}>
                      {formatBytes(file.size)}
                    </Typography>
                    <Divider orientation="vertical" flexItem sx={{ borderColor: "secondary.main" }} />
                    <IconButton
                      sx={iconStyles}
                      onClick={() => fileType.name && fileStore.removeFileFromInput(index, fileType.name)}>
                      <DeleteOutlinedIcon />
                    </IconButton>
                  </Paper>
                </Fade>
              </div>
            </CellMeasurer>
          );
        }}
      />
    </Box>
  );
};

const containerStyles = {
  position: "relative",
  mt: "10px",
  "& div::-webkit-scrollbar": {
    width: "5px",
  },
  "& div::-webkit-scrollbar-track": {
    bgcolor: "secondary.main",
    borderRadius: "5px",
  },
  "& div::-webkit-scrollbar-thumb": {
    bgcolor: "background.paper",
    borderRadius: "5px",
  },
  "& div": { scrollbarColor: "#333 #444", scrollbarWidth: "thin" },
} as const;

const itemWrapperStyles = {
  p: 0.5,
  bgcolor: "transparent",
  border: "1px solid",
  borderRadius: "5px",
  borderColor: "secondary.main",
  display: "flex",
  alignItems: "center",
};

const fileNameStyles = {
  ml: 0.5,
  mr: 1,
  width: "100%",
  minWidth: 0,
  overflow: "hidden",
  textOverflow: "ellipsis",
  flex: 1,
} as const;

const iconStyles = {
  ml: 1,
  bgcolor: "secondary.light",
  color: "text.primary",
  "& .MuiSvgIcon-root": {
    color: "text.primary",
    transition: "color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
  },
  "&:hover": {
    bgcolor: "secondary.light",
    "& .MuiSvgIcon-root": {
      color: "primary.main",
    },
  },
  "&.Mui-disabled": {
    bgcolor: "secondary.main",
    "& .MuiSvgIcon-root": {
      opacity: 0.2,
    },
  },
};

export default observer(List);
