import React, { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { observer } from "mobx-react-lite";

import { IParameters } from "stores/managerService/processingStore/types";

import { useRootStore } from "hooks/useRootStore";

export const MultipleLasFile: FC<{ parameter: IParameters }> = observer(({ parameter }) => {
  const { t } = useTranslation();
  const { processingStore, fileStore } = useRootStore();
  const { setUpdatedParams, processing } = processingStore;
  const [value, setValue] = useState<string[]>([]);
  const { parameterId } = parameter;
  const {
    pointCloudFilesForMerge,
    setSelectedPointCloudsForMerge,
    resetSelectedPointCloudsForMerge,
    getPointCloudFilesForMerge,
  } = fileStore;

  useEffect(() => {
    getPointCloudFilesForMerge(processing!.job);
    return () => resetSelectedPointCloudsForMerge();
  }, []);

  useEffect(() => {
    setSelectedPointCloudsForMerge(value);
    setUpdatedParams(parameterId, value);
  }, [value, setUpdatedParams]);

  return (
    <FormControl sx={{ my: 1.5, maxWidth: "500px" }} fullWidth required>
      <InputLabel>{t(`Processings:Parameters:${parameterId}:parameterName`)}</InputLabel>
      <Select
        value={value}
        label={t(`Processings:Parameters:${parameterId}:parameterName`)}
        disabled={!pointCloudFilesForMerge?.length}
        multiple
        onChange={e => {
          setValue(e.target.value as string[]);
        }}
        IconComponent={KeyboardArrowDownRoundedIcon}>
        {pointCloudFilesForMerge?.map(file => (
          <MenuItem value={file.storageMeta.key} key={file.id}>
            {file.fileMeta.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
});
