import { useRootStore } from "hooks/useRootStore";;
import React, { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";



const DeletePopupForm: FC<{ jobId: string; areaId: string }> = ({ jobId, areaId }) => {
  const { t } = useTranslation();
  const { jobStore } = useRootStore();

  const [comment, setComment] = useState("");

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    jobStore.delete(jobId, areaId, comment);
  };

  return (
    <form onSubmit={handleSubmit}>
      <Typography id="transition-modal-title" variant="h5" component="h2" textAlign="center" sx={{ mb: 2 }}>
        {t("General:ConfirmDeletion")}
      </Typography>
      <Divider sx={{ mx: -1.5 }} />
      <TextField
        id="comment"
        label={t("General:Comment")}
        fullWidth
        sx={{ mt: 2.5, mb: 2 }}
        required
        multiline
        rows={4}
        value={comment}
        onChange={e => setComment(e.target.value)}
      />
      <Button color="secondary" variant="contained" fullWidth type="submit">
        {t("General:Submit")}
      </Button>
    </form>
  );
};

export default DeletePopupForm;
