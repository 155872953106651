import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Typography from "@mui/material/Typography";

import "configs/i18nextConfig";

const languages = [
  {
    id: "en",
    title: "English",
    flag: <img alt="English" src="/images/flags/English.svg" />,
  },
  {
    id: "de",
    title: "Deutsch",
    flag: <img alt="German" src="/images/flags/German.svg" />,
  },
];

const LanguageSelector: FC<{ displayFlag: boolean }> = ({ displayFlag }) => {
  const { i18n } = useTranslation();

  const handleChange = (event: SelectChangeEvent) => {
    localStorage.setItem("i18nextLng", event.target.value);
    void i18n.changeLanguage(event.target.value);
  };

  return (
    <FormControl>
      <Select
        id="language-selector"
        defaultValue={localStorage.getItem("i18nextLng") ?? "en"}
        value={localStorage.getItem("i18nextLng") ?? "en"}
        onChange={handleChange}
        variant="standard"
        size="small"
        sx={selectorStyles}
        autoWidth
        IconComponent={KeyboardArrowDownRoundedIcon}>
        {languages.map(lang => (
          <MenuItem value={lang.id} key={lang.id}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              {displayFlag && <Box sx={{ mr: 1, display: "inline-flex" }}>{lang.flag}</Box>}
              <Typography color="text" variant="subtitle1">
                {lang.title}
              </Typography>
            </Box>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

const selectorStyles = {
  ":before": {
    border: "none",
    content: "none",
  },
  ":after": {
    border: "none",
  },
  "& .MuiSelect-select:focus": { background: "transparent" },
  ":hover:not(.Mui-disabled):before": {
    border: "none",
  },
};

export default LanguageSelector;
