import { TaskForeignType } from "stores/taskStore/types";

import { PATHS } from "router/paths";

export const CARD_CONTAINER_ID = "card_container_id";

export const belongToPaths: Record<TaskForeignType, (id?: string) => string> = {
  [TaskForeignType.CONSTRUCTION_SITE]: PATHS.HOME.CS_CARD,
  [TaskForeignType.AREA]: PATHS.HOME.AREA_CARD,
  [TaskForeignType.JOB]: PATHS.HOME.JOB_CARD,
};
