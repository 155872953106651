import React, { FC } from "react";
import { Backdrop, Box, Fade, Modal as MModal, SxProps, Theme } from "@mui/material";
import { observer } from "mobx-react-lite";

import { onboardingStore } from "stores/onboardingStore";
import { Modal } from "stores/uiStore/types";

import { OnboardingSelector } from "components/Onboarding/steps/types";
import { useRootStore } from "hooks/useRootStore";

const ModalWindow: FC<Modal> = ({ styles, body, onClose }) => {
  const {
    uiStore: { closeLastModal },
    fileStore,
  } = useRootStore();
  const { isOnboardingRunning } = onboardingStore;

  const handleClose = () => {
    if (isOnboardingRunning) {
      return;
    }

    onClose?.();
    closeLastModal();
  };

  return (
    <MModal
      disablePortal
      id={OnboardingSelector.COMMON_MODAL}
      open={true}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{ timeout: 500 }}>
      <Fade in={true}>
        <Box
          sx={{
            ...modalContainerStyles,
            ...styles,
            ...(fileStore.infoPanel.open && { width: "auto" }),
          }}>
          {body}
        </Box>
      </Fade>
    </MModal>
  );
};

const modalContainerStyles: SxProps<Theme> = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "500px",
  maxWidth: "100%",
  maxHeight: "calc(100% - 100px)",
  overflowY: "auto",
  overflowX: "hidden",
  bgcolor: "background.default",
  borderRadius: "10px",
  outline: "none",
  p: 1.5,
  pt: 2,
};

export default observer(ModalWindow);
