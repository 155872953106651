import React, { ChangeEventHandler, FC, MouseEvent } from "react";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import { Theme } from "@mui/material";
import Button from "@mui/material/Button";
import { SxProps } from "@mui/material/styles";
import Typography from "@mui/material/Typography";

import { FILE_TYPES } from "stores/fileStore/constants";
import { FileType } from "stores/fileStore/types";

import { getInputAccept } from "helpers/functions";

export interface FileInputProps {
  fileTypeName: FileType["name"];
  onChange: ChangeEventHandler<HTMLInputElement>;
  buttonTitle?: string;
  disabled: boolean;
}

const FileInput: FC<FileInputProps> = ({ fileTypeName, onChange, buttonTitle, disabled }) => {
  const handleClick = (e: MouseEvent<HTMLInputElement>) => {
    e.currentTarget.value = "";
  };
  return (
    <Button
      component="label"
      size="small"
      variant="contained"
      color="secondary"
      sx={btnStyles(buttonTitle)}
      disabled={disabled}>
      {buttonTitle ? <Typography>{buttonTitle}</Typography> : <AddRoundedIcon />}
      <input
        type="file"
        multiple
        hidden
        accept={getInputAccept(FILE_TYPES[fileTypeName].ext)}
        onClick={handleClick}
        onChange={onChange}
      />
    </Button>
  );
};

// TODO: create a func for getting sx styles with props, used overloading(() => SxWithProps | () => SxProps<Theme>)
const btnStyles: (buttonTitle: FileInputProps["buttonTitle"]) => SxProps<Theme> = buttonTitle => theme => ({
  padding: `${theme.spacing(0)} ${theme.spacing(buttonTitle ? 1 : 0.5)}`,
  height: "28px",
  minWidth: "unset",
  "&:hover": { bgcolor: "rgba(255, 255, 255, 0.1)" },
});

export default FileInput;
