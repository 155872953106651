import React from "react";
import * as geometryEngine from "@arcgis/core/geometry/geometryEngine";
import Polygon from "@arcgis/core/geometry/Polygon";
import Polyline from "@arcgis/core/geometry/Polyline";
import InfoIcon from "@mui/icons-material/Info";

import { Position } from "stores/mapStore/types";
import { MeasurementPoint, MeasurementType } from "stores/measurementStore/types";

import { getHoveredTextStyles } from "constants/styles";

import { LOCALES } from "./locales";
import { GetMeasurementItemInfo, MeasurementItemInfo } from "./types";
import { UIStore } from "stores/uiStore";
import { MapStore } from "stores/mapStore";

const DECIMAL_NUMBER_MEASUREMENT = 2;

interface Correction {
  correctionX: number;
  correctionY: number;
  correctionZ: number;
  durationSinceCalibration: number;
}

export interface MeasurementPointClipboard {
  x: number;
  y: number;
  z: number;
  correction?: Correction;
}

export const getPointCorrection = (point: MeasurementPoint): Correction | undefined => {
  const { correction } = point;
  if (correction) {
    const { fixPointName, fixPointId, ...rest } = correction;
    return { ...rest };
  }
};

export function checkPointHasCoords(
  point: MeasurementPoint
): point is MeasurementPoint & { x: number; y: number; z: number } {
  return point.x !== undefined && point.y !== undefined && point.z !== undefined;
}

const formatCoordinateNumber = (coordinate: number): string => {
  return coordinate.toFixed(3);
};

const get3DLineLength = (point1: Position, point2: Position, mapStore: MapStore): number => {
  if (point1[2] === undefined || point2[2] === undefined) {
    return 0;
  }

  const line = mapStore.getPolyline([[point1], [point2]]);
  const length2d = geometryEngine.planarLength(line, "meters");
  const absoluteHeightDifferent = Math.abs(point1[2] - point2[2]);

  return Math.sqrt(Math.pow(length2d, 2) + Math.pow(absoluteHeightDifferent, 2));
};

const get3DPathsLength = (paths: Position[], mapStore: MapStore): number => {
  if (paths.length > 2) {
    return get3DLineLength(paths[0], paths[1], mapStore) + get3DPathsLength(paths.slice(1), mapStore);
  }

  return get3DLineLength(paths[0], paths[1], mapStore);
};

// const getCommonInfo = (points: MeasurementPoint[]): MeasurementItemInfo[] => [
//   {
//     title: LOCALES.DATE,
//     value: getDateString(points[0]?.createdAt),
//   },
// ];

const getMultiPointsInfo = (points: MeasurementPoint[], geometry: Polyline | Polygon): MeasurementItemInfo[] => {
  const paths = geometry.type === "polyline" ? geometry.paths.flat(1) : geometry.rings.flat(1);

  return [
    {
      title: LOCALES.AMOUNT_OF_POINTS,
      value: points.length,
    },
    {
      title: LOCALES.LENGTH_2D,
      value: geometryEngine.geodesicLength(geometry, "meters").toFixed(DECIMAL_NUMBER_MEASUREMENT),
      unit: LOCALES.METER_UNIT_ABBREVIATION,
    },
    {
      title: LOCALES.LENGTH_3D,
      // value: get3DPathsLength(paths.map(p => p as Position)).toFixed(2),
      value: geometryEngine.geodesicLength(geometry, "meters").toFixed(DECIMAL_NUMBER_MEASUREMENT),
      unit: LOCALES.METER_UNIT_ABBREVIATION,
    },
  ];
};

export const getMeasurementItemInfoByTypeFunctions = (
  uiStore: UIStore,
  handleOpenPopup: Function
): Record<MeasurementType, GetMeasurementItemInfo> => ({
  SINGLE_POINT: points => {
    const point = points[0];

    const isCorrectionExist = point.correction;
    const isCodeExist = point.code;
    return [
      {
        title: LOCALES.X,
        value: checkPointHasCoords(point) ? formatCoordinateNumber(point.x) : "–",
      },
      {
        title: LOCALES.Y,
        value: checkPointHasCoords(point) ? formatCoordinateNumber(point.y) : "–",
      },
      {
        title: LOCALES.Z,
        value: checkPointHasCoords(point) ? formatCoordinateNumber(point.z) : "–",
        pointInfo: point,
      },
      ...(isCodeExist
        ? [
            {
              code: LOCALES.CODE,
              value: point.code!.name,
            },
          ]
        : []),
      ...(isCorrectionExist
        ? [
            {
              correction: LOCALES.CORRECTION,
              value: point.correction!.fixPointName,
              infoIcon: (
                <InfoIcon
                  onClick={e => {
                    const { fixPointId, fixPointName, ...rest } = point.correction!;
                    handleOpenPopup(e, { ...rest });
                  }}
                  sx={getHoveredTextStyles()}
                />
              ),
            },
          ]
        : []),
    ];
  },
  LINE: (points, polyline: Polyline) => [...getMultiPointsInfo(points, polyline)],
  POLYGON: (points, polygon: Polygon) => [
    ...getMultiPointsInfo(points, polygon),
    {
      title: LOCALES.AREA,
      value: geometryEngine.geodesicArea(polygon, "square-meters").toFixed(2),
      unit: LOCALES.SQUARE_METER_UNIT_ABBREVIATION,
    },
  ],
});
