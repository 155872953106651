import { useRootStore } from "hooks/useRootStore";

import useFetchMapManagerData from "./_useFetchMapManagerData";
import { FetchHook } from "./types";

/**
 * @returns { [isLoadingInProgress, isLoaded] }
 */
const useFetchProcessing: FetchHook = processingId => {
  const {
    processingStore: { processing, getProcessing, _setProcessing },
  } = useRootStore();

  const isProcessingExists = !!processing && processing.id === processingId;

  return useFetchMapManagerData(processingId, getProcessing, () => _setProcessing(null), isProcessingExists);
};

export default useFetchProcessing;
