import React, { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Button, Divider, TextField, Typography } from "@mui/material";

import { useRootStore } from "hooks/useRootStore";

import { PATHS } from "router/paths";

const DeletePopupForm: FC<{ id: string }> = ({ id }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { constructionSiteStore } = useRootStore();

  const [comment, setComment] = useState("");

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    await constructionSiteStore.delete(id, comment);
    navigate(PATHS.HOME.INDEX);
  };

  return (
    <form onSubmit={handleSubmit}>
      <Typography id="transition-modal-title" variant="h5" component="h2" textAlign="center" sx={{ mb: 2 }}>
        {t("General:ConfirmDeletion")}
      </Typography>
      <Divider sx={{ mx: -1.5, mb: 0.5 }} />
      <TextField
        id="comment"
        label={t("General:Comment")}
        fullWidth
        sx={{ mt: 2.5, mb: 2 }}
        required
        multiline
        rows={4}
        value={comment}
        onChange={e => setComment(e.target.value)}
      />
      <Button color="secondary" variant="contained" fullWidth type="submit">
        {t("General:Submit")}
      </Button>
    </form>
  );
};

export default DeletePopupForm;
