import React, { FC, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import { observer } from "mobx-react-lite";

import Tasks from "components/ConstructionSite/Tasks";
import Team from "components/ConstructionSite/Team";
import { iconStyles } from "constants/styles";
import { useRootStore } from "hooks/useRootStore";

import DeletePopupForm from "./DeletePopupForm";
import Description from "./Description";
import { Button } from "@mui/material";
import { FixPointsModal } from "./ConstructionSitesPanel/FixPointsModal";

const Card: FC = () => {
  const { t } = useTranslation();
  const { constructionSiteStore, teamStore, uiStore } = useRootStore();

  const { constructionSite, updateConstructionSite } = constructionSiteStore;
  const { csPermissions, isUserAdminOrOwner } = teamStore;
  const [title, setTitle] = useState(constructionSite.title);

  const finishEditing = useCallback(async () => {
    await updateConstructionSite(constructionSite.id, "title", title);
  }, [constructionSite.id, title, updateConstructionSite]);

  return (
    <>
      <Box sx={topStyles}>
        <Box sx={titleWrapperStyles}>
          <TextField
            size="small"
            sx={{ ...editableTitleStyles }}
            value={title}
            onChange={e => setTitle(e.target.value)}
            onBlur={finishEditing}
            inputProps={{ maxLength: 60, size: title.length }}
            disabled={!csPermissions.creating}
          />
          <IconButton
            sx={deleteIconStyles}
            onClick={() => uiStore.openModal(<DeletePopupForm id={constructionSite.id} />)}
            disabled={!csPermissions.deleting}>
            <DeleteOutlinedIcon fontSize="small" />
          </IconButton>
          {isUserAdminOrOwner && (
            <Button
              variant="contained"
              sx={{ color: "#000", ml: 2 }}
              color="primary"
              onClick={() => {
                uiStore.openModal(<FixPointsModal />);
              }}>
              {t("ConstructionSite:FixPoints")}
            </Button>
          )}
        </Box>
        <CloseRoundedIcon onClick={() => constructionSiteStore.setIsSliderCardExpanded(false)} sx={iconStyles} />
      </Box>
      <Divider sx={{ mx: -1.5, mt: 1 }} />
      <Box sx={{ display: "flex" }}>
        <Box sx={leftSideStyles}>
          <Tasks
            foreignId={constructionSite.id}
            constructionSiteId={constructionSite.id}
            acl={constructionSite.acl}
            noTasksMessage={t("Tasks:NoTasksInCS")}
            displaySectionName
          />
        </Box>
        <Divider orientation="vertical" flexItem />
        <Box sx={rightSideStyles}>
          <Description />
          <Team constructionSite={constructionSite} />
        </Box>
      </Box>
      <Divider sx={{ mx: -1.5 }} />
    </>
  );
};

const topStyles = {
  display: "flex",
  alignItems: "center",
  width: "100%",
};

const titleWrapperStyles = {
  m: "auto",
  transform: "translateX(35px)",
  display: "flex",
  alignItems: "center",
  maxWidth: "70%",
};

const editableTitleStyles = {
  "& .MuiInputBase-input": {
    fontFamily: "'futura-pt-bold', Sans-serif",
    fontSize: "22px",
    lineHeight: 1,
    padding: "4px 5px",
    textAlign: "center",
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: "transparent",
  },
} as const;

const deleteIconStyles = {
  ml: 0.5,
  color: "text.primary",
  bgcolor: "secondary.main",
  "& .MuiSvgIcon-root": {
    transition: "color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
  },
  "&:hover": {
    bgcolor: "secondary.main",
    "& .MuiSvgIcon-root": { color: "primary.main" },
  },
};

const leftSideStyles = {
  width: "50%",
  height: "300px",
  py: 1.5,
  pr: 1.5,
  display: "flex",
  flexDirection: "column",
} as const;

const rightSideStyles = {
  width: "50%",
  py: 1.5,
  pl: 1.5,
  display: "flex",
  flexDirection: "column",
} as const;

export default observer(Card);
