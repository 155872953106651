import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { observer } from "mobx-react-lite";

import { onboardingStore } from "stores/onboardingStore";

import { useRootStore } from "hooks/useRootStore";

import { OnboardingSelector } from "../../Onboarding/steps/types";

const CreateButton: FC = () => {
  const { t } = useTranslation();
  const { teamStore, areaStore } = useRootStore();

  const { csPermissions } = teamStore;
  const { isOnboardingRunning } = onboardingStore;

  const handleClick = () => {
    areaStore.startCreatingArea();

    if (isOnboardingRunning) {
      onboardingStore.nextStep();
    }
  };

  return (
    <Button
      id={OnboardingSelector.ADD_NEW_AREA}
      size="small"
      color="secondary"
      variant="contained"
      fullWidth
      sx={{ flexShrink: 0 }}
      onClick={handleClick}
      disabled={!csPermissions.creating}>
      <Typography variant="caption">+ {t("Area:AddNew")}</Typography>
    </Button>
  );
};

export default observer(CreateButton);
