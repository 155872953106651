import { useRootStore } from "hooks/useRootStore";;
import React, { FC, MouseEvent, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { observer } from "mobx-react-lite";

import { FileExt, FileType } from "stores/fileStore/types";

import { getInputAccept, isFileTypeExtEqual } from "helpers/functions";

import { handleInputFiles } from "../handleInputFiles";

import { FolderInputButton } from "./FolderInputButton";

export interface ChooseButtonProps {
  fileType: FileType;
}

const ChooseButton: FC<ChooseButtonProps> = ({ fileType }) => {
  const { t } = useTranslation();
  const rootStore = useRootStore();
  const { fileStore } = rootStore;

  const inputRef = useRef<HTMLInputElement>(null);
  const { filesFromInputs, infoPanel } = fileStore;
  const [files, setFiles] = useState<File[] | null>(null);
  const inputAccept = getInputAccept(fileType.ext);

  useEffect(() => {
    const filteredFiles = filesFromInputs.find(files => files.fileType.name === fileType.name);
    if (filteredFiles) {
      setFiles(filteredFiles.files);
    } else {
      setFiles(null);
    }
    if (inputRef.current) {
      inputRef.current.setAttribute("directory", "");
      inputRef.current.setAttribute("webkitdirectory", "");
    }
  }, [filesFromInputs.length]);

  const handleClick = (e: MouseEvent<HTMLInputElement>) => {
    e.currentTarget.value = "";
  };

  const handleInfoPanel = () => {
    if (files) {
      if (!infoPanel.open) {
        fileStore.setInfoPanel({ open: true, fileType });
      } else {
        fileStore.resetInfoPanel();
      }
    }
  };

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.setAttribute("directory", "");
      inputRef.current.setAttribute("webkitdirectory", "");
    }
  }, [inputRef]);

  return (
    <>
      <Button component="label" sx={btnStyles} color="secondary" onClick={handleInfoPanel}>
        <input
          type="file"
          accept={inputAccept}
          multiple={!isFileTypeExtEqual(fileType.ext, FileExt.csv)}
          hidden
          disabled={Boolean(files)}
          onClick={handleClick}
          onChange={async e => await handleInputFiles(e, fileType, false, fileStore, rootStore)}
        />
        <Box sx={{ display: "flex", alignItems: "center" }}>
          {files ? (
            <>
              <CheckRoundedIcon color="success" fontSize="small" sx={{ mr: 1 }} />
              <Typography variant="caption" letterSpacing="normal">
                {files.length + " " + t(`Files:${(files.length === 1 && "Singular") || "Plural"}`)}
              </Typography>
            </>
          ) : (
            <>
              <FileUploadOutlinedIcon fontSize="small" />
              <Typography sx={textStyles} variant="caption">
                {isFileTypeExtEqual(fileType.ext, FileExt.csv) ? t("Files:ChooseOne") : t("Files:ChooseMany")}
              </Typography>
            </>
          )}
        </Box>
      </Button>
      {(isFileTypeExtEqual(fileType.ext, FileExt.jpeg) || isFileTypeExtEqual(fileType.ext, FileExt.tiff)) && !files && (
        <FolderInputButton fileType={fileType} />
      )}
    </>
  );
};

export const btnStyles = {
  color: "text.primary",
  textTransform: "none",
  height: "100%",
  flex: "1",
  textAlign: "center",
  zIndex: 10,
  borderRadius: "0 5px 5px 0",
  "& .MuiTypography-root": {
    transition: "color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
  },
  "& .MuiSvgIcon-root": {
    transition: "color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
  },
  "&:hover": {
    "& .MuiTypography-root": {
      color: "#ADADAD",
    },
    "& .MuiSvgIcon-root": {
      color: "#ADADAD",
    },
  },
} as const;

export const textStyles = {
  ml: "6px",
  letterSpacing: "normal",
};

export default observer(ChooseButton);
