import { useRootStore } from "hooks/useRootStore";;
import React, { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Checkbox from "@mui/material/Checkbox";
import CircularProgress from "@mui/material/CircularProgress";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import { observer } from "mobx-react-lite";

import { IFile } from "stores/fileStore/types";

import { NoDataMessage } from "common/NoDataMessage";
import { TabPanel } from "components/ConstructionSite/Area/Card/Tabs";

import { formControlLabelStyles, formGroupStyles, InputType } from "..";

import { FormLabelContent } from "./FormLabelContent";

interface TabPanelWithCheckboxProps {
  tabIndex: number;
  index: number;
  isLoaded: boolean;
  files: IFile[];
  inputType: InputType;
}
export const CheckboxTabPanel: FC<TabPanelWithCheckboxProps> = observer(
  ({ tabIndex, index, isLoaded, files, inputType }) => {
    const { t } = useTranslation();
    const { fileStore } = useRootStore();

    const [dxfInput, setDxfInput] = useState([] as string[]);
    const [csvInput, setCsvInput] = useState([] as string[]);

    const getIsChecked = (id: string) => {
      if (inputType === InputType.DXF) {
        return dxfInput.includes(id);
      } else {
        return csvInput.includes(id);
      }
    };

    useEffect(() => {
      dxfInput && fileStore.setSelectedDxfFilesForReuse(dxfInput);
      csvInput && fileStore.setSelectedCsvFilesForReuse(csvInput);
    }, [dxfInput, csvInput]);

    const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const {
        target: { value, checked },
      } = event;

      if (inputType === InputType.DXF) {
        if (checked) {
          setDxfInput(prev => [...prev, value]);
        } else setDxfInput(prev => prev.filter(id => id !== value));
      } else {
        if (checked) {
          setCsvInput(prev => [...prev, value]);
        } else setCsvInput(prev => prev.filter(id => id !== value));
      }
    };

    return (
      <TabPanel value={tabIndex} index={index}>
        <FormGroup sx={formGroupStyles}>
          {isLoaded ? (
            <CircularProgress size={35} sx={{ color: "text.primary" }} />
          ) : files.length > 0 ? (
            files.map(file => (
              <FormControlLabel
                key={file.id}
                sx={formControlLabelStyles}
                control={<Checkbox checked={getIsChecked(file.id)} value={file.id} onChange={onChange} />}
                label={<FormLabelContent file={file} />}
              />
            ))
          ) : (
            <NoDataMessage message={t("Processings:NoFilesToChoose")} />
          )}
        </FormGroup>
      </TabPanel>
    );
  }
);
