import { useRootStore } from "hooks/useRootStore";
import React, { ChangeEvent, FC, MouseEvent, useState } from "react";
import { useTranslation } from "react-i18next";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import { Tooltip } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { observer } from "mobx-react-lite";

import { FileType, IFile } from "stores/fileStore/types";
import { Processings } from "stores/managerService/processingStore/types";

const UploadButton: FC<{
  file?: IFile;
  foreignId: string;
  aclId: string;
  fileType: FileType;
  projectId: number;
  disabled: boolean;
  processingType: Processings;
}> = ({ file, foreignId, aclId, fileType, projectId, disabled, processingType }) => {
  const { t } = useTranslation();
  const { fileStore } = useRootStore();

  const [inProgress, setInProgress] = useState(false);

  const handleClick = (e: MouseEvent<HTMLInputElement>) => {
    e.currentTarget.value = "";
  };

  const handleChange = async (e: ChangeEvent<HTMLInputElement>) => {
    if (file) {
      await fileStore.delete(foreignId, file.id);
    }
    setInProgress(true);
    const filesToUpload = Array.from(e.target.files!);

    fileStore.startUpload({ files: filesToUpload, foreignId, aclId, fileType, projectId, processingType });
  };

  return (
    <Button component="label" sx={btnStyles(file!)} color="secondary" disabled={disabled || inProgress}>
      <input type="file" accept={`.${fileType.ext}`} hidden onClick={handleClick} onChange={handleChange} />
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}>
        {(file && <ReplaceButton />) || (
          <>
            <FileUploadOutlinedIcon fontSize="small" />
            <Typography sx={textStyles} variant="caption">
              {t("Files:Upload")}
            </Typography>
          </>
        )}
      </Box>
    </Button>
  );
};

const ReplaceButton = () => {
  const { t } = useTranslation();
  return (
    <Tooltip title={t("Files:ReplaceFile")}>
      <FileUploadOutlinedIcon fontSize="small" />
    </Tooltip>
  );
};

const btnStyles = (file: IFile) => {
  return {
    color: "text.primary",
    minWidth: "0px",
    textTransform: "none",
    height: "100%",
    flex: !file ? "1" : "",
    textAlign: "center",
    zIndex: 10,
    ml: !file ? "-24px" : "",
    borderRadius: "0 5px 5px 0",
    "& .MuiTypography-root, .MuiSvgIcon-root": {
      transition: "color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    },
    "&:hover": {
      "& .MuiTypography-root, .MuiSvgIcon-root": {
        color: "#ADADAD",
      },
    },
    "&.Mui-disabled": {
      "& .MuiTypography-root, .MuiSvgIcon-root": {
        transition: "none",
      },
    },
  };
};

const textStyles = {
  ml: "6px",
  letterSpacing: "normal",
};

export default observer(UploadButton);
