import React, { FC } from "react";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import { Box, SxProps, Theme } from "@mui/material";

import { FileExt } from "stores/fileStore/types";

import FileUploaderIcon from "./FileUploaderIcon";
import { ImportFilesStore } from "stores/importFilesStore";

interface AdditionalToolbarProps {
  addGraphicsFromFile: ImportFilesStore["addGraphicsFromFile"];
}

const AdditionalToolbar: FC<AdditionalToolbarProps> = ({ addGraphicsFromFile }) => {
  return (
    <Box sx={containerStyles}>
      <FileUploaderIcon
        fileType={[FileExt.kml]}
        icon={<InsertDriveFileOutlinedIcon sx={{ cursor: "pointer" }} fontSize={"small"} />}
        onChange={addGraphicsFromFile}
      />
    </Box>
  );
};

const containerStyles: SxProps<Theme> = {
  display: "flex",
  height: "100%",
};

export default AdditionalToolbar;
