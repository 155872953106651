import axios from "axios";
import { stringify } from "query-string";

import { baseUrl, protocol } from "configs/servicesConfig";
import {
  ExportCSVResponse,
  ExportJSONResponse,
  TaskCreateParams,
  TaskDto,
  TaskExportType,
  TaskParamsForUpdate,
} from "stores/taskStore/types";

import { removeNullishFromObject } from "helpers/functions";

export const getAllTasks = async (queryObj: { constructionSiteId?: string; acl?: string }) => {
  const { constructionSiteId, acl } = queryObj;
  const queryParams = stringify(removeNullishFromObject({ constructionSite: constructionSiteId, acl }));

  const response = await axios.get<{ payload: { count: number; data: [] } }>(
    `${protocol}//${baseUrl}/taskService/tasks/?${queryParams}`
  );
  return response.data.payload.data;
};

export const getAllByAssignedTo = async (assignedTo: string) => {
  const response = await axios.get<{ payload: { count: number; data: [] } }>(
    `${protocol}//${baseUrl}/taskService/tasks/?assignedTo=${assignedTo}`
  );
  return response.data.payload.data;
};

export const create = async (params: TaskCreateParams) => {
  const response = await axios.post(`${protocol}//${baseUrl}/taskService/tasks`, {
    ...removeNullishFromObject(params),
  });
  return response.data;
};

export const updateTask = async (
  foreignId: string,
  id: string,
  name: TaskParamsForUpdate,
  value: string | number | string[]
) => {
  const response = await axios.patch(`${protocol}//${baseUrl}/taskService/tasks/${foreignId}/${id}`, {
    [name]: value,
  });
  return response as { data: { payload?: TaskDto; status?: string } };
};

export const deleteTask = async (foreignId: string, id: string) => {
  const response = await axios.delete<{ payload?: TaskDto; status?: string }>(
    `${protocol}//${baseUrl}/taskService/tasks/${foreignId}/${id}`
  );
  return response.data;
};

export const bcfExport = async (tasks: TaskDto[]) => {
  const response = await axios.post<{ tasks: TaskDto[] }, { data: { payload?: { downloadLink: string } } }>(
    `${protocol}//${baseUrl}/bcfService/export`,
    {
      tasks,
    }
  );
  return response.data;
};

const exportFormats: Record<TaskExportType, string | undefined> = {
  BCF: undefined,
  JSON: undefined,
  CSV: "csv",
};

type ExportTaskId = string[] | string;

export function jsonOrCsvExport(
  exportType: TaskExportType.JSON,
  csId: string,
  tasksIds: ExportTaskId | undefined
): Promise<ExportJSONResponse>;

export function jsonOrCsvExport(
  exportType: TaskExportType.CSV,
  csId: string,
  taskId: ExportTaskId | undefined
): Promise<ExportCSVResponse>;

export async function jsonOrCsvExport(
  exportType: TaskExportType.JSON | TaskExportType.CSV,
  csId: string,
  taskId: ExportTaskId | undefined
  // undefined is an impossible return value
  // @ts-expect-error
): Promise<ExportJSONResponse | ExportCSVResponse> {
  const format = exportFormats[exportType];

  // data is ApiResponse<ExportJSONResponse> | ExportCSVResponse
  const { data } = await axios.post(
    `${protocol}//${baseUrl}/taskService/export`,
    { taskId },
    { params: { constructionSite: csId, format } }
  );

  if (exportType === TaskExportType.JSON) {
    return data.payload as ExportJSONResponse;
  }

  if (exportType === TaskExportType.CSV) {
    return data as ExportCSVResponse;
  }
}
