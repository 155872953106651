import Graphic from "@arcgis/core/Graphic";
import { CreatedUpdated } from "types";

export const enum MeasurementType {
  SINGLE_POINT = "SINGLE_POINT",
  LINE = "LINE",
  POLYGON = "POLYGON",
}

export interface Measurement extends CreatedUpdated {
  foreignId: string;
  acl: string;
  description: string;
  id: string;
  title: string;
  type: MeasurementType;
}

export interface MeasurementPoint extends CreatedUpdated {
  foreignId: string;
  id: string;
  acl: string;
  title: string;
  latitude: number;
  longitude: number;
  altitude: number;
  accuracyXY: number;
  accuracyZ: number;
  description?: string;
  x?: number;
  y?: number;
  z?: number;
  accuracy?: number;
  altitudeCorrection?: number;
  order: number;
  duration?: number;
  imageId?: string;
  isGcp?: boolean;
  code?: {
    name: string;
    number: string;
    createdAt: number;
    type: string;
  };
  correction?: MeasurementCorrection;
}

export interface MeasurementCorrection {
  correctionY: number;
  durationSinceCalibration: number;
  correctionX: number;
  fixPointName: string;
  correctionZ: number;
  fixPointId: string;
}
export type FromPointsToGraphic = (points: MeasurementPoint[]) => Graphic;

export interface ImageInfo {
  foreignId: string;
  id: string;
  longitude: number;
  latitude: number;
}

export interface ExportDxfFileResponse {
  success: boolean;
  url: string;
}
