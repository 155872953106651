import { useRootStore } from "hooks/useRootStore";;
import React, { FC, FormEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Fade from "@mui/material/Fade";
import FormControl from "@mui/material/FormControl";
import InputAdornment from "@mui/material/InputAdornment";
import Link from "@mui/material/Link";
import OutlinedInput from "@mui/material/OutlinedInput";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { observer } from "mobx-react-lite";



const Email: FC = () => {
  const { t } = useTranslation();
  const { authStore, settingsStore } = useRootStore();

  const { error, userAttributes } = authStore;
  const [email, setEmail] = useState("");
  const [emailVerified, setEmailVerified] = useState("true");
  const [verificationCode, setVerificationCode] = useState("");
  const [countdown, setCountdown] = useState(0);

  useEffect(() => {
    if (userAttributes) {
      for (const attr of userAttributes) {
        if (attr.Name === "email") {
          setEmail(attr.Value);
        }
        if (attr.Name === "email_verified") {
          setEmailVerified(attr.Value);
        }
      }
    }
  }, [userAttributes]);

  useEffect(() => {
    if (countdown > 0) {
      setTimeout(() => setCountdown(countdown - 1), 1000);
    }
  }, [countdown]);

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    settingsStore.updateEmail(email);
  };

  const handleSubmitVerification = (e: FormEvent) => {
    e.preventDefault();
    authStore.verifyEmail(verificationCode);
  };

  const handleResendCode = () => {
    setCountdown(60);
    authStore.getEmailVerificationCode();
  };

  return (
    <>
      <Box component="form" onSubmit={handleSubmit} sx={formStyles}>
        <Box sx={{ mr: 2, width: "100%" }}>
          <Typography variant="caption" color="text.secondary" fontWeight="500">
            {t("General:Email")} *
          </Typography>
          <FormControl sx={{ mt: 0.5 }} variant="outlined" fullWidth size="small" required>
            <OutlinedInput
              id="email"
              type="email"
              autoComplete="email"
              value={email}
              onChange={e => setEmail(e.target.value)}
              endAdornment={
                <InputAdornment position="end">
                  {emailVerified === "true" ? (
                    <CheckCircleOutlineIcon color="success" />
                  ) : (
                    <ErrorOutlineIcon color="error" />
                  )}
                </InputAdornment>
              }
            />
          </FormControl>
        </Box>
        <div>
          <Button variant="contained" type="submit" sx={{ height: "40px" }}>
            {t("General:Change")}
          </Button>
        </div>
      </Box>
      {emailVerified === "false" && (
        <Fade in>
          <Box>
            <Typography variant="caption" component="div" color="error" sx={{ mt: 0.5 }}>
              {t("Settings:EmailNotVerified")}
            </Typography>
            <Box component="form" onSubmit={handleSubmitVerification} sx={{ display: "flex", mt: 2, mb: 1 }}>
              <TextField
                label={t("Auth:CodeLabel")}
                variant="outlined"
                size="small"
                type="text"
                sx={{ mr: 2 }}
                required
                value={verificationCode}
                onChange={e => setVerificationCode(e.target.value)}
              />
              <div>
                <Button variant="contained" color="secondary" type="submit" disableElevation sx={{ height: "100%" }}>
                  {t("Auth:Confirm")}
                </Button>
              </div>
            </Box>
            <Typography variant="caption" component="div" color="error" sx={{ mb: 1 }}>
              {error}
            </Typography>
            <Typography variant="caption" component="div" color="primary">
              {t("Auth:ConfirmSignUpMessage")}
            </Typography>
            <Box sx={{ display: "flex", alignItems: "center", mt: 1 }}>
              {countdown > 0 ? (
                <>
                  <Typography variant="caption" color="primary" sx={{ mr: 0.5 }}>
                    {t("Auth:CodeRequestCountdown")} {countdown}s.
                  </Typography>
                </>
              ) : (
                <Link component="button" variant="caption" onClick={handleResendCode}>
                  {t("Auth:ResendCode")}.
                </Link>
              )}
            </Box>
          </Box>
        </Fade>
      )}
    </>
  );
};

const formStyles = {
  mt: 1,
  display: "flex",
  alignItems: "flex-end",
  maxWidth: "540px",
  "& .MuiOutlinedInput-notchedOutline": { borderColor: "text.secondary" },
};

export default observer(Email);
