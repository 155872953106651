import { useRootStore } from "hooks/useRootStore";;
import React, { FC, FormEvent, useState } from "react";
import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { observer } from "mobx-react-lite";



const Password: FC = () => {
  const { t } = useTranslation();
  const { authStore, settingsStore } = useRootStore();

  const passwordValidationError = t("Auth:PasswordValidationError");

  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");

  const { isPasswordValid, isPasswordMatch, error } = authStore;

  const handleSubmitPassword = (e: FormEvent) => {
    e.preventDefault();
    settingsStore.updatePassword(currentPassword, newPassword, confirmNewPassword);
  };

  return (
    <Box sx={{ mt: 3 }}>
      <Box component="form" onSubmit={handleSubmitPassword} sx={{ display: "flex", alignItems: "flex-end" }}>
        <Box sx={{ mr: 2, width: "100%" }}>
          <Typography variant="caption" color="text.secondary" fontWeight="500">
            {t("Auth:CurrentPasswordLabel")}
          </Typography>
          <TextField
            variant="outlined"
            size="small"
            type="password"
            autoComplete="current-password"
            sx={inputStyles}
            fullWidth
            required
            value={currentPassword}
            onChange={e => setCurrentPassword(e.target.value)}
          />
        </Box>
        <Box sx={{ mr: 2, width: "100%" }}>
          <Typography variant="caption" color="text.secondary" fontWeight="500">
            {t("Auth:NewPasswordLabel")}
          </Typography>
          <TextField
            variant="outlined"
            size="small"
            type="password"
            autoComplete="new-password"
            sx={inputStyles}
            fullWidth
            required
            value={newPassword}
            onChange={e => setNewPassword(e.target.value)}
          />
        </Box>
        <Box sx={{ mr: 2, width: "100%" }}>
          <Typography variant="caption" color="text.secondary" fontWeight="500">
            {t("Auth:ConfirmNewPasswordLabel")}
          </Typography>
          <TextField
            variant="outlined"
            size="small"
            type="password"
            autoComplete="new-password"
            sx={inputStyles}
            fullWidth
            required
            value={confirmNewPassword}
            onChange={e => setConfirmNewPassword(e.target.value)}
          />
        </Box>
        <div>
          <Button variant="contained" type="submit" sx={{ height: "40px" }}>
            {`${t("General:Update")}`}
          </Button>
        </div>
      </Box>
      {!isPasswordValid && (
        <Typography variant="caption" color="error" component="div" sx={{ mt: 1 }}>
          {!isPasswordValid && passwordValidationError}
        </Typography>
      )}
      {(error || !isPasswordMatch) && (
        <Typography variant="caption" color="error" component="div" sx={{ mt: 1 }}>
          {error || t("Auth:PasswordMatchError")}
        </Typography>
      )}
    </Box>
  );
};

const inputStyles = {
  mt: 0.5,
  "& .MuiOutlinedInput-notchedOutline": { borderColor: "text.secondary" },
};

export default observer(Password);
