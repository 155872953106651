import React from "react";
import { useTranslation } from "react-i18next";
import { SxProps, Theme } from "@mui/material";
import Fade from "@mui/material/Fade";
import Paper from "@mui/material/Paper";

const EmptyCard = () => {
  const { t } = useTranslation();

  return (
    <Fade in>
      <Paper elevation={0} sx={emptyCardStyles}>
        {t("Tasks:NoTasks")}
      </Paper>
    </Fade>
  );
};

const emptyCardStyles: SxProps<Theme> = {
  minHeight: "130px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  border: "1px solid",
  borderColor: "secondary.light",
  color: "secondary.light",
  bgcolor: "transparent",
};

export default EmptyCard;
