import { makeAutoObservable, reaction, runInAction } from "mobx";
import RootStore from "stores/rootStore";
import { billingService } from "./service";
import { BillingReportItem, GetBillingInfoQueryParams, GetByCSIdResponse, MetricKey } from "./types";
import { calculateTotalCostForAllMetrics } from "./helper";

export class MetricStore {
  constructor(private readonly rootStore: RootStore) {
    makeAutoObservable(this);
    reaction(
      () => this.queryParams,
      async updatedFields => {
        if (updatedFields.constructionSiteId) {
          await this.getBillingInfo(updatedFields);
        }
      }
    );
  }

  public reports: BillingReportItem[] | null = null;
  private queryParams: GetBillingInfoQueryParams = {} as GetBillingInfoQueryParams;
  public isBillingInfoFetching = false;
  public totalCosts: number | null = null;

  get userId(): string {
    return this.rootStore.authStore.userName;
  }

  public setBillingInfoFetchingStatus = (value: boolean) => {
    this.isBillingInfoFetching = value;
  };

  public setReports = (value: any[]) => {
    this.reports = value;
  };

  public readonly setQueryParams = (value: Partial<GetBillingInfoQueryParams>) => {
    this.queryParams = { ...this.queryParams, ...value };
  };

  public getBillingInfo = async (params: GetBillingInfoQueryParams) => {
    this.setBillingInfoFetchingStatus(true);
    const data = await billingService.getBillingInfo(params);
    console.log(data);
    // this.setReports(data);
    const totalCosts = calculateTotalCostForAllMetrics(data);
    runInAction(() => (this.totalCosts = totalCosts));
    console.log("totalCosts", totalCosts);
    this.setBillingInfoFetchingStatus(false);
  };

  public fetchByCsID = async (constructionSiteId: string) => {
    const data = await billingService.getByCsID(constructionSiteId);
    // this.setReports(data);
  };

  public fetchByUserId = async (constructionSiteId: string, userId: string) => {
    const data = await billingService.getByUserId(constructionSiteId, userId);
    // this.setReports();
  };

  public fetchByMetricKey = async (constructionSiteId: string, metricKey: string) => {
    const data = await billingService.getByMetricKey(constructionSiteId, metricKey);
    // this.setReports(data);
  };

  public sumQuantitiesPerMetric = (response: GetByCSIdResponse): { [metricKey in MetricKey]?: number } => {
    const result: { [metricKey in MetricKey]?: number } = {};

    for (const metricKey in response.payload) {
      if (response.payload[metricKey]) {
        result[metricKey as MetricKey] = response.payload[metricKey].reduce((acc, item) => acc + item.quantity, 0);
      }
    }

    return result;
  };
}
