import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import DeleteOutlineRoundedIcon from "@mui/icons-material/DeleteOutlineRounded";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { Stack, SxProps, Theme } from "@mui/material";
import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";

import { Task, TaskStatus } from "stores/taskStore/types";

import { useRootStore } from "hooks/useRootStore";
import IconButton from "@mui/material/IconButton";

interface ActionsProps {
  task: Task;
  inputRef: React.MutableRefObject<HTMLInputElement | null>;
  exportMode: boolean;
  setEditTaskName: (isEdit: boolean) => void;
  setDisableRipple: (disabled: boolean) => void;
  isAllowedToDeleteOrEdit: boolean;
}

const Actions: FC<ActionsProps> = ({
  task,
  inputRef,
  exportMode,
  setEditTaskName,
  setDisableRipple,
  isAllowedToDeleteOrEdit,
}) => {
  const { t } = useTranslation();

  const {
    taskStore: { deleteTask },
    uiStore: { setConfirmModalOpen },
  } = useRootStore();

  const handleEdit = (e: React.MouseEvent<SVGSVGElement>) => {
    e.stopPropagation();
    if (isAllowedToDeleteOrEdit) {
      return;
    }
    setEditTaskName(true);
    inputRef.current!.select();
  };

  const handleDelete = (e: React.MouseEvent<SVGSVGElement>) => {
    e.stopPropagation();
    if (!isAllowedToDeleteOrEdit) {
      return;
    }
    setConfirmModalOpen({ onConfirm: async () => await deleteTask(task.foreignId, task.id) });
  };

  return (
    <Stack spacing={0.8} direction={"row"} sx={iconsContainerStyles}>
      <Tooltip title={t("Tasks:EditTaskName")}>
        <IconButton disabled={!isAllowedToDeleteOrEdit} sx={buttonStyles}>
          <EditOutlinedIcon
            sx={iconStyles}
            onMouseEnter={() => setDisableRipple(true)}
            onMouseLeave={() => setDisableRipple(false)}
            onClick={handleEdit}
          />
        </IconButton>
      </Tooltip>
      <Tooltip title={t("Tasks:DeleteTask")}>
        <IconButton disabled={!isAllowedToDeleteOrEdit || task.status !== TaskStatus.PENDING} sx={buttonStyles}>
          <DeleteOutlineRoundedIcon
            sx={iconStyles}
            onMouseEnter={() => setDisableRipple(true)}
            onMouseLeave={() => setDisableRipple(false)}
            onClick={handleDelete}
          />
        </IconButton>
      </Tooltip>
    </Stack>
  );
};

const iconsContainerStyles: SxProps<Theme> = {
  alignItems: "center",
};

const buttonStyles: SxProps<Theme> = {
  backgroundColor: "#444",
  "&:disabled": {
    backgroundColor: "#444",
    "& svg": {
      color: "text.disabled",
    },
  },
  "&:hover": {
    backgroundColor: "#444",
    "& svg": { color: "primary.main" },
  },
};

const iconStyles: SxProps<Theme> = {
  fontSize: "1rem",
  color: "#fff",
  transition: "color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
};

export default Actions;
