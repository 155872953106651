export const iconStyles = {
  color: "text.primary",
  bgcolor: "background.paper",
  "& .MuiSvgIcon-root": {
    transition: "color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
  },
  "&:hover": {
    bgcolor: "background.paper",
    "& .MuiSvgIcon-root": {
      color: "primary.main",
    },
  },
  "&.Mui-disabled": {
    bgcolor: "secondary.main",
    "& .MuiSvgIcon-root": {
      opacity: 0.2,
    },
  },
};
