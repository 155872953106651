import axios from "axios";
import { Service, baseUrl, protocol } from "configs/servicesConfig";
import { BillingReportItem, GetBillingInfoQueryParams, GetByCSIdResponse, GetByMetricKeyResponse } from "./types";
import { stringify } from "querystring";
import { removeNullishFromObject } from "helpers/functions";
const { BILLING } = Service;

export const billingService = {
  getBillingInfo: async (params: GetBillingInfoQueryParams) => {
    const queryParams = stringify(removeNullishFromObject(params));

    const response = await axios.get<GetByCSIdResponse | GetByMetricKeyResponse>(
      `${protocol}//${baseUrl}/${BILLING}/report?${queryParams}`
    );
    return response.data;
  },
  getByCsIDWithPeriod: async (constructionSiteId: string, fromDate: number, toDate: number) => {
    const response = await axios.get<GetByCSIdResponse>(
      `${protocol}//${baseUrl}/report?constructionSiteId=${constructionSiteId}&fromDate${fromDate}&toDate${toDate}`
    );
    return response.data.payload;
  },
  getByCsID: async (constructionSiteId: string) => {
    const response = await axios.get<GetByCSIdResponse>(
      `${protocol}//${baseUrl}/report?constructionSiteId=${constructionSiteId}`
    );
    return response.data.payload;
  },

  getByUserId: async (constructionSiteId: string, userId: string) => {
    const response = await axios.get<GetByCSIdResponse>(
      `${protocol}//${baseUrl}/report?constructionSiteId=${constructionSiteId}&userId=${userId}`
    );
    return response.data.payload;
  },

  getByMetricKey: async (constructionSiteId: string, metricKey: string): Promise<BillingReportItem[]> => {
    const response = await axios.get<GetByMetricKeyResponse>(
      `${protocol}//${baseUrl}/report?constructionSiteId=${constructionSiteId}&metricKey=${metricKey}`
    );
    return response.data.payload;
  },
};
