import React, { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import UndoIcon from "@mui/icons-material/Undo";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Typography from "@mui/material/Typography";
import { observer } from "mobx-react-lite";

import { IArea } from "stores/managerService/areaStore";
import { IConstructionSite } from "stores/managerService/constructionSiteStore/types";
import { GetTeamBy } from "stores/managerService/teamStore/types";

import { useRootStore } from "hooks/useRootStore";

import AddMemberPopup from "./AddMemberPopup";
import MembersTable from "./MembersTable";

const Teams: FC<{ constructionSiteId?: string; areaId?: string; minimized?: boolean }> = props => {
  const { t } = useTranslation();
  const { constructionSiteStore, teamStore, uiStore, areaStore } = useRootStore();

  const { constructionSites } = constructionSiteStore;
  const [constructionSiteId, setConstructionSiteId] = useState(props.constructionSiteId ?? "");
  const [areaId, setAreaId] = useState(props.areaId ?? "");
  const {
    currentUserRoleInCS,
    csPermissions,
    protectedAreaPermissions,
    currentUserRoleInProtectedArea,
    getCSTeamMembers,
    getProtectedAreaMembers,
    resetMembers,
    setProtectedAreaMembers,
  } = teamStore;
  const { getProtectedAreas, protectedAreas } = areaStore;
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    !constructionSites && constructionSiteStore.getAll();
    return () => {
      resetMembers();
    };
  }, [constructionSites]);

  async function handleChangeCS(value: string) {
    setConstructionSiteId(value);
    setIsLoading(true);
    setAreaId("");
    await getCSTeamMembers(GetTeamBy.RESOURCE, value);
    await getProtectedAreas(value);
    setIsLoading(false);
  }

  async function handleChangeArea(value: string) {
    setAreaId(value);
    setIsLoading(true);
    await getProtectedAreaMembers(GetTeamBy.RESOURCE, value);
    setIsLoading(false);
  }

  function resetArea() {
    setAreaId("");
    setProtectedAreaMembers(null);
  }

  useEffect(() => {
    constructionSiteId && handleChangeCS(constructionSiteId);
    areaId && handleChangeArea(areaId);
  }, []);

  return (
    <Box sx={containerStyles}>
      {constructionSites && (
        <>
          <Box sx={wrapperStyles}>
            <Box>
              <SelectorFormControl
                label={t("ConstructionSite:Singular")}
                value={constructionSiteId}
                onChange={async ({ target: { value } }) => await handleChangeCS(value)}
                options={constructionSites}
              />
              {!!protectedAreas.length && (
                <SelectorFormControl
                  label={t("Area:ProtectedArea")}
                  value={areaId}
                  onChange={async ({ target: { value } }) => await handleChangeArea(value)}
                  options={protectedAreas}
                />
              )}
              {areaId && (
                <Button>
                  <UndoIcon onClick={resetArea} />
                </Button>
              )}
            </Box>
            <Button
              variant="contained"
              onClick={() =>
                uiStore.openModal(
                  <AddMemberPopup
                    constructionSiteId={constructionSiteId}
                    areaId={areaId}
                    currentUserRole={areaId ? currentUserRoleInProtectedArea : currentUserRoleInCS}
                  />
                )
              }
              sx={btnStyles}
              disabled={
                (!!constructionSiteId && !csPermissions.creating) || (!!areaId && !protectedAreaPermissions.creating)
              }>
              + {t("Team:AddMember")}
            </Button>
          </Box>
          {constructionSiteId ? (
            <MembersTable
              isLoading={isLoading}
              minimized={props.minimized ?? false}
              constructionSiteId={constructionSiteId}
              areaId={areaId}
              currentUserRole={(!!areaId && currentUserRoleInProtectedArea) || currentUserRoleInCS}
            />
          ) : (
            <Typography variant="body1" component="div" sx={textStyles}>
              {t("Team:SelectConstructionSite")}
            </Typography>
          )}
        </>
      )}
    </Box>
  );
};

interface SelectorFormControlProps {
  label: string;
  value: string;
  onChange: (e: SelectChangeEvent) => Promise<void>;
  options: IConstructionSite[] | IArea[];
}

const SelectorFormControl: FC<SelectorFormControlProps> = ({ label, value, onChange, options }) => {
  const { t } = useTranslation();

  return (
    <FormControl sx={selectorStyles} size="small">
      <InputLabel id={`${label}-label`}>{label}</InputLabel>
      <Select labelId={`${label}-label`} id={`${label}-select`} value={value} label={label} onChange={onChange}>
        {!options.length ? (
          <MenuItem value="">
            <em>{t("General:None")}</em>
          </MenuItem>
        ) : (
          options.map(option => (
            <MenuItem value={option.id} key={option.id}>
              {option.title}
            </MenuItem>
          ))
        )}
      </Select>
    </FormControl>
  );
};

const containerStyles = { mt: 2, mr: 2, ml: 4 };

const wrapperStyles = {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  flex: 1,
};

const selectorStyles = {
  width: "240px",
  mb: 1,
  mr: 1,
  "& .MuiInputLabel-root": { color: "#ADADAD" },
  "& .MuiOutlinedInput-notchedOutline": { borderColor: "#ADADAD" },
};

const btnStyles = { "&.Mui-disabled": { bgcolor: "secondary.main" } };

const textStyles = {
  mt: 20,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};

export default observer(Teams);
