import { useCallback, useMemo, useState } from "react";

import { useRootStore } from "hooks/useRootStore";

import useFetchMapManagerData from "./_useFetchMapManagerData";
import { FetchHook } from "./types";

/**
 * @returns { [isLoadingInProgress, isLoaded] }
 */
const useFetchAreaAndJobs: FetchHook = areaId => {
  const {
    areaStore: { getAndSetArea, _setArea, area },
    jobStore: { getAllJobs, _setJobs },
  } = useRootStore();

  const fetchAreaAndJobs = useCallback(
    async (areaId: string) => {
      await getAndSetArea(areaId);
      await getAllJobs(areaId);
    },
    [getAllJobs, getAndSetArea]
  );

  const clearAreaAndJobs = useCallback(() => {
    _setArea(null);
    _setJobs([]);
  }, [_setArea, _setJobs]);

  const isExists = useMemo(() => !!area && area.id === areaId, [area, areaId]);

  return useFetchMapManagerData(areaId, fetchAreaAndJobs, clearAreaAndJobs, isExists);
};

export default useFetchAreaAndJobs;
