import { action, computed, makeObservable, observable } from "mobx";
import { BreadcrumbSteps, SortParams } from "types";

import areas from "stores/managerService/areaStore/service";
import { CheckGraphics, ErrorI18KeyAndOptions } from "stores/managerService/helpers/checkGraphics";
import { GraphicId, MapItem } from "stores/mapStore/types";
import RootStore from "stores/rootStore";

import { LOCALES } from "constants/locales";
import { sortByParameter } from "helpers/functions";

const localStorage = window.localStorage;

export interface IArea extends MapItem {
  constructionSite: string;
  isProtected: boolean;
}

const CONTAINER_LOCALE_CS = LOCALES.CONSTRUCTION_SITE.SINGULAR;
const CURRENT_LOCALE_AREA = LOCALES.AREA.AREA;
const NESTED_LOCALE_JOB = LOCALES.JOB.JOB;

export class AreaStore {
  private readonly checkGraphics = new CheckGraphics(this.rootStore, ["polygon"]);

  @observable
  filteredAreas: IArea[] = [];

  @observable
  areas: IArea[] = [];

  @observable
  area: IArea | null = null;

  @computed
  get areaIndex(): number | undefined {
    return this.areas.findIndex(a => a.id === this.area?.id);
  }

  @observable
  createMode = false;

  @observable
  editMode = false;

  @observable
  editableArea: string | null = null;

  @observable
  protectedAreas = [] as IArea[];

  constructor(private readonly rootStore: RootStore) {
    makeObservable(this);
  }

  @observable
  sortParameterForAreas: SortParams =
    (localStorage.getItem(BreadcrumbSteps.constructionSiteCard) as SortParams) || SortParams.dateNew;

  @action
  setSortParameterForAreas(parameter: SortParams) {
    this.sortParameterForAreas = parameter;
    localStorage.setItem(BreadcrumbSteps.constructionSiteCard, parameter);
    this.setAreas(sortByParameter(parameter, this.areas) as IArea[]);
  }

  @action
  public readonly setAreas = (areas: IArea[]): void => {
    this.areas = areas;
    this.setFilteredAreas(areas);
  };

  @action
  public readonly _setArea = (area: IArea | null) => {
    this.area = area;
  };

  @action
  setCreateMode(createMode: boolean) {
    this.createMode = createMode;
  }

  @action
  setEditMode(editMode: boolean) {
    this.editMode = editMode;
  }

  @action
  setEditableArea(editableArea: string | null) {
    this.editableArea = editableArea;
  }

  @action
  startCreatingArea() {
    this.setCreateMode(true);
    this.rootStore.mapStore.initAreaGraphic();
  }

  @action
  changeBoundaries(areaId: string, map: string) {
    this.setEditableArea(areaId);

    this.rootStore.mapStore.utils.removeGraphicByItemId(areaId);
    this.rootStore.mapStore.utils.clearGraphicLayer();

    const graphic = JSON.parse(map);
    this.rootStore.mapStore.addAttributes(graphic, { itemId: areaId });

    this.rootStore.mapStore.addToGraphicsLayer(graphic, areaId);
  }

  @action
  public readonly getAllAreas = async (constructionSiteId: string): Promise<void> => {
    const response = await areas.getAll(constructionSiteId);
    const withMap = this.rootStore.mapStore.utils.filterExistingMap(response);
    this.setAreas(sortByParameter(this.sortParameterForAreas, withMap) as IArea[]);
    this.rootStore.mapStore.initGeometries(withMap, GraphicId.area);
  };

  @action
  getProtectedAreas = async (constructionSiteId: string) => {
    const response = await areas.getAll(constructionSiteId);

    const protectedAreas = response.filter(a => a.isProtected);

    this.protectedAreas = sortByParameter(this.sortParameterForAreas, protectedAreas) as IArea[];
  };

  @action
  public readonly filterAreas = (value: string) => {
    this.setFilteredAreas(this.areas.filter(area => area.title.toLowerCase().includes(value.toLowerCase())));
  };

  @action
  setFilteredAreas(value: IArea[]) {
    this.filteredAreas = value;
  }

  @action
  public readonly getAndSetArea = async (areaId: string) => {
    const response = await this.getArea(areaId);
    this._setArea(response);
  };

  @action
  public readonly getArea = async (areaId: string): Promise<IArea> => {
    return await areas.get(areaId);
  };

  @action
  async createArea(
    constructionSiteId: string,
    title: string,
    description: string,
    isProtected: boolean
  ): Promise<IArea | null> {
    const graphic = this.rootStore.mapStore.graphicToJSON();
    this.rootStore.mapStore.addAttributes(graphic, { id: GraphicId.area });

    const response = await areas.createArea(constructionSiteId, title, description, isProtected, graphic);

    this.setCreateMode(false);

    await this.getAllAreas(constructionSiteId);

    this.rootStore.mapStore.utils.clearGraphicLayer();
    this.rootStore.uiStore.closeLastModal();

    return response.data.payload ?? null;
  }

  @action
  async updateGraphic(areaId: string) {
    const graphic = this.rootStore.mapStore.graphicToJSON();
    const map = JSON.stringify(graphic);
    return await areas.update(areaId, "map", map);
  }

  @action
  async updateTitle(areaId: string, title: string) {
    await areas.update(areaId, "title", title);
    const area = this.areas.filter(({ id }) => areaId === id)[0];
    area.title = title;
  }

  @action
  async updateDescription(areaId: string, description: string) {
    await areas.update(areaId, "description", description);
  }

  @action
  async delete(areaId: string, constructionSiteId: string, comment: string) {
    await areas.delete(areaId, comment);
    this.rootStore.uiStore.closeLastModal();
    await this.getAllAreas(constructionSiteId);
  }

  checkAreaGraphics = (constructionSiteId: string, areaId: string | null): ErrorI18KeyAndOptions | undefined => {
    const selectedGraphic = this.rootStore.mapStore.selectedGraphic?.geometry;

    const containerGeometry = this.rootStore.mapStore.utils.getGraphicByItemId(constructionSiteId)?.geometry;
    const insideGeometry = areaId
      ? this.rootStore.mapStore.utils.getGraphicByItemId(areaId)?.geometry ?? selectedGraphic
      : selectedGraphic;

    return this.checkGraphics.checkGraphicsAndGetErrorMessageAndOptions(
      {
        itemLocaleName: CURRENT_LOCALE_AREA,
        containerLocaleName: CONTAINER_LOCALE_CS,
      },
      {
        containerGeometry,
        insideGeometry,
      }
    );
  };

  public checkNestedGraphics = (areaId: string | null): ErrorI18KeyAndOptions | undefined => {
    const selectedGraphic = this.rootStore.mapStore.selectedGraphic?.geometry;

    const containerGeometry = areaId
      ? this.rootStore.mapStore.utils.getGraphicByItemId(areaId)?.geometry ?? selectedGraphic
      : selectedGraphic;
    const insideGeometries = this.rootStore.mapStore.utils
      .getGraphicsByGraphicType(GraphicId.job)
      ?.map(g => g.geometry);

    let error: ErrorI18KeyAndOptions | undefined;

    insideGeometries?.some(insideGeometry => {
      const res = this.checkGraphics.checkGraphicsAndGetErrorMessageAndOptions(
        {
          itemLocaleName: NESTED_LOCALE_JOB,
          containerLocaleName: CURRENT_LOCALE_AREA,
        },
        {
          containerGeometry,
          insideGeometry,
        }
      );

      if (res) {
        error = res;
        return res;
      }
    });

    return error;
  };
}
