export enum PointTypes {
  FIX_POINT = "FIX_POINT",
}

export interface FixPointUpload {
  name: string;
  x: number;
  y: number;
  z: number;
  type: PointTypes.FIX_POINT;
}

export interface FixPoint {
  id: string;
  foreignId: string;
  name: string;
  x: number;
  y: number;
  z: number;
  acl: string;
  createdAt: number;
  updatedAt: number;
  createdBy: string;
  type: PointTypes.FIX_POINT;
}

export interface FixPointResponse {
  status: string;
  payload: FixPoint | FixPoint[];
  message?: string;
}

export enum ResponseStatus {
  SUCCESS = "success",
  ERROR = "error",
}
