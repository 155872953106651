import { useRootStore } from "hooks/useRootStore";;
import React, { createRef, FC, useEffect, useLayoutEffect,useState } from "react";
import { useTranslation } from "react-i18next";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import Box from "@mui/material/Box";
import Fade from "@mui/material/Fade";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { observer } from "mobx-react-lite";

import { FileStatus, IFile } from "stores/fileStore/types";
import { Processings, PROCESSINGS_FILES_TYPES } from "stores/managerService/processingStore/types";



import DownloadButton from "../Buttons/DownloadButton";
import UploadButton from "../Buttons/UploadButton";

import Reports from "./Reports";

const Outputs: FC = () => {
  const { t } = useTranslation();
  const { fileStore, processingStore } = useRootStore();

  const ref = createRef<HTMLDivElement>();
  const [isOverflow, setIsOverflow] = useState(false);

  useLayoutEffect(() => {
    const node = ref.current;

    if (node && node.clientHeight < node.scrollHeight) {
      setIsOverflow(true);
    } else {
      setIsOverflow(false);
    }
  }, [ref]);

  const { outputs, reports } = fileStore;
  const { processing } = processingStore;
  const [fileList, setFileList] = useState([] as IFile[]);
  const defaultType = processing?.type === Processings.VIPOINT ? Processings.VIPOINT_OUTPUT : Processings.PIX4D_VIEW;

  useEffect(() => {
    if (outputs) {
      const filteredFiles = PROCESSINGS_FILES_TYPES[defaultType].map(
        fileType => outputs.filter(file => file.type === fileType.name)[0]
      );
      setFileList(filteredFiles);
    } else {
      fileStore.getOutputs(processing!.id, processing!.acl);
    }
  }, [outputs, processing?.id]);

  return (
    <Box ref={ref} sx={containerStyles(isOverflow, processing?.type!)}>
      {PROCESSINGS_FILES_TYPES[defaultType].map((fileType, index) => (
        <React.Fragment key={fileType.name}>
          {processing && fileType.name !== "NDVI" && (
            <Fade in>
              <Paper sx={itemWrapperStyles} elevation={0}>
                <Box sx={leftStyles}>
                  <Box>
                    {fileList[index] && fileList[index].status === FileStatus.UPLOAD_SUCCESS && (
                      <CheckRoundedIcon color="success" fontSize="small" sx={{ mr: 1 }} />
                    )}
                    <Typography variant="body1" component="div" fontWeight={500}>
                      {t(`Files:${fileType.name}`)}
                    </Typography>
                    <Typography variant="overline" component="div" fontWeight={300} sx={fileTypeStyles}>
                      {t(`Files:${fileType.ext}`)}
                    </Typography>
                    {fileType.ext === "zip" && (
                      <Typography
                        variant="caption"
                        component="div"
                        fontWeight={300}
                        sx={{ color: "#ADADAD", fontSize: "12px" }}>
                        obj +. mtl + .jpg + offset. xyz
                      </Typography>
                    )}
                  </Box>
                </Box>
                {fileList[index] && fileList[index].status === FileStatus.UPLOAD_SUCCESS ? (
                  <>
                    <DownloadButton file={fileList[index]} />
                    <UploadButton
                      file={fileList[index]}
                      foreignId={processing.id}
                      aclId={processing.acl}
                      fileType={fileType}
                      projectId={processing.projectId}
                      disabled={processing.type === Processings.VIPOINT}
                      processingType={processing.type}
                    />
                  </>
                ) : (
                  <UploadButton
                    foreignId={processing.id}
                    aclId={processing.acl}
                    fileType={fileType}
                    projectId={processing.projectId}
                    disabled={Boolean(fileList[index]) || processing.type === Processings.VIPOINT}
                    processingType={processing.type}
                  />
                )}
              </Paper>
            </Fade>
          )}
        </React.Fragment>
      ))}
      {reports && <Reports />}
    </Box>
  );
};

const containerStyles = (isOverflow: boolean, processingType: Processings) => {
  return {
    pr: isOverflow ? 1 : 0,
    MozPaddingEnd: "",
    mt: "10px",
    overflowY: "auto",
    overflowX: "hidden",
    width: "100%",
    scrollbarColor: "#333 #444",
    scrollbarWidth: "thin",
    "&::-webkit-scrollbar": {
      width: "5px",
    },
    "&::-webkit-scrollbar-track": {
      bgcolor: "secondary.main",
      borderRadius: "5px",
    },
    "&::-webkit-scrollbar-thumb": {
      bgcolor: "background.paper",
      borderRadius: "5px",
    },
    "& .MuiPaper-root + .MuiTypography-root": {
      mt: "15px",
    },
  } as const;
};

const itemWrapperStyles = {
  mb: 0.5,
  height: "48px",
  display: "flex",
  alignItems: "center",
  bgcolor: "secondary.main",
  borderRadius: "5px",
  "&:last-of-type": {
    mb: 0,
  },
};

const leftStyles = {
  pl: 2,
  width: "55%",
  borderRadius: "5px 40px 40px 5px",
  height: "48px",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  bgcolor: "secondary.light",
  zIndex: 20,
  "& .MuiBox-root": {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
  },
};

const fileTypeStyles = {
  ml: 1,
  fontSize: "14px",
  lineHeight: "16px",
  color: "#ADADAD",
};

export default observer(Outputs);
