import Graphic from "esri/Graphic";
import { makeAutoObservable, runInAction } from "mobx";
import { BreadcrumbSteps } from "types";

import { CheckGraphics, ErrorI18KeyAndOptions } from "stores/managerService/helpers/checkGraphics";
import { GraphicId, SymbolStyleType } from "stores/mapStore/types";
import RootStore from "stores/rootStore";

import { LOCALES } from "constants/locales";

import constructionSites from "./service";
import { IConstructionSite } from "./types";

const CURRENT_LOCALE_CS = LOCALES.CONSTRUCTION_SITE.SINGULAR;
const NESTED_LOCALE_AREA = LOCALES.AREA.AREA;

export class ConstructionSiteStore {
  private readonly checkGraphics = new CheckGraphics(this.rootStore, ["polygon"]);

  constructionSites: IConstructionSite[] | null = null;
  constructionSite = {} as IConstructionSite;
  editableConstructionSite: string | null = "";

  createMode = false;
  isSliderCardExpanded = false;
  map: any = "";
  breadcrumbStepName: BreadcrumbSteps | null = null;
  isCardOpen: boolean = true;
  isConstructionSitesBottomPanelOpen: boolean = true;
  filteredCS: IConstructionSite[] = [];
  private editMode: boolean = false;
  public searchQuery = "";

  constructor(private readonly rootStore: RootStore) {
    makeAutoObservable(this);
  }

  setSearchQuery = (value: string) => {
    this.searchQuery = value;
  };

  setFilteredCS = (value: IConstructionSite[]) => {
    this.filteredCS = value;
  };

  filterConstructionSitesByTitle = (value: string) => {
    if (this.constructionSites) {
      this.setFilteredCS(
        this.constructionSites.filter(cs => cs.title.toLocaleLowerCase().includes(value.toLocaleLowerCase()))
      );
    }
  };

  setConstructionSites = async (constructionSites: IConstructionSite[]) => {
    this.constructionSites = constructionSites.sort((a, b) => Date.parse(b.createdAt) - Date.parse(a.createdAt));
    this.setFilteredCS(constructionSites);
    this.setSearchQuery("");
    await this.rootStore.mapStore.addConstructionSiteIcons(constructionSites);
  };

  public readonly _setConstructionSite = async (constructionSite: IConstructionSite): Promise<void> => {
    this.rootStore.jobStore._setJob(null);
    this.rootStore.areaStore._setArea(null);
    this.rootStore.mapStore.utils.clearViewGraphics();
    if (this.constructionSites && Object.keys(constructionSite).length === 0) {
      await this.rootStore.mapStore.addConstructionSiteIcons(this.constructionSites);
    }
    this.constructionSite = constructionSite;
    this.rootStore.fixPointStore.getAllFixPoints();
  };

  public setEditableConstructionSite = (csId: string | null): void => {
    this.editableConstructionSite = csId;
  };

  setCreateMode = (createMode: boolean) => {
    this.createMode = createMode;
  };

  public readonly setIsSliderCardExpanded = (expanded: boolean) => {
    this.isSliderCardExpanded = expanded;
  };

  public setEditMode = (isEditing: boolean): void => {
    this.editMode = isEditing;
  };

  startCreatingConstructionSite = async () => {
    this.setCreateMode(true);
    await this.rootStore.mapStore.initConstructionSiteGraphic();
  };

  cancelCreatingConstructionSite() {
    this.setCreateMode(false);
    this.rootStore.mapStore.utils.clearGraphicLayer();
    this.rootStore.mapStore.addConstructionSiteIcons(this.constructionSites);
  }

  public readonly getAll = async (): Promise<void> => {
    const response = await constructionSites.getAllConstructionSites();
    const withMap = this.rootStore.mapStore.utils.filterExistingMap(response);
    await this.setConstructionSites(withMap);
  };

  public readonly getConstructionSite = async (id: string): Promise<IConstructionSite | null> => {
    const response = await constructionSites.getConstructionSite(id);

    if (this.checkIsConstructionSiteRecycled(response)) {
      console.warn(`Recycled cs: ${response.id}`);
      return null;
    }

    await this._setConstructionSite(response);
    this.setIsSliderCardExpanded(false);

    this.map = JSON.parse(response.map);

    const graphic = this.rootStore.mapStore.utils.getGraphicFromMap(response.map);

    if (graphic === null) {
      return null;
    }

    this.rootStore.mapStore.utils.setSymbolStyle(graphic, SymbolStyleType.UNSELECT);

    this.rootStore.mapStore.utils.addToView(graphic);
    await this.rootStore.mapStore.utils.zoomToGeometry(graphic.geometry);

    return response;
  };

  public readonly create = async (
    title: string,
    description: string,
    coordinateSystem: string,
    verticalCoordinateSystem: string
  ) => {
    const cs = coordinateSystem.toUpperCase().replace(" ", "_");

    const getEPSGCodeOfVCS = (vcs: string) => {
      if (vcs === "Ellipsoidal") {
        return "115700";
      }
      if (vcs === "DHHN16") {
        return "7837";
      }
      if (vcs === "DHHN92") {
        return "5783";
      }
      return "";
    };

    const vcs = getEPSGCodeOfVCS(verticalCoordinateSystem);

    const graphic = await this.rootStore.mapStore.graphicToJSON();
    this.rootStore.mapStore.addAttributes(graphic, {
      id: GraphicId.constructionSite,
      title,
      description,
    });

    const coordinates = this.rootStore.mapStore.getCoordinates();
    const response = await constructionSites.create(title, description, cs, vcs, graphic, coordinates);

    await this.getAll();
    this.setCreateMode(false);

    this.rootStore.mapStore.utils.clearGraphicLayer();

    return response;
  };

  updateGraphic = async (id: string) => {
    const graphic = this.rootStore.mapStore.graphicToJSON();
    const coordinates = this.rootStore.mapStore.getCoordinates();

    const map = JSON.stringify({ graphic, coordinates });

    return await constructionSites.update(id, "map", map);
  };

  updateConstructionSite = async (id: string, attribute: "title" | "description", value: string) => {
    if (this.map.graphic) {
      this.map.graphic.attributes[attribute] = value;
    } else {
      this.map.attributes[attribute] = value;
    }
    const response = await constructionSites.update(id, attribute, value, JSON.stringify(this.map));
    if (response.data.payload) {
      this._setConstructionSite(response.data.payload);
      const constructionSite = this.constructionSites!.find(constructionSite => id === constructionSite.id);
      runInAction(() => (constructionSite![attribute] = value));
    }
  };

  delete = async (id: string, comment: string) => {
    await constructionSites.delete(id, comment);
    await this.getAll();
    this.setIsSliderCardExpanded(false);
    this._setConstructionSite({} as IConstructionSite);
    this.rootStore.uiStore.closeLastModal();
    this.rootStore.mapStore.utils.clearViewGraphics();
  };

  setBreadcrumbStep = (stepName: BreadcrumbSteps): void => {
    this.breadcrumbStepName = stepName;
  };

  setIsCardOpen = (isOpen: boolean): void => {
    this.isCardOpen = isOpen;
  };

  setIsConstructionSitesBottomPanelOpen = (isOpen: boolean): void => {
    this.isConstructionSitesBottomPanelOpen = isOpen;
  };

  changeBoundaries = async (csId: string, map: string, index: number) => {
    this.setEditableConstructionSite(csId);

    this.rootStore.mapStore.utils.removeGraphicByItemId(csId);

    const parsedMap = JSON.parse(map);
    const graphic = parsedMap.graphic ?? parsedMap;
    await this.rootStore.mapStore.addToGraphicsLayer(graphic, csId);
  };

  public checkConstructionSiteElements = (): ErrorI18KeyAndOptions | undefined => {
    return this.checkGraphics.checkGraphicsAndGetErrorMessageAndOptions({
      itemLocaleName: CURRENT_LOCALE_CS,
      containerLocaleName: "",
    });
  };

  public checkNestedGraphics = (csId: string | null): ErrorI18KeyAndOptions | undefined => {
    const selectedGraphic = this.rootStore.mapStore.selectedGraphic;

    const containerGeometry = csId
      ? this.rootStore.mapStore.utils.getGraphicByItemId(csId) ?? selectedGraphic
      : selectedGraphic;
    const areas = this.rootStore.mapStore.utils.getGraphicsByGraphicType(GraphicId.area);

    if (!containerGeometry) {
      console.error("Container not found");
      return;
    }

    let error: ErrorI18KeyAndOptions | undefined;
    areas?.some(area => {
      const res = this.checkGraphics.checkGraphicsAndGetErrorMessageAndOptions(
        {
          itemLocaleName: NESTED_LOCALE_AREA,
          containerLocaleName: CURRENT_LOCALE_CS,
        },
        {
          containerGeometry: containerGeometry.geometry,
          insideGeometry: area.geometry,
        }
      );

      if (res) {
        error = res;
      }

      return !!res;
    });

    return error;
  };

  removeCSIcons = () => {
    this.rootStore.mapStore.removeCSIcons(this.map);
  };

  private readonly checkIsConstructionSiteRecycled = (cs: IConstructionSite): boolean => {
    return cs.recycledAt !== null;
  };
}
