import { useRootStore } from "hooks/useRootStore";
import React, { FC } from "react";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import Button from "@mui/material/Button";
import { observer } from "mobx-react-lite";

import { onboardingStore } from "stores/onboardingStore";

import { OnboardingSelector } from "../../Onboarding/steps/types";

const CreateIconButton: FC = () => {
  const { constructionSiteStore } = useRootStore();

  const { isOnboardingRunning } = onboardingStore;
  const { createMode } = constructionSiteStore;

  const handleStartCreate = () => {
    constructionSiteStore.startCreatingConstructionSite();

    if (isOnboardingRunning) {
      onboardingStore.nextStep();
    }
  };

  return (
    <Button
      id={OnboardingSelector.CREATE_NEW_CONSTRUCTION_SITE_BUTTON}
      color="secondary"
      sx={{ ...buttonStyles }}
      onClick={handleStartCreate}
      disabled={createMode}
      disableRipple>
      <AddRoundedIcon fontSize="large" />
    </Button>
  );
};

export const buttonStyles = {
  height: 50,
  width: 50,
  bgcolor: "background.paper",
  borderRadius: "10px",
  minWidth: "50px",
  "& .MuiSvgIcon-root": {
    color: "text.primary",
    transition: "color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
  },
  "&:hover": {
    bgcolor: "background.paper",
    "& .MuiSvgIcon-root": { color: "primary.main" },
  },
  "&.Mui-disabled": {
    "& .MuiSvgIcon-root": {
      color: "text.disabled",
    },
  },
};

export default observer(CreateIconButton);
