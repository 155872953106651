import React, { FC, useEffect, useState } from "react";
import { Box, Divider, Paper, SxProps, Theme, Input, InputAdornment, IconButton } from "@mui/material";
import { Search as SearchIcon } from "@mui/icons-material";
import { observer } from "mobx-react-lite";

import { OnboardingSelector } from "components/Onboarding/steps/types";
import { useRootStore } from "hooks/useRootStore";

import Card from "../Card";
import CreateMode from "../CreateMode";

import CreateIconButton from "./CreateIconButton";
import CreateTextButton from "./CreateTextButton";
import ExpandIconButton from "./ExpandIconButton";
import Slider from "./Slider";
import { useTranslation } from "react-i18next";

interface ConstructionSitesPanelProps {
  isConstructionSitesLoaded: boolean;
}

const ConstructionSitesPanel: FC<ConstructionSitesPanelProps> = ({ isConstructionSitesLoaded }) => {
  const { constructionSiteStore } = useRootStore();
  const { t } = useTranslation();
  const {
    constructionSites,
    constructionSite,
    createMode,
    isSliderCardExpanded,
    setIsSliderCardExpanded,
    isConstructionSitesBottomPanelOpen,
    setIsConstructionSitesBottomPanelOpen,
    filterConstructionSitesByTitle,
    searchQuery,
    setSearchQuery,
  } = constructionSiteStore;

  const handleOpenClose = () => {
    if (isConstructionSitesBottomPanelOpen) {
      setIsSliderCardExpanded(false);
      setIsConstructionSitesBottomPanelOpen(false);
    } else {
      setIsConstructionSitesBottomPanelOpen(true);
    }
  };

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
  };

  useEffect(() => {
    if (constructionSites) filterConstructionSitesByTitle(searchQuery);
  }, [searchQuery, isSliderCardExpanded, constructionSite]);

  const showSlider = constructionSites?.length !== 0;
  const containerStyles = getContainerStyles(showSlider);

  return (
    <>
      <Box
        id={OnboardingSelector.CONSTRUCTION_SITES_BOTTOM_PANEL}
        sx={isConstructionSitesBottomPanelOpen ? containerStyles : { ...containerStyles, width: 72 }}>
        <Paper sx={panelStyles}>
          <Box sx={isSliderCardExpanded ? openedCardStyles : closedCardStyles}>
            {constructionSite.id && isSliderCardExpanded && <Card />}
          </Box>
          <Box sx={wrapperStyles}>
            <Box sx={getActionsStyles(isConstructionSitesBottomPanelOpen, showSlider)}>
              <ExpandIconButton
                open={isConstructionSitesBottomPanelOpen}
                onOpen={handleOpenClose}
                onClose={handleOpenClose}
              />
              {isConstructionSitesLoaded && showSlider && <CreateIconButton />}
            </Box>
            {isConstructionSitesLoaded && isConstructionSitesBottomPanelOpen && (
              <Input
                value={searchQuery}
                onChange={handleSearch}
                placeholder={t("General:Search")}
                sx={{ textAlign: "center", paddingLeft: 0.5, paddingRight: 0.5 }}
              />
            )}
            <Divider sx={dividerStyles} orientation="vertical" />
            {isConstructionSitesLoaded && !showSlider && !createMode && <CreateTextButton />}
            {isConstructionSitesLoaded && showSlider && <Slider />}
            {createMode && <CreateMode />}
          </Box>
        </Paper>
      </Box>
    </>
  );
};

const getContainerStyles = (showSlider: boolean): SxProps<Theme> => ({
  position: "absolute",
  bottom: 25,
  width: showSlider ? "calc(100% - 126px)" : "305px",
  transition: "width 0.8s cubic-bezier(0.33, 1, 0.68, 1)",
  MozTransition: "width 0.8s cubic-bezier(0.33, 1, 0.68, 1)",
  display: "flex",
  justifyContent: "flex-end",
});

const panelStyles = {
  zIndex: 1,
  position: "relative",
  width: "100%",
  bgcolor: "background.default",
  display: "flex",
  flexDirection: "column",
  boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.2)",
} as const;

const wrapperStyles = {
  display: "flex",
  alignItems: "center",
  gap: 1,
  ml: 1,
  minHeight: "70px",
};

const getActionsStyles: (open: boolean, showSlider: boolean) => SxProps<Theme> = (open, showSlider) => ({
  minWidth: open && showSlider ? 110 : 50,
  width: 130,
  display: "flex",
  gap: 1,
  overflow: "hidden",
});

const openedCardStyles = {
  width: "100%",
  height: "362px",
  transition: "height 0.8s cubic-bezier(0.33, 1, 0.68, 1)",
  MozTransition: "height 0.8s cubic-bezier(0.33, 1, 0.68, 1)",
  overflow: "hidden",
  pt: 1,
  px: 1.5,
};

const closedCardStyles = {
  width: "100%",
  height: "0px",
  transition: "height 0.6s cubic-bezier(0.33, 1, 0.68, 1)",
  MozTransition: "height 0.6s cubic-bezier(0.33, 1, 0.68, 1)",
  overflow: "hidden",
};

const dividerStyles: SxProps<Theme> = {
  bgcolor: "secondary.light",
  height: "80%",
  ml: "2px",
  minWidth: 0,
};

export default observer(ConstructionSitesPanel);
