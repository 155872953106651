import React, { FC, useEffect } from "react";
import { useParams } from "react-router-dom";
import { observer } from "mobx-react-lite";

import CardEditableTitle from "common/Card/CardEditableTitle";
import { Loader } from "common/Loader";
import Files from "components/ConstructionSite/Files";
import InfoPanel from "components/ConstructionSite/Files/FileSelection/InfoPanel";
import useFetchAreaAndJobs from "hooks/manager/useFetchAreaAndJobs";
import useFetchConstructionSiteAndAreas from "hooks/manager/useFetchConstructionSiteAndAreas";
import useFetchJob from "hooks/manager/useFetchJobAndProcessings";
import useFetchProcessing from "hooks/manager/useFetchProcessing";
import { useRootStore } from "hooks/useRootStore";

import { OnboardingSelector } from "../../Onboarding/steps/types";

const ProcessingFiles: FC = () => {
  const { id } = useParams();

  const {
    areaStore: { area },
    jobStore: { job },
    processingStore: { processing, resetStore },
    fileStore,
  } = useRootStore();

  useFetchConstructionSiteAndAreas(area?.constructionSite);
  useFetchAreaAndJobs(job?.area);
  useFetchJob(processing?.job);
  const [isLoading] = useFetchProcessing(id);

  useEffect(() => resetStore, [resetStore]);

  if (isLoading || !processing) {
    return <Loader />;
  }

  return (
    <>
      <div style={containerStyles} id={OnboardingSelector.PROCESSING_FILES}>
        <CardEditableTitle title={processing.title} />
        <Files />
      </div>
      {fileStore.infoPanel.open && <InfoPanel />}
    </>
  );
};

const containerStyles = {
  display: "inline-block",
  width: "100%",
};

export default observer(ProcessingFiles);
