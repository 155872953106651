import { initReactI18next } from "react-i18next";
import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-xhr-backend";

i18n
  .use(Backend)
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    lng: localStorage.getItem("i18nextLng") ?? "en",
    fallbackLng: "en",
    returnEmptyString: false,
    debug: false,
    ns: [
      "Auth",
      "General",
      "Sidebar",
      "ConstructionSite",
      "Area",
      "Job",
      "Processings",
      "Team",
      "Settings",
      "CountriesList",
      "Files",
      "Tasks",
      "Onboarding",
      "Errors",
      "Measurements",
      "Metrics",
    ],
    keySeparator: " ",
    backend: {
      loadPath: "/locale/{{lng}}/{{ns}}.json",
    },
    lowerCaseLng: true,
    interpolation: {
      escapeValue: false,
      skipOnVariables: false,
    },
    react: {
      useSuspense: false,
    },
    fallbackNS: "default",
  });

export default i18n;
