import { useRootStore } from "hooks/useRootStore";;
import React, { FC, FormEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import MuiPhoneNumber from "material-ui-phone-number";
import { observer } from "mobx-react-lite";



import Avatar from "./Avatar";

export const UserProfile: FC = observer(() => {
  const { t } = useTranslation();
  const { authStore, settingsStore } = useRootStore();

  const { userAttributes } = authStore;
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");

  useEffect(() => {
    if (userAttributes) {
      for (const attr of userAttributes) {
        if (attr.Name === "given_name") {
          setFirstName(attr.Value);
        }
        if (attr.Name === "family_name") {
          setLastName(attr.Value);
        }
        if (attr.Name === "phone_number") {
          setPhoneNumber(attr.Value);
        }
      }
    }
  }, [userAttributes]);

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    settingsStore.updateUserProfile(firstName, lastName, phoneNumber);
  };

  return (
    <>
      <Typography variant="h6" component="div">
        {t("Settings:UserProfile")}
      </Typography>
      <Avatar />
      <form onSubmit={handleSubmit}>
        <Box sx={{ display: "flex" }}>
          <Box sx={{ mr: 2, width: "100%" }}>
            <Typography variant="caption" color="text.secondary" fontWeight="500">
              {t("Settings:FirstName")} *
            </Typography>
            <TextField
              variant="outlined"
              size="small"
              type="text"
              autoComplete="given-name"
              value={firstName}
              onChange={e => setFirstName(e.target.value)}
              sx={inputStyles}
              fullWidth
              required
            />
          </Box>
          <Box sx={{ mr: 2, width: "100%" }}>
            <Typography variant="caption" color="text.secondary" fontWeight="500">
              {t("Settings:LastName")} *
            </Typography>
            <TextField
              variant="outlined"
              size="small"
              type="text"
              autoComplete="family-name"
              value={lastName}
              onChange={e => setLastName(e.target.value)}
              InputLabelProps={{ shrink: true }}
              sx={inputStyles}
              fullWidth
              required
            />
          </Box>
          <Box sx={{ width: "100%" }}>
            <Typography variant="caption" color="text.secondary" fontWeight="500">
              {t("Settings:PhoneNumber")} *
            </Typography>
            <MuiPhoneNumber
              variant="outlined"
              size="small"
              type="tel"
              autoComplete="tel"
              defaultCountry={"de"}
              value={phoneNumber}
              onChange={value => setPhoneNumber((value as string).replace(/[^+\d]+/g, ""))}
              sx={phoneNumberStyles}
              required
              fullWidth
            />
          </Box>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "flex-end", my: 2 }}>
          <Button variant="contained" type="submit">
            {t("Settings:SaveChanges")}
          </Button>
        </Box>
      </form>
    </>
  );
});

const inputStyles = {
  mt: 0.5,
  "& .MuiOutlinedInput-notchedOutline": { borderColor: "text.secondary" },
};

const phoneNumberStyles = {
  mt: 0.5,
  "& .MuiPhoneNumber-flagButton": { width: "30px" },
  "& .MuiOutlinedInput-notchedOutline": { borderColor: "text.secondary" },
};
