import axios from "axios";

import { baseUrl, protocol, Service } from "configs/servicesConfig";

import { NotificationTopic } from "./constants";
import { RequestNotification, ResponseOfPostNotification } from "./types";

const { EMAIL } = Service;

export const emailService = {
  getUnsubscribes: async (userId: string) => {
    const response = await axios.get<RequestNotification>(`${protocol}//${baseUrl}/${EMAIL}/unsubscribes/${userId}`);
    return response.data.payload;
  },
  addUnsubscribes: async (userId: string, topicName: NotificationTopic) => {
    const response = await axios.post<NotificationTopic, ResponseOfPostNotification>(
      `${protocol}//${baseUrl}/${EMAIL}/unsubscribes/${userId}`,
      {
        topicName,
      }
    );
    return response.data.payload;
  },
  deleteUnsubscribes: async (userId: string, topicName: NotificationTopic) => {
    const response = await axios.delete<NotificationTopic, ResponseOfPostNotification>(
      `${protocol}//${baseUrl}/${EMAIL}/unsubscribes/${userId}`,
      {
        data: {
          topicName,
        },
      }
    );
    return response.data.payload;
  },
  getUnsubscribesByUserIdAndTopicName: async (userId: string, topicName: NotificationTopic) => {
    const response = await axios.get(`${protocol}//${baseUrl}/${EMAIL}/unsubscribes/${userId}/${topicName}`);
  },
};
