import { useRootStore } from "hooks/useRootStore";;
import React, { ChangeEvent, FC, MouseEvent } from "react";
import { useTranslation } from "react-i18next";
import MuiAvatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { observer } from "mobx-react-lite";



import AvatarCropPopup from "./AvatarCropPopup";

const Avatar: FC = () => {
  const { t } = useTranslation();
  const { authStore, uiStore, settingsStore } = useRootStore();

  const { avatar, initials } = authStore;

  const handleClick = (e: MouseEvent<HTMLInputElement>) => {
    e.currentTarget.value = "";
  };
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    settingsStore.setImageSrc(URL.createObjectURL(e.target.files![0]));
    uiStore.openModal(<AvatarCropPopup />);
  };

  return (
    <Box sx={{ mt: 1 }}>
      <Typography variant="caption" color="text.secondary" fontWeight="500">
        {t("Settings:Avatar")}
      </Typography>
      <Box sx={wrapperStyles}>
        <MuiAvatar sx={avatarStyles} src={avatar.source ? avatar.source : undefined} key={avatar.key}>
          {initials}
        </MuiAvatar>
        <Button variant="contained" component="label" sx={{ mr: 1 }}>
          <input type="file" accept="image/*" hidden onClick={handleClick} onChange={handleChange} />
          {t("General:Upload")}
        </Button>
        {/* <Button variant="contained" color="secondary">
          {t("General:Remove")}
        </Button> */}
      </Box>
    </Box>
  );
};

const wrapperStyles = { mt: 1, mb: 3, display: "flex", alignItems: "center" };

const avatarStyles = {
  mr: 1.5,
  width: "60px",
  height: "60px",
  fontSize: "1.4rem",
};

export default observer(Avatar);
