import axios from "axios";

import { ICategories, IParameters, IProcessing } from "stores/managerService/processingStore/types";

import { baseUrl, protocol, Service } from "configs/servicesConfig";

const { PROJECT, VIPOINT } = Service;

export const processings = {
  getAll: async (job: string) => {
    const response = await axios.get<{ payload: [] }, { data: { payload: IProcessing[] } }>(
      `${protocol}//${baseUrl}/${PROJECT}/processings?job=${job}`
    );
    return response.data.payload;
  },

  get: async (id: string) => {
    const response = await axios.get<{
      payload: IProcessing;
    }>(`${protocol}//${baseUrl}/${PROJECT}/processings/${id}`);
    return response.data.payload;
  },

  create: async (title: string, type: string, job: string) => {
    const response = await axios.post(`${protocol}//${baseUrl}/${PROJECT}/processings`, {
      title,
      type,
      job,
    });
    return response as { data: { payload?: IProcessing } };
  },

  update: async (id: string, name: string, value: string) => {
    const response = await axios.put<{}, { data: { payload: IProcessing; status: string } }>(
      `${protocol}//${baseUrl}/${PROJECT}/processings/${id}`,
      {
        [name]: value,
      }
    );
    return response.data;
  },

  delete: async (id: string) => {
    const response = await axios.delete(`${protocol}//${baseUrl}/${PROJECT}/processings/${id}`);
    return response;
  },

  launch: async (id: string, onlyRegisterFiles?: boolean) => {
    const response = await axios.post<null, { data: { payload: IProcessing; status: string } }>(
      `${protocol}//${baseUrl}/${PROJECT}/processings/${id}/start?onlyRegisterFiles=${onlyRegisterFiles || false}`,
      null
    );
    return response.data;
  },
  getCategories: async function () {
    const response = await axios.get<{ data: ICategories[] }>(
      `${protocol}//${baseUrl}/${VIPOINT}/processings/get-categories`
    );
    return response.data.data;
  },

  getScripts: async function (categoryId: string) {
    const response = await axios.get<{ data: [] }>(
      `${protocol}//${baseUrl}/${VIPOINT}/processings/get-scripts/${categoryId}`
    );
    return response.data.data;
  },

  getParameters: async function (scriptId: number) {
    const response = await axios.get<{ data: IParameters[] }>(
      `${protocol}//${baseUrl}/${VIPOINT}/processings/get-parameters/${scriptId}`
    );
    return response.data.data;
  },
  getTemplates: async () => {
    const response = await axios.get<{
      payload: { bucket: string; region: string; templates: [] };
    }>(`${protocol}//${baseUrl}/${PROJECT}/processingTemplates`);
    return response.data.payload;
  },
};
