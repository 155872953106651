import React, { FC, PropsWithChildren, useLayoutEffect, useRef, useState } from "react";
import { Box } from "@mui/material";
import { observer } from "mobx-react-lite";

import { cardMaxHeightProps, thumbStyles } from "constants/styles";

import { isOverflowContext } from "./contexts";

interface ItemsListProps {
  id?: string;
}

const ItemsList: FC<PropsWithChildren<ItemsListProps>> = ({ children, id }) => {
  const ref = useRef<HTMLDivElement>();

  const [isOverflow, setIsOverflow] = useState(false);

  useLayoutEffect(() => {
    const node = ref.current;

    if (node && node.clientHeight < node.scrollHeight) {
      setIsOverflow(true);
    } else {
      setIsOverflow(false);
    }
  }, [ref]);

  return (
    <isOverflowContext.Provider value={{ isOverflow }}>
      <Box sx={containerStyles} ref={ref} id={id}>
        {children}
      </Box>
    </isOverflowContext.Provider>
  );
};

const containerStyles = {
  ...cardMaxHeightProps(),
  ...thumbStyles,
} as const;

export default observer(ItemsList);
