import { useRootStore } from "hooks/useRootStore";
import React, { FC, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import DeleteOutlineRoundedIcon from "@mui/icons-material/DeleteOutlineRounded";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import IconButton from "@mui/material/IconButton";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import { observer } from "mobx-react-lite";

import { TeamRole } from "stores/managerService/teamStore/types";
import { TeamStore } from "stores/managerService/teamStore";

import { getFullName } from "components/ConstructionSite/Team";
import { thumbStyles } from "constants/styles";

import ConfirmationDialog from "./ConfirmationDialog";
import { Loader } from "common/Loader";

const columns = [{ name: "Account" }, { name: "AccessGranted" }, { name: "Role" }, { name: "MemberSince" }];

export const getPersonById = (id: string, teamStore: TeamStore) => {
  const personWhoInvited = teamStore.getPersonWhoInvited(id);
  if (personWhoInvited) return getFullName(personWhoInvited);
};

const MembersTable: FC<{
  isLoading: boolean;
  constructionSiteId: string;
  areaId: string;
  currentUserRole: TeamRole;
  minimized: boolean;
}> = ({ constructionSiteId, areaId, currentUserRole, minimized, isLoading }) => {
  const { t, i18n } = useTranslation();
  const { teamStore, authStore, areaStore } = useRootStore();
  const { constructionSiteTeamMembers, protectedAreaMembers } = teamStore;

  const [dialogOpen, setDialogOpen] = useState(false);
  const [user, setUser] = useState("");
  const [id, setId] = useState("");

  const currentUserHasRights = (): boolean => {
    return currentUserRole === TeamRole.OWNER || currentUserRole === TeamRole.ADMIN;
  };

  const openDialog = (user: string, id: string) => {
    setDialogOpen(true);
    setUser(user);
    setId(id);
  };

  const getMembersForRender = useCallback(
    () => protectedAreaMembers ?? constructionSiteTeamMembers,
    [protectedAreaMembers, constructionSiteTeamMembers]
  );

  const roleMenuItems = (owner: boolean) => [
    { role: TeamRole.OWNER, show: owner },
    { role: TeamRole.ADMIN, show: true },
    { role: TeamRole.COORDINATOR, show: true},
    { role: TeamRole.MEMBER, show: true },
    { role: TeamRole.GUEST, show: true },
  ];

  if (isLoading) return <Loader />;

  return (
    <TableContainer component={Box} sx={tableContainerStyle(minimized)}>
      <Table sx={tableStyles(minimized)} stickyHeader>
        <TableHead>
          <TableRow>
            {columns.map(column => (
              <TableCell key={column.name}>
                <Typography variant="subtitle2" fontSize={minimized ? "14px" : "16px"}>
                  {t(`Team:${column.name}`)}
                </Typography>
              </TableCell>
            ))}
            <TableCell width={5} />
          </TableRow>
        </TableHead>
        <TableBody>
          {getMembersForRender()?.map(member => {
            const owner = member.role === TeamRole.OWNER;
            const currentUser = member.userId === authStore.userName;
            return (
              <TableRow key={member.id}>
                <TableCell>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Avatar src={member.picture} />
                    <Box sx={nameWrapperStyles(minimized)}>
                      {member.given_name && member.family_name && (
                        <Typography
                          sx={{ fontSize: minimized ? 14 : 12 }}
                          variant="body1"
                          component="div"
                          fontWeight={600}>
                          {member.given_name + " " + member.family_name}
                        </Typography>
                      )}
                      <Typography variant="caption" component="div" sx={{ color: "#ADADAD" }}>
                        {member.email}
                      </Typography>
                    </Box>
                  </Box>
                </TableCell>
                <TableCell>
                  <Typography variant="caption" component="div" sx={{ color: "#ADADAD" }}>
                    {getPersonById(member.invitedBy, teamStore)}
                  </Typography>
                </TableCell>
                <TableCell>
                  <FormControl fullWidth size="small" sx={{ width: "150px" }}>
                    <Select
                      value={member.role}
                      onChange={async ({ target: { value } }) =>
                        await teamStore.changeRole(member.id, value as TeamRole, !!areaId)
                      }
                      disabled={owner || !currentUserHasRights() || currentUser}>
                      {roleMenuItems(owner)
                        .filter(({ show }) => show)
                        .map(({ role }) => (
                          <MenuItem key={role} value={role}>
                            {t(role)}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </TableCell>
                <TableCell>
                  <Typography
                    variant="body1"
                    component="div"
                    fontSize={minimized ? "12px" : "14px"}
                    sx={{ color: "#ADADAD" }}>
                    {member.createdAt.toLocaleString(i18n.language === "en" ? "en-GB" : "de", {
                      day: "numeric",
                      month: "short",
                      year: "numeric",
                    })}
                  </Typography>
                </TableCell>
                <TableCell>
                  {!owner && (currentUserHasRights() || currentUser) && (
                    <IconButton onClick={() => openDialog(getFullName(member), member.id)}>
                      <DeleteOutlineRoundedIcon fontSize="small" />
                    </IconButton>
                  )}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
      <ConfirmationDialog
        open={dialogOpen}
        setOpen={setDialogOpen}
        user={user}
        id={id}
        resource={{
          areaId,
          constructionSiteId,
        }}
      />
    </TableContainer>
  );
};
const tableContainerStyle = (minimized: boolean) => ({
  maxHeight: minimized ? "500px" : "90vh",
  pr: 1,
  ...thumbStyles,
});

const tableStyles = (minimized: boolean) => ({
  borderCollapse: "separate",
  borderSpacing: "0 5px",
  "& .MuiTableCell-root": {
    border: "none",
  },
  "& .MuiTableBody-root .MuiTableRow-root": {
    bgcolor: "background.paper",
    borderRadius: "10px",
    "& .MuiTableCell-root": {
      p: minimized ? 1 : 1.5,
      ":first-of-type": { borderRadius: "10px 0 0 10px" },
      ":last-of-type": { borderRadius: "0 10px 10px 0", p: 0 },
    },
  },
  "& .MuiAvatar-root": {
    height: minimized ? "40px" : "50px",
    width: minimized ? "40px" : "50px",
  },
  "& .MuiSelect-select": {
    fontSize: minimized ? "12px" : "14px",
    color: "#ADADAD",
    "&.Mui-disabled": {
      WebkitTextFillColor: "unset",
    },
  },
  "& .MuiSelect-icon.Mui-disabled": {
    display: "none",
  },
  "& .MuiInputBase-root.Mui-disabled": {
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#ADADAD",
    },
  },
  "& .MuiInputBase-input": {
    fontSize: 12,
  },
  "& .MuiOutlinedInput-notchedOutline": { borderColor: "#ADADAD" },
  "& .MuiButtonBase-root": {
    bgcolor: "background.paper",
    color: "text.primary",
    transition: "background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    "&:hover": { bgcolor: "rgba(0, 0, 0, 0.2)" },
  },
});

const nameWrapperStyles = (minimized: boolean) => ({
  display: "flex",
  flexDirection: "column",
  ml: 1,
  wordWrap: "break-word",
  maxWidth: minimized ? 130 : 200,
  fontSize: minimized ? 12 : 14,
});

export default observer(MembersTable);
