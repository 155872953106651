import { useRootStore } from "hooks/useRootStore";;
import React, { FC, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import CreateNewFolderIcon from "@mui/icons-material/CreateNewFolder";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

import { handleInputFiles } from "../handleInputFiles";

import { btnStyles, ChooseButtonProps, textStyles } from "./ChooseButton";

export const FolderInputButton: FC<ChooseButtonProps> = ({ fileType }) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const { t } = useTranslation();
  const rootStore = useRootStore();
  const { fileStore } = rootStore;

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.setAttribute("directory", "");
      inputRef.current.setAttribute("webkitdirectory", "");
    }
  }, [inputRef]);

  return (
    <Button component="label" variant="contained" color="secondary" sx={btnStyles}>
      <input ref={inputRef} type="file" hidden onChange={async e => await handleInputFiles(e, fileType, true, fileStore, rootStore)} />
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-around",
        }}>
        <CreateNewFolderIcon fontSize="small" />
        <Typography sx={textStyles} variant="caption">
          {t(`Files:ChooseFolder`)}
        </Typography>
      </Box>
    </Button>
  );
};
