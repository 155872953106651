import React, { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Typography from "@mui/material/Typography";
import { observer } from "mobx-react-lite";

import { IFile } from "stores/fileStore/types";
import { IParameters } from "stores/managerService/processingStore/types";

import { useRootStore } from "hooks/useRootStore";

export const DxfCsvFiles: FC<{ parameter: IParameters; files: IFile[] }> = observer(({ parameter, files }) => {
  const { t } = useTranslation();
  const { processingStore } = useRootStore();
  const [value, setValue] = useState("");
  const { parameterId } = parameter;
  useEffect(() => {
    processingStore.setUpdatedParams(parameterId, value);
  }, [value]);

  return (
    <>
      <Typography variant="body1" sx={{ mt: 1 }}>
        {t(`Processings:Parameters:${parameterId}:description`)}
      </Typography>
      <FormControl key={parameterId} sx={{ my: 1 }} fullWidth>
        <InputLabel>
          {(files.length && t(`Processings:Parameters:${parameterId}:parameterName`)) || t(`Files:NoFilesFound`)}
        </InputLabel>
        <Select
          value={value}
          label={t(`Processings:Parameters:${parameterId}:parameterName`)}
          disabled={!files.length}
          onChange={e => {
            setValue(e.target.value);
          }}
          IconComponent={KeyboardArrowDownRoundedIcon}>
          {files?.map(file => (
            <MenuItem value={file.storageMeta.key} key={file.id}>
              {file.fileMeta.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  );
});
