import { useCallback, useMemo } from "react";

import { IConstructionSite } from "stores/managerService/constructionSiteStore/types";

import { FetchingState } from "hooks/types";
import { useRootStore } from "hooks/useRootStore";

import useFetchMapManagerData from "./_useFetchMapManagerData";
import { UrlParam } from "./types";

const useFetchConstructionSiteAndAreas = (csId: UrlParam): FetchingState => {
  const {
    constructionSiteStore: { constructionSite, _setConstructionSite, getConstructionSite },
    areaStore: { setAreas, getAllAreas },
  } = useRootStore();

  const fetchAreas = useCallback(
    async (id: string) => {
      await getConstructionSite(id);
      await getAllAreas(id);
    },
    [getAllAreas, getConstructionSite]
  );

  const clear = useCallback(() => {
    // TODO: change a default value of the constructionSite as null
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    _setConstructionSite({} as IConstructionSite);
    setAreas([]);
  }, [_setConstructionSite, setAreas]);

  const isItemExists = useMemo(() => constructionSite.id === csId, [constructionSite.id, csId]);

  const [isLoading, isLoaded] = useFetchMapManagerData(csId, fetchAreas, clear, isItemExists);

  return [isLoading, isLoaded];
};

export default useFetchConstructionSiteAndAreas;
