import axios from "axios";
import { FixPoint, FixPointUpload, FixPointResponse } from "./types";

import { protocol, baseUrl, Service } from "configs/servicesConfig";

const FIXPOINT_ENDPOINT = `${Service.PROJECT}/constructionSites`;

export const FixPointService = {
  async getAllFixPoints(projectId: string): Promise<FixPoint[]> {
    const response = await axios.get<FixPointResponse>(
      `${protocol}//${baseUrl}/${FIXPOINT_ENDPOINT}/${projectId}/fixPoints`
    );
    return response.data.payload as FixPoint[];
  },

  async createFixPoint(projectId: string, fixPointData: FixPointUpload[]): Promise<FixPoint> {
    const response = await axios.post<FixPointResponse>(
      `${protocol}//${baseUrl}/${FIXPOINT_ENDPOINT}/${projectId}/fixPoints`,
      fixPointData
    );
    return response.data.payload as FixPoint;
  },

  async updateFixPoint(projectId: string, fixPointId: string, fixPointData: FixPoint): Promise<FixPoint> {
    const response = await axios.put<FixPointResponse>(
      `${protocol}//${baseUrl}/${FIXPOINT_ENDPOINT}/${projectId}/fixPoints/${fixPointId}`,
      fixPointData
    );
    return response.data.payload as FixPoint;
  },

  async deleteFixPoint(projectId: string, fixPointId: string): Promise<void> {
    await axios.delete(`${protocol}//${baseUrl}/${FIXPOINT_ENDPOINT}/${projectId}/fixPoints/${fixPointId}`);
  },
};
