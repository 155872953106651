import { FC, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { LoadingButton, ToggleButtonGroup } from "@mui/lab";
import { Box, Button, Stack, SxProps, Theme, ToggleButton } from "@mui/material";
import { observer } from "mobx-react-lite";
import { AsyncFunction } from "types";

import { TaskExportType } from "stores/taskStore/types";

import { LOCALES } from "constants/locales";
import { useFetchWithLoading, useRootStore } from "hooks";

const DEFAULT_EXPORT_TYPE = TaskExportType.BCF;

interface ExportTasksProps {
  constructionSiteId: string;
}

const ExportTasks: FC<ExportTasksProps> = ({ constructionSiteId }) => {
  const { t } = useTranslation();

  const {
    tasksExportStore: { exportMode, tasksForExport, toggleExportMode, exportTasks },
  } = useRootStore();

  const exportFunctions = useMemo<Record<TaskExportType, AsyncFunction>>(
    () => ({
      BCF: async () => await exportTasks(TaskExportType.BCF),
      JSON: async () => await exportTasks(TaskExportType.JSON, constructionSiteId),
      CSV: async () => await exportTasks(TaskExportType.CSV, constructionSiteId),
    }),
    [constructionSiteId, exportTasks]
  );
  const [exportType, setExportType] = useState<TaskExportType>(DEFAULT_EXPORT_TYPE);
  const exportFunc = useMemo<AsyncFunction>(() => exportFunctions[exportType], [exportFunctions, exportType]);

  const [sendWithLoading, isLoading] = useFetchWithLoading(exportFunc);

  useEffect(() => {
    return () => {
      setExportType(DEFAULT_EXPORT_TYPE);
    };
    // if the exportMode has been changed we need to reset exportModeType
  }, [exportMode]);

  return (
    <Stack spacing={0.5} sx={exportButtonContainerStyles}>
      {exportMode && (
        <ToggleButtonGroup value={exportType} onChange={(_, v) => setExportType(v)} exclusive>
          <ToggleButton value={TaskExportType.BCF}>{t(LOCALES.TASKS.EXPORT_TYPE["BCF_3.0"])}</ToggleButton>
          <ToggleButton value={TaskExportType.JSON}>{t(LOCALES.TASKS.EXPORT_TYPE.JSON)}</ToggleButton>
          <ToggleButton value={TaskExportType.CSV}>{t(LOCALES.TASKS.EXPORT_TYPE.CSV)}</ToggleButton>
        </ToggleButtonGroup>
      )}
      <Box>
        <Button
          sx={{
            backgroundColor: exportMode ? "secondary.light" : "secondary.main",
          }}
          onClick={() => toggleExportMode()}>
          {t(exportMode ? LOCALES.TASKS.CANCEl : LOCALES.TASKS.EXPORT)}
        </Button>
        {exportMode && (
          <LoadingButton
            startIcon={<FileDownloadIcon />}
            onClick={sendWithLoading}
            disabled={tasksForExport.length === 0}
            loading={isLoading}>
            {t(isLoading ? LOCALES.TASKS.PREPARING : LOCALES.TASKS.DOWNLOAD)}
          </LoadingButton>
        )}
      </Box>
    </Stack>
  );
};

const exportButtonContainerStyles: SxProps<Theme> = {
  zIndex: 100,
  position: "absolute",
  left: 8,
  bottom: "5%",
  display: "flex",
};

export default observer(ExportTasks);
