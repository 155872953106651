import { useRootStore } from "hooks/useRootStore";
import React, { FC, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { Link as RouterLink } from "react-router-dom";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Fade from "@mui/material/Fade";
import Link from "@mui/material/Link";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { observer } from "mobx-react-lite";

import LanguageSelector from "components/Sidebar/LanguageSelector";

import { absolutePath, PATHS } from "router/paths";

const Confirm: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { authStore } = useRootStore();

  const { values, inProgress, error, cognitoUser } = authStore;

  useEffect(() => {
    if (cognitoUser) {
      navigate(absolutePath(PATHS.HOME.INDEX));
    }
  }, []);

  const handleCodeChange = (e: React.ChangeEvent<HTMLInputElement>) => authStore.setCode(e.target.value);
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    await authStore.confirmRegistration();
    navigate(absolutePath(PATHS.LOGIN.INDEX));
  };

  return (
    <Fade in>
      <Box sx={containerStyles}>
        <img src="/images/logo.svg" alt="Logo" />
        <Box component="form" onSubmit={handleSubmit} sx={formStyles}>
          <Box
            sx={{
              alignSelf: "flex-end",
              mt: 4,
            }}>
            <LanguageSelector displayFlag={false} />
          </Box>
          <Typography sx={messageStyles} variant="subtitle1" component="div">
            {t("Auth:ConfirmSignUpMessage")}
          </Typography>
          <Typography sx={{ my: 1 }} variant="body1" color="error">
            {error}
          </Typography>
          <TextField
            required
            fullWidth
            variant="standard"
            name="code"
            label={t("Auth:CodeLabel")}
            type="text"
            id="code"
            value={values.code}
            onChange={handleCodeChange}
          />
          <Link
            component={RouterLink}
            to="/register/new-code"
            variant="body2"
            underline="hover"
            sx={{
              mt: 2,
              color: "text.primary",
            }}>
            {t("Auth:ResendCode")}
          </Link>
          <Button
            type="submit"
            fullWidth
            color="secondary"
            variant="contained"
            sx={{ mt: 4 }}
            disabled={inProgress}
            disableElevation>
            {t("Auth:Confirm")}
          </Button>
        </Box>
      </Box>
    </Fade>
  );
};

const containerStyles = {
  width: "100%",
  height: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  bgcolor: "background.default",
  "& img": {
    maxWidth: "160px",
  },
} as const;

const formStyles = {
  maxWidth: "340px",
  display: "flex",
  flexDirection: "column",
  width: "100%",
  alignItems: "center",
} as const;

const messageStyles = {
  mt: 2,
  color: "text.primary",
};

export default observer(Confirm);
