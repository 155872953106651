import { CSSProperties } from "react";
import { TFunction } from "i18next";

export interface ExpandProps {
  open: boolean;
  onOpen: () => void;
  onClose: () => void;
  closedHeight?: CSSProperties["maxHeight"];
}

export enum SortParams {
  dateOld = "dateOld",
  dateNew = "dateNew",
  type = "type",
  name = "name",
}

export enum BreadcrumbSteps {
  constructionSiteCard = "constructionSiteCard",
  areaCard = "areaCard",
  jobCard = "jobCard",
}

export interface CreatedUpdated {
  createdAt: number;
  updatedAt: number;
}

export const enum ResponseStatus {
  OK = "ok",
  ERROR = "error",
}

interface FailedPayload {
  error: string;
}

interface SuccessApiResponse<T = any> {
  status: ResponseStatus.OK;
  payload: T;
}

interface FailedApiResponse {
  status: ResponseStatus.ERROR;
  payload: FailedPayload;
}

export type ApiResponse<T = any> = SuccessApiResponse<T> | FailedApiResponse;

export type TFuncBody = string | Parameters<TFunction>;
export type TFuncBodyWithOptions = Parameters<TFunction>;

export type StringMap = Record<string, string>;

export type Enum = string | number;
export type AsyncFunction<T = unknown> = () => Promise<T>;
