import { useEffect } from "react";
import { useParams } from "react-router-dom";

import { useRootStore } from "./useRootStore";

export const useUpdateRouteParams = () => {
  const params = useParams();

  const {
    navigateStore: { setParams },
  } = useRootStore();

  useEffect(() => {
    setParams(params);
  }, [params, setParams]);
};
