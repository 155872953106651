import React, { useEffect } from "react";
import { useSearchParams } from "react-router-dom";

import { SEARCH_PARAMS_TASK_ID_KEY } from "stores/mapStore/constants";
import { Task } from "stores/taskStore/types";

import TaskDetailsPopup from "components/Tasks/TaskDetails";

import { useRootStore } from "./useRootStore";

export const useOpenTaskDetails = (tasksByForeignId: Task[] | null) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const {
    uiStore: { openModal, getModalById },
  } = useRootStore();

  const clearSearchParams = setSearchParams;

  useEffect(() => {
    const taskId = searchParams.get(SEARCH_PARAMS_TASK_ID_KEY);
    if (tasksByForeignId && taskId) {
      const task = tasksByForeignId?.find(t => t.id === taskId);
      const modal = getModalById(taskId);
      if (task && !modal) {
        openModal(<TaskDetailsPopup task={task} />, {
          onClose: clearSearchParams,
          modalId: taskId,
          styles: { width: "800px" },
        });
      }
    }
  }, [clearSearchParams, getModalById, openModal, searchParams, tasksByForeignId]);
};
