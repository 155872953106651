import { useRootStore } from "hooks/useRootStore";
import React, { FC } from "react";
import SearchIcon from "@mui/icons-material/Search";
import { Box } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import GroupIcon from "@mui/icons-material/Group";
import { observer } from "mobx-react-lite";
import { iconStyles } from "../styles";
import Teams from "components/Teams";

export const MembersButton = observer(() => {
  const {
    uiStore: { openModal },
    constructionSiteStore: { constructionSite },
    areaStore: { area },
  } = useRootStore();
  return (
    <Box sx={{ justifyContent: "center", alignItems: "center" }}>
      <IconButton
        size="small"
        sx={iconStyles}
        color="secondary"
        onClick={() => {
          openModal(<Teams constructionSiteId={constructionSite.id} areaId={area!.id} minimized />, {
            styles: { width: "800px", height: "650px" },
          });
        }}>
        <GroupIcon />
      </IconButton>
    </Box>
  );
});
