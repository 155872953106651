import React, { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import DataUsageRoundedIcon from "@mui/icons-material/DataUsageRounded";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Typography from "@mui/material/Typography";

import { TaskStatus, TaskStatuses } from "stores/taskStore/types";

const StatusField: FC<{
  status: TaskStatus;
  setStatus: React.Dispatch<React.SetStateAction<TaskStatus>>;
  isAllowedToEdit: boolean;
}> = ({ status, setStatus, isAllowedToEdit }) => {
  const { t } = useTranslation();

  const [statusSelectorOpen, setStatusSelectorOpen] = useState(false);
  const isTaskStatusChangedFromToDo = (menuStatus: TaskStatus) =>
    menuStatus === TaskStatus.PENDING && status !== TaskStatus.PENDING;
  const isTaskStatusToDo = (menuStatus: TaskStatus) =>
    status === TaskStatus.PENDING && (menuStatus === TaskStatus.IN_REVIEW || menuStatus === TaskStatus.COMPLETED);
  const isAllowedToFinish = (menuStatus: TaskStatus) => menuStatus === TaskStatus.COMPLETED && !isAllowedToEdit;

  return (
    <>
      <DataUsageRoundedIcon sx={{ color: "primary.main" }} />
      <Typography variant="body1" component="div" fontWeight={600} sx={{ ml: 1.2, color: "primary.main" }}>
        {t("Tasks:Status")}
      </Typography>
      <FormControl
        size="small"
        sx={{
          ...selectorStyles,
          ...(statusSelectorOpen && { width: "140px" }),
        }}
        focused={statusSelectorOpen}>
        <Select
          disabled={status === TaskStatus.COMPLETED}
          onOpen={() => setStatusSelectorOpen(true)}
          onClose={() => setStatusSelectorOpen(false)}
          value={status}
          onChange={e => setStatus(e.target.value as TaskStatus)}>
          {TaskStatuses.map(menuStatus => (
            <MenuItem
              key={menuStatus}
              sx={statusSelectorOpen ? { width: "140px" } : undefined}
              value={menuStatus}
              disabled={
                isTaskStatusChangedFromToDo(menuStatus) || isAllowedToFinish(menuStatus) || isTaskStatusToDo(menuStatus)
              }>
              <Typography variant="caption" component="div">
                {t(`Tasks:${menuStatus}`)}
              </Typography>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  );
};

const selectorStyles = {
  ml: 1.5,
  maxWidth: "100%",
  "& .MuiOutlinedInput-root": { pr: 0 },
  "& .MuiSelect-select.MuiInputBase-input": {
    py: 0.8,
    height: "16px",
    minHeight: "16px",
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: "transparent",
  },
};

export default StatusField;
