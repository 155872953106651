import React, { FC } from "react";
import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";

import { IFile } from "stores/fileStore/types";

import { formatBytes } from "helpers/functions";

export const FormLabelContent: FC<{ file: IFile }> = ({ file }) => {
  const fileName = file.fileMeta.name;
  return (
    <Box sx={{ display: "flex", width: "330px" }}>
      <Tooltip title={fileName}>
        <Typography
          sx={{ width: "80%", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}
          variant="body1"
          component="div"
          fontWeight={400}>
          {fileName}
        </Typography>
      </Tooltip>
      <Typography
        sx={{
          width: "20%",
          fontSize: 13,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          color: "#ADADAD",
        }}
        variant="caption"
        component="div"
        fontWeight={300}>
        {formatBytes(file.fileMeta.size)}
      </Typography>
    </Box>
  );
};
