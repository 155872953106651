import { useRootStore } from "hooks/useRootStore";
import React, { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import Autocomplete from "@mui/material/Autocomplete";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import { onboardingStore } from "stores/onboardingStore";

import ClearingTextField from "common/ClearingTextField";
import { coordinateSystems } from "constants/coordinateSystems";
import { verticalCoordinateSystems } from "constants/verticalCoordinateSystems";
import { useNavigate } from "react-router-dom";
import { PATHS, absolutePath, pathWithParams } from "../../router/paths";
import { observer } from "mobx-react-lite";

interface CoordinateSystemFieldProps {
  label: string;
  setState: React.Dispatch<React.SetStateAction<string>>;
  options: string[];
}

const CoordinateSystemField: FC<CoordinateSystemFieldProps> = ({ label, setState, options }) => {
  return (
    <Autocomplete
      sx={{ mt: 2 }}
      fullWidth
      size="small"
      popupIcon={<KeyboardArrowDownRoundedIcon sx={{ color: "text.primary" }} />}
      disableClearable
      options={options}
      getOptionLabel={option => {
        const standardOptions = ["Arbitrary Meters", "Arbitrary Feet", "Ellipsoidal", "DHHN16", "DHHN92"];

        if (standardOptions.includes(option)) {
          return option;
        } else {
          return `EPSG:${option}`;
        }
      }}
      onChange={(e, cs) => cs && setState(cs)}
      renderInput={params => <TextField autoFocus={true} {...params} required label={label} />}
    />
  );
};

const CreatePopupForm: FC = observer(() => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { constructionSiteStore, mapStore, uiStore } = useRootStore();

  const { isOnboardingRunning } = onboardingStore;

  const { kmlFileName } = mapStore;

  const [title, setTitle] = useState(kmlFileName ?? "");
  const [description, setDescription] = useState("");
  const [coordinateSystem, setCoordinateSystem] = useState("");
  const [verticalCoordinateSystem, setVerticalCoordinateSystem] = useState("");

  const handleCreateConstructionSite = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const {
      data: { payload },
    } = await constructionSiteStore.create(title, description, coordinateSystem, verticalCoordinateSystem);
    navigate(absolutePath(PATHS.HOME.CS_CARD(payload?.id)));

    uiStore.closeLastModal();

    if (isOnboardingRunning) {
      onboardingStore.nextStep();
    }
  };

  return (
    <form onSubmit={handleCreateConstructionSite}>
      <Typography id="transition-modal-title" variant="h5" component="h2" textAlign="center" sx={{ mb: 2 }}>
        {t("ConstructionSite:CreateConstructionSite")}
      </Typography>
      <Divider sx={{ mx: -1.5, mb: 0.5 }} />
      <CoordinateSystemField
        label={t("ConstructionSite:CoordinateSystem")}
        setState={setCoordinateSystem}
        options={coordinateSystems}
      />
      <CoordinateSystemField
        label={t("ConstructionSite:VerticalCoordinateSystem")}
        setState={setVerticalCoordinateSystem}
        options={verticalCoordinateSystems}
      />
      <ClearingTextField
        id="title"
        label={t("General:Title")}
        fullWidth
        sx={{ mt: 2 }}
        required
        value={title}
        onChange={e => setTitle(e.target.value)}
        inputProps={{ maxLength: 60 }}
        size="small"
        onClear={() => setTitle("")}
      />
      <TextField
        id="description"
        label={t("General:Description")}
        multiline
        rows={3}
        fullWidth
        sx={{ my: 2 }}
        value={description}
        onChange={e => setDescription(e.target.value)}
        inputProps={{ maxLength: 255 }}
        size="small"
      />
      <Button color="secondary" variant="contained" fullWidth type="submit">
        {t("General:Submit")}
      </Button>
    </form>
  );
});

export default CreatePopupForm;
