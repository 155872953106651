import { extendObservable, makeAutoObservable, runInAction } from "mobx";
import { v4 as uuidv4 } from "uuid";

import RootStore from "stores/rootStore";

import { FileSize } from "./constants";
import { fileService } from "./service";
import {
  FileExt,
  FileName,
  FileStatus,
  FileType,
  GetFilesByQueryParams,
  ICredentials,
  IFile,
  IFileUpload,
  IFilesFromInput,
  InfoPanel,
  QueryParamsGetFiles,
  Reports,
  ReuseBody,
  StartUploadParams,
  StorageOperationMode,
  StorageProvider,
  SubSystem,
  SubType,
} from "./types";
import { Processings } from "stores/managerService/processingStore/types";

interface SourceFiles {
  id: string;
  foreignId: string;
}

type Nullable<T> = T | null;

export class FileStore {
  inputs = [] as IFile[];
  outputs = null as Nullable<IFile[]>;
  lastId = null as Nullable<string>;
  totalCount = null as Nullable<number>;
  dxfFilesForReuse = [] as IFile[];
  selectedDxfFilesForReuse = [] as IFile[];
  csvFilesForReuse = [] as IFile[];
  selectedCsvFilesForReuse = [] as IFile[];
  pointCloudFilesForReuse = [] as IFile[];
  selectedPointCloudForReuse = [] as IFile[];
  pointCloudFilesForMerge = [] as IFile[];
  selectedPointCloudsForMerge = [] as IFile[];
  uploads = [] as IFileUpload[];
  filesFromInputs = [] as IFilesFromInput[];
  progress = 0;
  infoPanel = { open: false, fileType: null } as InfoPanel;
  uuidForRtkFiles: { [name: string]: string } = {};

  constructor(private readonly rootStore: RootStore) {
    makeAutoObservable(this);
  }

  get isUploading(): boolean {
    return this.uploads.some(f => f.status === FileStatus.UPLOAD_PENDING);
  }

  get isFullyUploaded(): boolean {
    return !!this.uploads?.length && this.uploads.every(f => f.status === FileStatus.UPLOAD_SUCCESS);
  }

  createSourceFiles = (array: IFile[]): SourceFiles[] => {
    return array.map(file => ({ id: file.id, foreignId: file.foreignId }));
  };

  reuseFiles = async () => {
    const sourceFiles = [
      ...this.createSourceFiles(this.selectedPointCloudForReuse),
      ...this.createSourceFiles(this.selectedDxfFilesForReuse),
      ...this.createSourceFiles(this.selectedCsvFilesForReuse),
      ...this.createSourceFiles(this.selectedPointCloudsForMerge),
    ];
    console.log("sourceFiles", sourceFiles);
    return await this.runReuseFiles(sourceFiles);
  };

  runReuseFiles = async (sourceFiles: SourceFiles[]): Promise<IFile[]> => {
    const { processing } = this.rootStore.processingStore;
    if (processing) {
      const reuseBody: ReuseBody = {
        aclId: processing.acl,
        foreignId: processing.id,
        sourceFiles,
      };
      console.log("reuseBody", reuseBody);
      const response = await fileService.reuse(reuseBody);
      if (response.status === "ok") {
        await this.getInputs(processing.id, processing.acl);
        console.log("getInputs");
      }
      console.log("response.payload.successful", response.payload.successful);
      return response.payload.successful;
    }
    return [];
  };

  setInputs = (inputs: IFile[]) => {
    this.inputs = inputs;
  };

  setOutputs = (outputs: IFile[] | null) => {
    this.outputs = outputs;
  };

  get reports(): IFile[] | null {
    if (!this.outputs) return null;
    const reports = this.outputs
      .filter(item => Object.values(Reports).some(value => value === item.type))
      .filter((item, index, array) => array.findIndex(obj => obj.type === item.type) === index);
    if (reports.length !== 0) {
      return reports;
    } else return null;
  }

  setLastId = (lastId: string | null) => {
    this.lastId = lastId;
  };

  setTotalCount = (totalCount: number | null) => {
    this.totalCount = totalCount;
  };

  setInfoPanel = (infoPanel: InfoPanel) => {
    this.infoPanel = infoPanel;
  };

  resetInfoPanel = () => {
    this.setInfoPanel({ open: false, fileType: null });
    this.resetSelectedPointCloudForReuse();
  };

  setFilesFromInputs = (files: IFilesFromInput["files"], fileType: FileType) => {
    if (files.length > 0) {
      const index = this.filesFromInputs.findIndex(file => {
        return file.fileType.name === fileType.name;
      });
      if (index >= 0) {
        files.forEach(file => {
          if (
            !this.filesFromInputs[index].files.some(
              currentFile =>
                currentFile.name === file.name &&
                currentFile.size === file.size &&
                currentFile.lastModified === file.lastModified
            )
          ) {
            const regExpDigit = /_\d*/;
            const digit = file.name.match(regExpDigit)![0];
            const filesLength = this.filesFromInputs[index].files.length;
            const isNameRepeated = this.filesFromInputs[index].files.some(
              currentFile => currentFile.name === file.name
            );
            if (isNameRepeated) {
              Object.defineProperty(file, "name", {
                writable: true,
                value: file.name.replace(digit, `${digit}${filesLength}`),
              });
            }
            this.filesFromInputs[index].files.push(file);
          }
        });
      } else {
        this.filesFromInputs.push({
          files,
          fileType,
        });
      }
    }
  };

  setUuidForRtkFiles = (files: File[]) => {
    files.forEach(file => {
      const fileKeyRegex = /_\d*/;
      const fileKey = file.name.match(fileKeyRegex)![0];
      if (!this.uuidForRtkFiles[fileKey]) {
        this.uuidForRtkFiles[fileKey] = uuidv4();
      }
      Object.defineProperty(file, "name", {
        writable: true,
        value: file.name.replace(fileKeyRegex, `_${this.uuidForRtkFiles[fileKey]}`),
      });
    });
  };

  clearFilesFromInputs = () => {
    this.filesFromInputs = [] as IFilesFromInput[];
    this.uuidForRtkFiles = {};
  };

  removeFileFromInput = (index: number, fileName: FileName) => {
    const inputIndex = this.filesFromInputs.findIndex(input => input.fileType.name === fileName);

    if (this.filesFromInputs[inputIndex].files.length === 1) {
      this.removeAllFilesFromInput(fileName, inputIndex);
    } else {
      this.filesFromInputs[inputIndex].files.splice(index, 1);
    }
  };

  removeAllFilesFromInput = (fileName: FileName, index?: number) => {
    const inputIndex = index ?? this.filesFromInputs.findIndex(input => input.fileType.name === fileName);
    this.filesFromInputs.splice(inputIndex, 1);
    this.resetInfoPanel();
  };

  getInputs = async (foreignId: string, aclId: string, withTotalCount = false, refresh = false) => {
    const queryParams = {
      lastId: this.lastId,
      subtype: SubType.INPUT,
      withTotalCount,
      status: FileStatus.UPLOAD_SUCCESS,
      aclId,
    };
    const response = await fileService.getAllFiles(queryParams, foreignId);
    const filteredFiles = response.files
      .filter(file => !file.type.includes(FileName.TEMPLATE) && file.status === FileStatus.UPLOAD_SUCCESS)
      .sort((a, b) => Date.parse(b.createdAt) - Date.parse(a.createdAt));

    this.setInputs(refresh ? filteredFiles : [...this.inputs, ...filteredFiles]);

    if (response.lastId) {
      this.setLastId(response.lastId);
    }

    if (response.totalCount) {
      const template = response.files.length - filteredFiles.length;
      this.setTotalCount(response.totalCount - template);
    }
  };

  get dxfFilesOfProcessing(): IFile[] {
    return this.inputs.filter(file => file.type === FileName.DXF);
  }

  get csvFilesOfProcessing(): IFile[] {
    return this.inputs.filter(file => file.type === FileName.GCP);
  }

  get pointCloudFileOfProcessing(): IFile {
    return this.inputs.filter((file: { type: string }) => file.type === FileName.POINT_CLOUD)[0];
  }

  resetSelectedPointCloudForReuse = () => {
    this.selectedPointCloudForReuse = [];
  };

  setSelectedPointCloudForReuse = (id: string) => {
    this.selectedPointCloudForReuse = this.pointCloudFilesForReuse.filter(pc => pc.id === id);
  };

  setSelectedPointCloudsForMerge = (ids: string[]) => {
    this.selectedPointCloudsForMerge = this.pointCloudFilesForMerge.filter(pc => ids.includes(pc.storageMeta.key));
  };

  resetSelectedPointCloudsForMerge = () => {
    this.selectedPointCloudsForMerge = [];
  };

  setSelectedDxfFilesForReuse = (ids: string[]) => {
    this.selectedDxfFilesForReuse = this.dxfFilesForReuse.filter(dxf => ids.some(id => id === dxf.id));
  };

  setSelectedCsvFilesForReuse = (ids: string[]) => {
    this.selectedCsvFilesForReuse = this.csvFilesForReuse.filter(csv => ids.some(id => id === csv.id));
  };

  getPointCloudFilesForReuse = async (jobId: string) => {
    const response = await this.getFilesForReuse(FileName.POINT_CLOUD, jobId);
    const onlyVipoint = response.filter(pc => pc.storageProvider === StorageProvider.VIPOINT);
    this.pointCloudFilesForReuse = onlyVipoint;
  };

  getPointCloudFilesForMerge = async (jobId: string): Promise<void> => {
    const response = await this.getFilesForReuse(FileName.POINT_CLOUD, jobId);
    const onlyVipoint = response.filter(pc => pc.storageProvider === StorageProvider.VIPOINT);

    this.pointCloudFilesForMerge = onlyVipoint.filter(pc => pc.id !== this.pointCloudFileOfProcessing.id);
  };

  resetPointCloudFilesForReuse = () => {
    this.pointCloudFilesForReuse = [];
  };

  getDxfFilesForReuse = async (jobId: string) => {
    this.dxfFilesForReuse = await this.getFilesForReuse(FileName.DXF, jobId);
  };

  getCsvFilesForReuse = async (jobId: string) => {
    this.csvFilesForReuse = await this.getFilesForReuse(FileName.VP_CSV, jobId);
  };

  getAllFilesForReuse = async (jobId: string) => {
    await this.getPointCloudFilesForReuse(jobId);
    await this.getDxfFilesForReuse(jobId);
    await this.getCsvFilesForReuse(jobId);
  };

  getFilesForReuse = async (fileName: FileName, jobId: string) => {
    const subtype = fileName === (FileName.DXF || FileName.VP_CSV) ? SubType.INPUT : SubType.OUTPUT;
    const queryParams: QueryParamsGetFiles = {
      subtype,
      type: fileName,
      jobId,
      status: FileStatus.UPLOAD_SUCCESS,
      subsystem: SubSystem.VIGRAM,
    };
    const response = await fileService.getAllFiles(queryParams);
    return response.files
      .filter((item, index, array) => array.findIndex(obj => obj.id === item.id) === index)
      .filter(f => f.fileMeta.size);
  };

  getOutputs = async (foreignId: string, aclId: string, lastId?: string) => {
    const queryParams = {
      lastId,
      subtype: SubType.OUTPUT,
      status: FileStatus.UPLOAD_SUCCESS,
      aclId,
    };
    const response = await fileService.getAllFiles(queryParams, foreignId);

    if (response.files.length) {
      this.setOutputs(Array.isArray(this.outputs) ? [...this.outputs, ...response.files] : [...response.files]);
    }

    if (response.lastId != null) {
      await this.getOutputs(foreignId, aclId, response.lastId);
    }
  };

  delete = async (foreignId: string, id: string) => {
    await fileService.delete(foreignId, id);
  };

  startUpload = async ({ files, foreignId, aclId, fileType, projectId, processingType }: StartUploadParams) => {
    const BATCH_SIZE = FileSize.REGISTERED_FILES_BATCH_SIZE;
    const splitFiles = [] as File[][];

    for (let i = 0; i < Math.ceil(files.length / BATCH_SIZE); i++) {
      splitFiles[i] = files.slice(i * BATCH_SIZE, i * BATCH_SIZE + BATCH_SIZE);
    }

    let registeredFiles = [] as IFile[];

    for (const fileArr of splitFiles) {
      const response = await fileService.register(
        fileArr,
        foreignId,
        aclId,
        fileType.name,
        fileType.subsystem,
        fileType.subtype
      );
      registeredFiles = registeredFiles.concat(response);
      this.rootStore.measurementStore.createPoint(fileArr, registeredFiles, aclId);
    }

    const filesObj: { [name: string]: File } = {};

    files.forEach(File => {
      filesObj[File.name] = File;
    });

    const credentials = await fileService.getCredentials(
      registeredFiles[0].subsystem,
      registeredFiles[0].storageProvider,
      foreignId,
      aclId,
      StorageOperationMode.PutObject,
      projectId
    );

    registeredFiles.forEach(async (file, index) => {
      await this.sendFileToS3(filesObj[file.fileMeta.name], file, aclId, credentials, index, processingType);
    });
  };

  sendFileToS3 = async (
    File: File,
    file: IFile,
    aclId: string,
    credentials: ICredentials,
    index: number,
    processingType?: Processings
  ) => {
    const fileExt = file.fileMeta.name.split(".").pop()!;

    const fileName =
      file.storageProvider === StorageProvider.PIX4D &&
      (file.type === FileName.IMAGE || file.type === FileName.CONFIDENCE || file.type === FileName.DEPTHMAP)
        ? file.fileMeta.name
        : `${file.id}.${fileExt}`;

    const isAgisoft = processingType === Processings.AGISOFT ? "input/" : "";

    const key = `${credentials.key}/${isAgisoft}${fileName}`;

    const upload = fileService.sendFileToS3(
      credentials.bucket,
      File,
      key,
      credentials.region,
      credentials.accessKeyId,
      credentials.secretAccessKey,
      credentials.sessionToken,
      file.id,
      file.foreignId,
      aclId
    );

    extendObservable(upload, { loaded: 0 });

    upload.storageMeta = {
      key,
      bucket: credentials.bucket,
      region: credentials.region,
      metadata: {
        id: file.id,
        foreignId: file.foreignId,
        aclId,
      },
    };

    upload.status = file.status;

    runInAction(() => this.uploads.push(upload));

    if (index < FileSize.UPLOAD_BATCH_SIZE) {
      this.uploadFile(this.uploads.length - 1);
    }
  };

  uploadFile = (index: number) => {
    const foreignId = this.uploads[index].storageMeta.metadata.foreignId;
    const fileExt = this.uploads[index].body.name.split(".").pop();

    this.uploads[index]
      .promise()
      .then(async e => {
        await this.updateStatus(index, FileStatus.UPLOAD_SUCCESS);

        if (this.uploads.every(upload => upload.status === FileStatus.UPLOAD_SUCCESS)) {
          if (
            fileExt !== FileExt.tmpl &&
            fileExt !== FileExt.csv &&
            !this.uploads[index].storageMeta.bucket.includes("document")
          ) {
            const processingIds = [...new Set(this.uploads.map(obj => obj.storageMeta.metadata.foreignId))];
            processingIds.forEach(async id => await this.rootStore.processingStore.initiateProcessing(id));
          }
          const processing = this.rootStore.processingStore.processing;
          if (processing) {
            await this.getInputs(foreignId, processing.acl, true, true);
          }
        }
      })
      .catch(err => {
        throw err;
      })
      .finally(async () => {
        if (this.uploads.length > index + FileSize.UPLOAD_BATCH_SIZE) {
          this.uploadFile(index + FileSize.UPLOAD_BATCH_SIZE);
        }
      });

    this.uploads[index].on("httpUploadProgress", progress => {
      runInAction(() => {
        this.uploads[index].loaded = Math.round((progress.loaded / progress.total) * 100);
      });
      this.countProgress();
    });
  };

  countProgress = () => {
    runInAction(() => {
      this.progress = this.uploads.map(upload => upload.loaded).reduce((a, b) => a + b) / this.uploads.length;
    });
  };

  abortUpload = (index: number) => {
    this.uploads[index].abort();
  };

  updateStatus = async (index: number, status: FileStatus) => {
    await fileService.updateStatus(this.uploads[index].storageMeta, status);
    this.uploads[index].status = status;
    if (this.uploads.every(f => f.status === FileStatus.UPLOAD_SUCCESS)) {
      this.updateUploads();
    }
  };

  updateUploads = () => {
    this.uploads = [...this.uploads];
  };

  clearUploads = () => {
    runInAction(() => {
      this.uploads = [];
      this.progress = 0;
    });
  };

  public readonly createBlobDownloadLink = (fileData: string): string => {
    return window.URL.createObjectURL(new Blob([fileData], { type: "text/plain" }));
  };

  public readonly downloadBlobFile = (url: string, fileExt: string, fileName?: string) => {
    const _fileName = fileName ?? new Date().toLocaleDateString();

    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `${_fileName}.${fileExt}`);
    document.body.appendChild(link);
    link.click();
    link.remove();
  };

  public readonly getFilesByQueryParams = async (foreignId: string, params: GetFilesByQueryParams) => {
    return await fileService.getFilesByQueryParams(foreignId, params);
  };

  public readonly downloadFileFromURL = async (url: string, filename?: string) => {
    const link = document.createElement("a");
    link.href = url;

    if (filename) {
      try {
        const response = await fetch(url);
        if (!response.ok) throw new Error("Network response was not ok.");
        const blob = await response.blob();
        const downloadUrl = window.URL.createObjectURL(blob);
        link.href = downloadUrl;
        link.download = filename;
      } catch (error) {
        console.error("Error when downloading file :", error);
        return;
      }
    }
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    if (filename) {
      window.URL.revokeObjectURL(link.href);
    }
  };

  public readonly createDownloadLink = async (foreignId: string, id: string) => {
    return await fileService.createDownloadLink(foreignId, id);
  };

  public readonly download = async (
    subSystem: SubSystem,
    storageProvider: StorageProvider,
    foreignId: string,
    aclId: string,
    projectId: number,
    key: string
  ) => {
    const credentials = await fileService.getCredentials(
      subSystem,
      storageProvider,
      foreignId,
      aclId,
      StorageOperationMode.GetObject,
      projectId
    );
    const response = await fileService.getSignedUrl(
      credentials.region,
      credentials.accessKeyId,
      credentials.secretAccessKey,
      credentials.sessionToken,
      credentials.bucket,
      key
    );
    await this.downloadFileFromURL(response);
  };

  open = async (
    subSystem: SubSystem,
    storageProvider: StorageProvider,
    foreignId: string,
    aclId: string,
    key: string,
    projectId?: number
  ) => {
    const credentials = await fileService.getCredentials(
      subSystem,
      storageProvider,
      foreignId,
      aclId,
      StorageOperationMode.GetObject,
      projectId
    );
    const response = await fileService.getSignedUrl(
      credentials.region,
      credentials.accessKeyId,
      credentials.secretAccessKey,
      credentials.sessionToken,
      credentials.bucket,
      key
    );
    await this.downloadFileFromURL(response);
  };
}
