import { isChrome } from "react-device-detect";
import { action, makeObservable, observable } from "mobx";

import RootStore from "stores/rootStore";

export class MainStore {
  @observable
  isVerificationPassed = false;

  @observable
  isLanguageDefined = false;

  @observable
  isAppLoaded = false;

  @observable
  logoAnimationDuration = 3400;

  constructor(private readonly rootStore: RootStore) {
    makeObservable(this);
  }

  @action
  setAppLoaded() {
    if (this.isVerificationPassed && !this.logoAnimationDuration) {
      this.isAppLoaded = true;
    }
  }

  @action
  setVerificationPassed() {
    this.isVerificationPassed = true;
    this.setAppLoaded();
  }

  @action
  setLanguageDefined() {
    this.isLanguageDefined = true;
    this.setAppLoaded();
  }

  @action
  setTimerToZero() {
    this.logoAnimationDuration = 0;
    this.setAppLoaded();
  }

  @action
  showBrowserWarning() {
    if (!isChrome) {
      this.rootStore.uiStore.setSnackbarOpen("General:UseChromeBrowser", "info");
    }
  }
}

// export const mainStore = new MainStore();
