import React, { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import AccessAlarmRoundedIcon from "@mui/icons-material/AccessAlarmRounded";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import ClearRoundedIcon from "@mui/icons-material/ClearRounded";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { de, enGB } from "date-fns/locale";

const DueDateField: FC<{
  dueDate: Date | undefined | null;
  setDueDate: React.Dispatch<React.SetStateAction<Date | undefined | null>>;
  isAllowedToEdit: boolean;
}> = ({ dueDate, setDueDate, isAllowedToEdit }) => {
  const { t, i18n } = useTranslation();

  const [dueDateOpen, setDueDateOpen] = useState(false);

  return (
    <Box sx={wrapperStyles}>
      <AccessAlarmRoundedIcon />
      <Typography variant="body1" component="div" fontWeight={600} sx={{ ml: 1.2 }}>
        {t("Tasks:DueDate")}
      </Typography>
      {!dueDateOpen && !dueDate ? (
        <Button
          disabled={!isAllowedToEdit}
          size="small"
          variant="contained"
          color="secondary"
          sx={btnStyles}
          onClick={() => setDueDateOpen(true)}>
          <AddRoundedIcon fontSize="small" />
        </Button>
      ) : (
        <LocalizationProvider adapterLocale={i18n.language === "en" ? enGB : de} dateAdapter={AdapterDateFns}>
          <DesktopDatePicker
            disabled={!isAllowedToEdit}
            value={dueDate}
            onChange={value => {
              setDueDate(value);
            }}
            open={dueDateOpen}
            onOpen={() => setDueDateOpen(true)}
            onClose={() => setDueDateOpen(false)}
            disableOpenPicker
            PaperProps={{
              sx: {
                "& .MuiSvgIcon-root": {
                  color: "text.primary",
                },
              },
            }}
            renderInput={params => (
              <TextField
                disabled={!isAllowedToEdit}
                {...params}
                size="small"
                focused={dueDateOpen}
                onClick={() => setDueDateOpen(true)}
                sx={dueDate ? { ...datePickerStyles, width: "calc(10ch + 32px)" } : datePickerStyles}
                InputProps={{
                  endAdornment:
                    dueDate && isAllowedToEdit ? (
                      <ClearRoundedIcon fontSize="small" sx={clearIconStyles} onClick={() => setDueDate(null)} />
                    ) : undefined,
                }}
              />
            )}
          />
        </LocalizationProvider>
      )}
    </Box>
  );
};

const wrapperStyles = {
  display: "flex",
  alignItems: "center",
  height: "32px",
  mt: 0.5,
};

const btnStyles = {
  ml: 1.5,
  height: "28px",
  width: "28px",
  minWidth: "unset",
  "&:hover": { bgcolor: "rgba(255, 255, 255, 0.1)" },
};

const datePickerStyles = {
  ml: 1.5,
  width: "107px",
  maxWidth: "100%",
  cursor: "pointer",
  "& .MuiInputBase-input": {
    py: 0.8,
    fontSize: "14px",
    lineHeight: "16px",
    height: "16px",
    minHeight: "16px",
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: "transparent",
  },
};

const clearIconStyles = {
  color: "text.primary",
  position: "absolute",
  right: "7px",
  top: "calc(50% - 0.5em)",
  cursor: "pointer",
  transition: "color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
  "&:hover": { color: "rgba(255, 255, 255, 0.5)" },
};

export default DueDateField;
