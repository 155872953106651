import { useRootStore } from "hooks/useRootStore";;
import React, { FC, FormEvent, useEffect,useState } from "react";
import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import { countryCodes } from "constants/countryCodes";
import { industries } from "constants/industries";

const CompanyProfile: FC = () => {
  const { t } = useTranslation();
  const { authStore, settingsStore } = useRootStore();

  const { userAttributes } = authStore;

  const [company, setCompany] = useState("");
  const [industry, setIndustry] = useState("PublicClient");
  const [country, setCountry] = useState("DE");
  const [postcode, setPostcode] = useState("");
  const [city, setCity] = useState("");
  const [address, setAddress] = useState("");
  const [vatCode, setVatCode] = useState("");
  const [website, setWebsite] = useState("");

  useEffect(() => {
    if (userAttributes) {
      for (const attr of userAttributes) {
        if (attr.Name === "custom:company_name") {
          setCompany(attr.Value);
        }
        if (attr.Name === "custom:industries") {
          setIndustry(attr.Value);
        }
        if (attr.Name === "custom:country") {
          setCountry(attr.Value);
        }
        if (attr.Name === "custom:postcode") {
          setPostcode(attr.Value);
        }
        if (attr.Name === "custom:city") {
          setCity(attr.Value);
        }
        if (attr.Name === "address") {
          setAddress(attr.Value);
        }
        if (attr.Name === "custom:vat_code") {
          setVatCode(attr.Value);
        }
        if (attr.Name === "website") {
          setWebsite(attr.Value);
        }
      }
    }
  }, [userAttributes]);

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    settingsStore.updateCompanyProfile(company, industry, country, postcode, city, address, vatCode, website);
  };

  return (
    <Box>
      <Typography variant="h6" component="div">
        {t("Settings:CompanyProfile")}
      </Typography>
      <Box component="form" onSubmit={handleSubmit}>
        <Box sx={{ display: "flex", mt: 1 }}>
          <Box sx={{ mr: 2, width: "100%" }}>
            <Typography variant="caption" color="text.secondary" fontWeight="500">
              {t("Settings:CompanyName")} *
            </Typography>
            <TextField
              variant="outlined"
              size="small"
              type="text"
              autoComplete="organization"
              sx={inputStyles}
              fullWidth
              required
              value={company}
              onChange={e => setCompany(e.target.value)}
            />
          </Box>
          <Box sx={{ width: "100%" }}>
            <Typography variant="caption" color="text.secondary" fontWeight="500">
              {t("Settings:Industries")} *
            </Typography>
            <FormControl size="small" sx={inputStyles} fullWidth required>
              <Select value={industry} onChange={e => setIndustry(e.target.value)}>
                {industries.map(value => (
                  <MenuItem key={value} value={value}>
                    {t(`Settings:${value}`)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </Box>
        <Box sx={{ display: "flex", mt: 1 }}>
          <Box sx={{ mr: 2, width: "100%" }}>
            <Typography variant="caption" color="text.secondary" fontWeight="500">
              {t("Settings:Country")} *
            </Typography>
            <FormControl size="small" sx={inputStyles} fullWidth required>
              <Select value={country} onChange={e => setCountry(e.target.value)}>
                {countryCodes.map(country => (
                  <MenuItem key={country} value={country}>
                    {t(`CountriesList:${country}`)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
          <Box sx={{ mr: 2, width: "100%" }}>
            <Typography variant="caption" color="text.secondary" fontWeight="500">
              {t("Settings:Postcode")} *
            </Typography>
            <TextField
              variant="outlined"
              size="small"
              type="text"
              autoComplete="postal-code"
              sx={inputStyles}
              fullWidth
              required
              value={postcode}
              onChange={e => setPostcode(e.target.value)}
            />
          </Box>
          <Box sx={{ width: "100%" }}>
            <Typography variant="caption" color="text.secondary" fontWeight="500">
              {t("Settings:City")} *
            </Typography>
            <TextField
              variant="outlined"
              size="small"
              type="text"
              sx={inputStyles}
              fullWidth
              required
              value={city}
              onChange={e => setCity(e.target.value)}
            />
          </Box>
        </Box>
        <Box sx={{ mt: 1 }}>
          <Typography variant="caption" color="text.secondary" fontWeight="500">
            {t("Settings:Address")} *
          </Typography>
          <TextField
            variant="outlined"
            size="small"
            type="text"
            autoComplete="street-address"
            sx={inputStyles}
            fullWidth
            required
            value={address}
            onChange={e => setAddress(e.target.value)}
          />
        </Box>
        <Box sx={{ display: "flex", mt: 1 }}>
          <Box sx={{ mr: 2, width: "100%" }}>
            <Typography variant="caption" color="text.secondary" fontWeight="500">
              {t("Settings:VatCode")}
            </Typography>
            <TextField
              variant="outlined"
              size="small"
              type="text"
              sx={inputStyles}
              fullWidth
              value={vatCode}
              onChange={e => setVatCode(e.target.value)}
            />
          </Box>
          <Box sx={{ width: "100%" }}>
            <Typography variant="caption" color="text.secondary" fontWeight="500">
              {t("Settings:Website")}
            </Typography>
            <TextField
              variant="outlined"
              size="small"
              type="text"
              autoComplete="url"
              sx={inputStyles}
              fullWidth
              value={website}
              onChange={e => setWebsite(e.target.value)}
            />
          </Box>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
          <Button variant="contained" type="submit">
            {t("Settings:SaveChanges")}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

const inputStyles = {
  mt: 0.5,
  "& .MuiOutlinedInput-notchedOutline": { borderColor: "text.secondary" },
};

export default CompanyProfile;
