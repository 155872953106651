import { Enum } from "types";

import { ExistingStep, Step } from "./types";

export const getExistingSteps = <T extends Enum>(
  steps: Array<Step<T>>,
  missNotExisting?: true
): Array<ExistingStep<T>> => {
  if (missNotExisting) {
    return steps.filter(s => !!s) as Array<ExistingStep<T>>;
  }

  const res: Array<ExistingStep<T>> = [];
  for (const step of steps) {
    if (!step) {
      break;
    }
    res.push(step);
  }

  return res;
};
