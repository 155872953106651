import axios from "axios";

import { getServiceUrl, Service } from "configs/servicesConfig";

import { ApiResponse } from "../../types";

import { InviteByEmailDto, SearchByEmailUserItem, SearchByUserIdsItem, UploadAvatarDto } from "./types";

const userServiceUrl = getServiceUrl(Service.USER, "/users");

export const searchUsersByEmail = async (email: string) =>
  (
    await axios.get<ApiResponse<SearchByEmailUserItem[]>>(userServiceUrl, {
      params: { email },
    })
  ).data;

export const searchUsersByUserIds = async (userIds: string) =>
  (
    await axios.get<ApiResponse<SearchByUserIdsItem[]>>(userServiceUrl, {
      params: { username: userIds },
    })
  ).data;

export const getOneById = async (userId: string) =>
  (await axios.get<ApiResponse<SearchByUserIdsItem>>(userServiceUrl + `/${userId}`)).data;

export const inviteByEmail = async (dto: InviteByEmailDto) => (await axios.post(userServiceUrl, dto)).data;

export const uploadAvatar = async (userId: string, avatar: Buffer, avatarType: string) => {
  return (
    await axios.put<ApiResponse<UploadAvatarDto>>(userServiceUrl + `/${userId}/avatar`, avatarType, {
      headers: {
        "Content-Type": avatarType,
      },
    })
  ).data;
};

export const deleteUser = async (userId: string) =>
  (await axios.delete<ApiResponse<unknown>>(userServiceUrl + `/${userId}`)).data;
