import { TeamRole, UserPermissions, UserPermissionsTypes } from "./types";

export const GUEST_PERMISSIONS: UserPermissions = {
  creating: false,
  reading: true,
  updating: false,
  deleting: false,
} as const;

export const ROLES_THAT_CAN: Record<UserPermissionsTypes, readonly TeamRole[]> = {
  creating: [TeamRole.ADMIN, TeamRole.OWNER, TeamRole.COORDINATOR, TeamRole.MEMBER],
  deleting: [TeamRole.OWNER, TeamRole.ADMIN],
  reading: [TeamRole.ADMIN, TeamRole.OWNER, TeamRole.GUEST, TeamRole.COORDINATOR, TeamRole.MEMBER],
  updating: [TeamRole.OWNER, TeamRole.ADMIN, TeamRole.COORDINATOR],
} as const;
