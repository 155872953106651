import {
  AuthenticationDetails,
  CognitoRefreshToken,
  CognitoUser,
  CognitoUserAttribute,
  CognitoUserPool,
} from "amazon-cognito-identity-js";
import { action, makeAutoObservable, runInAction } from "mobx";
import { v4 as uuidv4 } from "uuid";

import { clientId } from "configs/servicesConfig";
import RootStore from "stores/rootStore";

const userPool = new CognitoUserPool({
  UserPoolId: `${process.env.REACT_APP_AWS_COGNITO_USER_POOL_ID}`,
  ClientId: `${process.env.REACT_APP_AWS_COGNITO_CLIENT_ID}`,
});

enum UserAttributes {
  email = "email",
  given_name = "given_name",
  family_name = "family_name",
  picture = "picture",
  customPictureMini = "custom:picture-mini",
}

export class AuthStore {
  error = "";

  values = {
    email: "",
    password: "",
    newPassword: "",
    code: "",
  };

  cognitoUser = {} as CognitoUser;
  userAttributes = undefined as CognitoUserAttribute[] | undefined;
  email = "";
  firstName = "";
  lastName = "";
  initials = "";
  avatar = {
    source: "",
    base64: "",
    key: "",
  };

  newUser = false;
  isPasswordValid = true;
  isPasswordMatch = true;
  inProgress = false;

  success = {
    resendCode: false,
    forgotPassword: false,
  };

  userName: string = "";

  constructor(private readonly rootStore: RootStore) {
    makeAutoObservable(this);
  }

  setEmail(email: string) {
    this.values.email = email;
  }

  setPassword(password: string) {
    this.values.password = password;
  }

  setNewPassword(newPassword: string) {
    this.values.newPassword = newPassword;
  }

  setCode(code: string) {
    this.values.code = code;
  }

  setCognitoUser = (cognitoUser: CognitoUser) => {
    this.cognitoUser = cognitoUser;
  };

  async verifySession() {
    this.setCognitoUser(userPool.getCurrentUser()!);

    if (this.cognitoUser) this.userName = this.cognitoUser.getUsername();

    return await new Promise((res, rej) => {
      this.cognitoUser.getSession((err: any, session: any) => {
        return err ? rej(err) : res(session);
      });
    })
      .then(
        async () =>
          await new Promise((res, rej) => {
            this.cognitoUser?.getUserAttributes((err, attributes) => {
              if (err) {
                return rej(err);
              }
              res(attributes);
              this.setUserAttributes(attributes);
              this.setUserInfo();
              this.checkIfUserIsNew();
            });
          })
      )
      .catch(() => {})
      .finally(() => this.rootStore.mainStore.setVerificationPassed());
  }

  setUserAttributes(userAttributes: CognitoUserAttribute[] | undefined) {
    this.userAttributes = userAttributes;
  }

  setUserInfo() {
    if (this.userAttributes) {
      for (const attr of this.userAttributes) {
        switch (attr.Name) {
          case UserAttributes.email:
            this.email = attr.Value;
            break;
          case UserAttributes.given_name:
            this.firstName = attr.Value;
            break;
          case UserAttributes.family_name:
            this.lastName = attr.Value;
            break;
          case UserAttributes.picture:
            this.avatar.source = attr.Value;
            break;
          case UserAttributes.customPictureMini:
            this.avatar.base64 = attr.Value;
            break;
          default:
            break;
        }
      }
      this.avatar.key = uuidv4();
      if (this.firstName && this.lastName) {
        this.initials = this.firstName.charAt(0) + this.lastName.charAt(0);
      }
    }
  }

  async refreshToken() {
    const refreshToken = localStorage.getItem(
      `CognitoIdentityServiceProvider.${clientId}.${this.userName}.refreshToken`
    );
    const cognitoRefreshToken = new CognitoRefreshToken({
      RefreshToken: refreshToken!,
    });

    return await new Promise((res, rej) =>
      this.cognitoUser?.refreshSession(cognitoRefreshToken, (err, result) => {
        if (err) {
          return rej(err);
        }
        localStorage.setItem(
          `CognitoIdentityServiceProvider.${clientId}.${this.userName}.idToken`,
          result.idToken.jwtToken
        );
        localStorage.setItem(
          `CognitoIdentityServiceProvider.${clientId}.${this.userName}.accessToken`,
          result.accessToken.jwtToken
        );
        res(result);
      })
    ).catch(() => {});
  }

  checkIfUserIsNew() {
    if (this.userAttributes) {
      if (
        !this.userAttributes.some(attr => attr.Name === "family_name") ||
        !this.userAttributes.some(attr => attr.Name === "custom:company_name")
      ) {
        runInAction(() => {
          this.newUser = true;
        });
      } else {
        runInAction(() => {
          this.newUser = false;
        });
      }
    }
  }

  validatePassword(password: string) {
    this.isPasswordValid = true;
    const regexPassword = /^(?=^.{8,99}$)(?=.*[0-9])(?=.*[A-Z])(?=.*[a-z])(?=.*[^A-Za-z0-9]).*$/;
    if (!regexPassword.test(password)) {
      this.isPasswordValid = false;
    }
  }

  checkPasswordsMatch(password: string, confirmPassword: string) {
    if (password === confirmPassword) {
      this.isPasswordMatch = true;
    } else {
      this.isPasswordMatch = false;
    }
  }

  async register() {
    localStorage.setItem("username", uuidv4());

    const attributeList = [
      new CognitoUserAttribute({
        Name: "email",
        Value: this.values.email,
      }),
    ];

    this.inProgress = true;
    this.error = "";

    return await new Promise((res, rej) =>
      userPool.signUp(localStorage.getItem("username")!, this.values.password, attributeList, [], (err, result) => {
        if (err) {
          return rej(err);
        }
        res(result);
      })
    )
      .catch(
        action(err => {
          if (err.code === "UserLambdaValidationException") {
            this.error = "This email address is already being used";
          } else {
            this.error = err.message;
          }
          throw err;
        })
      )
      .finally(action(() => (this.inProgress = false)));
  }

  async confirmRegistration() {
    this.inProgress = true;
    this.error = "";

    const cognitoUser = new CognitoUser({
      Username: localStorage.getItem("username")!,
      Pool: userPool,
    });

    return await new Promise((res, rej) =>
      cognitoUser.confirmRegistration(this.values.code, true, (err, result) => {
        if (err) {
          return rej(err);
        }
        res(result);
      })
    )
      .catch(
        action(err => {
          this.error = err.message;
          throw err;
        })
      )
      .finally(action(() => (this.inProgress = false)));
  }

  async resendCode() {
    this.inProgress = true;
    this.error = "";
    this.success.resendCode = false;

    const cognitoUser = new CognitoUser({
      Username: this.values.email,
      Pool: userPool,
    });

    return await new Promise((res, rej) =>
      cognitoUser.resendConfirmationCode((err, result) => {
        if (err) {
          return rej(err);
        }
        res(result);
        this.success.resendCode = true;
      })
    )
      .catch(
        action(err => {
          this.error = err.message;
          throw err;
        })
      )
      .finally(action(() => (this.inProgress = false)));
  }

  async login() {
    this.inProgress = true;
    this.error = "";

    const Username = this.values.email.toLowerCase();

    const authenticationDetails = new AuthenticationDetails({
      Username,
      Password: this.values.password,
    });

    const cognitoUser = new CognitoUser({
      Username,
      Pool: userPool,
    });

    return await new Promise((res, rej) => {
      cognitoUser.authenticateUser(authenticationDetails, {
        onSuccess: result => {
          window.location.reload();
          return res(result);
        },
        onFailure: err => {
          return rej(err);
        },
        newPasswordRequired: (userAttributes, requiredAttributes) => {
          runInAction(() => {
            this.inProgress = !this.inProgress;
          });
          if (this.newUser) {
            cognitoUser.completeNewPasswordChallenge(this.values.newPassword, this.userAttributes, {
              onSuccess: result => {
                window.location.reload();
                return res(result);
              },
              onFailure: err => {
                return rej(err);
              },
            });
          }
          runInAction(() => {
            this.newUser = true;
          });
        },
      });
    })
      .catch(
        action(err => {
          this.error = err.message;
          throw err;
        })
      )
      .finally(action(() => (this.inProgress = false)));
  }

  async forgotPassword() {
    this.inProgress = true;
    this.error = "";

    const cognitoUser = new CognitoUser({
      Username: this.values.email,
      Pool: userPool,
    });

    return await new Promise((res, rej) => {
      cognitoUser.forgotPassword({
        onSuccess: result => {
          return res(result);
        },
        onFailure: err => {
          return rej(err);
        },
      });
    })
      .catch(
        action(err => {
          this.error = err.message;
          throw err;
        })
      )
      .finally(action(() => (this.inProgress = false)));
  }

  async confirmPassword() {
    this.inProgress = true;
    this.error = "";

    const cognitoUser = new CognitoUser({
      Username: this.values.email,
      Pool: userPool,
    });

    return await new Promise((res, rej) => {
      cognitoUser.confirmPassword(this.values.code, this.values.password, {
        onSuccess: result => {
          return res(result);
        },
        onFailure: err => {
          return rej(err);
        },
      });
    })
      .catch(
        action(err => {
          this.error = err.message;
          throw err;
        })
      )
      .finally(action(() => (this.inProgress = false)));
  }

  async updateAttributes(attributesList: CognitoUserAttribute[]) {
    return await new Promise((res, rej) => {
      this.cognitoUser?.updateAttributes(attributesList, (err, result) => {
        if (err) {
          return rej(err);
        }
        res(result);
        this.verifySession();
      });
    }).catch(
      action(err => {
        throw err;
      })
    );
  }

  async verifyEmail(verificationCode: string) {
    this.error = "";

    return await new Promise((res, rej) => {
      this.cognitoUser?.verifyAttribute("email", verificationCode, {
        onSuccess: result => {
          this.verifySession();
          return res(result);
        },
        onFailure: err => {
          return rej(err);
        },
      });
    }).catch(
      action(err => {
        this.error = err.message;
        throw err;
      })
    );
  }

  async getEmailVerificationCode() {
    this.error = "";

    return await new Promise((res, rej) => {
      this.cognitoUser?.getAttributeVerificationCode("email", {
        onSuccess: result => {
          return res(result);
        },
        onFailure: err => {
          return rej(err);
        },
      });
    }).catch(
      action(err => {
        this.error = err.message;
        throw err;
      })
    );
  }

  async changePassword(currentPassword: string, newPassword: string) {
    this.error = "";

    return await new Promise((res, rej) => {
      this.cognitoUser?.changePassword(currentPassword, newPassword, (err, result) => {
        if (err) {
          return rej(err);
        }
        res(result);
      });
    }).catch(
      action(err => {
        this.error = err.message;
        throw err;
      })
    );
  }

  logout = async (): Promise<void> => {
    return await new Promise(() => {
      if (this.cognitoUser !== null) {
        this.cognitoUser.signOut();
        window.location.reload();
      }
    });
  };
}
