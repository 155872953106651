import React, { FC, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Box from "@mui/material/Box";
import Fade from "@mui/material/Fade";
import { observer } from "mobx-react-lite";

import CardEditableTitle from "common/Card/CardEditableTitle";
import useFetchAreaAndJobs from "hooks/manager/useFetchAreaAndJobs";
import useFetchConstructionSiteAndAreas from "hooks/manager/useFetchConstructionSiteAndAreas";
import { useRootStore } from "hooks/useRootStore";

import Description from "./Description";
import Tabs from "./Tabs";

const Card: FC = () => {
  const { id } = useParams();

  const { constructionSiteStore, areaStore, teamStore } = useRootStore();

  const { isCardOpen } = constructionSiteStore;
  const { area } = areaStore;
  const [title, setTitle] = useState(area?.title ?? "");
  const { csPermissions } = teamStore;

  useFetchConstructionSiteAndAreas(area?.constructionSite);
  const [isAreaLoadingInProgress] = useFetchAreaAndJobs(id);

  const finishEditing = async () => {
    if (!area?.id) {
      return;
    }

    if (title) {
      await areaStore.updateTitle(area?.id, title);
    } else {
      setTitle(area?.title);
    }
  };

  useEffect(() => {
    if (area && area?.title !== title) {
      setTitle(area?.title || "");
    }
  }, [area]);

  return (
    <>
      <CardEditableTitle
        title={title}
        setTitle={setTitle}
        onFinish={finishEditing}
        disabled={!isCardOpen || !csPermissions.creating}
      />
      <Fade in>
        <Box sx={innerContainerStyles}>
          <Description />
          <Tabs isAreaLoadingInProgress={isAreaLoadingInProgress} />
        </Box>
      </Fade>
    </>
  );
};

const innerContainerStyles = {
  display: "flex",
  flexDirection: "column",
} as const;

export default observer(Card);
