import React, { FC, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { observer } from "mobx-react-lite";

import { ErrorI18KeyAndOptions } from "stores/managerService/helpers/checkGraphics";

import { LOCALES } from "constants/locales";
import { useRootStore } from "hooks/useRootStore";

const EditMode: FC = () => {
  const { t } = useTranslation();
  const {
    constructionSiteStore: {
      checkNestedGraphics,
      editableConstructionSite,
      constructionSite,
      getConstructionSite,
      updateGraphic,
      setEditableConstructionSite,
      setEditMode,
    },
    mapStore: { utils },
    areaStore: { getAllAreas },
  } = useRootStore();

  const [error, setError] = useState<ErrorI18KeyAndOptions>();

  const handleSubmit = useCallback(async () => {
    const errorMessageAndOptions = checkNestedGraphics(editableConstructionSite);

    if (errorMessageAndOptions) {
      setError(errorMessageAndOptions);
      return;
    }

    if (!editableConstructionSite) {
      return;
    }

    await updateGraphic(editableConstructionSite);
    utils.clearGraphicLayer();
    await getConstructionSite(constructionSite.id);
    await getAllAreas(constructionSite.id);
    await setEditableConstructionSite(null);
  }, [
    checkNestedGraphics,
    constructionSite.id,
    editableConstructionSite,
    getAllAreas,
    getConstructionSite,
    setEditableConstructionSite,
    updateGraphic,
    utils,
  ]);

  const handleCancel = useCallback(async () => {
    setError(undefined);
    setEditableConstructionSite(null);
    utils.clearGraphicLayer();
    await getConstructionSite(constructionSite.id);
    setEditMode(false);
  }, [constructionSite.id, getConstructionSite, setEditMode, setEditableConstructionSite, utils]);

  return (
    <Box sx={{ mt: 1, flexShrink: 0 }}>
      <Typography variant="body1" sx={{ mt: 1.5 }}>
        {t(LOCALES.CONSTRUCTION_SITE.DETERMINATE_LOCATION)}
      </Typography>
      {error && (
        <Typography variant="caption" component="div" color="error" sx={{ mt: 0.5, mb: 0.25 }}>
          {t(...error)}
        </Typography>
      )}
      <Button variant="contained" sx={{ mt: 1.75, mr: 1, mb: 1 }} onClick={handleSubmit}>
        {t(LOCALES.GENERAL.SAVE)}
      </Button>
      <Button variant="contained" color="secondary" sx={{ mt: 1.75, mb: 1 }} onClick={handleCancel}>
        {t(LOCALES.GENERAL.CANCEL)}
      </Button>
    </Box>
  );
};

export default observer(EditMode);
