import React, { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import MuiTabs from "@mui/material/Tabs";
import { observer } from "mobx-react-lite";

import Job from "components/ConstructionSite/Job";
import Tasks from "components/ConstructionSite/Tasks";
import { useRootStore } from "hooks/useRootStore";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

export const TabPanel = (props: TabPanelProps) => {
  const { children, value, index } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      sx={tabPanelStyles}>
      {value === index && children}
    </Box>
  );
};

interface TabsProps {
  isAreaLoadingInProgress: boolean;
}

const JOBS_TAB = 0;
const TASKS_TAB = 1;

const Tabs: FC<TabsProps> = ({ isAreaLoadingInProgress }) => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();

  const { areaStore, jobStore } = useRootStore();

  const { area } = areaStore;
  const { createMode, editMode } = jobStore;

  const [value, setValue] = useState(JOBS_TAB);
  
  useEffect(() => {
    if (searchParams.get("taskId")) {
      setValue(TASKS_TAB);
    }
  }, [searchParams]);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const disableTabs = createMode || editMode;

  return (
    <>
      <MuiTabs value={value} onChange={handleChange} aria-label="tabs" variant="fullWidth" sx={tabsStyles}>
        <Tab disabled={disableTabs} label={t("Job:Jobs")} disableRipple />
        <Tab disabled={disableTabs} label={t("Tasks:Tasks")} disableRipple />
      </MuiTabs>
      <TabPanel value={value} index={JOBS_TAB}>
        {area && <Job areaId={area.id} isAreaLoadingInProgress={isAreaLoadingInProgress} />}
      </TabPanel>
      <TabPanel value={value} index={TASKS_TAB}>
        <Box sx={tasksWrapperStyles}>{area && <Tasks foreignId={area.id} acl={area.acl} />}</Box>
      </TabPanel>
    </>
  );
};

const tasksWrapperStyles = {
  mt: 2,
  width: "100%",
  display: "flex",
  flexDirection: "column",
};

const tabsStyles = {
  borderRadius: "10px",
  minHeight: "38px",
  bgcolor: "secondary.main",
  mt: 1.5,
  flexShrink: 0,
  "& .MuiButtonBase-root": {
    zIndex: 2,
    minHeight: "38px",
    color: "text.primary",
    textTransform: "none",
    fontSize: "16px",
    fontFamily: "Roboto",
    letterSpacing: 0,
    p: 0,
    "&.Mui-selected": {
      color: "text.primary",
    },
  },
  "& .MuiTabs-indicator": {
    borderRadius: "8px",
    bgcolor: "secondary.light",
    height: "calc(100% - 6px)",
    maxWidth: "calc(50% - 6px)",
    margin: "3px",
  },
} as const;

const tabPanelStyles = {
  display: "flex",
} as const;

export default observer(Tabs);
