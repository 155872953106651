import { useRootStore } from "hooks/useRootStore";;
import React, { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import InfoIcon from "@mui/icons-material/Info";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import { Box, Typography } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import Grow from "@mui/material/Grow";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Paper from "@mui/material/Paper";
import Select from "@mui/material/Select";
import parse from "html-react-parser";
import { observer } from "mobx-react-lite";

import { iconStyles } from "constants/styles";

import s from "./index.module.css";

export const CommonParams: FC = observer(() => {
  const { t } = useTranslation();
  const { processingStore } = useRootStore();

  const [category, setCategory] = useState<string>("");
  const { categories, scripts, script, setScript, getCategories } = processingStore;
  const [showDescription, setShowDescription] = useState(false);

  useEffect(() => {
    getCategories();
  }, []);

  useEffect(() => {
    setScript("");
  }, [category]);

  useEffect(() => {
    // resetInputs();
    setShowDescription(false);
  }, [category, script]);

  return (
    <Box sx={{ flex: 1, pr: 2, mt: 2 }}>
      <FormControl sx={formControlStyles} fullWidth required>
        <InputLabel>{t(`Processings:Categories`)}</InputLabel>
        <Select
          label={t(`Processings:Categories`)}
          disabled={!categories}
          value={category || ""}
          onChange={e => {
            setCategory(e.target.value);
            processingStore.getScripts(e.target.value);
          }}
          IconComponent={KeyboardArrowDownRoundedIcon}>
          {categories?.map(item => (
            <MenuItem value={item.categoryId} key={item.categoryId}>
              {t(`Processings:Category:${item.categoryId}`)}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl sx={{ ...formControlStyles, my: 0.1 }} fullWidth required>
        <InputLabel>{t(`Processings:Functions`)}</InputLabel>
        <Select
          label={t(`Processings:Functions`)}
          value={script ?? ""}
          disabled={category.length === 0}
          onChange={e => {
            setScript(e.target.value);
            processingStore.getParameters(Number(e.target.value));
          }}
          IconComponent={KeyboardArrowDownRoundedIcon}>
          {scripts?.map(item => (
            <MenuItem value={item.scriptId} key={item.scriptId}>
              {t(`Processings:Scripts:${item.scriptId}:alias`)}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {script && <InfoIcon sx={iconStyles} onClick={() => setShowDescription(prev => !prev)} />}
      <Grow in={showDescription}>
        <Paper sx={paperStyles} elevation={4}>
          <Typography sx={{ ml: 0.25, mr: 0.5, fontSize: 13 }} className={s.typography}>
            {parse(t(`Processings:Scripts:${script}:description`))}
          </Typography>
        </Paper>
      </Grow>
    </Box>
  );
});

const formControlStyles = {
  width: "250px",
  my: 1.5,
};

const paperStyles = {
  m: 1,
  padding: "10px",
  zIndex: 10,
  minWidth: "150px",
  maxWidth: "230px",
};
