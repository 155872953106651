import React, { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Box, Checkbox, FormControlLabel } from "@mui/material";
import Typography from "@mui/material/Typography";
import { observer } from "mobx-react-lite";

import { IParameters } from "stores/managerService/processingStore/types";

import { useRootStore } from "hooks/useRootStore";

export const Boolean: FC<{ parameter: IParameters }> = observer(({ parameter }) => {
  const { t } = useTranslation();
  const { processingStore } = useRootStore();
  const [value, setValue] = useState(false);
  const { parameterId } = parameter;

  useEffect(() => {
    processingStore.setUpdatedParams(parameterId, value);
  }, [value]);

  return (
    <Box key={parameterId}>
      <Typography variant="body1">{t(`Processings:Parameters:${parameterId}:parameterName`)}</Typography>
      <Typography variant="body2" sx={{ mt: 1, color: "text.secondary" }}>
        {t(`Processings:Parameters:${parameterId}:description`)}
      </Typography>
      <FormControlLabel
        label={<Typography variant="body2">{t(`Processings:Parameters:${parameterId}:parameterName`)}</Typography>}
        control={
          <Checkbox
            checked={value}
            onChange={e => {
              setValue(e.target.checked);
            }}
          />
        }
      />
    </Box>
  );
});
