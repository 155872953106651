import { useRootStore } from "hooks/useRootStore";;
import React, { FC, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { Link as RouterLink } from "react-router-dom";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Fade from "@mui/material/Fade";
import Link from "@mui/material/Link";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { observer } from "mobx-react-lite";

import LanguageSelector from "components/Sidebar/LanguageSelector";
import { absolutePath, PATHS } from "router/paths";

const Register: FC = () => {
  const { t } = useTranslation();
  const { authStore } = useRootStore();
  const navigate = useNavigate();

  const passwordValidationError = t("Auth:PasswordValidationError");

  const { values, inProgress, isPasswordValid, error, cognitoUser } = authStore;

  useEffect(() => {
    if (cognitoUser) {
      navigate(absolutePath(PATHS.HOME.INDEX));
    }
  }, []);

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => authStore.setEmail(e.target.value);
  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => authStore.setPassword(e.target.value);
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    authStore.validatePassword(values.password);
    authStore.isPasswordValid &&
      authStore.register().then(() => {
        navigate(PATHS.REGISTER.CONFIRM);
      });
  };

  return (
    <Fade in>
      <Box sx={containerStyles}>
        <img src="/images/logo.svg" alt="Logo" />
        <Box component="form" onSubmit={handleSubmit} sx={formStyles}>
          <Box sx={{ alignSelf: "flex-end", mt: 4 }}>
            <LanguageSelector displayFlag={false} />
          </Box>
          <Typography variant="body1" sx={{ my: 1 }} component="div" color="error">
            {error}
          </Typography>
          <TextField
            required
            fullWidth
            variant="standard"
            id="email"
            label={t("General:Email")}
            type="email"
            name="email"
            autoComplete="email"
            value={values.email}
            onChange={handleEmailChange}
          />
          <TextField
            error={!isPasswordValid}
            required
            fullWidth
            variant="standard"
            name="password"
            label={t("Auth:PasswordLabel")}
            type="password"
            id="password"
            autoComplete="new-password"
            value={values.password}
            onChange={handlePasswordChange}
            helperText={!isPasswordValid && passwordValidationError}
            sx={{ mt: 2 }}
          />
          <Button
            type="submit"
            fullWidth
            color="secondary"
            variant="contained"
            sx={{ mt: 4 }}
            disabled={inProgress}
            disableElevation>
            {t("Auth:SignUp")}
          </Button>
          <Box sx={bottomStyles}>
            <Typography variant="body2" component="span">
              {t("Auth:LoginMessage")}
            </Typography>
            <Link component={RouterLink} to="/login" variant="body2" underline="hover" sx={{ ml: 2 }}>
              {t("Auth:Login")}
            </Link>
          </Box>
        </Box>
      </Box>
    </Fade>
  );
};

const containerStyles = {
  width: "100%",
  height: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  bgcolor: "background.default",
  "& img": {
    maxWidth: "160px",
  },
} as const;

const formStyles = {
  maxWidth: "340px",
  display: "flex",
  flexDirection: "column",
  width: "100%",
  alignItems: "center",
} as const;

const bottomStyles = {
  mt: 2,
  display: "flex",
  alignItems: "center",
};

export default observer(Register);
