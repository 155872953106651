import { useRootStore } from "hooks/useRootStore";;
import React, { FC, PropsWithChildren } from "react";
import { useTranslation } from "react-i18next";
import { LoadingButton } from "@mui/lab";
import { Stack } from "@mui/material";

import { LOCALES } from "constants/locales";

type ConfirmAction = () => void | Promise<void>;

interface ConfirmDialogPropsWithoutChildren {
  onConfirm: ConfirmAction;
  onCancel?: ConfirmAction;
  confirmText?: string;
  cancelText?: string;
  isConfirmingInProgress?: boolean;
  isCancelingInProgress?: boolean;
}

export type ConfirmDialogProps = PropsWithChildren<ConfirmDialogPropsWithoutChildren>;

const ConfirmDialog: FC<ConfirmDialogProps> = ({
  children,
  onConfirm,
  onCancel,
  confirmText,
  cancelText,
  isConfirmingInProgress,
  isCancelingInProgress,
}) => {
  const { t } = useTranslation();
  const { uiStore } = useRootStore();

  const handleAction = (action?: ConfirmAction) => async () => {
    await action?.();
    uiStore.closeLastModal();
  };

  return (
    <Stack spacing={2}>
      {children ?? t(LOCALES.GENERAL.CONFIRMATION_QUESTION)}
      <Stack alignSelf={"flex-end"} direction={"row"} spacing={1}>
        <LoadingButton onClick={handleAction(onCancel)} loading={isCancelingInProgress}>
          {cancelText ? t(cancelText) : t(LOCALES.GENERAL.CANCEL)}
        </LoadingButton>
        <LoadingButton variant={"contained"} onClick={handleAction(onConfirm)} loading={isConfirmingInProgress}>
          {confirmText ? t(confirmText) : t(LOCALES.GENERAL.CONFIRM)}
        </LoadingButton>
      </Stack>
    </Stack>
  );
};

export default ConfirmDialog;
