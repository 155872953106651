import React, { FC } from "react";
import { observer } from "mobx-react-lite";

import { IParameters, ParameterType } from "stores/managerService/processingStore/types";

import { useRootStore } from "hooks/useRootStore";

import { Boolean } from "./Boolean";
import { DxfCsvFiles } from "./DxfCsvFiles";
import { Float } from "./Float";
import { MultipleLasFile } from "./MultipleLasFile";
import { MultipleSelect } from "./MultipleSelect";
import { SingleSelect } from "./SingleSelect";

export const Inputs: FC<{ parameter: IParameters }> = observer(({ parameter }) => {
  const { fileStore } = useRootStore();
  const { dxfFilesOfProcessing, csvFilesOfProcessing } = fileStore;
  const { parameterType } = parameter;

  switch (parameterType) {
    case ParameterType.singleSelect:
      return <SingleSelect parameter={parameter} />;
    case ParameterType.float:
      return <Float parameter={parameter} />;
    case ParameterType.boolean:
      return <Boolean parameter={parameter} />;
    case ParameterType.multipleSelect:
      return <MultipleSelect parameter={parameter} />;
    case ParameterType.dxfFile:
      return <DxfCsvFiles parameter={parameter} files={dxfFilesOfProcessing} />;
    case ParameterType.csvFile:
      return <DxfCsvFiles parameter={parameter} files={csvFilesOfProcessing} />;
    case ParameterType.multipleLasFile:
      return <MultipleLasFile parameter={parameter} />;
    default:
      return null;
  }
});
