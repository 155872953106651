import React, { FC } from "react";
import ArrowForwardIosRoundedIcon from "@mui/icons-material/ArrowForwardIosRounded";
import Button from "@mui/material/Button";
import { ExpandProps } from "types";

import { buttonStyles } from "./CreateIconButton";

const FORWARD = 0;
const BACKWARD = 180;

const ExpandIconButton: FC<ExpandProps> = ({ open, onOpen, onClose }) => {
  return (
    <Button color="secondary" sx={{ ...buttonStyles }} onClick={open ? onClose : onOpen} disableRipple>
      <ArrowForwardIosRoundedIcon
        sx={{
          transform: `rotate(${open ? BACKWARD : FORWARD}deg)`,
        }}
        fontSize={"large"}
      />
    </Button>
  );
};

export default ExpandIconButton;
