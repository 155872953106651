import Fuse from "fuse.js";
import { makeAutoObservable, runInAction } from "mobx";

import RootStore from "stores/rootStore";
import comments from "stores/taskStore/comments";
import * as tasks from "stores/taskStore/service";

import {
  Comment,
  CommentType,
  ProcessType,
  Task,
  TaskCreateParams,
  TaskDto,
  TaskForeignType,
  TaskParamsForUpdate,
  TaskPriority,
  TaskStatus,
} from "./types";

export interface Filters {
  processType: ProcessType | null;
  priority: TaskPriority | null;
  createdAtDateStart: Date | null;
  createdAtDateEnd: Date | null;
  dueDateAtDateStart: Date | null;
  dueDateAtDateEnd: Date | null;
  isCreator: boolean;
  isAssigned: boolean;
  isReviewer: boolean;
}

const DEFAULT_FILTERS: Filters = {
  processType: null,
  priority: null,
  createdAtDateStart: null,
  createdAtDateEnd: null,
  dueDateAtDateStart: null,
  dueDateAtDateEnd: null,
  isCreator: false,
  isAssigned: false,
  isReviewer: false,
};

export class TaskStore {
  tasksByAssignedTo: Task[] | null = null;
  tasks: Task[] | null = null;
  task: TaskDto | null = null;
  comments: Comment[] | null = null;

  search: string | null = null;
  filters: Filters = DEFAULT_FILTERS;

  public readonly resetFilters = () => {
    this.filters = DEFAULT_FILTERS;
  };

  public readonly setSearch = (value: string | null) => {
    if (value === "") {
      this.search = null;
    } else {
      this.search = value;
    }
  };

  public readonly setFilter = <T extends keyof Partial<Filters>>(filterName: T, value: Filters[T]) => {
    const temp = { ...this.filters };
    temp[filterName] = value;
    this.filters = temp;
  };

  private get filteredTasks(): Task[] {
    let res = this.tasks ?? [];

    if (this.filters.processType !== null) {
      res = res.filter(t => t.processType === this.filters.processType);
    }

    if (this.filters.priority !== null) {
      res = res.filter(t => t.priority === this.filters.priority);
    }

    if (this.filters.createdAtDateStart !== null) {
      res = res.filter(t => t.createdAt >= this.filters.createdAtDateStart!.getTime());
    }

    if (this.filters.createdAtDateEnd !== null) {
      res = res.filter(t => t.createdAt <= this.filters.createdAtDateEnd!.getTime());
    }

    if (this.filters.dueDateAtDateStart !== null) {
      res = res.filter(
        t => t.dueDate === undefined || t.dueDate.getTime() >= this.filters.dueDateAtDateStart!.getTime()
      );
    }

    if (this.filters.dueDateAtDateEnd !== null) {
      res = res.filter(t => t.dueDate === undefined || t.dueDate.getTime() <= this.filters.dueDateAtDateEnd!.getTime());
    }

    const currentUserId = this.rootStore.authStore.userName;
    if (this.filters.isCreator) {
      res = res.filter(t => t.createdBy === currentUserId);
    }

    if (this.filters.isAssigned) {
      res = res.filter(t => t.assignedTo === currentUserId);
    }

    if (this.filters.isReviewer) {
      res = res.filter(t => t.reviewBy === currentUserId);
    }

    return res;
  }

  private get fuse() {
    return new Fuse(this.filteredTasks, {
      includeScore: true,
      keys: ["title"],
    });
  }

  public get searchedTasks(): Task[] {
    return this.search === null ? this.filteredTasks : this.fuse.search(this.search).map(i => i.item);
  }

  public get todayTasks(): Task[] | null {
    const currentDate = new Date();
    return (
      this.tasksByAssignedTo?.filter(task => {
        if (task.dueDate && task.status !== TaskStatus.COMPLETED) {
          const dueDate = new Date(task.dueDate);
          return dueDate.getDate() === currentDate.getDate();
        }
        return false;
      }) ?? null
    );
  }

  public get overdueTasks(): Task[] | null {
    return (
      this.tasksByAssignedTo?.filter(
        task =>
          task.dueDate &&
          new Date(task.dueDate).getDate() < new Date().getDate() &&
          task.status !== TaskStatus.COMPLETED
      ) ?? null
    );
  }

  public get recentlyAssignedTasks(): Task[] | null {
    return (
      this.tasksByAssignedTo?.filter(
        task =>
          (task.dueDate &&
            new Date(task.dueDate).getDate() > new Date().getDate() &&
            task.status !== TaskStatus.COMPLETED) ||
          !task.dueDate
      ) ?? null
    );
  }

  constructor(private readonly rootStore: RootStore) {
    makeAutoObservable(this);
  }

  setTasks = (tasks: Task[] | null) => {
    this.tasks = tasks;
  };

  setTasksByAssignedTo = (tasksByAssinedTo: Task[] | null) => {
    this.tasksByAssignedTo = tasksByAssinedTo;
  };

  setComments(comments: null | Comment[]) {
    this.comments = comments;
  }

  public readonly getAllTasks = async (
    queryObj: { constructionSiteId?: string; acl?: string },
    shouldFetchAffiliationTitles = false
  ): Promise<Task[]> => {
    const response = await tasks.getAllTasks(queryObj);

    const filteredTasks = response.filter((task: Task) => !task.removedAt);

    let taskToSet: Task[] = filteredTasks;

    if (shouldFetchAffiliationTitles) {
      try {
        taskToSet = await this.fetchForeignTitles(filteredTasks);
      } catch (e) {}
    }

    taskToSet.forEach((task: TaskDto) => {
      if (task.dueDate) {
        task.dueDate = new Date(task.dueDate);
      }
    });
    this.setTasks(taskToSet);

    return taskToSet;
  };

  getAllByAssignedTo = async (userId: string) => {
    const response = await tasks.getAllByAssignedTo(userId);
    const existTasks = response.filter((task: Task) => !task.removedAt);
    const tasksWithForeignTitles = await this.fetchForeignTitles(existTasks);
    tasksWithForeignTitles
      .sort((a: TaskDto, b: TaskDto) => b.createdAt - a.createdAt)
      .forEach((task: TaskDto) => {
        if (task.dueDate) {
          task.dueDate = new Date(task.dueDate);
        }
      });

    this.setTasksByAssignedTo(tasksWithForeignTitles);
  };

  createTask = async (params: Omit<TaskCreateParams, "dueDate"> & { dueDate?: Date }) => {
    const { dueDate: dueDateFromParams, ...otherParams } = params;
    const dueDate = dueDateFromParams ? dueDateFromParams.getTime() : undefined;
    await tasks.create({ ...otherParams, ...(dueDate ? { dueDate } : {}) });
    this.rootStore.uiStore.closeLastModal();
    await this.getAllTasks({ constructionSiteId: params.constructionSite });
  };

  deleteTask = async (foreignId: string, id: string) => {
    const response = await tasks.deleteTask(foreignId, id);
    if (response.status === "ok") {
      runInAction(() => {
        if (this.tasks) {
          const index = this.tasks.findIndex(task => task.id === id);
          this.tasks.splice(index, 1);
        }
        if (this.tasksByAssignedTo) {
          const index = this.tasksByAssignedTo.findIndex(task => task.id === id);
          this.tasksByAssignedTo.splice(index, 1);
        }
      });
    }
  };

  updateTask = async (
    foreignId: string,
    id: string,
    name: TaskParamsForUpdate,
    value: string | number | string[],
    dateString?: Date | null
  ) => {
    const response = await tasks.updateTask(foreignId, id, name, value);
    if (response.data.status === "ok" && this.tasks) {
      const index = this.tasks.findIndex(task => task.id === id);
      if (index >= 0) {
        runInAction(() => {
          this.tasks![index][name] = name === "dueDate" ? dateString : value;
        });
      }
    }
    if (response.data.status === "ok" && this.tasksByAssignedTo) {
      const index = this.tasksByAssignedTo.findIndex(task => task.id === id);
      if (index >= 0) {
        runInAction(() => {
          this.tasksByAssignedTo![index][name] = name === "dueDate" ? dateString : value;
        });
      }
    }
  };

  getAllComments = async (foreignId: string) => {
    const response = await comments.getAll(foreignId);
    const sortedByDate = response.data.sort((a, b) => a.createdAt - b.createdAt);
    this.setComments(sortedByDate);
  };

  createComment = async (foreignId: string, acl: string, content: string, commentType?: CommentType) => {
    const response = await comments.create(foreignId, acl, content, commentType);
    await this.getAllComments(foreignId);
    return response;
  };

  public deleteComment = async (taskId: string, id: string): Promise<void> => {
    await comments.delete(taskId, id);
    await this.getAllComments(taskId);
  };

  private fetchForeignTitles = async (tasks: TaskDto[]): Promise<Task[]> => {
    return await Promise.all(tasks.map(async t => await this.fetchAndSetForeignTitle(t)));
  };

  private fetchAndSetForeignTitle = async (task: TaskDto): Promise<Task> => {
    let foreignTitle = null;
    if (task.foreignType !== undefined) {
      try {
        const fetchTitle = this.fetchForeignTitleFunctions[task.foreignType];
        foreignTitle = await fetchTitle(task.foreignId);
      } catch (e) {}
    }

    return { ...task, foreignTitle };
  };

  private readonly fetchForeignTitleFunctions: Record<TaskForeignType, (foreignId: string) => Promise<string | null>> =
    {
      AREA: async foreignId => (await this.rootStore.areaStore.getArea(foreignId)).title,
      CONSTRUCTION_SITE: async foreignId =>
        (await this.rootStore.constructionSiteStore.getConstructionSite(foreignId))?.title ?? null,
      JOB: async foreignId => (await this.rootStore.jobStore.getJob(foreignId)).title,
    };
}
