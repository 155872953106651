import { makeAutoObservable } from "mobx";

import RootStore from "../rootStore";
import { handleApiResponse } from "../utils";

import * as userService from "./service";
import { SearchByEmailUserItem, SearchByUserIdsItem, UploadAvatarDto } from "./types";

const ID_SEPARATOR = ",";

export class UsersStore {
  constructor(private readonly rootStore: RootStore) {
    makeAutoObservable(this);
  }

  public readonly searchUsersByEmail = async (email: string): Promise<SearchByEmailUserItem[] | null> => {
    const res = await userService.searchUsersByEmail(email);
    return handleApiResponse(res);
  };

  public readonly searchUsersByIds = async (userIds: string | string[]): Promise<SearchByUserIdsItem[] | null> => {
    const userIdsString = Array.isArray(userIds) ? userIds.join(ID_SEPARATOR) : userIds;
    const res = await userService.searchUsersByUserIds(userIdsString);
    return handleApiResponse(res);
  };

  public readonly getUserById = async (userId: string): Promise<SearchByUserIdsItem | null> => {
    const res = await userService.getOneById(userId);
    return handleApiResponse(res);
  };

  public readonly inviteByEmail = async (email: string): Promise<unknown | null> => {
    const res = await userService.inviteByEmail({ email });
    return handleApiResponse(res);
  };

  public readonly uploadAvatar = async (userId: string, avatar: File): Promise<UploadAvatarDto | null> => {
    const avatarData = Buffer.from(await avatar.arrayBuffer());
    const res = await userService.uploadAvatar(userId, avatarData, avatar.type);
    return handleApiResponse(res);
  };

  public readonly deleteUser = async (userId: string): Promise<unknown | null> => {
    const res = await userService.deleteUser(userId);
    return handleApiResponse(res);
  };
}
