import axios from "axios";

import { baseUrl, protocol, Service } from "configs/servicesConfig";

import { Job } from "./types";

const { PROJECT } = Service;

const jobs = {
  getAll: async (area: string): Promise<Job[]> => {
    const response = await axios.get<{ payload: [] }>(`${protocol}//${baseUrl}/${PROJECT}/jobs?area=${area}`);
    return response.data.payload;
  },

  getById: async (id: string) => {
    const response = await axios.get<{ payload: Job }>(`${protocol}//${baseUrl}/${PROJECT}/jobs/${id}`);

    if (response.data) {
      return response.data.payload;
    } else {
      // eslint-disable-next-line @typescript-eslint/no-throw-literal
      throw response;
    }
  },

  create: async (area: string, title: string, description: string, graphic: JSON) => {
    const map = JSON.stringify(graphic);
    const params = () => {
      if (description) {
        return {
          area,
          title,
          description,
          map,
        };
      }
      return { area, title, map };
    };
    const response = await axios.post(`${protocol}//${baseUrl}/${PROJECT}/jobs`, params());
    return response as { data: { payload?: Job } };
  },

  update: async (id: string, name: string, value: string) => {
    const response = await axios.put(`${protocol}//${baseUrl}/${PROJECT}/jobs/${id}`, {
      [name]: value,
    });
    if (response.data.status === "ok") {
      return response as { data: { payload?: Job } };
    }
  },

  delete: async (id: string, recycledComment: string) => {
    return await axios.delete(`${protocol}//${baseUrl}/${PROJECT}/jobs/${id}/recycle`, {
      data: { recycledComment },
    });
  },
};

export default jobs;
