import { useRootStore } from "hooks/useRootStore";
import React, { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import Button from "@mui/material/Button";
import Fade from "@mui/material/Fade";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";

import { PROCESSINGS, Processings } from "stores/managerService/processingStore/types";
import { onboardingStore } from "stores/onboardingStore";

const CreationForm: FC<{ jobId: string }> = ({ jobId }) => {
  const { t } = useTranslation();
  const { uiStore, processingStore } = useRootStore();

  const { isOnboardingRunning } = onboardingStore;

  const [title, setTitle] = useState("");
  const [type, setType] = useState<Processings>(Processings.PIX4D_VIEW);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    createAndGetAll();
    uiStore.closeLastModal();

    if (isOnboardingRunning) {
      onboardingStore.nextStep();
    }
  };

  const createAndGetAll = async () => {
    await processingStore.create(title, type, jobId);
    await processingStore.getAllProcessings(jobId);
  };
  return (
    <>
      <Fade in>
        <form onSubmit={handleSubmit}>
          <TextField
            id="title"
            label={t("General:Title")}
            fullWidth
            sx={{ mt: 1.5 }}
            required
            value={title}
            onChange={e => setTitle(e.target.value)}
            inputProps={{ maxLength: 255 }}
          />
          <FormControl sx={{ my: 1.5 }} fullWidth required>
            <InputLabel id="type">{t("Processings:Type")}</InputLabel>
            <Select
              labelId="type"
              value={type}
              label={`${t("Processings:Type")} *`}
              onChange={e => setType(e.target.value as Processings)}
              IconComponent={KeyboardArrowDownRoundedIcon}>
              {PROCESSINGS.map(type => {
                if (type !== Processings.VIPOINT_OUTPUT && type !== Processings.AGISOFT)
                  return (
                    <MenuItem value={type} key={type}>
                      {t(`Processings:${type}`)}
                    </MenuItem>
                  );
              })}
            </Select>
          </FormControl>
          <Button color="secondary" variant="contained" fullWidth type="submit">
            {t("General:Submit")}
          </Button>
        </form>
      </Fade>
    </>
  );
};

export default CreationForm;
