export enum GetTeamBy {
  RESOURCE = "resource", //csId, areaId
  ACL = "acl",
}
export enum TeamRole {
  OWNER = "OWNER",
  ADMIN = "ADMIN",
  COORDINATOR = "COORDINATOR",
  MEMBER = "MEMBER",
  GUEST = "GUEST",
}

export interface Member {
  acl: string;
  id: string;
  userId: string;
  email: string;
  role: TeamRole;
  createdAt: Date;
  invitedBy: string;
  family_name?: string;
  given_name?: string;
  picture?: string;
  "custom:picture-mini"?: string;
}

export type UserPermissionsTypes = "creating" | "reading" | "updating" | "deleting";
export type UserPermissions = Record<UserPermissionsTypes, boolean>;
