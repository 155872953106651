import { Box } from "@mui/material";
import { Loader } from "common/Loader";

export const LoadingIndicatorOverlay: React.FC = (): React.ReactElement => {
  return (
    <Box sx={containerStyles}>
      <Loader />
    </Box>
  );
};

const containerStyles = {
  position: "absolute",
  top: 0,
  right: 0,
  left: 0,
  bottom: 0,
};
