import { makeAutoObservable } from "mobx";

import service from "stores/managerService/teamStore/service";
import RootStore from "stores/rootStore";
import userService from "stores/settingStore/service";

import { GUEST_PERMISSIONS, ROLES_THAT_CAN } from "./constants";
import { GetTeamBy, Member, TeamRole, UserPermissions } from "./types";

export class TeamStore {
  constructor(private readonly rootStore: RootStore) {
    makeAutoObservable(this);
  }

  public constructionSiteTeamMembers: null | Member[] = null;
  protectedAreaMembers: null | Member[] = null;

  public get constructionSiteTeamMembersMap(): Record<string, Member> {
    const membersMap: Record<string, Member> = {};

    this.constructionSiteTeamMembers?.forEach(m => (membersMap[m.userId] = m));

    return membersMap;
  }

  public get protectedAreaMembersMap(): Record<string, Member> {
    const membersMap: Record<string, Member> = {};

    this.protectedAreaMembers?.forEach(m => (membersMap[m.userId] = m));

    return membersMap;
  }

  public get isUserAdminOrOwner(): boolean {
    return this.currentUserRoleInCS === TeamRole.ADMIN || this.currentUserRoleInCS === TeamRole.OWNER;
  }

  public currentUserRoleInCS = TeamRole.GUEST;
  public currentUserRoleInProtectedArea = TeamRole.GUEST;
  public csPermissions: UserPermissions = GUEST_PERMISSIONS;
  public protectedAreaPermissions = GUEST_PERMISSIONS;

  setProtectedAreaMembers = (members: Member[] | null) => {
    this.protectedAreaMembers = members;
  };

  public setConstructionSiteTeamMembers = (team: null | Member[]): void => {
    this.constructionSiteTeamMembers = team;
  };

  public getCurrentUserRoleInCS = async (resourceId: string): Promise<void> => {
    const currentTeam = this.constructionSiteTeamMembers ?? (await service.getAll(GetTeamBy.RESOURCE, resourceId));
    currentTeam.forEach((member: Member) => {
      if (member.userId === this.rootStore.authStore.userName) {
        this.setCurrentUserRoleInCS(member.role);
        this.setUserCSPermissions(member.role);
      }
    });
  };

  private readonly setCurrentUserRoleInCS = (value: TeamRole): void => {
    this.currentUserRoleInCS = value;
  };

  private readonly setUserCSPermissions = (role: TeamRole) => {
    this.csPermissions = this.getUserPermissionsByRole(role);
  };

  private readonly setUserProtectedAreaPermissions = (role: TeamRole) => {
    this.protectedAreaPermissions = this.getUserPermissionsByRole(role);
  };

  public readonly getCurrentUserRoleInProtectedArea = (team: Member[]) => {
    team.forEach((member: Member) => {
      if (member.userId === this.rootStore.authStore.userName) {
        this.setCurrentUserRoleInProtectedArea(member.role);
        this.setUserProtectedAreaPermissions(member.role);
      }
    });
  };

  setCurrentUserRoleInProtectedArea = (role: TeamRole) => {
    this.currentUserRoleInProtectedArea = role;
  };

  public getPersonWhoInvited = (id: string): Member | null => {
    return this.constructionSiteTeamMembers?.find(member => member.userId === id) ?? null;
  };

  public getTeam = async (getBy: GetTeamBy, id: string): Promise<Member[]> => {
    const team = await service.getAll(getBy, id);
    const ids = team.map((member: Member) => member.userId);
    const members = await userService.get(ids);
    team.forEach((member: Member, index) => {
      Object.assign(member, members[index]);
      member.createdAt = new Date(member.createdAt);
    });
    return team;
  };

  getCSTeamMembers = async (getBy: GetTeamBy, id: string) => {
    this.setProtectedAreaMembers(null);
    const team = await this.getTeam(getBy, id);
    this.setConstructionSiteTeamMembers(team);
    await this.getCurrentUserRoleInCS(id);
  };

  getProtectedAreaMembers = async (getBy: GetTeamBy, id: string) => {
    const team = await this.getTeam(getBy, id);
    this.setProtectedAreaMembers(team);
    this.getCurrentUserRoleInProtectedArea(team);
  };

  resetMembers = () => {
    this.setConstructionSiteTeamMembers(null);
    this.setProtectedAreaMembers(null);
  };

  public inviteUser = async (email: string, role: string, resourceId: string): Promise<Member[]> => {
    const username = await this.getUsername(email);
    await service.invite(username!, role, resourceId);
    this.rootStore.uiStore.closeLastModal();
    return await this.getTeam(GetTeamBy.RESOURCE, resourceId);
  };

  inviteUserToCS = async (email: string, role: string, resourceId: string) => {
    const response = await this.inviteUser(email, role, resourceId);
    this.setConstructionSiteTeamMembers(response);
  };

  inviteUserToProtectedArea = async (email: string, role: string, resourceId: string) => {
    const response = await this.inviteUser(email, role, resourceId);
    this.setProtectedAreaMembers(response);
  };

  public getUsername = async (email: string): Promise<string | null> => {
    const response = await userService.checkIfEmailExists(email);
    if (response[0]) {
      return response[0].username;
    } else {
      const response = await userService.createUser(email);
      return response.data.payload?.username ?? null;
    }
  };

  public changeRole = async (id: string, role: TeamRole, isProtectedArea: boolean) => {
    const response = await service.set(id, role);
    if (response.data.status === "ok") {
      if (isProtectedArea) {
        this.protectedAreaMembers?.forEach(member => {
          if (member.id === id) {
            member.role = role;
          }
        });
      } else {
        this.constructionSiteTeamMembers?.forEach(member => {
          if (member.id === id) {
            member.role = role;
          }
        });
      }
    }
  };

  public removeUser = async (id: string, resourceId: string): Promise<Member[]> => {
    await service.delete(id);
    return await this.getTeam(GetTeamBy.RESOURCE, resourceId);
  };

  removeUserFromCSTeamMembers = async (id: string, resourceId: string) => {
    const response = await this.removeUser(id, resourceId);
    this.setConstructionSiteTeamMembers(response);
  };

  removeUserFromProtectedAreaMembers = async (id: string, resourceId: string) => {
    const response = await this.removeUser(id, resourceId);
    this.setProtectedAreaMembers(response);
  };

  private readonly getUserPermissionsByRole = (role: TeamRole): UserPermissions => {
    return {
      creating: ROLES_THAT_CAN.creating.includes(role),
      deleting: ROLES_THAT_CAN.deleting.includes(role),
      reading: ROLES_THAT_CAN.reading.includes(role),
      updating: ROLES_THAT_CAN.updating.includes(role),
    };
  };
}
