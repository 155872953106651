import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { Stack } from "@mui/material";
import TextField from "@mui/material/TextField";

import { Filters, TaskStore } from "stores/taskStore";

import { LOCALES } from "constants/locales";

import TasksFilters from "./TasksFilters";

interface TasksFiltersProps {
  search: string | null;
  setSearch: (value: string | null) => void;
  filters: Filters;
  setFilter: TaskStore["setFilter"];
  resetFilters: () => void;
}

const TasksSearchAndFilters: FC<TasksFiltersProps> = ({ search, setSearch, filters, setFilter, resetFilters }) => {
  const { t } = useTranslation();

  return (
    <Stack direction={"row"} spacing={1} ml={3.3} mt={1}>
      <TextField
        size={"small"}
        label={t(LOCALES.TASKS.SEARCH_BY_TITLE)}
        value={search}
        onChange={e => setSearch(e.target.value)}
      />
      <TasksFilters filters={filters} setFilter={setFilter} resetFilters={resetFilters} />
    </Stack>
  );
};

export default TasksSearchAndFilters;
