import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import LocalParkingIcon from "@mui/icons-material/LocalParking";
import { Box, Typography } from "@mui/material";

import { ProcessType } from "stores/taskStore/types";

import ProcessTypesSelect from "./ProcessTypesSelect";

interface ProcessTypesFieldProps {
  processType: ProcessType;
  setProcessType: (p: ProcessType | null) => void;
  isAllowedToEdit: boolean;
}

export const ProcessTypesField: FC<ProcessTypesFieldProps> = ({ isAllowedToEdit, ...props }) => {
  const { t } = useTranslation();

  return (
    <Box sx={wrapperStyles}>
      <LocalParkingIcon />
      <Typography variant="body1" component="div" fontWeight={600} sx={{ ml: 1.2 }}>
        {t("Tasks:ProcessType")}
      </Typography>
      <ProcessTypesSelect {...props} disabled={!isAllowedToEdit} sx={selectorStyles} />
    </Box>
  );
};

const selectorStyles = {
  ml: 1.5,
  maxWidth: "100%",
  "& .MuiOutlinedInput-root": { pr: 0 },
  "& .MuiSelect-select.MuiInputBase-input": {
    py: 0.8,
    height: "16px",
    minHeight: "16px",
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: "transparent",
  },
};

const wrapperStyles = {
  display: "flex",
  alignItems: "center",
  height: "32px",
  mt: 0.5,
};
