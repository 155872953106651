import { EffectCallback } from "react";
import { Step as JoyrideStep } from "react-joyride";

import { ErrorI18KeyAndOptions } from "stores/managerService/helpers/checkGraphics";

export const enum OnboardingSelector {
  MAP_VIEW_CONTAINER = "viewDiv",
  CONSTRUCTION_SITES_BOTTOM_PANEL = "construction-sites-bottom-panel",
  CREATE_NEW_CONSTRUCTION_SITE_BUTTON = "create-new-construction-bite-button",
  MAP_TOOLBAR = ".esri-component.esri-sketch.esri-widget",
  MAP_TOOLBAR_TRASH_BUTTON = "calcite-action[icon = trash]",
  MAP_TOOLBAR_RECTANGLE_BUTTON = "calcite-action[icon = rectangle]",
  START_CREATING_CONSTRUCTION_SITE = "start-creating-construction-site",
  COMMON_MODAL = "common-modal",
  ADD_NEW_AREA = "add-new-area",
  ADD_NEW_JOB = "add-new-job",
  JOBS_LIST_ITEM = "jobs-list-item",
  ADD_NEW_PROCESSING = "add-new-processing",
  PROCESSING_FILES = "processing-files",
  KML_UPLOAD_BUTTON = "kml-upload-button",
}

export type Prefix = "#" | ".";
export interface Step extends JoyrideStep {
  target: OnboardingSelector;
  prefix?: Prefix;
  callback?: EffectCallback;
  checkBeforeNextStep?: () => (ErrorI18KeyAndOptions | undefined) | Promise<ErrorI18KeyAndOptions | undefined>;
  resetCallback?: () => Promise<void>;
  onClose?: () => Promise<void>;
}
