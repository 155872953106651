import { useRootStore } from "hooks/useRootStore";;
import React, { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import CloseIcon from "@mui/icons-material/Close";
import { Box } from "@mui/material";
import Button from "@mui/material/Button";
import Fade from "@mui/material/Fade";
import Typography from "@mui/material/Typography";
import { observer } from "mobx-react-lite";

import { thumbStyles } from "constants/styles";

import { CommonParams } from "./CommonParams";
import { Inputs } from "./Inputs";

export const ViPointProcessing: FC<{ id: string }> = observer(({ id }) => {
  const { t } = useTranslation();
  const { fileStore, processingStore, uiStore } = useRootStore();
  const { parameters, vipointProcessingLaunched, script, resetProcessingParams } = processingStore;
  const { reuseFiles } = fileStore;

  useEffect(() => {
    return () => {
      resetProcessingParams();
    };
  }, [resetProcessingParams]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (script == "23") {
      const response = await reuseFiles();
      const fileKeys = response.map(f => f.storageMeta.key);
      processingStore.setUpdatedParams(95, fileKeys);
      await processingStore.initialViPointProcessing(id);
    } else await processingStore.initialViPointProcessing(id);
  };

  return (
    <React.Fragment>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}>
        <Box>
          <Button
            size="small"
            variant="contained"
            color="secondary"
            sx={{ mt: 1.5, px: 0.5 }}
            onClick={() => {
              uiStore.closeLastModal();
            }}>
            <CloseIcon fontSize="small" />
            <Typography variant="caption" sx={{ ml: 0.25, mr: 0.5 }}>
              {t("Processings:Close")}
            </Typography>
          </Button>
        </Box>
        <Box>
          <Button disabled={vipointProcessingLaunched} type="submit" form="params" color="primary" variant="contained">
            {t("General:Start")}
          </Button>
        </Box>
      </Box>
      <Fade in>
        <form id="params" onSubmit={handleSubmit}>
          <Box
            sx={{
              display: "grid",
              gridTemplateRows: "10fr 1fr",
              height: "100%",
              margin: 0,
            }}>
            <Box sx={{ flex: 10, display: "flex" }}>
              <CommonParams />
              <Box sx={parameterStyles()}>
                {script && parameters ? (
                  parameters?.map(parameter => <Inputs parameter={parameter} key={parameter.parameterId} />)
                ) : (
                  <Box
                    sx={{
                      display: "grid",
                      height: "100%",
                      justifyContent: "center",
                      alignItems: "center",
                    }}>
                    <Typography paragraph variant="caption" sx={{ ml: 0.25, mr: 0.5 }}>
                      {t(`Processings:${(vipointProcessingLaunched && "DataIsSent") || "SelectFunction"}`)}
                    </Typography>
                  </Box>
                )}
              </Box>
            </Box>
          </Box>
        </form>
      </Fade>
    </React.Fragment>
  );
});

const parameterStyles = () => ({
  flex: 2,
  overflowX: "hidden",
  height: "500px",
  ...thumbStyles,
  "& .MuiPaper-root + .MuiTypography-root": {
    mt: "15px",
  },
});
