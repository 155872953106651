import { useRootStore } from "hooks/useRootStore";
import React, { FC, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { Link as RouterLink } from "react-router-dom";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Fade from "@mui/material/Fade";
import Link from "@mui/material/Link";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { observer } from "mobx-react-lite";

import LanguageSelector from "components/Sidebar/LanguageSelector";
import { absolutePath, PATHS } from "router/paths";

import NewPassword from "./NewPassword";

const ForgotPassword: FC = () => {
  const { t } = useTranslation();
  const { authStore } = useRootStore();

  const { values, inProgress, success, error, cognitoUser } = authStore;

  const navigate = useNavigate();

  useEffect(() => {
    if (cognitoUser) {
      navigate(absolutePath(PATHS.HOME.INDEX));
    }
  }, []);

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => authStore.setEmail(e.target.value);
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    authStore.forgotPassword().then(() => {
      success.forgotPassword = true;
    });
  };

  return (
    <Fade in>
      <Box sx={containerStyles}>
        <img src="/images/logo.svg" alt="Logo" />
        {success.forgotPassword ? (
          <NewPassword />
        ) : (
          <Box component="form" onSubmit={handleSubmit} sx={formStyles}>
            <Box sx={{ alignSelf: "flex-end", mt: 4 }}>
              <LanguageSelector displayFlag={false} />
            </Box>
            <Typography sx={messageStyles} variant="subtitle1">
              {t("Auth:EnterEmailMessage")}
            </Typography>
            <Typography sx={{ my: 1 }} variant="body1" color="error">
              {error}
            </Typography>
            <TextField
              required
              fullWidth
              variant="standard"
              id="email"
              label={t("General:Email")}
              type="email"
              name="email"
              autoComplete="email"
              value={values.email}
              onChange={handleEmailChange}
            />
            <Link
              component={RouterLink}
              to="/login"
              variant="body2"
              underline="hover"
              sx={{ mt: 2, color: "text.primary" }}>
              {t("Auth:GoBackToLogin")}
            </Link>
            <Button
              type="submit"
              fullWidth
              color="secondary"
              variant="contained"
              sx={{ mt: 4 }}
              disabled={inProgress}
              disableElevation>
              {t("Auth:ResetPassword")}
            </Button>
          </Box>
        )}
      </Box>
    </Fade>
  );
};

const containerStyles = {
  width: "100%",
  height: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  bgcolor: "background.default",
  "& img": {
    maxWidth: "160px",
  },
} as const;

const formStyles = {
  maxWidth: "340px",
  display: "flex",
  flexDirection: "column",
  width: "100%",
  alignItems: "center",
} as const;

const messageStyles = { mt: 2, color: "text.primary" };

export default observer(ForgotPassword);
