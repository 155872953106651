import axios from "axios";

import { baseUrl, protocol } from "configs/servicesConfig";
import { CommentType, Comment } from "stores/taskStore/types";

interface CreateData {
  foreignId: string;
  acl: string;
  content: string;
}

interface CreateResponse {
  data: { status: string };
}

interface IGetAllResponse {
  payload: {
    count: number;
    data: Comment[];
  };
}

const comments = {
  getAll: async (foreignId: string) => {
    const response = await axios.get<IGetAllResponse>(`${protocol}//${baseUrl}/taskService/comments/${foreignId}`);
    return response.data.payload;
  },

  create: async (foreignId: string, acl: string, content: string, commentType?: CommentType) => {
    const response = await axios.post<CreateData, CreateResponse>(`${protocol}//${baseUrl}/taskService/comments`, {
      foreignId,
      acl,
      content,
      commentType,
    });
    return response.data;
  },

  delete: async (foreignId: string, commentId: string) => {
    const { data } = await axios.delete(`${protocol}//${baseUrl}/taskService/comments/${foreignId}/${commentId}`);
    return data;
  },
};

export default comments;
