import React, { CSSProperties, FC, PropsWithChildren, useCallback, useMemo, useState } from "react";
import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";
import { Box, Button, Paper, SxProps, Theme } from "@mui/material";
import { ExpandProps } from "types";

import { getExpandRotationStyles } from "constants/styles";

import { CARD_CLOSED_HEIGHT, CARD_WIDTH } from "./constants";

const CardExpandingContainer: FC<PropsWithChildren<Partial<ExpandProps>>> = ({
  children,
  closedHeight = CARD_CLOSED_HEIGHT,
  open,
  onOpen,
  onClose,
}) => {
  const [expanded, setExpanded] = useState(true);

  const isOpen = useMemo(() => (open === undefined ? expanded : open), [open, expanded]);
  const handleClick = useCallback(() => {
    if (isOpen) {
      onClose?.() ?? setExpanded(false);
    } else {
      onOpen?.() ?? setExpanded(true);
    }
  }, [isOpen, onClose, onOpen]);

  return (
    <Paper sx={paperContainerStyles(closedHeight, isOpen)}>
      <Box sx={cardBodyStyles}>{children}</Box>
      <Button onClick={handleClick} sx={expandButton}>
        <ExpandMoreRoundedIcon sx={getExpandRotationStyles(isOpen)} fontSize={"small"} />
      </Button>
    </Paper>
  );
};

const paperContainerStyles =
  (closedHeight: CSSProperties["maxHeight"], isOpen: boolean): SxProps<Theme> =>
  theme => ({
    zIndex: 10,
    flexDirection: "column",
    position: "relative",
    display: "flex",
    width: CARD_WIDTH,
    p: 1,
    boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.2)",
    bgcolor: "background.default",
    maxHeight: isOpen ? "100%" : closedHeight,
    transition: theme.transitions.create("max-height", { duration: ".5s" }),
  });

const cardBodyStyles: SxProps<Theme> = {
  overflow: "hidden",
  height: "100%",
  flex: 1,
};

const expandButton: SxProps<Theme> = {
  position: "absolute",
  bottom: -20,
  zIndex: 1,
  left: "40%",
  height: 30,
  width: 70,
  bgcolor: "background.default",
  borderRadius: "0px 0px 10px 10px",
  transition: "0.3s",
  color: "primary.contrastText",
  "&:hover": {
    bottom: -30,
    color: "primary.main",
    bgcolor: "background.default",
  },
};

export default CardExpandingContainer;
