import React, { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { Box, Tab } from "@mui/material";
import MuiTabs from "@mui/material/Tabs";

import { Task, TaskStatus } from "stores/taskStore/types";

import Attachments from "common/Attachments";
import { TabPanel } from "components/ConstructionSite/Area/Card/Tabs";
import { LOCALES } from "constants/locales";
import { tabsStyles } from "constants/styles";

import Comments from "./Comments";
import { useIsAllowedToDeleteOrEdit } from "../utils/useIsAllowedToDeleteOrEdit";

const COMMENTS_TAB_INDEX = 0;
const ATTACHMENTS_TAB_INDEX = 1;

interface TabsProps {
  task: Task;
}

const Tabs: FC<TabsProps> = ({ task }) => {
  const { t } = useTranslation();
  const [tabIndex, setTabIndex] = useState(COMMENTS_TAB_INDEX);
  const isAllowedToDeleteOrEdit = useIsAllowedToDeleteOrEdit(task);

  return (
    <>
      <MuiTabs value={tabIndex} onChange={(e, index) => setTabIndex(index)} variant={"fullWidth"} sx={tabsStyles}>
        <Tab disableRipple label={t(LOCALES.TASKS.COMMENTS)} />
        <Tab disableRipple label={t(LOCALES.TASKS.ATTACHMENTS)} />
      </MuiTabs>
      <TabPanel index={COMMENTS_TAB_INDEX} value={tabIndex}>
        <Comments task={task} />
      </TabPanel>
      <TabPanel index={ATTACHMENTS_TAB_INDEX} value={tabIndex}>
        <Box sx={{ mt: 1, width: "100%" }}>
          <Attachments id={task.id} acl={task.acl} disabled={task.status === TaskStatus.COMPLETED} />
        </Box>
      </TabPanel>
    </>
  );
};

export default Tabs;
