import { useRootStore } from "hooks/useRootStore";;
import React, { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

import { ErrorI18KeyAndOptions } from "stores/managerService/helpers/checkGraphics";



const EditMode: FC<{ areaId: string }> = ({ areaId }) => {
  const { t } = useTranslation();
  const { jobStore, mapStore } = useRootStore();

  const { editableJob, checkJobGraphics } = jobStore;

  const [error, setError] = useState<ErrorI18KeyAndOptions>();

  const handleSubmit = async () => {
    const errorAndOptions = checkJobGraphics(areaId, editableJob);

    setError(errorAndOptions);
    if (errorAndOptions) {
      return;
    }

    await jobStore.updateGraphic(editableJob);
    await jobStore.getAllJobs(areaId);
    mapStore.utils.clearGraphicLayer();
    jobStore.setEditMode(false);
  };

  const handleCancel = async () => {
    setError(undefined);
    jobStore.setEditableJob("");
    await jobStore.getAllJobs(areaId);
    mapStore.utils.clearGraphicLayer();
    jobStore.setEditMode(false);
  };

  return (
    <div>
      <Typography variant="body1">{t("Job:DetermineLocation")}</Typography>
      {error && (
        <Typography variant="caption" component="div" color="error" sx={{ mt: 0.5, mb: 0.25 }}>
          {t(...error)}
        </Typography>
      )}
      <Button variant="contained" sx={{ mt: 1.75, mr: 1, mb: 1 }} onClick={handleSubmit}>
        {t("General:Save")}
      </Button>
      <Button variant="contained" color="secondary" sx={{ mt: 1.75, mb: 1 }} onClick={handleCancel}>
        {t("General:Cancel")}
      </Button>
    </div>
  );
};

export default EditMode;
