import React, { FC } from "react";
import Avatar from "@mui/material/Avatar";

import { stringAvatar } from "helpers/functions";

interface UserAvatarProps {
  givenName: string | undefined;
  familyName: string | undefined;
  pictureMini: string | undefined;
}

const MEMO_BASE64 = "data:image/jpeg;base64,";

const UserAvatar: FC<UserAvatarProps> = ({ givenName, familyName, pictureMini }) => {
  const initials = (givenName ?? "")[0] + (familyName ?? "")[0];

  return (
    <Avatar {...stringAvatar(`${givenName} ${familyName}`)} src={pictureMini && `${MEMO_BASE64}${pictureMini}`}>
      {initials}
    </Avatar>
  );
};

export default UserAvatar;
