import { useRootStore } from "hooks/useRootStore";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { Alert } from "@mui/material";
import Snackbar from "@mui/material/Snackbar";
import { observer } from "mobx-react-lite";

const AUTO_HIDE_DURATION = 6000;

const Notification: FC = () => {
  const { t } = useTranslation();
  const { uiStore } = useRootStore();

  const { snackbar } = uiStore;

  if (snackbar === null) {
    return null;
  }

  const alertMessage = Array.isArray(snackbar.body) ? t(...snackbar.body) : t(snackbar.body);

  return (
    <Snackbar
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      open={snackbar.show}
      autoHideDuration={AUTO_HIDE_DURATION}
      style={{ zIndex: 9999 }}>
      <Alert onClose={() => uiStore.setSnackbarClose()} elevation={6} variant="filled" severity={snackbar.severity}>
        {alertMessage}
      </Alert>
    </Snackbar>
  );
};

export default observer(Notification);
