import { useRootStore } from "hooks/useRootStore";;
import React, { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";

import { ErrorI18KeyAndOptions } from "stores/managerService/helpers/checkGraphics";



import CreatePopupForm from "./CreatePopupForm";

const CreateMode: FC<{ areaId: string }> = ({ areaId }) => {
  const { t } = useTranslation();
  const { jobStore, uiStore, mapStore } = useRootStore();

  const { checkJobGraphics } = jobStore;
  const [error, setError] = useState<ErrorI18KeyAndOptions>();

  const handleSubmit = async () => {
    const errorAndOptions = checkJobGraphics(areaId);
    setError(errorAndOptions);
    if (errorAndOptions) {
      return;
    }

    uiStore.openModal(<CreatePopupForm areaId={areaId} />);
  };

  const handleCancel = () => {
    setError(undefined);
    jobStore.setCreateMode(false);
    mapStore.utils.clearGraphicLayer();
  };

  return (
    <div>
      <Paper sx={styles}>
        <CircularProgress sx={{ color: "text.primary" }} size={35} />
      </Paper>
      <Typography variant="body1" sx={{ mt: 2 }}>
        {t("Job:DetermineLocation")}
      </Typography>
      {error && (
        <Typography variant="caption" component="div" color="error" sx={{ mt: 0.5, mb: 0.25 }}>
          {t(...error)}
        </Typography>
      )}
      <Button variant="contained" sx={{ mt: 1.75, mr: 1, mb: 0.5 }} onClick={handleSubmit}>
        {t("General:Save")}
      </Button>
      <Button
        variant="contained"
        color="secondary"
        sx={{ mt: 1.75, mb: 0.5 }}
        onClick={handleCancel}
      >
        {t("General:Cancel")}
      </Button>
    </div>
  );
};

const styles = {
  height: 66,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

export default CreateMode;
