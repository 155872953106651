import { pricingMap } from "./constants";
import { AggregatedResponse, GetByCSIdResponse, GetByMetricKeyResponse } from "./types";

export const calculateCost = (subscriptionItemKey: string, quantity: number): number => {
  const ranges = pricingMap[subscriptionItemKey];
  if (!ranges) return 0;

  for (const range of ranges) {
    if ((range.min === undefined || quantity >= range.min) && (range.max === undefined || quantity <= range.max)) {
      return range.price * quantity;
    }
  }

  return 0;
};

function isMetricKeyResponse(response: GetByMetricKeyResponse | GetByCSIdResponse): response is GetByMetricKeyResponse {
  return Array.isArray(response.payload);
}

function aggregateQuantitiesFromMetricKeyResponse(response: GetByMetricKeyResponse): AggregatedResponse {
  const result: AggregatedResponse = {};

  response.payload.forEach(item => {
    result[item.subscriptionItemKey] = (result[item.subscriptionItemKey] || 0) + item.quantity;
  });

  return result;
}

function aggregateQuantitiesFromCSIdResponse(response: GetByCSIdResponse): AggregatedResponse {
  const result: AggregatedResponse = {};

  for (const metricKey in response.payload) {
    result[metricKey] = response.payload[metricKey].reduce((total, item) => total + item.quantity, 0);
  }

  return result;
}

function aggregateQuantities(response: GetByMetricKeyResponse | GetByCSIdResponse): AggregatedResponse {
  if (isMetricKeyResponse(response)) {
    return aggregateQuantitiesFromMetricKeyResponse(response);
  } else {
    return aggregateQuantitiesFromCSIdResponse(response);
  }
}

export function calculateTotalCostForAllMetrics(response: GetByMetricKeyResponse | GetByCSIdResponse): number {
  const aggregates = aggregateQuantities(response);
  const costs: Record<string, number> = {};

  Object.keys(aggregates).forEach(metricKey => {
    costs[metricKey] = calculateCost(metricKey, aggregates[metricKey]);
  });

  const totalCost = Object.values(costs).reduce((sum, currentCost) => sum + currentCost, 0);

  return totalCost;
}
