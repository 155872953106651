import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import SubjectRoundedIcon from "@mui/icons-material/SubjectRounded";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

const DescriptionField: FC<{
  description: string;
  setDescription: React.Dispatch<React.SetStateAction<string>>;
  onBlur?: () => void;
  isAllowedToEdit: boolean;
}> = ({ description, setDescription, onBlur, isAllowedToEdit }) => {
  const { t } = useTranslation();

  return (
    <>
      <Box sx={{ display: "flex", alignItems: "center", mt: 1 }}>
        <SubjectRoundedIcon />
        <Typography variant="body1" component="div" fontWeight={600} sx={{ ml: 1.2 }}>
          {t("General:Description")}
        </Typography>
      </Box>
      <TextField
        disabled={!isAllowedToEdit}
        size="small"
        fullWidth
        multiline
        value={description}
        onChange={e => setDescription(e.target.value)}
        inputProps={{ maxLength: 255 }}
        placeholder={t("General:TypeHere")}
        rows={2}
        sx={{ mt: 1.5, "& .MuiInputBase-root": { fontSize: "14px" } }}
        onBlur={onBlur ?? undefined}
      />
    </>
  );
};

export default DescriptionField;
