import React, { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { observer } from "mobx-react-lite";

import { useRootStore } from "hooks/useRootStore";

import UserAvatar from "./UserAvatar";
import { Task, TaskStatus } from "stores/taskStore/types";
import { useIsAllowedToDeleteOrEdit } from "components/Tasks/utils/useIsAllowedToDeleteOrEdit";

interface WriteCommentProps {
  task: Task;
}

const WriteComment: FC<WriteCommentProps> = ({ task }) => {
  const { t } = useTranslation();
  const { authStore, taskStore } = useRootStore();

  const { firstName, lastName, avatar } = authStore;
  const [newComment, setNewComment] = useState("");
  const [showButton, setShowButton] = useState(false);
  const isAllowedToDeleteOrEdit = useIsAllowedToDeleteOrEdit(task);

  const handleFocus = () => {
    setShowButton(true);
  };

  const handleBlur = () => {
    !newComment && setShowButton(false);
  };

  const comment = async () => {
    const response = await taskStore.createComment(task.id, task.acl, newComment);
    if (response.status === "ok") {
      setNewComment("");
      setShowButton(false);
    }
  };

  return (
    <Box sx={fieldWrapperStyles}>
      <UserAvatar givenName={firstName} familyName={lastName} pictureMini={avatar.base64} />
      <TextField
        disabled={task.status === TaskStatus.COMPLETED}
        id="new-comment"
        multiline
        rows={showButton ? 3 : 1}
        fullWidth
        label={showButton ? "" : t("Tasks:WriteComment")}
        onFocus={handleFocus}
        onBlur={handleBlur}
        value={newComment}
        onChange={e => setNewComment(e.target.value)}
        size="small"
        sx={{
          ...newCommentFieldStyles,
          borderRadius: showButton ? "15px" : "50px",
          ...(showButton && { "& .MuiOutlinedInput-root": { pb: 5 } }),
          "& .MuiOutlinedInput-notchedOutline": {
            borderRadius: showButton ? "15px" : "50px",
          },
        }}
      />
      {showButton && (
        <Button variant="contained" sx={btnStyles} onClick={comment}>
          {t("General:Submit")}
        </Button>
      )}
    </Box>
  );
};

const fieldWrapperStyles = {
  position: "relative",
  display: "flex",
  alignItems: "flex-end",
  zIndex: 100,
};

const newCommentFieldStyles = {
  ml: 0.8,
  bgcolor: "background.paper",
  "& .MuiInputBase-root, .MuiInputLabel-root": { fontSize: "14px" },
  transition: "all 0.3s cubic-bezier(0.33, 1, 0.68, 1)",
  "& div, textarea": {
    transition: "all 0.3s cubic-bezier(0.33, 1, 0.68, 1)",
  },
  "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
    transition: "height 0.3s cubic-bezier(0.33, 1, 0.68, 1), border-radius 0.4s cubic-bezier(0.33, 1, 0.68, 1)",
    borderColor: "background.paper",
  },
  "& .MuiOutlinedInput-root.Mui-focused:hover .MuiOutlinedInput-notchedOutline": {
    borderColor: "background.paper",
  },
  "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
    borderColor: "text.primary",
  },
};

const btnStyles = {
  position: "absolute",
  right: "10px",
  bottom: "10px",
  p: "2px 16px",
};

export default observer(WriteComment);
