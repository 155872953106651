import S3 from "aws-sdk/clients/s3";
import axios from "axios";
import { stringify } from "query-string";

import {
  FileName,
  FilesToRegister,
  GetAllFilesResponse,
  GetFilesByQueryParams,
  ICredentials,
  IFile,
  IFileUpload,
  IStorageMeta,
  PostResponse,
  QueryParamsGetFiles,
  ReuseBody,
  StorageOperationMode,
  StorageProvider,
  SubSystem,
  SubType,
} from "stores/fileStore/types";

import { baseUrl, protocol } from "../../configs/servicesConfig";
import { removeNullishFromObject } from "../../helpers/functions";

export const fileService = {
  register: async (
    files: File[],
    foreignId: string,
    aclId: string,
    type: FileName,
    subsystem: SubSystem,
    subtype: SubType | null
  ) => {
    const response = await axios.post<FilesToRegister, PostResponse>(`${protocol}//${baseUrl}/fileService/files`, {
      foreignId,
      aclId,
      type,
      subsystem,
      subtype,
      files: files.map(file => ({
        name: file.name,
        size: file.size,
        type: file.type,
      })),
    });
    // TODO: implement handling files in payload.failed
    // retry it after some pause
    console.log("register", response);
    return response.data.payload.successful;
  },

  getCredentials: async (
    subSystem: SubSystem,
    storageProvider: StorageProvider,
    foreignId: string,
    aclId: string,
    mode?: StorageOperationMode,
    projectId?: number | string
  ) => {
    const queryObject = {
      storageProvider,
      aclId,
      ...(subSystem === SubSystem.PIX4D ? { projectId } : { foreignId }),
      mode,
    };

    const queryParams = stringify(removeNullishFromObject(queryObject));
    const {
      data: {
        payload: { credentials },
      },
    } = await axios.get(`${protocol}//${baseUrl}/fileService/credentials/${storageProvider}?${queryParams}`);

    return credentials as ICredentials;
  },

  sendFileToS3: (
    Bucket: string,
    Body: File,
    Key: string,
    region: string,
    accessKeyId: string,
    secretAccessKey: string,
    sessionToken: string,
    id: string,
    foreignId: string,
    aclId: string
  ) => {
    const service = new S3({
      region,
      credentials: {
        accessKeyId,
        secretAccessKey,
        sessionToken,
      },
      maxRetries: 10,
    });

    const params: S3.Types.PutObjectRequest = {
      Bucket,
      Key,
      Body,
      Metadata: {
        id,
        foreignId,
        aclId,
      },
      ContentType: Body.type,
    };
    const response = new S3.ManagedUpload({
      params,
      service,
      leavePartsOnError: true,
    }) as IFileUpload;

    return response;
  },

  updateStatus: async (storageMeta: IStorageMeta, status: string) => {
    const { metadata, ...storageMetaWithoutMetadata } = storageMeta;
    const { foreignId, id } = metadata;
    return await axios.patch(`${protocol}//${baseUrl}/fileService/files/${foreignId}/${id}`, {
      status,
      storageMeta: storageMetaWithoutMetadata,
    });
  },

  getAllFiles: async (queryParams: QueryParamsGetFiles, foreignId?: string) => {
    const query = stringify(removeNullishFromObject(queryParams));
    const symbol = foreignId ? `/${foreignId}?` : "?";
    const response = await axios.get<{ payload: GetAllFilesResponse }>(
      `${protocol}//${baseUrl}/fileService/files${symbol}${query}`
    );
    return response.data.payload;
  },

  createDownloadLink: async (foreignId: string, id: string): Promise<IFile> => {
    const response = await axios.post<{ payload: IFile }>(`${protocol}//${baseUrl}/fileService/links`, {
      foreignId,
      id,
    });
    return response.data.payload;
  },

  delete: async (foreignId: string, id: string) => {
    return await axios.delete(`${protocol}//${baseUrl}/fileService/files/${foreignId}/${id}`);
  },

  get: async (
    region: string,
    accessKeyId: string,
    secretAccessKey: string,
    sessionToken: string,
    Bucket: string,
    Key: string
  ) => {
    const service = new S3({
      region,
      credentials: {
        accessKeyId,
        secretAccessKey,
        sessionToken,
      },
    });

    const params = {
      Bucket,
      Key,
    };

    return await new Promise<S3.GetObjectOutput>((resolve, reject) =>
      service.getObject(params, (err, result) => {
        if (err) {
          return reject(err);
        }
        resolve(result);
      })
    ).catch(err => {
      throw err;
    });
  },

  getSignedUrl: async (
    region: string,
    accessKeyId: string,
    secretAccessKey: string,
    sessionToken: string,
    Bucket: string,
    Key: string
  ) => {
    const service = new S3({
      region,
      credentials: {
        accessKeyId,
        secretAccessKey,
        sessionToken,
      },
    });

    const params = {
      Bucket,
      Key,
    };

    return await new Promise<string>((resolve, reject) =>
      service.getSignedUrl("getObject", params, (err, result) => {
        if (err) {
          return reject(err);
        }
        resolve(result);
      })
    ).catch(err => {
      throw err;
    });
  },

  reuse: async (body: ReuseBody) => {
    const { data } = await axios.post<ReuseBody, PostResponse>(`${protocol}//${baseUrl}/fileService/files/reuse`, body);
    return data;
  },

  getFilesByQueryParams: async (foreignId: string, params: GetFilesByQueryParams): Promise<GetAllFilesResponse> => {
    const queryParams = stringify(removeNullishFromObject(params));

    const response = await axios.get<{ payload: GetAllFilesResponse }>(
      `${protocol}//${baseUrl}/fileService/files/${foreignId}?${queryParams}`
    );

    return response.data.payload;
  },
};
