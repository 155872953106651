import React, { FC } from "react";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Box, SxProps, Theme } from "@mui/material";

interface TaskMaskProps {
  selected: boolean;
}

const TaskMask: FC<TaskMaskProps> = ({ selected }) => {
  return (
    <Box sx={containerStyles(selected)} bgcolor={selected ? "rgba(0, 0, 0, 0.5)" : "transparent"}>
      {selected && <CheckCircleIcon fontSize={"large"} />}
    </Box>
  );
};

const containerStyles: (selected: boolean) => SxProps<Theme> = selected => ({
  zIndex: "10",
  position: "absolute",
  width: "100%",
  height: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  color: "primary.main",
  bgcolor: selected ? "rgba(0, 0, 0, 0.5)" : "transparent",
  borderWidth: selected ? "1px" : "0px",
  borderStyle: "solid",
  borderColor: "primary.main",
  borderRadius: "inherit",
  "&:hover": {
    borderWidth: "1px",
    borderStyle: "solid",
    borderColor: "primary.main",
    borderRadius: "inherit",
  },
});

export default TaskMask;
