import { ReactNode } from "react";
import { makeAutoObservable } from "mobx";

import RootStore from "stores/rootStore";

import ConfirmDialog, { ConfirmDialogProps } from "common/ConfirmDialog";

import { Modal, OpenModalOptions, Snackbar } from "./types";

export class UIStore {
  public modals: Modal[] = [];
  public snackbar: Snackbar | null = null;
  public isSearchFieldVisible: boolean = false;

  constructor(private readonly rootStore: RootStore) {
    makeAutoObservable(this);
  }

  public readonly openModal = (body: ReactNode, options?: OpenModalOptions) => {
    const newModal: Modal = {
      show: true,
      body,
      ...options,
    };

    this.setModals([...this.modals, newModal]);
  };

  public readonly setConfirmModalOpen = (confirmOptions: ConfirmDialogProps, options?: OpenModalOptions) => {
    const body = <ConfirmDialog {...confirmOptions} />;
    this.openModal(body, options);
  };

  public readonly setSnackbarOpen = (body: Snackbar["body"], severity: Snackbar["severity"] = "error") => {
    this.snackbar = {
      show: true,
      body,
      severity,
    };
  };

  public readonly closeLastModal = () => {
    this.setModals(this.modals.slice(0, -1));
  };

  public readonly getModalById = (modalId: string): Modal | null => {
    return this.modals.find(m => m.modalId === modalId) ?? null;
  };

  public readonly closeModalByModalId = (modalId: string): void => {
    const modalsWithoutSpecified = this.modals.filter(m => m.modalId !== modalId);
    this.setModals(modalsWithoutSpecified);
  };

  public readonly clearModals = (): void => {
    this.setModals([]);
  };

  public readonly setSnackbarClose = () => {
    this.snackbar = null;
  };

  public readonly setIsSearchFieldVisible = (isVisible: boolean) => {
    this.isSearchFieldVisible = isVisible;
  };

  private readonly setModals = (modals: Modal[]): void => {
    this.modals = modals;
  };
}
