import React, { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Stack, SxProps, Theme } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";

import { Task } from "stores/taskStore/types";

import { LOCALES } from "constants/locales";
import { stringAvatar } from "helpers/functions";

import TaskDate from "./TaskDate";
import { Assignee } from "./types";

interface DatesAndAvatarProps {
  task: Task;
  assignee?: Assignee;
}

const DatesAndAvatar: FC<DatesAndAvatarProps> = ({ task, assignee }) => {
  const { t } = useTranslation();

  const avatarImg = useMemo(() => {
    if (assignee?.["custom:picture-mini"]) {
      return "data:image/jpeg;base64," + assignee["custom:picture-mini"];
    } else {
      return undefined;
    }
  }, [assignee]);

  const userName = useMemo(() => {
    if (assignee) {
      if (assignee.given_name && assignee.family_name) {
        return `${assignee.given_name} ${assignee.family_name}`;
      } else {
        return assignee.email;
      }
    } else {
      return "";
    }
  }, [assignee]);

  return (
    <Box sx={containerStyles}>
      <Stack spacing={0.5}>
        {task.dueDate && <TaskDate title={t(LOCALES.TASKS.DUE_DATE)} date={task.dueDate} dateTimeMask={"d MMMM"} />}
        <TaskDate title={t(LOCALES.TASKS.CREATED_AT)} date={new Date(task.createdAt)} dateTimeMask={"HH:mm, d MMMM"} />
      </Stack>
      <Tooltip title={userName}>
        <Avatar
          {...(assignee?.given_name &&
            assignee?.family_name &&
            stringAvatar(`${assignee.given_name} ${assignee.family_name}`))}
          sx={{ ml: "auto", cursor: assignee ? "pointer" : "default" }}
          src={avatarImg}
        />
      </Tooltip>
    </Box>
  );
};

const containerStyles: SxProps<Theme> = {
  display: "flex",
  alignItems: "center",
};

export default DatesAndAvatar;
