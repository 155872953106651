import { useRootStore } from "hooks/useRootStore";
import React, { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import TuneIcon from "@mui/icons-material/Tune";
import { Box } from "@mui/material";
import Divider from "@mui/material/Divider";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Zoom from "@mui/material/Zoom";
import { observer } from "mobx-react-lite";
import { BreadcrumbSteps, SortParams } from "types";

export const SortButton: FC = observer(() => {
  const { constructionSiteStore, areaStore, jobStore, processingStore } = useRootStore();

  const { t } = useTranslation();
  const [checked, setChecked] = useState(false);
  const sortParams = Object.values(SortParams);
  const { breadcrumbStepName } = constructionSiteStore;
  const { sortParameterForAreas } = areaStore;
  const { sortParameterForJobs } = jobStore;
  const { sortParameterForProcessings } = processingStore;

  function handleChange() {
    setChecked(prev => !prev);
  }

  function setSortParameter(e: React.ChangeEvent<HTMLInputElement>) {
    setChecked(prev => !prev);
    const value = e.target.value as SortParams;
    valueForRadioGroup(value);
  }

  function valueForRadioGroup(sortParameter?: SortParams): SortParams | void {
    switch (breadcrumbStepName) {
      case BreadcrumbSteps.constructionSiteCard:
        return sortParameter ? areaStore.setSortParameterForAreas(sortParameter) : sortParameterForAreas;
      case BreadcrumbSteps.areaCard:
        return sortParameter ? jobStore.setSortParameterForJobs(sortParameter) : sortParameterForJobs;
      case BreadcrumbSteps.jobCard:
        return sortParameter
          ? processingStore.setSortParameterForProcessings(sortParameter)
          : sortParameterForProcessings;
      default:
        return SortParams.dateNew;
    }
  }
  return (
    <Box sx={{ justifyContent: "center", alignItems: "center" }}>
      <IconButton size="small" sx={iconStyles} onClick={handleChange}>
        <TuneIcon />
      </IconButton>
      <Box sx={{ display: "flex" }}>
        <Zoom in={checked}>
          <Paper
            sx={{
              m: 1,
              position: "absolute",
              padding: "10px",
              zIndex: 10,
              minWidth: "150px",
            }}
            elevation={4}>
            <FormControl size="small">
              <FormLabel id="controlled-radio-buttons-group">{t("ConstructionSite:SortBy")}</FormLabel>
              <Divider light sx={{ backgroundColor: "text.secondary" }} />
              <RadioGroup
                aria-labelledby="controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                value={valueForRadioGroup()}
                onChange={setSortParameter}>
                {sortParams.map(sp => {
                  const isSortByType = sp === SortParams.type;
                  const isJobCard = breadcrumbStepName === BreadcrumbSteps.jobCard;
                  const shouldRenderControl = isJobCard || !isSortByType;

                  return shouldRenderControl ? (
                    <FormControlLabel
                      key={sp}
                      value={sp}
                      control={<Radio />}
                      label={t(`ConstructionSite:SortParams:${sp}`)}
                    />
                  ) : null;
                })}
              </RadioGroup>
            </FormControl>
          </Paper>
        </Zoom>
      </Box>
    </Box>
  );
});

const iconStyles = {
  color: "text.primary",
  bgcolor: "background.paper",
  borderRadius: 10,
  "& .MuiSvgIcon-root": {
    transition: "color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
  },
  "&:hover": {
    bgcolor: "background.paper",
    "& .MuiSvgIcon-root": {
      color: "primary.main",
    },
  },
};
