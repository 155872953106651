import { string } from "yup";

export const UNKNOWN_SERVER_ERROR = "Unknown server error";

const REQUIRED_FIELD_MESSAGE = "This field is required";
const MIN_STRING_LENGTH = 3;
const MIN_LENGTH_STRING_MESSAGE = `Min length ${MIN_STRING_LENGTH}`;
const MAX_STRING_LENGTH = 255;
const MAX_LENGTH_STRING_MESSAGE = `Max length ${MAX_STRING_LENGTH}`;
export const stringRules = string()
  .required(REQUIRED_FIELD_MESSAGE)
  .min(MIN_STRING_LENGTH, MIN_LENGTH_STRING_MESSAGE)
  .max(MAX_STRING_LENGTH, MAX_LENGTH_STRING_MESSAGE);
