import React, { FC, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import { onboardingStore } from "stores/onboardingStore";

import ClearingTextField from "common/ClearingTextField";
import { addLeadingZeros } from "helpers/functions";
import { useRootStore } from "hooks/useRootStore";

const CreatePopupForm: FC<{ areaId: string }> = ({ areaId }) => {
  const { t } = useTranslation();
  const { mapStore, jobStore } = useRootStore();

  const { isOnboardingRunning } = onboardingStore;
  const { kmlFileName } = mapStore;
  const { jobs } = jobStore;

  const preTitle = useMemo(() => `#${addLeadingZeros(jobs.length + 1, 3)}-`, [jobs.length]);
  const [title, setTitle] = useState(kmlFileName ?? preTitle);
  const [description, setDescription] = useState("");

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    jobStore.create(title, description, areaId);

    if (isOnboardingRunning) {
      onboardingStore.nextStep();
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <Typography id="transition-modal-title" variant="h5" component="h2" textAlign="center" sx={{ mb: 2 }}>
        {t("Job:CreateJob")}
      </Typography>
      <Divider sx={{ mx: -1.5 }} />
      <ClearingTextField
        id="title"
        label={t("General:Title")}
        fullWidth
        sx={{ mt: 2.5 }}
        required
        value={title}
        onChange={e => setTitle(e.target.value)}
        inputProps={{ maxLength: 63 }}
        onClear={() => setTitle("")}
      />
      <TextField
        id="description"
        label={t("General:Description")}
        multiline
        rows={3}
        fullWidth
        sx={{ my: 2 }}
        value={description}
        onChange={e => setDescription(e.target.value)}
        inputProps={{ maxLength: 255 }}
      />
      <Button color="secondary" variant="contained" fullWidth type="submit">
        {t("General:Submit")}
      </Button>
    </form>
  );
};

export default CreatePopupForm;
