import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { Box, Button, SxProps, Theme, Typography } from "@mui/material";

interface ButtonsListProps {
  buttonsTitles: string[];
  selectedButtonTitle: string;
  setButtonTitle: (title: string) => void;
}

const ButtonsList: FC<ButtonsListProps> = ({ buttonsTitles, selectedButtonTitle, setButtonTitle }) => {
  const { t } = useTranslation();

  return (
    <Box>
      {buttonsTitles.map(title => (
        <Button sx={getElementStyles(title === selectedButtonTitle)} key={title} onClick={() => setButtonTitle(title)}>
          <Typography>{t(title)}</Typography>
        </Button>
      ))}
    </Box>
  );
};

export const getElementStyles: (selected: boolean) => SxProps<Theme> = selected => ({
  p: 1,
  color: selected ? "primary.main" : "text.disabled",
  bgcolor: selected ? "secondary.light" : "secondary.main",
  mt: 0.5,
  mr: 0.5,
  textTransform: "none",
  "&:hover": {
    color: selected ? "primary.main" : "primary.contrastText",
  },
});

export default ButtonsList;
