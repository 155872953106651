import { SortParams } from "types";

import { FileExt, FileType } from "stores/fileStore/types";
import { IArea } from "stores/managerService/areaStore";
import { Job } from "stores/managerService/jobStore/types";
import { IProcessing } from "stores/managerService/processingStore/types";
import { Position } from "stores/mapStore/types";
import { LOCALES } from "../constants/locales";
import { UIStore } from "../stores/uiStore";
import { Location, NavigateFunction } from "react-router-dom";
import { MouseEvent } from "react";
import { MeasurementPointClipboard } from "common/Measurements/helpers";

export const formatBytes = (bytes: number, decimals = 2) => {
  if (!+bytes) return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "kB", "mB", "gB"];
  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
};

export const reloadImg = async (url: string) => {
  await fetch(url, {
    cache: "reload",
    mode: "no-cors",
  }).then(() =>
    document.body
      .querySelectorAll<HTMLImageElement>(`img[src='${url}']`)
      .forEach((img: HTMLImageElement) => (img.src = url))
  );
};

export const stringToColor = (string: string) => {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
};

export const stringAvatar = (name: string) => {
  return {
    sx: {
      bgcolor: stringToColor(name),
      color: "text.primary",
      fontSize: "1em",
    },
    children: `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`,
  };
};

export const getExtForAccept = (ext: string): string => {
  return `.${ext}`;
};

export const getInputAccept = (exts: string | string[]): string => {
  return Array.isArray(exts) ? exts.map(getExtForAccept).join(", ") : getExtForAccept(exts);
};

export const isFileTypeExtEqual = (type: FileType["ext"], equal: FileExt): boolean => {
  if (Array.isArray(type)) {
    return type.includes(equal);
  }
  return type === equal;
};

export const isObject = (val: any): boolean => {
  if (val === null || val === undefined) {
    return false;
  }
  return typeof val === "function" || typeof val === "object";
};

export const getFileName = (file: File): string => file.name.split(".").slice(0, -1).join(".");

export const getFileExt = (file: File): string | undefined => {
  return file.name.split(".").pop();
};

export const sortByParameter = (sortParameter: SortParams, array: IArea[] | Job[] | IProcessing[]) => {
  const sortByDate = (a: IArea | Job | IProcessing, b: IArea | Job | IProcessing) =>
    Date.parse(sortParameter === SortParams.dateNew ? b.createdAt : a.createdAt) -
    Date.parse(sortParameter === SortParams.dateNew ? a.createdAt : b.createdAt);

  const sortByTitle = (a: IArea | Job | IProcessing, b: IArea | Job | IProcessing) => a.title.localeCompare(b.title);

  const sortByType = (a: IProcessing, b: IProcessing) => a.type.localeCompare(b.type);

  switch (sortParameter) {
    case SortParams.dateNew:
    case SortParams.dateOld:
      return array.sort(sortByDate);
    case SortParams.name:
      return array.sort(sortByTitle);
    case SortParams.type:
      return (array as IProcessing[]).sort(sortByType);
    default:
      return array;
  }
};

export const isExists = (value: any): boolean => value !== undefined && value !== null;

export const removeNullishFromObject = <T extends Record<string, any>, R extends Record<string, any>>(object: T): R => {
  return Object.entries(object)
    .filter(([, value]) => isExists(value))
    .reduce((result, [key, value]) => ({ ...result, [key]: value }), {}) as R;
};

export const delay = async (delayMs: number): Promise<void> => {
  return await new Promise(resolve => setTimeout(resolve, delayMs));
};

export const clipboardCopyPosition = async (position: Position | null): Promise<void> => {
  if (!position) {
    return;
  }

  await navigator.clipboard.writeText(`${position[0]}, ${position[1]}`);
};

const formatDateNumber = (value: number) => (value < 10 ? `0${value}` : value);
export const getDateString = (dateValue: number): string => {
  const date = new Date(dateValue);

  const day = formatDateNumber(date.getDate());
  const month = formatDateNumber(date.getMonth() + 1);
  const year = formatDateNumber(date.getFullYear());

  return `${day}.${month}.${year}`;
};

export const addLeadingZeros = (num: number, totalLength: number): string => {
  if (num < 0) {
    const withoutMinus = String(num).slice(1);
    return "-" + withoutMinus.padStart(totalLength, "0");
  }

  return String(num).padStart(totalLength, "0");
};

export const truncateText = (inputText: string, maxLength: number) => {
  let truncated = inputText.slice(0, maxLength).replace(/\s+/g, " ").trim();
  if (inputText.length > maxLength) {
    truncated += "...";
  }
  return truncated;
};

export const getFullName = (firstName: string, secondName: string): string => `${firstName} ${secondName}`;

export const getUndefinedSingleOrMultiple = <T>(value: T[]): undefined | T | T[] => {
  const length = value.length;
  if (length === 0) {
    return undefined;
  }

  if (length === 1) {
    return value[0];
  }

  if (length > 1) {
    return value;
  }
};

export const copyCoordinatesToClipboard = async (
  e: MouseEvent<SVGSVGElement>,
  measurementInfo: MeasurementPointClipboard,
  uiStore: UIStore
): Promise<void> => {
  e.stopPropagation();

  await navigator.clipboard.writeText(JSON.stringify(measurementInfo));
  uiStore.setSnackbarOpen("General:SuccessfullyCopied", "success");
};
