import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import ErrorOutlineRoundedIcon from "@mui/icons-material/ErrorOutlineRounded";
import { Chip, SxProps, Theme, useTheme } from "@mui/material";

import RefreshStatus from "./RefreshStatus";
import { StatusesMatch, StatusName } from "./types";

interface StatusProps<T extends string> {
  status: T;
  statusesMatch: StatusesMatch<T>;
  updateFunc: () => Promise<void>;
}

function Status<T extends string>({ updateFunc, status, statusesMatch }: StatusProps<T>) {
  const { t } = useTranslation();
  const { palette } = useTheme();

  const [angle, setAngle] = useState(0);
  const statusName = useMemo(() => statusesMatch[status], [status, statusesMatch]);

  const statusesIcons = useMemo<Record<StatusName, React.ReactElement>>(
    () => ({
      FAIL: <ErrorOutlineRoundedIcon sx={iconStyles(palette.error.main)} />,
      IN_PROGRESS: <RefreshStatus updateFunc={updateFunc} setAngle={setAngle} />,
      SUCCESS: <CheckRoundedIcon sx={iconStyles(palette.success.main)} />,
    }),
    // statusName needed for the updating icon color
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [updateFunc, statusName]
  );
  const IconComponent = useMemo(() => statusesIcons[statusName], [statusName, statusesIcons]);

  return (
    <Chip
      label={t(`Processings:${status}`)}
      variant="outlined"
      icon={IconComponent}
      sx={{
        // borderColor: statusName === StatusName.IN_PROGRESS ? "background.default" : "secondary.light",
        // backgroundColor: statusName === StatusName.IN_PROGRESS ? "background.default" : "transparent",
        borderColor: "secondary.light",
        fontSize: (t(`Processings:${status}`).length > 11 && "12px") || "14px",
        "& .MuiSvgIcon-root": {
          transform: `rotate(${angle}deg)`,
          transition: "transform 1s ease-out",
          "&:hover": {
            cursor: "pointer",
          },
        },
      }}
    />
  );
}

const iconStyles: (color: string) => SxProps<Theme> = color => ({
  pointerEvents: "none",
  "&&": {
    color: `${color} !important`,
  },
});

export default Status;
