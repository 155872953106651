export enum NotificationTopic {
  ConstructionSite_ActorInvited = "ConstructionSite_ActorInvited",
  ConstructionSite_ActorRemoved = "ConstructionSite_ActorRemoved",
  Task_Created = "Task_Created",
  Task_NewComment = "Task_NewComment",
  Task_Overdue = "Task_Overdue",
  Task_StatusChanged = "Task_StatusChanged",
  Project_Finish = "Project_Finish",
  Project_Launch = "Project_Launch",
}

export const NOTIFICATION_TOPICS = Object.values(NotificationTopic);
