import { useRootStore } from "hooks/useRootStore";
import React, { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { ErrorI18KeyAndOptions } from "stores/managerService/helpers/checkGraphics";
import { GraphicId } from "../../../stores/mapStore/types";

const EditMode: FC<{ constructionSiteId: string }> = ({ constructionSiteId }) => {
  const { t } = useTranslation();
  const { areaStore, mapStore } = useRootStore();

  const { editableArea, checkAreaGraphics, checkNestedGraphics } = areaStore;

  const [error, setError] = useState<ErrorI18KeyAndOptions>();

  const handleSubmit = async () => {
    const errorMessageAndOptions =
      checkAreaGraphics(constructionSiteId, editableArea) ?? checkNestedGraphics(editableArea);

    setError(errorMessageAndOptions);
    if (errorMessageAndOptions) {
      return;
    }

    areaStore.updateGraphic(editableArea!).then(async () => {
      await areaStore.getAllAreas(constructionSiteId);
      mapStore.utils.clearGraphicLayer();
      mapStore.utils.removeGraphicsByGraphicType(GraphicId.job);
      areaStore.setEditMode(false);
    });
  };

  const handleCancel = async () => {
    setError(undefined);
    areaStore.setEditableArea("");
    await areaStore.getAllAreas(constructionSiteId);
    mapStore.utils.clearGraphicLayer();
    areaStore.setEditMode(false);
  };

  return (
    <Box sx={{ mt: 1, flexShrink: 0 }}>
      <Typography variant="body1" sx={{ mt: 1.5 }}>
        {t("Area:DetermineLocation")}
      </Typography>
      {error && (
        <Typography variant="caption" component="div" color="error" sx={{ mt: 0.5, mb: 0.25 }}>
          {t(...error)}
        </Typography>
      )}
      <Button variant="contained" sx={{ mt: 1.75, mr: 1, mb: 1 }} onClick={handleSubmit}>
        {t("General:Save")}
      </Button>
      <Button variant="contained" color="secondary" sx={{ mt: 1.75, mb: 1 }} onClick={handleCancel}>
        {t("General:Cancel")}
      </Button>
    </Box>
  );
};

export default EditMode;
