import { useRootStore } from "hooks/useRootStore";;
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { Link as RouterLink } from "react-router-dom";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Link from "@mui/material/Link";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { observer } from "mobx-react-lite";

import LanguageSelector from "components/Sidebar/LanguageSelector";
import { absolutePath, PATHS } from "router/paths";

const Stage2: FC = () => {
  const { t } = useTranslation();
  const { authStore } = useRootStore();

  const passwordValidationError = t("Auth:PasswordValidationError");

  const { values, inProgress, isPasswordValid, error } = authStore;

  const navigate = useNavigate();

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => authStore.setPassword(e.target.value);
  const handleCodeChange = (e: React.ChangeEvent<HTMLInputElement>) => authStore.setCode(e.target.value);
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    authStore.validatePassword(values.password);
    authStore.isPasswordValid && authStore.confirmPassword().then(() => navigate(absolutePath(PATHS.LOGIN.INDEX)));
  };

  return (
    <Box component="form" onSubmit={handleSubmit} sx={formStyles}>
      <Box sx={{ alignSelf: "flex-end", mt: 4 }}>
        <LanguageSelector displayFlag={false} />
      </Box>
      <Typography sx={messageStyles} variant="subtitle1">
        {t("Auth:SuccessForgotPasswordMessage")}
      </Typography>
      <Typography sx={{ my: 1 }} variant="body1" color="error">
        {error}
      </Typography>
      <TextField
        required
        fullWidth
        variant="standard"
        name="code"
        label={t("Auth:CodeLabel")}
        type="text"
        id="code"
        value={values.code}
        onChange={handleCodeChange}
      />
      <TextField
        error={!isPasswordValid}
        required
        fullWidth
        variant="standard"
        name="password"
        label={t("Auth:NewPasswordLabel")}
        type="password"
        id="password"
        autoComplete="new-password"
        value={values.password}
        onChange={handlePasswordChange}
        helperText={!isPasswordValid && passwordValidationError}
        sx={{ mt: 2 }}
      />
      <Link component={RouterLink} to="/login" variant="body2" underline="hover" sx={{ mt: 2, color: "text.primary" }}>
        {t("Auth:GoBackToLogin")}
      </Link>
      <Button
        type="submit"
        fullWidth
        color="secondary"
        variant="contained"
        sx={{ mt: 4 }}
        disabled={inProgress}
        disableElevation>
        {t("Auth:Confirm")}
      </Button>
    </Box>
  );
};

const formStyles = {
  maxWidth: "340px",
  display: "flex",
  flexDirection: "column",
  width: "100%",
  alignItems: "center",
} as const;

const messageStyles = { mt: 2, color: "text.primary" };

export default observer(Stage2);
