import { PriceRange } from "./types";

export const pricingMap: Record<string, PriceRange[]> = {
  ownerLicence: [{ price: 150.0 }],
  webUsers: [{ price: 100.0 }],
  mobileUsers: [
    { max: 5, price: 100.0 },
    { min: 5, price: 80.0 },
  ],
  droneAndCrane: [
    { max: 30000, price: 0.25 },
    { min: 30000, max: 1000000, price: 0.18 },
    { min: 1000000, price: 0.14 },
  ],
  vidocAndPix4d: [
    { max: 30000, price: 0.08 },
    { min: 30000, max: 1000000, price: 0.06 },
    { min: 1000000, price: 0.04 },
  ],
  vipoint: [
    { max: 50000, price: 0.29 },
    { min: 50000, max: 100000, price: 0.19 },
    { min: 100000, price: 0.11 },
  ],
  viplan: [
    { max: 50000, price: 0.61 },
    { min: 50000, max: 100000, price: 0.41 },
    { min: 100000, price: 0.21 },
  ],
  liveStorage: [{ price: 1.62 }],
  archiveStorage: [
    { max: 3000, price: 0.49 },
    { min: 3000, max: 10000, price: 0.39 },
    { min: 100000, price: 0.29 },
  ],
};
