import React, { FC } from "react";
import Typography from "@mui/material/Typography";
import { format as dformat } from "date-fns";

import { useFormatDate } from "hooks/useFormatDate";

interface DateProps {
  title: string;
  date: Date;
  dateTimeMask: string;
}

const TaskDate: FC<DateProps> = ({ date, title, dateTimeMask }) => {
  const format = useFormatDate(dformat);

  return (
    <Typography variant="caption" component="div" fontWeight={300} sx={{ letterSpacing: "normal", color: "#ADADAD" }}>
      {title + ": " + format(date, dateTimeMask)}
    </Typography>
  );
};

export default TaskDate;
