import { useCallback } from "react";

import { useRootStore } from "hooks/useRootStore";

import useFetchMapManagerData from "./_useFetchMapManagerData";
import { FetchHook } from "./types";

/**
 * @returns { [isLoadingInProgress, isLoaded] }
 */
const useFetchJobAndProcessings: FetchHook = jobId => {
  const {
    jobStore: { job, getAndSetJob, _setJob },
    processingStore: { getAllProcessings, _setProcessings },
  } = useRootStore();

  const fetchJobAndProcessings = useCallback(
    async (jobId: string) => {
      await getAndSetJob(jobId);
      await getAllProcessings(jobId);
    },
    [getAllProcessings, getAndSetJob]
  );

  const clearJobAndArea = useCallback(() => {
    _setJob(null);
    _setProcessings([]);
  }, [_setJob, _setProcessings]);

  const isExists = !!job && job.id === jobId;

  return useFetchMapManagerData(jobId, fetchJobAndProcessings, clearJobAndArea, isExists);
};

export default useFetchJobAndProcessings;
