import { useRootStore } from "hooks/useRootStore";;
import React, { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { LoadingButton } from "@mui/lab";
import Fade from "@mui/material/Fade";
import TextField from "@mui/material/TextField";
import { observer } from "mobx-react-lite";

import { TeamRole } from "stores/managerService/teamStore/types";

import { LOCALES } from "constants/locales";
import { useFetchWithLoading } from "hooks";

const EditTitle: FC<{ jobId: string }> = observer(({ jobId }) => {
  const { t } = useTranslation();
  const { processingStore, teamStore, uiStore } = useRootStore();

  useEffect(() => () => processingStore.resetStore(), []);

  const { editableProcessing } = processingStore;
  const { csPermissions } = teamStore;
  const [title, setTitle] = useState(editableProcessing?.title ?? "");

  const [fetchWithLoading, isLoading] = useFetchWithLoading(
    async () => await processingStore.updateTitle(editableProcessing!.id, title, jobId)
  );

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    await fetchWithLoading();
    uiStore.closeLastModal();
  };

  return (
    <Fade in>
      <form onSubmit={handleSubmit}>
        <TextField
          id="title"
          label={t(LOCALES.GENERAL.TITLE)}
          fullWidth
          sx={{ my: 1.5 }}
          required
          value={title}
          onChange={e => setTitle(e.target.value)}
          inputProps={{ maxLength: 255 }}
          disabled={!csPermissions.creating}
        />
        <LoadingButton loading={isLoading} color="secondary" variant="contained" fullWidth type="submit">
          {t(LOCALES.GENERAL.EDIT)}
        </LoadingButton>
      </form>
    </Fade>
  );
});

export default EditTitle;
