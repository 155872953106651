import { useRootStore } from "hooks/useRootStore";
import React, { FC, useEffect, useRef } from "react";
import { Box } from "@mui/material";
import { observer } from "mobx-react-lite";

import { thumbStyles } from "constants/styles";

import CommentsListItem from "./CommentsListItem";

interface CommentsListProps {
  taskId: string;
}

const CommentsList: FC<CommentsListProps> = ({ taskId }) => {
  const { taskStore, teamStore } = useRootStore();

  const { comments } = taskStore;
  const { constructionSiteTeamMembersMap, protectedAreaMembersMap } = teamStore;
  const ref = useRef<HTMLDivElement>(null);

  // useEffect(() => {
  //   if (ref.current) {
  //     ref.current.scrollIntoView({ behavior: "auto", block: "end" });
  //   }
  // });

  useEffect(() => {
    taskStore.getAllComments(taskId);
    return () => taskStore.setComments(null);
  }, [taskId, taskStore]);

  return (
    <Box sx={thumbStyles}>
      {comments?.map((comment, index) => {
        const author = protectedAreaMembersMap[comment.createdBy] || constructionSiteTeamMembersMap[comment.createdBy];

        if (!author) {
          return null;
        }

        return (
          <Box key={comment.id} ref={index === comments.length - 1 ? ref : null}>
            <CommentsListItem comment={comment} author={author} taskId={taskId} />
          </Box>
        );
      })}
    </Box>
  );
};

export default observer(CommentsList);
