import React, { CSSProperties, FC, ReactNode, useRef } from "react";

import { FileExt } from "stores/fileStore/types";

import { getInputAccept } from "helpers/functions";

import { OnboardingSelector } from "../../../../Onboarding/steps/types";

import ToolbarItemContainer from "./ToolbarItemContainer";

interface FileUploaderIconProps {
  fileType: FileExt | FileExt[];
  icon: ReactNode;
  onChange: (file: File) => void;
}

const FileUploaderIcon: FC<FileUploaderIconProps> = ({ fileType, icon, onChange }) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const handleChange = () => {
    if (inputRef.current?.files?.[0]) {
      onChange(inputRef.current.files[0]);
    }
  };

  const handleReset = () => {
    if (inputRef.current) {
      inputRef.current.value = "";
    }
  };

  return (
    <ToolbarItemContainer id={OnboardingSelector.KML_UPLOAD_BUTTON}>
      {icon}
      <input
        ref={inputRef}
        onClick={handleReset}
        onChange={handleChange}
        type="file"
        style={inputStyles}
        accept={getInputAccept(fileType)}
      />
    </ToolbarItemContainer>
  );
};

const inputStyles: CSSProperties = {
  position: "absolute",
  width: "100%",
  height: "100%",
  opacity: 0,
  cursor: "pointer",
};

export default FileUploaderIcon;
