const getMeasurementLocale = (id: string) => `Measurements:${id}`;

export const LOCALES = {
  DATE: getMeasurementLocale("Date"),
  AMOUNT_OF_POINTS: getMeasurementLocale("AmountOfPoints"),
  LENGTH_2D: getMeasurementLocale("2DLength"),
  LENGTH_3D: getMeasurementLocale("3DLength"),
  EAST: getMeasurementLocale("East"),
  NORTH: getMeasurementLocale("North"),
  HEIGHT: getMeasurementLocale("Height"),
  X: getMeasurementLocale("X_Coordinate"),
  Y: getMeasurementLocale("Y_Coordinate"),
  Z: getMeasurementLocale("Z_Coordinate"),
  AREA: getMeasurementLocale("Area"),
  METER_UNIT_ABBREVIATION: getMeasurementLocale("Meter_unit_abbreviation"),
  SQUARE_METER_UNIT_ABBREVIATION: getMeasurementLocale("Square_meter_unit_abbreviation"),
  CORRECTION: getMeasurementLocale("Correction"),
  CODE: getMeasurementLocale("Code"),
  CANCEL: getMeasurementLocale("Cancel"),
  EXPORT: getMeasurementLocale("Export"),
  PREPARING: getMeasurementLocale("Preaparing"),
  DOWNLOAD: getMeasurementLocale("Download"),
};
