import { useRootStore } from "hooks/useRootStore";;
import React, { FC, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { observer } from "mobx-react-lite";

import LanguageSelector from "components/Sidebar/LanguageSelector";
import { absolutePath, PATHS } from "router/paths";

const ForceChangePassword: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { authStore } = useRootStore();

  const passwordValidationError = t("Auth:PasswordValidationError");

  const { values, inProgress, error, cognitoUser, isPasswordValid } = authStore;

  useEffect(() => {
    if (cognitoUser) {
      navigate(absolutePath(PATHS.HOME.INDEX));
    }
  }, []);

  const handleNewPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    authStore.setNewPassword(e.target.value);
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    authStore.validatePassword(values.newPassword);
    isPasswordValid && authStore.login();
  };

  return (
    <Box component="form" onSubmit={handleSubmit} sx={formStyles}>
      <Box sx={{ alignSelf: "flex-end", mt: 4 }}>
        <LanguageSelector displayFlag={false} />
      </Box>
      <Typography variant="body1" sx={{ my: 1 }} component="div" color="error">
        {error}
      </Typography>
      <TextField
        error={!isPasswordValid}
        required
        fullWidth
        variant="standard"
        id="new-password"
        label={t("Auth:NewPasswordLabel")}
        type="password"
        name="new-password"
        autoComplete="new-password"
        value={values.newPassword}
        helperText={!isPasswordValid && passwordValidationError}
        onChange={handleNewPasswordChange}
      />
      <Button
        type="submit"
        fullWidth
        color="secondary"
        variant="contained"
        sx={{ mt: 4 }}
        disabled={inProgress}
        disableElevation>
        {t("General:Update")}
      </Button>
    </Box>
  );
};

const formStyles = {
  maxWidth: "340px",
  display: "flex",
  flexDirection: "column",
  width: "100%",
  alignItems: "center",
} as const;

export default observer(ForceChangePassword);
