import { useRootStore } from "hooks/useRootStore";
import React, { FC, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import TextField from "@mui/material/TextField";
import { observer } from "mobx-react-lite";

import SelectUserField, { SelectType } from "../../components/Tasks/Fields/SelectUserField";
import DescriptionField from "../../components/Tasks/Fields/DescriptionField";
import DueDateField from "../../components/Tasks/Fields/DueDateField";
import { BreadcrumbSteps } from "types";
import { ProcessType, TaskForeignType, TaskPriority } from "stores/taskStore/types";
import { ProcessTypesField } from "../../components/Tasks/Fields/ProcessTypesField/ProcessTypesField";
import { LinkedProcessingsField } from "../../components/Tasks/Fields/LinkedProcessingsField";
import { PriorityField } from "../../components/Tasks/Fields/PriorityField";

interface AddTaskPopupProps {
  foreignId: string;
  acl: string;
  status: string;
  constructionSiteId?: string;
}

const AddTaskPopup: FC<AddTaskPopupProps> = ({ foreignId, acl, status, constructionSiteId }) => {
  console.log("constructionSiteId", constructionSiteId);
  const { t } = useTranslation();
  const { taskStore, constructionSiteStore } = useRootStore();
  const { breadcrumbStepName, constructionSite } = constructionSiteStore;
  const [title, setTitle] = useState("");
  const [assignedTo, setAssignedTo] = useState<string | undefined>("");
  const [reviewBy, setReviewBy] = useState<string | undefined>("");
  const [dueDate, setDueDate] = useState<Date | undefined | null>();
  const [description, setDescription] = useState("");
  const [inProgress, setInProgress] = useState(false);
  const [processType, setProcessType] = useState<ProcessType>(ProcessType.APPROVAL);
  const [linkedProcessings, setLinkedProcessings] = useState<string[]>([]);
  const [priority, setPriority] = useState(TaskPriority.NORMAL);
  const [assigneeOrganization, setAssigneeOrganization] = useState<string | undefined>("");
  const [reviewerOrganization, setReviewerOrganization] = useState<string | undefined>("");
  const getTaskForeignType = useCallback(() => {
    switch (breadcrumbStepName) {
      case BreadcrumbSteps.jobCard:
        return TaskForeignType.JOB;
      case BreadcrumbSteps.areaCard:
        return TaskForeignType.AREA;
      default:
        return TaskForeignType.CONSTRUCTION_SITE;
    }
  }, [breadcrumbStepName]);

  const addTask = async (e: React.FormEvent) => {
    e.preventDefault();
    setInProgress(true);
    await taskStore.createTask({
      foreignId,
      acl,
      title,
      description,
      ...(dueDate ? { dueDate } : {}),
      ...(assignedTo ? { assignedTo } : {}),
      status,
      foreignType: getTaskForeignType(),
      constructionSite: constructionSiteId ?? foreignId,
      processType,
      ...(reviewBy ? { reviewBy } : {}),
      processings: linkedProcessings,
      ...(assigneeOrganization ? { assigneeOrganization } : {}),
      ...(reviewerOrganization ? { reviewerOrganization } : {}),
      priority,
    });
  };

  const shouldDisplayLinkedProcessings = getTaskForeignType() === TaskForeignType.JOB;

  return (
    <form onSubmit={addTask}>
      <TextField
        required
        size="small"
        fullWidth
        multiline
        value={title}
        onChange={e => setTitle(e.target.value)}
        inputProps={{ maxLength: 255 }}
        placeholder={t("Tasks:AddTaskName")}
        sx={titleStyles}
      />
      <Divider sx={{ mx: -1.5, mt: 1, mb: 1.6 }} />
      <ProcessTypesField
        processType={processType}
        setProcessType={p => setProcessType(p ?? ProcessType.APPROVAL)}
        isAllowedToEdit
      />
      <PriorityField priority={priority} setPriority={setPriority} isAllowedToEdit />
      <DescriptionField isAllowedToEdit description={description} setDescription={setDescription} />
      <DueDateField dueDate={dueDate} setDueDate={setDueDate} isAllowedToEdit />
      <SelectUserField
        acl={acl}
        user={assigneeOrganization}
        setUser={setAssigneeOrganization}
        selectType={SelectType.ASSIGNEE_ORGANIZATION}
        isAllowedToEdit
      />
      <SelectUserField
        acl={acl}
        user={assignedTo}
        setUser={setAssignedTo}
        selectType={SelectType.ASSIGNEE}
        isAllowedToEdit
      />
      <SelectUserField
        acl={acl}
        user={reviewerOrganization}
        setUser={setReviewerOrganization}
        selectType={SelectType.REVIEWER_ORGANIZATION}
        isAllowedToEdit
      />
      <SelectUserField
        acl={acl}
        user={reviewBy}
        setUser={setReviewBy}
        selectType={SelectType.REVIEWER}
        isAllowedToEdit
      />
      {shouldDisplayLinkedProcessings && (
        <LinkedProcessingsField
          foreignId={foreignId}
          selectedProcessingsId={linkedProcessings}
          setSelectedProcessingsId={setLinkedProcessings}
          isAllowedToEdit
        />
      )}
      <Box sx={{ mt: 2.5, textAlign: "right" }}>
        <Button variant="contained" disabled={inProgress} type="submit">
          {t("General:Create")}
        </Button>
      </Box>
    </form>
  );
};

const titleStyles = {
  mt: "-10px",
  ml: "-5px",
  width: "calc(100% + 10px)",
  "& .MuiOutlinedInput-root": { px: "5px", py: 1 },
  "& .MuiInputBase-input": {
    fontFamily: "'futura-pt-bold', Sans-serif",
    fontSize: "22px",
    lineHeight: "30px",
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: "transparent",
  },
} as const;

export default observer(AddTaskPopup);
