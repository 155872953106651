import { Step as JStep } from "react-joyride";

import { ConstructionSiteStore } from "stores/managerService/constructionSiteStore";
import { onboardingStore } from "stores/onboardingStore";

import { addEventListenerToTargetElement, getOnboardingLocaleId, getTargetSelector } from "./helpers";
import { OnboardingSelector, Step } from "./types";

export const getAppTourExtendedSteps = (constructionSiteStore: ConstructionSiteStore): Step[] => [
  {
    target: OnboardingSelector.MAP_VIEW_CONTAINER,
    content: getOnboardingLocaleId("MapDescription"),
    prefix: "#",
    placement: "center",
  },
  {
    target: OnboardingSelector.CONSTRUCTION_SITES_BOTTOM_PANEL,
    content: getOnboardingLocaleId("ConstructionSitesBottomPanelDescription"),
    offset: 15,
    onClose: constructionSiteStore.getAll,
    prefix: "#",
  },
  {
    target: OnboardingSelector.CREATE_NEW_CONSTRUCTION_SITE_BUTTON,
    content: getOnboardingLocaleId("CreateConstructionSiteButtonDescription"),
    spotlightClicks: true,
    hideFooter: true,
    prefix: "#",
  },
  {
    target: OnboardingSelector.MAP_TOOLBAR,
    content: getOnboardingLocaleId("ToolbarDescription"),
    offset: 30,
  },
  {
    target: OnboardingSelector.KML_UPLOAD_BUTTON,
    content: getOnboardingLocaleId("KmlUploadButton"),
    prefix: "#",
  },
  {
    target: OnboardingSelector.MAP_TOOLBAR_TRASH_BUTTON,
    content: getOnboardingLocaleId("DeleteDefaultConstructionSiteLocation"),
    spotlightClicks: true,
    hideFooter: true,
    callback: addEventListenerToTargetElement(OnboardingSelector.MAP_TOOLBAR_TRASH_BUTTON, onboardingStore.nextStep),
  },
  {
    target: OnboardingSelector.MAP_TOOLBAR_RECTANGLE_BUTTON,
    content: getOnboardingLocaleId("SelectRectangleTool"),
    spotlightClicks: true,
    hideFooter: true,
    callback: addEventListenerToTargetElement(
      OnboardingSelector.MAP_TOOLBAR_RECTANGLE_BUTTON,
      onboardingStore.nextStep
    ),
    spotlightPadding: 0,
  },
  {
    target: OnboardingSelector.MAP_VIEW_CONTAINER,
    content: getOnboardingLocaleId("DeterminateConstructionSiteLocation"),
    spotlightClicks: true,
    spotlightPadding: -200,
    checkBeforeNextStep: constructionSiteStore.checkConstructionSiteElements,
    prefix: "#",
  },
  {
    target: OnboardingSelector.START_CREATING_CONSTRUCTION_SITE,
    content: getOnboardingLocaleId("SaveConstructionSite"),
    placement: "right",
    hideFooter: true,
    spotlightClicks: true,
    prefix: "#",
  },
  {
    target: OnboardingSelector.ADD_NEW_AREA,
    content: getOnboardingLocaleId("CreateNewArea"),
    hideFooter: true,
    spotlightClicks: true,
    prefix: "#",
  },
  {
    target: OnboardingSelector.MAP_TOOLBAR,
    content: getOnboardingLocaleId("DeterminateAreaLocation"),
    disableOverlay: true,
    offset: 10,
    hideFooter: true,
  },
  {
    target: OnboardingSelector.ADD_NEW_JOB,
    content: getOnboardingLocaleId("CreateNewJob"),
    hideFooter: true,
    spotlightClicks: true,
    prefix: "#",
  },
  {
    target: OnboardingSelector.MAP_TOOLBAR,
    content: getOnboardingLocaleId("DeterminateJobLocation"),
    disableOverlay: true,
    offset: 10,
    hideFooter: true,
    spotlightClicks: true,
  },
  {
    target: OnboardingSelector.MAP_TOOLBAR,
    content: getOnboardingLocaleId("SelectJustCreatedJob"),
    disableOverlay: true,
    offset: 10,
    hideFooter: true,
    spotlightClicks: true,
  },
  {
    target: OnboardingSelector.ADD_NEW_PROCESSING,
    content: getOnboardingLocaleId("CreateNewProcessing"),
    hideFooter: true,
    spotlightClicks: true,
    prefix: "#",
  },
  {
    target: OnboardingSelector.MAP_TOOLBAR,
    content: getOnboardingLocaleId("SelectJustCreatedProcessing"),
    disableOverlay: true,
    offset: 10,
    hideFooter: true,
    spotlightClicks: true,
  },
  {
    target: OnboardingSelector.MAP_TOOLBAR,
    content: getOnboardingLocaleId("ProcessingFilesDescription"),
    disableOverlay: true,
    offset: 10,
    spotlightClicks: true,
  },
  {
    target: OnboardingSelector.MAP_VIEW_CONTAINER,
    content: getOnboardingLocaleId("FinalDescription"),
    prefix: "#",
    placement: "center",
  },
];

export const getAppTourSteps = (constructionSiteStore: ConstructionSiteStore): JStep[] =>
  getAppTourExtendedSteps(constructionSiteStore).map(s => ({
    ...s,
    target: getTargetSelector(s.target, s.prefix),
  }));
