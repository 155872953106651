import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import GpsFixedRoundedIcon from "@mui/icons-material/GpsFixedRounded";
import PolylineRoundedIcon from "@mui/icons-material/PolylineRounded";
import RectangleRoundedIcon from "@mui/icons-material/RectangleRounded";
import { ListItemIcon, ListItemText, MenuItem, MenuList, SxProps, Theme } from "@mui/material";

import { MeasurementType } from "stores/measurementStore/types";

import { MeasurementSection } from "./types";

interface MeasurementsSectionsListProps {
  setMeasurementType: (measurementType: MeasurementType) => void;
}

const MeasurementsSectionsList: FC<MeasurementsSectionsListProps> = ({ setMeasurementType }) => {
  const { t } = useTranslation();

  const measurementSections: MeasurementSection[] = [
    {
      icon: <GpsFixedRoundedIcon />,
      title: t("Measurements:SinglePoints"),
      type: MeasurementType.SINGLE_POINT,
    },
    {
      icon: <PolylineRoundedIcon />,
      title: t("Measurements:Lines"),
      type: MeasurementType.LINE,
    },
    {
      icon: <RectangleRoundedIcon />,
      title: t("Measurements:Polygons"),
      type: MeasurementType.POLYGON,
    },
  ];
  return (
    <MenuList>
      {measurementSections.map(({ icon, title, type }) => (
        <MenuItem sx={menuItemStyles} key={title} onClick={() => setMeasurementType(type)}>
          <ListItemIcon sx={{ color: "#fff" }}>{icon}</ListItemIcon>
          <ListItemText>{t(title)}</ListItemText>
        </MenuItem>
      ))}
    </MenuList>
  );
};

const menuItemStyles: SxProps<Theme> = theme => ({
  borderRadius: 2,

  "&:hover": {
    bgcolor: theme.palette.background.paper,
  },
});

export default MeasurementsSectionsList;
