import React, { FC } from "react";
import ClearRoundedIcon from "@mui/icons-material/ClearRounded";
import { Stack } from "@mui/material";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { DateTimePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import DateRangeItemSelector from "./DateRangeItemSelector";

interface RangeDatePickerProps {
  startDateValue: Date | null;
  endDateValue: Date | null;
  setStartDateValue: (value: Date | null) => void;
  setEndDateValue: (value: Date | null) => void;
  startLabel?: string;
  endLabel?: string;
}

const RangeDatePicker: FC<RangeDatePickerProps> = ({
  startDateValue,
  endDateValue,
  setStartDateValue,
  setEndDateValue,
  startLabel,
  endLabel,
}) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Stack
        direction={"row"}
        sx={{ "& .MuiSvgIcon-root": { color: "#fff" } }}
        width={"100%"}
        spacing={0.5}
        alignItems={"center"}>
        <DateRangeItemSelector value={startDateValue} setValue={setStartDateValue} label={startLabel} />
        <Typography textAlign={"center"}>—</Typography>
        <DateRangeItemSelector value={endDateValue} setValue={setEndDateValue} label={endLabel} />
      </Stack>
    </LocalizationProvider>
  );
};

export default RangeDatePicker;
