import { useRootStore } from "hooks/useRootStore";;
import React, { FC, FormEvent, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Button from "@mui/material/Button";
import Fade from "@mui/material/Fade";
import Typography from "@mui/material/Typography";



const ConfirmDeletion: FC<{ processingId: string; jobId: string }> = ({ processingId, jobId }) => {
  const { t } = useTranslation();
  const { processingStore, uiStore } = useRootStore();

  useEffect(() => () => processingStore.resetStore(), []);

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    await processingStore.delete(processingId, jobId);
    processingStore.setDeleteId("");
    uiStore.closeLastModal();
  };

  return (
    <Fade in>
      <form onSubmit={handleSubmit}>
        <Typography variant="body1" component="div" sx={{ my: 1.75 }}>
          {t("Processings:ConfirmDeletion")}
        </Typography>
        <Button variant="contained" type="submit">
          {t("General:Delete")}
        </Button>
        <Button variant="contained" color="secondary" onClick={() => uiStore.closeLastModal()} sx={{ ml: 1 }}>
          {t("General:Cancel")}
        </Button>
      </form>
    </Fade>
  );
};

export default ConfirmDeletion;
