import { CognitoUserAttribute } from "amazon-cognito-identity-js";
import { makeAutoObservable } from "mobx";

import RootStore from "stores/rootStore";

import { reloadImg } from "helpers/functions";

import userService from "./service";

export class SettingsStore {
  imageSrc = "";

  constructor(private readonly rootStore: RootStore) {
    makeAutoObservable(this);
  }

  setImageSrc(imageSrc: string) {
    this.imageSrc = imageSrc;
  }

  updateEmail(email: string) {
    this.rootStore.authStore.updateAttributes([{ Name: "email", Value: email } as CognitoUserAttribute]);
  }

  updatePassword(currentPassword: string, newPassword: string, confirmNewPassword: string) {
    this.rootStore.authStore.checkPasswordsMatch(newPassword, confirmNewPassword);
    this.rootStore.authStore.validatePassword(newPassword);
    this.rootStore.authStore.isPasswordValid &&
      this.rootStore.authStore.isPasswordMatch &&
      this.rootStore.authStore.changePassword(currentPassword, newPassword).then(() => {
        this.rootStore.uiStore.setSnackbarOpen("Settings:PasswordUpdated", "success");
      });
  }

  updateUserProfile(firstName: string, lastName: string, phoneNumber: string) {
    this.rootStore.authStore
      .updateAttributes([
        { Name: "given_name", Value: firstName } as CognitoUserAttribute,
        { Name: "family_name", Value: lastName } as CognitoUserAttribute,
        {
          Name: "phone_number",
          Value: phoneNumber,
        } as CognitoUserAttribute,
      ])
      .then(() => this.rootStore.uiStore.setSnackbarOpen("Settings:UserProfileUpdated", "success"));
  }

  updateCompanyProfile(
    company: string,
    industry: string,
    country: string,
    postcode: string,
    city: string,
    address: string,
    vatCode: string,
    website: string
  ) {
    this.rootStore.authStore
      .updateAttributes([
        { Name: "custom:company_name", Value: company } as CognitoUserAttribute,
        { Name: "custom:industries", Value: industry } as CognitoUserAttribute,
        { Name: "custom:country", Value: country } as CognitoUserAttribute,
        { Name: "custom:postcode", Value: postcode } as CognitoUserAttribute,
        { Name: "custom:city", Value: city } as CognitoUserAttribute,
        { Name: "address", Value: address } as CognitoUserAttribute,
        { Name: "custom:vat_code", Value: vatCode } as CognitoUserAttribute,
        { Name: "website", Value: website } as CognitoUserAttribute,
      ])
      .then(() => this.rootStore.uiStore.setSnackbarOpen("Settings:CompanyProfileUpdated", "success"));
  }

  async uploadAvatar(blobUrl: string) {
    const file = await userService.getAvatarAsFile(blobUrl);
    const username = this.rootStore.authStore.cognitoUser?.getUsername();
    const response = await userService.uploadAvatar(username, file);
    reloadImg(response.avatarProfileUrl);
    this.rootStore.uiStore.closeLastModal();
  }
}
