import { ApiResponse, ResponseStatus } from "../types";

type Handler<T> = (value: T) => void;

interface ApiResponseHandlers<T> {
  onSuccess: Handler<T>;
  onError: Handler<string>;
}

export const handleApiResponse = <T>(res: ApiResponse<T>, handlers?: Partial<ApiResponseHandlers<T>>): T | null => {
  if (res.status === ResponseStatus.ERROR) {
    handlers?.onError?.(res.payload.error);
    return null;
  }

  handlers?.onSuccess?.(res.payload);
  return res.payload;
};
