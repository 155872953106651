import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import Typography from "@mui/material/Typography";

interface NoDataMessageProps {
  message?: string;
}

export const NoDataMessage: FC<NoDataMessageProps> = ({ message }) => {
  const { t } = useTranslation();

  return (
    <Typography variant="body1" component="div" sx={textStyles}>
      {message ?? t("General:NoData")}
    </Typography>
  );
};

const textStyles = {
  mt: 1,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  height: "80px",
};
