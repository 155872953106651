import { useRootStore } from "hooks/useRootStore";;
import React, { MouseEvent, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import CreateNewFolderIcon from "@mui/icons-material/CreateNewFolder";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";

import { getInputAccept } from "helpers/functions";

import { handleInputFiles } from "../handleInputFiles";

import List from "./List";

const InfoPanel = () => {
  const { t } = useTranslation();
  const rootStore = useRootStore();
  const { fileStore } = rootStore;

  const inputRef = useRef<HTMLInputElement>(null);
  const { fileType } = fileStore.infoPanel;

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.setAttribute("directory", "");
      inputRef.current.setAttribute("webkitdirectory", "");
    }
  }, [inputRef]);

  if (!fileType) {
    return null;
  }

  const inputAccept = getInputAccept(fileType.ext);

  const handleClick = (e: MouseEvent<HTMLInputElement>) => {
    e.currentTarget.value = "";
  };

  return (
    <>
      <Box sx={containerStyles}>
        <Box sx={topStyles}>
          <Box sx={titleStyles}>
            <Typography variant="body1" component="div" align="center" fontWeight={500}>
              {t(`Files:${name}`)}
            </Typography>
            <Button
              component="label"
              size="small"
              variant="contained"
              color="secondary"
              sx={btnStyles}
              disabled={fileType.ext === "csv"}>
              <input
                type="file"
                accept={inputAccept}
                multiple={fileType.ext !== "csv"}
                hidden
                onClick={handleClick}
                onChange={async e => await handleInputFiles(e, fileType, false, fileStore, rootStore)}
              />
              <AddRoundedIcon fontSize="small" />
            </Button>
            {(fileType.name === "IMAGE" || fileType.name === "CONFIDENCE" || fileType.name === "DEPTHMAP") && (
              <Button component="label" size="small" variant="contained" color="secondary" sx={btnStyles}>
                <input
                  ref={inputRef}
                  type="file"
                  hidden
                  onClick={handleClick}
                  onChange={async e => await handleInputFiles(e, fileType, true, fileStore, rootStore)}
                />
                <CreateNewFolderIcon fontSize="small" />
              </Button>
            )}
          </Box>
          <Button
            size="small"
            variant="contained"
            color="secondary"
            sx={{ px: 0.5, minWidth: "unset" }}
            onClick={() => fileStore.removeAllFilesFromInput(name!)}>
            <Typography variant="caption" sx={{ ml: 0.25, mr: 0.5 }}>
              {t("Files:ClearAll")}
            </Typography>
          </Button>
        </Box>
        <List />
      </Box>
    </>
  );
};

const containerStyles = { display: "inline-block", pt: 1 };

const topStyles = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};

const titleStyles = {
  display: "inline-flex",
  margin: "auto",
  transform: "translateX(36px)",
};

const btnStyles = { height: "28px", width: "28px", minWidth: "unset", ml: 1 };

export default InfoPanel;
