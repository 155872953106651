import React, { FC, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { yupResolver } from "@hookform/resolvers/yup";
import TextField from "@mui/material/TextField";

import { UNKNOWN_SERVER_ERROR } from "constants/defaultValidatingRules";
import { useRootStore } from "hooks/useRootStore";

import { DescriptionSchema, descriptionSchema } from "./schema";

interface DescriptionProps {
  description: string;
  onUpdateDescription: (description: string) => Promise<void>;
}

const Description: FC<DescriptionProps> = ({ description, onUpdateDescription }) => {
  const { t } = useTranslation();
  const { teamStore } = useRootStore();

  const { csPermissions } = teamStore;

  const {
    handleSubmit,
    register,
    formState: { errors },
    setValue,
    setError,
    clearErrors,
  } = useForm<DescriptionSchema>({
    mode: "onBlur",
    resolver: yupResolver(descriptionSchema),
  });

  useEffect(() => {
    setValue("description", description);
    return clearErrors;
  }, [clearErrors, description, setValue]);

  const handleOnBlurSubmit = async ({ description }: DescriptionSchema) => {
    try {
      await onUpdateDescription(description);
      // await onUpdateEntityAfterUpdate?.();
    } catch (e) {
      setError("description", { message: UNKNOWN_SERVER_ERROR });
    }
  };

  return (
    <form onBlur={handleSubmit(handleOnBlurSubmit)}>
      <TextField
        size="small"
        fullWidth
        multiline
        maxRows={3}
        sx={editableDescriptionStyles}
        placeholder={t("General:TypeHere")}
        error={!!errors.description}
        helperText={errors.description?.message}
        {...register("description")}
        disabled={!csPermissions.creating}
      />
    </form>
  );
};

const editableDescriptionStyles = {
  "& .MuiInputBase-root": {
    padding: "4px 5px",
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: "transparent",
  },
};

export default Description;
