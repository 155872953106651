import React, { FC, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { observer } from "mobx-react-lite";

import { NOTIFICATION_TOPICS } from "stores/emailStore/constants";

import { Loader } from "common/Loader";
import { useRootStore } from "hooks/useRootStore";

import Notification from "./Notification";
import { LoadingIndicatorOverlay } from "common/LoadingIndicatorOverlay";

export const Notifications: FC = observer(() => {
  const { t } = useTranslation();
  const { emailStore } = useRootStore();

  const { unsubscribes } = emailStore;

  useEffect(() => {
    emailStore.getUnsubscribes();
  }, []);

  return (
    <Box>
      <Typography variant="h6" component="div">
        {t("Settings:Notifications")}
      </Typography>
      {unsubscribes ? (
        <Box sx={wrapperStyle}>
          {NOTIFICATION_TOPICS.map(topicName => (
            <Notification key={topicName} topicName={topicName} />
          ))}
        </Box>
      ) : (
        <LoadingIndicatorOverlay />
      )}
    </Box>
  );
});

const wrapperStyle = { display: "flex", flexDirection: "column", alignItems: "flex-start", mt: 2 };
