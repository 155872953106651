import { useRootStore } from "hooks/useRootStore";
import React, { FC, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { Link as RouterLink } from "react-router-dom";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Fade from "@mui/material/Fade";
import Link from "@mui/material/Link";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { observer } from "mobx-react-lite";

import LanguageSelector from "components/Sidebar/LanguageSelector";
import { absolutePath, PATHS } from "router/paths";

import ForceChangePassword from "./ForceChangePassword";

const Login: FC = () => {
  const { t } = useTranslation();
  const { authStore } = useRootStore();

  const { values, inProgress, error, cognitoUser, newUser } = authStore;
  const navigate = useNavigate();

  useEffect(() => {
    if (cognitoUser) {
      navigate(absolutePath(PATHS.HOME.INDEX));
    }
  }, [cognitoUser, navigate]);

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => authStore.setEmail(e.target.value);

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => authStore.setPassword(e.target.value);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    await authStore.login();
  };

  return (
    <Fade in>
      <Box sx={containerStyles}>
        <img src="/images/logo.svg" alt="Logo" />
        {newUser ? (
          <ForceChangePassword />
        ) : (
          <Box component="form" onSubmit={handleSubmit} sx={formStyles}>
            <Box
              sx={{
                alignSelf: "flex-end",
                mt: 4,
              }}>
              <LanguageSelector displayFlag={false} />
            </Box>
            <Typography variant="body1" sx={{ my: 1 }} component="div" color="error">
              {error}
            </Typography>
            <TextField
              required
              fullWidth
              variant="standard"
              id="email"
              label={t("General:Email")}
              type="email"
              name="email"
              autoComplete="email"
              value={values.email}
              onChange={handleEmailChange}
            />
            <TextField
              required
              fullWidth
              variant="standard"
              name="password"
              label={t("Auth:PasswordLabel")}
              type="password"
              id="password"
              autoComplete="current-password"
              value={values.password}
              onChange={handlePasswordChange}
              sx={{ mt: 2 }}
            />
            <Link
              component={RouterLink}
              to="/login/new-password"
              variant="body2"
              underline="hover"
              sx={{
                mt: 2,
                color: "text.primary",
              }}>
              {t("Auth:ForgotPassword")}?
            </Link>
            <Button
              type="submit"
              fullWidth
              color="secondary"
              variant="contained"
              sx={{ mt: 4 }}
              disabled={inProgress}
              disableElevation>
              {t("Auth:Login")}
            </Button>
            {/* <Box sx={bottomStyles}>
             <Typography variant="body2" component="span">
               {t("Auth:SignUpMessage")}
             </Typography>
             <Link
               component={RouterLink}
               to={absolutePath(PATHS.REGISTER.INDEX)}
               variant="body2"
               underline="hover"
               sx={{ ml: 2 }}>
               {t("Auth:SignUp")}
             </Link>
            </Box> */}
          </Box>
        )}
      </Box>
    </Fade>
  );
};

const containerStyles = {
  width: "100%",
  height: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  bgcolor: "background.default",
  "& img": {
    maxWidth: "160px",
  },
} as const;

const formStyles = {
  maxWidth: "340px",
  display: "flex",
  flexDirection: "column",
  width: "100%",
  alignItems: "center",
} as const;

const bottomStyles = {
  mt: 2,
  display: "flex",
  alignItems: "center",
};

export default observer(Login);
