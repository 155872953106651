import React, { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Box } from "@mui/material";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { observer } from "mobx-react-lite";

import { IParameters } from "stores/managerService/processingStore/types";
import { useRootStore } from "hooks/useRootStore";

export const Float: FC<{ parameter: IParameters }> = observer(({ parameter }) => {
  const { t } = useTranslation();
  const { processingStore } = useRootStore();
  const [value, setValue] = useState<string>("");
  const { parameterId, defaultValue } = parameter;

  useEffect(() => {
    processingStore.setUpdatedParams(parameter.parameterId, value);
  }, [value]);

  return (
    <Box key={parameterId}>
      <Typography variant="body1">{t(`Processings:Parameters:${parameterId}:parameterName`)}</Typography>
      <Typography variant="body2" sx={{ color: "text.secondary" }}>
        {t(`Processings:Parameters:${parameterId}:description`)}
      </Typography>
      <TextField
        type="number"
        size="small"
        key={parameterId}
        placeholder={defaultValue.toFixed(2)}
        value={value}
        onChange={e => {
          setValue(e.target.value);
        }}
        inputProps={{ maxLength: 10 }}
        sx={{ my: 1, ml: 0.5 }}
      />
    </Box>
  );
});
