import React from "react";
import { useTranslation } from "react-i18next";
import { Paper, SxProps, Theme, Typography } from "@mui/material";
import { observer } from "mobx-react-lite";

import { Attributes } from "stores/mapStore/types";

import { useRootStore } from "hooks/useRootStore";

const MapItemInfo = () => {
  const { t } = useTranslation();
  const { mapStore } = useRootStore();

  const { hoveredGraphic } = mapStore;
  const attributes = hoveredGraphic?.attributes as Attributes;

  if (!hoveredGraphic) {
    return null;
  }

  return (
    <Paper variant={"outlined"} sx={infoWrapperStyles}>
      <Typography sx={headerStyles}>{attributes?.title ?? t(`General:${attributes?.id}`)}</Typography>
      <Typography sx={descriptionStyles}>{attributes?.description || t(`General:NoDescr`)}</Typography>
    </Paper>
  );
};

const infoWrapperStyles: SxProps<Theme> = theme => ({
  zIndex: 5,
  position: "fixed",
  right: "20%",
  top: 100,
  bgcolor: theme.palette.background.default,
  padding: theme.spacing(1),
  minWidth: 250,
  maxWidth: 350,
  transition: "0.5s",
  transform: "translateX(50%)",
});

const headerStyles: SxProps<Theme> = theme => ({
  color: theme.palette.primary.main,
  fontWeight: "bold",
  fontSize: 18,
});

const descriptionStyles: SxProps<Theme> = {
  fontSize: 16,
  maxWidth: 300,
};

export default observer(MapItemInfo);
