export const PATHS = {
  HOME: {
    INDEX: "/",
    CS_CARD: (id = ":id") => `construction_site/${id}`,
    AREA_CARD: (id = ":id") => `area/${id}`,
    JOB_CARD: (id = ":id") => `job/${id}`,
    PROCESSING_CARD: (id = ":id") => `processing/${id}`,
  },
  TASKS: {
    INDEX: "tasks",
    CONSTRUCTION_SITE: ":constructionSiteId",
  },
  TEAMS: "teams",
  HELP: "help",
  SETTINGS: {
    INDEX: "settings",
    USER: "user",
    COMPANY: "company",
    ACCOUNT: "account",
    NOTIFICATIONS: "notifications",
  },
  REGISTER: {
    INDEX: "register",
    CONFIRM: "confirm",
    NEW_CODE: "new-code",
  },
  LOGIN: {
    INDEX: "login",
    NEW_PASSWORD: "new-password",
  },
  METRICS: "metrics",
} as const;

const ABSOLUTE_SYMBOL = "/";
export const absolutePath = (path: string): string => (path[0] === ABSOLUTE_SYMBOL ? path : `/${path}`);
export const nestedRoute = (...routes: string[]): string => routes.join("/");
export const pathWithParams = (...params: Array<string | number | null | undefined>) =>
  ABSOLUTE_SYMBOL +
  params
    .filter(p => {
      if (!p) {
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        console.warn(`param is ${p}`);
        return false;
      }

      return true;
    })
    .join(ABSOLUTE_SYMBOL);
