import { useRootStore } from "hooks/useRootStore";
import React, { FC, useEffect } from "react";
import { useTranslation } from "react-i18next";
import AddIcon from "@mui/icons-material/Add";
import PeopleAltOutlinedIcon from "@mui/icons-material/PeopleAltOutlined";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Fade from "@mui/material/Fade";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { observer } from "mobx-react-lite";

import { Member, TeamRole } from "stores/managerService/teamStore/types";

import AddMemberPopup from "../../Teams/AddMemberPopup";
import { stringAvatar } from "helpers/functions";
import { IConstructionSite } from "stores/managerService/constructionSiteStore/types";
import { GetTeamBy } from "stores/managerService/teamStore/types";

export const getFullName = (member: Member) => {
  if (member.given_name && member.family_name) {
    return `${member.given_name} ${member.family_name}`;
  } else {
    return member.email;
  }
};

const Team: FC<{ constructionSite: IConstructionSite }> = ({ constructionSite }) => {
  const { t } = useTranslation();
  const { teamStore, uiStore } = useRootStore();

  const { constructionSiteTeamMembers, getCurrentUserRoleInCS, currentUserRoleInCS } = teamStore;

  useEffect(() => {
    teamStore.getCSTeamMembers(GetTeamBy.ACL, constructionSite.acl);
    return () => teamStore.setConstructionSiteTeamMembers(null);
  }, [constructionSite]);

  useEffect(() => {
    if (constructionSiteTeamMembers) getCurrentUserRoleInCS(constructionSite.id);
  }, [constructionSiteTeamMembers]);

  return (
    <Box sx={{ flex: "50%" }}>
      <Box sx={wrapperStyles}>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <PeopleAltOutlinedIcon />
          <Typography variant="body1" component="div" fontWeight={600} sx={{ ml: 0.8 }}>
            {t("Team:Team")}
          </Typography>
        </Box>
      </Box>
      <Box sx={avatarsStyles}>
        {constructionSiteTeamMembers?.map((member: Member) => (
          <Fade in key={member.id}>
            <Tooltip title={getFullName(member)}>
              <Avatar
                {...(member.given_name &&
                  member.family_name &&
                  stringAvatar(`${member.given_name} ${member.family_name}`))}
                src={
                  member["custom:picture-mini"] ? "data:image/jpeg;base64," + member["custom:picture-mini"] : undefined
                }
              />
            </Tooltip>
          </Fade>
        ))}
        {currentUserRoleInCS !== TeamRole.GUEST && (
          <Tooltip title={t("General:Add")}>
            <Avatar
              onClick={() =>
                uiStore.openModal(
                  <AddMemberPopup constructionSiteId={constructionSite.id} currentUserRole={currentUserRoleInCS} />
                )
              }>
              <AddIcon />
            </Avatar>
          </Tooltip>
        )}
      </Box>
    </Box>
  );
};

const avatarsStyles = {
  display: "flex",
  mb: 2,
  "& .MuiAvatar-root": {
    mr: 0.5,
    fontSize: "1rem",
    color: "text.primary",
    cursor: "pointer",
  },
  "& .MuiAvatar-root:last-child": {
    mr: 0,
    backgroundColor: "transparent",
    border: "1px solid",
    borderColor: "text.primary",
    transition: "all 0.3s",
    "& .MuiSvgIcon-root": {
      color: "text.primary",
      transition: "all 0.3s",
    },
    "&:hover": {
      cursor: "pointer",
      borderColor: "primary.main",
      "& .MuiSvgIcon-root": {
        color: "primary.main",
      },
    },
  },
};

const wrapperStyles = {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  mb: 1,
  ml: "-3px",
};

export default observer(Team);
