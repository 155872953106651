import { Navigate, RouteObject } from "react-router-dom";

import Confirm from "components/Auth/Confirm";
import ForgotPassword from "components/Auth/ForgotPassword";
import Login from "components/Auth/Login";
import Register from "components/Auth/Register";
import ResendCode from "components/Auth/ResendCode";
import ConstructionSite from "components/ConstructionSite";
import Areas from "components/ConstructionSite/Area/Areas";
import AreaCard from "components/ConstructionSite/Area/Card";
import JobCard from "components/ConstructionSite/Job/Card";
import ProcessingFiles from "components/ConstructionSite/Processings/ProcessingFiles";
import { Metrics } from "components/Metrics";
import { Settings } from "components/Settings";
import { Account } from "components/Settings/Account";
import CompanyProfile from "components/Settings/CompanyProfile";
import { Notifications } from "components/Settings/Notifications";
import { UserProfile } from "components/Settings/UserProfile";
import Tasks from "components/Tasks";
import ConstructionSiteTasks from "components/Tasks/ConstructionSiteTasks";
import Teams from "components/Teams";

import { absolutePath, PATHS } from "./paths";

interface PrivateRoute {
  private?: boolean;
}
type Route = PrivateRoute & RouteObject;

const routesConfig: Route[] = [
  {
    path: PATHS.HOME.INDEX,
    element: <ConstructionSite />,
    private: true,
    children: [
      {
        path: PATHS.HOME.CS_CARD(),
        element: <Areas />,
      },
      {
        path: PATHS.HOME.AREA_CARD(),
        element: <AreaCard />,
      },
      {
        path: PATHS.HOME.JOB_CARD(),
        element: <JobCard />,
      },
      {
        path: PATHS.HOME.PROCESSING_CARD(),
        element: <ProcessingFiles />,
      },
    ],
  },
  {
    path: PATHS.TASKS.INDEX,
    private: true,
    children: [
      {
        index: true,
        element: <Tasks />,
      },
      {
        path: PATHS.TASKS.CONSTRUCTION_SITE,
        element: <ConstructionSiteTasks />,
      },
    ],
  },
  {
    path: PATHS.TEAMS,
    element: <Teams />,
    private: true,
  },
  // { path: PATHS.METRICS, element: <Metrics />, private: true },
  {
    path: PATHS.SETTINGS.INDEX,
    private: true,
    element: <Settings />,
    children: [
      {
        index: true,
        element: <UserProfile />,
      },
      {
        path: PATHS.SETTINGS.COMPANY,
        element: <CompanyProfile />,
      },
      {
        path: PATHS.SETTINGS.ACCOUNT,
        element: <Account />,
      },
      {
        path: PATHS.SETTINGS.NOTIFICATIONS,
        element: <Notifications />,
      },
    ],
  },
  {
    path: PATHS.REGISTER.INDEX,
    children: [
      {
        index: true,
        element: <Register />,
      },
      {
        path: PATHS.REGISTER.CONFIRM,
        element: <Confirm />,
      },
      {
        path: PATHS.REGISTER.NEW_CODE,
        element: <ResendCode />,
      },
    ],
  },
  {
    path: PATHS.LOGIN.INDEX,
    children: [
      {
        index: true,
        element: <Login />,
      },
      {
        path: PATHS.LOGIN.NEW_PASSWORD,
        element: <ForgotPassword />,
      },
    ],
  },
];

export const getRoutes = (cognitoUser: boolean): Route[] =>
  routesConfig.map(r => {
    const element = !r.private || cognitoUser ? r.element : <Navigate to={absolutePath(PATHS.LOGIN.INDEX)} />;
    return { ...r, element };
  });
