import { useRootStore } from "hooks/useRootStore";;
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import Box from "@mui/material/Box";
import Fade from "@mui/material/Fade";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { observer } from "mobx-react-lite";



import DownloadButton from "../Buttons/DownloadButton";

const Reports: FC = () => {
  const { t } = useTranslation();
  const { fileStore } = useRootStore();

  const { reports } = fileStore;

  return (
    <>
      <Typography variant="body1" component="div" fontWeight={300} sx={{ mb: 1 }}>
        {t("Processings:Reports")}
      </Typography>
      {reports?.map(report => (
        <Fade in key={report.id}>
          <Paper sx={itemWrapperStyles} elevation={0}>
            <Box sx={leftStyles}>
              <Box>
                <CheckRoundedIcon color="success" fontSize="small" sx={{ mr: 1 }} />
                <Typography variant="body1" component="div" fontWeight={500}>
                  {t(`Files:${report.type}`)}
                </Typography>
                <Typography variant="overline" component="div" fontWeight={300} sx={fileTypeStyles}>
                  {t(`Files:${report.fileMeta.type}`)}
                </Typography>
              </Box>
            </Box>
            <DownloadButton file={report} />
          </Paper>
        </Fade>
      ))}
    </>
  );
};

const itemWrapperStyles = {
  mb: 0.5,
  height: "50px",
  display: "flex",
  alignItems: "center",
  bgcolor: "secondary.main",
  borderRadius: "5px",
  "&:last-of-type": {
    mb: 0,
  },
};

const leftStyles = {
  pl: 2,
  width: "calc(50% + 30px)",
  borderRadius: "5px 40px 40px 5px",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  bgcolor: "secondary.light",
  zIndex: 20,
  height: "48px",
  "& .MuiBox-root": {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
  },
};

const fileTypeStyles = {
  ml: 1,
  fontSize: "14px",
  lineHeight: "16px",
  color: "#ADADAD",
};

export default observer(Reports);
