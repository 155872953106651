import { EffectCallback } from "react";
import { faker } from "@faker-js/faker";

import { IConstructionSite } from "stores/managerService/constructionSiteStore/types";

import { ConstructionSiteStore } from "../../../stores/managerService/constructionSiteStore";

import { OnboardingSelector, Prefix } from "./types";

const SPOTLIGHT_CLASS_NAME = "react-joyride__spotlight";
const getSpotlightElement = () => document.getElementsByClassName(SPOTLIGHT_CLASS_NAME)[0];

export const dispatchSpotlightEvent = (eventType: string, spotlightElement?: Element) => {
  const spotlight = spotlightElement || getSpotlightElement();
  spotlight?.dispatchEvent(new Event(eventType));
};

export const getTargetSelector = (target: OnboardingSelector, prefix?: Prefix) => `${prefix || ""}${target}`;

export const addEventListenerToTargetElement =
  (target: OnboardingSelector | Element, eventListener: () => void, eventType: string = "click"): EffectCallback =>
  () => {
    const element = typeof target === "string" ? document.querySelector(target) : target;
    element?.addEventListener(eventType, eventListener);
    return () => element?.removeEventListener(eventType, eventListener);
  };

const getMockConstructionSite = () =>
  ({
    title: faker.address.street(),
    map: faker.datatype.json(),
  } as IConstructionSite);
export const fillMockConstructionSitesData = (constructionSiteStore: ConstructionSiteStore) => {
  const fakeData = faker.helpers.uniqueArray<IConstructionSite>(getMockConstructionSite, 7);
  constructionSiteStore.constructionSites = fakeData;
};

export const resetConstructionSites = (constructionSiteStore: ConstructionSiteStore) => {
  constructionSiteStore.setConstructionSites([]);
};

export const changeSpotlightByChangingTargetElement =
  (target: OnboardingSelector, eventType: string, prefix?: Prefix): EffectCallback =>
  () => {
    const targetElement = document.querySelector(getTargetSelector(target, prefix));
    const spotlightElement = getSpotlightElement();

    return addEventListenerToTargetElement(
      targetElement!,
      // () => dispatchSpotlightEvent(eventType, spotlightElement),
      () => console.log("123"),
      eventType
    )();
  };

export const getOnboardingLocaleId = (key: string) => `Onboarding:${key}`;
