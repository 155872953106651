import { useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { LOCALES } from "constants/locales";
import { useFetchWithLoading } from "hooks";
import { FetchingState } from "hooks/types";
import { useRootStore } from "hooks/useRootStore";

import { statusErrorsHandlers } from "./helpers";
import { UrlParam } from "./types";

const useFetchMapManagerData = (
  id: UrlParam,
  fetchDataFun: (id: string) => Promise<any>,
  clearDataFun: () => void,
  isItemExists: boolean = false
): FetchingState => {
  const navigate = useNavigate();

  const {
    mapStore: { isAllMapInit, clearCSAndNestedAndFetchAllCS },
    uiStore: { setSnackbarOpen },
  } = useRootStore();

  const checkIdAndFetchData = useCallback(async () => {
    try {
      if (id) {
        await fetchDataFun(id);
      } else {
        console.warn("`id` is not exists`");
      }
    } catch (e: any) {
      const errorHandler = e.response.status ? statusErrorsHandlers[e.response.status] : null;
      if (errorHandler) {
        errorHandler(id as string, setSnackbarOpen, navigate);
      } else {
        setSnackbarOpen(LOCALES.ERRORS.SOME_ERROR);
      }
      console.error(e);

      clearCSAndNestedAndFetchAllCS();
    }
  }, [clearCSAndNestedAndFetchAllCS, fetchDataFun, id, navigate, setSnackbarOpen]);

  const [fetchWithLoading, isLoadingInProgress, isLoaded] = useFetchWithLoading(checkIdAndFetchData);

  useEffect(() => {
    if (!isItemExists && isAllMapInit) {
      fetchWithLoading();
    }
    // fetchWithLoading func trigger one extra useEffect call
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchDataFun, isAllMapInit, isItemExists, id]);

  // TODO: to implement clearing after implementing of nested of the map components
  // useEffect(() => {
  //   if (!isItemExists) {
  //     return clearDataFun;
  //   }
  //   // clearDataFun unnecessary
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [isItemExists]);

  return [isLoadingInProgress, isLoaded];
};

export default useFetchMapManagerData;
