import { useRootStore } from "hooks/useRootStore";
import React, { FC, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import ClearRoundedIcon from "@mui/icons-material/ClearRounded";
import PersonOutlineRoundedIcon from "@mui/icons-material/PersonOutlineRounded";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Typography from "@mui/material/Typography";
import { observer } from "mobx-react-lite";
import { GetTeamBy } from "stores/managerService/teamStore/types";
import { getFullName } from "components/ConstructionSite/Team";

export enum SelectType {
  ASSIGNEE = "Assignee",
  REVIEWER = "Reviewer",
  ASSIGNEE_ORGANIZATION = "ASSIGNEE_ORGANIZATION",
  REVIEWER_ORGANIZATION = "REVIEWER_ORGANIZATION",
}

interface SelectUserFieldProps {
  acl: string;
  user: string | undefined;
  setUser: React.Dispatch<React.SetStateAction<string | undefined>>;
  selectType: SelectType;
  isAllowedToEdit: boolean;
}

const SelectUserField: FC<SelectUserFieldProps> = ({ acl, user, setUser, selectType, isAllowedToEdit }) => {
  const { t } = useTranslation();
  const { constructionSiteStore, teamStore, areaStore } = useRootStore();

  const { constructionSites } = constructionSiteStore;
  const { constructionSiteTeamMembers, protectedAreaMembers } = teamStore;
  const { area } = areaStore;
  const [userSelectorOpen, setUserSelectorOpen] = useState(false);
  const [defaultOpen, setDefaultOpen] = useState(!user);
  const isAreaProtected = area?.isProtected;

  useEffect(() => {
    if (isAreaProtected) {
      teamStore.getProtectedAreaMembers(GetTeamBy.ACL, acl);
    } else {
      // const constructionSiteId = constructionSites?.find(cs => cs.acl === acl)?.id;
      if (!constructionSiteTeamMembers) {
        teamStore.getCSTeamMembers(GetTeamBy.ACL, acl);
      }
      if (constructionSiteTeamMembers && constructionSiteTeamMembers[0].acl !== acl) {
        teamStore.setConstructionSiteTeamMembers(null);
        teamStore.getCSTeamMembers(GetTeamBy.ACL, acl);
      }
    }
  }, [isAreaProtected, constructionSiteTeamMembers, acl, constructionSites]);

  useEffect(() => {
    if (!user) {
      setDefaultOpen(true);
    }
  }, [user]);

  const getMembersForRender = useCallback(() => {
    if (isAreaProtected) return protectedAreaMembers;
    else return constructionSiteTeamMembers;
  }, [isAreaProtected, protectedAreaMembers, constructionSiteTeamMembers]);

  return (
    <Box sx={wrapperStyles}>
      <PersonOutlineRoundedIcon />
      <Typography variant="body1" component="div" fontWeight={600} sx={{ ml: 1.2 }}>
        {t(`Tasks:${selectType}`)}
      </Typography>
      {!userSelectorOpen && !user ? (
        <Button
          size="small"
          variant="contained"
          color="secondary"
          sx={btnStyles}
          disabled={!isAllowedToEdit}
          onClick={() => setUserSelectorOpen(true)}>
          <AddRoundedIcon fontSize="small" />
        </Button>
      ) : getMembersForRender() ? (
        <FormControl
          sx={{
            ...selectorStyles,
            ...(userSelectorOpen && { width: "250px" }),
          }}
          size="small"
          focused={userSelectorOpen}>
          <Select
            disabled={!isAllowedToEdit}
            defaultOpen={defaultOpen}
            IconComponent={user ? () => null : undefined}
            displayEmpty
            renderValue={
              user !== ""
                ? undefined
                : () => (
                    <Typography variant="caption" component="div" color="#8b8b8b">
                      {t("Tasks:NoAssignee")}
                    </Typography>
                  )
            }
            value={user}
            onChange={e => setUser(e.target.value)}
            onOpen={() => setUserSelectorOpen(true)}
            onClose={() => setUserSelectorOpen(false)}
            endAdornment={
              isAllowedToEdit ? <ClearRoundedIcon fontSize="small" sx={clearIconStyles} onClick={() => setUser("")} /> : undefined
            }
            >
            {getMembersForRender()?.map(member => (
              <MenuItem
                value={member.userId}
                key={member.userId}
                sx={userSelectorOpen ? { width: "250px" } : undefined}>
                <Typography variant="caption" component="div">
                  {getFullName(member)}
                </Typography>
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      ) : (
        <Box sx={{ display: "flex" }}>
          <CircularProgress size={20} sx={{ ml: 1.5, color: "text.primary" }} />
        </Box>
      )}
    </Box>
  );
};

const btnStyles = {
  ml: 1.5,
  height: "28px",
  width: "28px",
  minWidth: "unset",
  "&:hover": { bgcolor: "rgba(255, 255, 255, 0.1)" },
};

const wrapperStyles = {
  display: "flex",
  alignItems: "center",
  height: "32px",
  mt: 0.5,
};

const selectorStyles = {
  ml: 1.5,
  maxWidth: "100%",
  "& .MuiOutlinedInput-root": { pr: 0 },
  "& .MuiSelect-select.MuiInputBase-input": {
    py: 0.8,
    height: "16px",
    minHeight: "16px",
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: "transparent",
  },
};

const clearIconStyles = {
  color: "text.primary",
  position: "absolute",
  right: "7px",
  top: "calc(50% - 0.5em)",
  cursor: "pointer",
  transition: "color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
  "&:hover": { color: "rgba(255, 255, 255, 0.5)" },
};

export default observer(SelectUserField);
