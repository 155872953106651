import { useRootStore } from "hooks/useRootStore";;
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Box, Button } from "@mui/material";
import { observer } from "mobx-react-lite";

import { onboardingStore } from "stores/onboardingStore";



const TourModal = () => {
  const { t } = useTranslation();
  const { uiStore } = useRootStore();

  const { startTour } = onboardingStore;

  const handleStart = () => {
    uiStore.closeLastModal();
    startTour();
  };

  return (
    <Box sx={tourModalBodyStyles}>
      {t("Onboarding:StartTourText")}
      <Box sx={buttonsGroupStyles}>
        <Button onClick={uiStore.closeLastModal}>{t("Onboarding:CancelStartAppTour")}</Button>
        <Button onClick={handleStart}>{t("Onboarding:StartTourButton")}</Button>
      </Box>
    </Box>
  );
};

const StartTourModal = () => {
  const { authStore, uiStore } = useRootStore();

  const {} = authStore; // need a new field: notPassTour
  const { openModal } = uiStore;

  useEffect(() => {
    if (false) {
      // need a new field: notPassTour
      openModal(<TourModal />);
    }
  }, []);

  return null;
};

const tourModalBodyStyles = {
  width: "100%",
  display: "flex",
  flexFlow: "column",
  justifyContent: "center",
  alignItems: "center",
};

const buttonsGroupStyles = {
  display: "flex",
  justifyContent: "flex-end",
  gap: 1,
  marginTop: 1,
};

export default observer(StartTourModal);
