import { NavigateFunction, Params } from "react-router-dom";
import { makeAutoObservable } from "mobx";
import { StringMap } from "types";

import RootStore from "stores/rootStore";

export class NavigateStore {
  public readonly navigate: NavigateFunction;

  private _params: Params<string> = {};

  public readonly setParams = (params: Params<string>) => {
    this._params = params;
  };

  public readonly getParams = <T extends Record<string, any> = StringMap>(): T => {
    return this._params as T;
  };

  constructor(rootStore: RootStore, navigate: NavigateFunction) {
    makeAutoObservable(this);
    this.navigate = navigate;
  }
}
