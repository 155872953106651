export enum TaskStatus {
  PENDING = "PENDING",
  IN_PROGRESS = "IN_PROGRESS",
  IN_REVIEW = "IN_REVIEW",
  COMPLETED = "COMPLETED",
}

export const TaskStatuses = Object.values(TaskStatus);

export enum TaskPriority {
  CRITICAL = "CRITICAL",
  MAJOR = "MAJOR",
  NORMAL = "NORMAL",
  MINOR = "MINOR",
  ON_HOLD = "ON_HOLD",
}

export interface TaskDto {
  [key: string]: any;
  acl: string;
  id: string;
  foreignId: string;
  title: string;
  status: TaskStatus;
  createdBy: string;
  createdAt: number;
  updatedAt: number;
  constructionSite: string;
  dueDate?: Date;
  assignedTo?: string;
  reviewBy?: string;
  description?: string;
  removedAt?: number;
  foreignType: TaskForeignType;
  processType: ProcessType;
  processings: string[];
  assigneeOrganization?: string;
  reviewerOrganization?: string;
  priority: TaskPriority;
  index: number;
}

export interface Task extends TaskDto {
  foreignTitle: string | null; // title of the job/area, linked to the task
}

export enum CommentType {
  USER_CREATED = "USER_CREATED",
  SYSTEM_CREATED = "SYSTEM_CREATED",
}

export interface Comment {
  id: string;
  content: string;
  createdBy: string;
  createdAt: number;
  commentType?: CommentType;
}

export interface TaskWithCommentsDto extends TaskDto {
  comments: Comment[];
}

export enum TaskParamsForUpdate {
  status = "status",
  assignedTo = "assignedTo",
  dueDate = "dueDate",
  approvedAt = "approvedAt",
  title = "title",
  description = "description",
  processType = "processType",
  reviewBy = "reviewBy",
  processings = "processings",
  priority = "priority",
  assigneeOrganization = "assigneeOrganization",
  reviewerOrganization = "reviewerOrganization",
}

export interface TaskCreateParams {
  foreignId: string;
  acl: string;
  title: string;
  description?: string;
  dueDate?: number;
  assignedTo?: string;
  status: string;
  foreignType: TaskForeignType;
  constructionSite: string;
  processType: ProcessType;
  reviewBy?: string;
  processings: string[];
  assigneeOrganization?: string;
  reviewerOrganization?: string;
  priority?: TaskPriority;
}
export enum TaskForeignType {
  CONSTRUCTION_SITE = "CONSTRUCTION_SITE",
  AREA = "AREA",
  JOB = "JOB",
}

export enum ProcessType {
  APPROVAL = "APPROVAL",
  MEASUREMENT_ACCOUNTING = "MEASUREMENT_ACCOUNTING",
  CONSTRUCTION_REPORT = "CONSTRUCTION_REPORT",
  DEFECT_MANAGEMENT = "DEFECT_MANAGEMENT",
  INCOMING_INSPECTION = "INCOMING_INSPECTION",
  CONDITION_ASSESSMENT = "CONDITION_ASSESSMENT",
}

export const enum TaskExportType {
  BCF = "BCF",
  JSON = "JSON",
  CSV = "CSV",
}

export type ExportJSONResponse = TaskWithCommentsDto[];
export type ExportCSVResponse = string;
