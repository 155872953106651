import axios from "axios";

import { baseUrl, protocol, Service } from "../../../configs/servicesConfig";
import { Position } from "../../mapStore/types";

import { IConstructionSite } from "./types";

const { PROJECT } = Service;

const constructionSites = {
  getAllConstructionSites: async () => {
    const response = await axios.get<{ payload: [] }>(`${protocol}//${baseUrl}/${PROJECT}/constructionSites?type=TEAM`);
    return response.data.payload;
  },

  getConstructionSite: async (id: string) => {
    const response = await axios.get<{ payload: IConstructionSite }>(
      `${protocol}//${baseUrl}/${PROJECT}/constructionSites/${id}`
    );
    return response.data.payload;
  },

  create: async (
    title: string,
    description: string,
    coordinateSystem: string,
    verticalCoordinateSystem: string,
    graphic: JSON,
    coordinates: Position
  ) => {
    const map = JSON.stringify({ graphic, coordinates });
    const params = () => {
      if (description) {
        return {
          title,
          description,
          coordinateSystem,
          verticalCoordinateSystem,
          map,
          type: "TEAM",
        };
      }
      return {
        title,
        coordinateSystem,
        verticalCoordinateSystem,
        map,
        type: "TEAM",
      };
    };
    const response = await axios.post(`${protocol}//${baseUrl}/${PROJECT}/constructionSites`, params());
    return response as { data: { payload?: IConstructionSite } };
  },

  update: async (id: string, name: string, value: string | null, map?: string) => {
    const response = await axios.put(
      `${protocol}//${baseUrl}/${PROJECT}/constructionSites/${id}`,
      map ? { [name]: value, map } : { [name]: value }
    );
    return response as { data: { payload?: IConstructionSite } };
  },

  delete: async (id: string, recycledComment: string) => {
    const response = await axios.delete(`${protocol}//${baseUrl}/${PROJECT}/constructionSites/${id}/recycle`, {
      data: { recycledComment },
    });
    return response;
  },
};

export default constructionSites;
