import { useMemo } from "react";

import { Task, TaskStatus } from "stores/taskStore/types";

import { useRootStore } from "hooks/useRootStore";

export const useIsAllowedToDeleteOrEdit = (task: Task): boolean => {
  const {
    authStore: { cognitoUser, userName },
  } = useRootStore();
  const currentUserId = cognitoUser?.getUsername();
  const taskOwner = currentUserId === task.createdBy;

  const isTaskCompleted = task.status === TaskStatus.COMPLETED;
  const isTaskLeftFromToDo = task.status !== TaskStatus.PENDING;

  return useMemo(() => {
    return (taskOwner || task.reviewBy === userName) && !isTaskCompleted && !isTaskLeftFromToDo;
  }, [taskOwner, task.reviewBy, userName, isTaskCompleted, isTaskLeftFromToDo]);
};
