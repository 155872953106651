import { FC, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Box, Card, Chip, Fade, Stack, SxProps, TextField, Theme, Typography } from "@mui/material";
import { observer } from "mobx-react-lite";

import { Task, TaskForeignType, TaskParamsForUpdate } from "stores/taskStore/types";

import { LOCALES } from "constants/locales";
import { useRootStore } from "hooks/useRootStore";

import TaskDetailsPopup from "../TaskDetails";
import { useIsAllowedToDeleteOrEdit } from "../utils/useIsAllowedToDeleteOrEdit";

import Actions from "./Actions";
import AffiliationInfo from "./AffiliationInfo";
import { TASK_CARD_PADDING } from "./constants";
import DatesAndAvatar from "./DatesAndAvatar";
import TaskMask from "./TaskMask";
import { Assignee } from "./types";
import { getForeignTypeNameLocale, getProcessTypeLocale } from "./utils";

interface CardProps {
  task: Task;
}

const TaskCard: FC<CardProps> = ({ task }) => {
  const { t } = useTranslation();

  const {
    teamStore: { constructionSiteTeamMembers },
    taskStore: { updateTask },
    tasksExportStore: { isSelected, exportMode, toggleExportTask },
    uiStore: { openModal },
    authStore,
    constructionSiteStore: { constructionSites },
  } = useRootStore();

  const inputRef = useRef<HTMLInputElement | null>(null);
  const [title, setTitle] = useState(task?.title);
  const [editTaskName, setEditTaskName] = useState(false);
  const [disableRipple, setDisableRipple] = useState(false);
  const [assignee, setAssignee] = useState<Assignee | undefined>();

  const isAllowedToDeleteOrEdit = useIsAllowedToDeleteOrEdit(task);

  useEffect(() => {
    if (constructionSiteTeamMembers && task && task.assignedTo) {
      const user = constructionSiteTeamMembers.find(member => member.userId === task.assignedTo);
      setAssignee(user);
    }
    if (task && task.assignedTo === authStore.cognitoUser?.getUsername()) {
      setAssignee({
        email: authStore.email,
        given_name: authStore.firstName,
        family_name: authStore.lastName,
        "custom:picture-mini": authStore.avatar.base64,
      });
    }
  }, [
    authStore.avatar.base64,
    authStore.cognitoUser,
    authStore.email,
    authStore.firstName,
    authStore.lastName,
    constructionSiteTeamMembers,
    task,
    task.assignedTo,
  ]);

  const finishEditingTitle = async () => {
    setEditTaskName(false);
    await updateTask(task.foreignId, task.id, TaskParamsForUpdate.title, title);
  };

  const handleClick = (task: Task) => {
    if (!exportMode)
      openModal(<TaskDetailsPopup task={task} />, {
        styles: {
          display: "flex",
          flexDirection: "column",
          width: "800px",
        },
      });
    else {
      toggleExportTask(task);
    }
  };

  const csTitle = useMemo<string | undefined>(
    () => constructionSites?.find(cs => cs.id === task.constructionSite)?.title,
    [constructionSites, task.constructionSite]
  );
  const isHasAffiliationAreaOrJob = useMemo(
    () =>
      task.foreignType &&
      task.foreignType !== TaskForeignType.CONSTRUCTION_SITE &&
      task.foreignTitle !== null &&
      task.foreignTitle !== undefined,
    [task.foreignTitle, task.foreignType]
  );

  return (
    <Fade in>
      <Card
        sx={{
          ...cardStyles(exportMode && !isSelected(task.id)),
          ...(editTaskName && {
            "& .MuiTouchRipple-root": { display: "none" },
          }),
        }}
        onClick={() => handleClick(task)}>
        <Box sx={cardHeaderStyles}>
          {task.index !== undefined && (
            <Typography variant={"h6"} sx={{ color: "text.disabled", position: "absolute", top: 10, right: 10 }}>
              #{task.index}
            </Typography>
          )}
          <TextField
            variant="standard"
            multiline
            fullWidth
            value={title}
            onChange={e => setTitle(e.target.value)}
            size="small"
            inputRef={inputRef}
            InputProps={{ disableUnderline: true }}
            sx={editTaskName ? taskNameActiveStyles : taskNameDisabledStyles}
            onClick={e => editTaskName && e.stopPropagation()}
            onBlur={finishEditingTitle}
          />
        </Box>
        <Box sx={cardBodyStyles}>
          <Box sx={actionsAndChipStyles}>
            <Box mr={2.2}>
              <Actions
                task={task}
                inputRef={inputRef}
                exportMode={exportMode}
                setEditTaskName={setEditTaskName}
                setDisableRipple={setDisableRipple}
                isAllowedToDeleteOrEdit={isAllowedToDeleteOrEdit}
              />
            </Box>
            {task.processType && (
              <Box sx={{ display: "flex", maxWidth: 151 }}>
                <Chip
                  label={t(getProcessTypeLocale(task.processType))}
                  sx={{ backgroundColor: "#3a3a3a" }}
                  variant={"outlined"}
                />
              </Box>
            )}
          </Box>
          {/* {exportMode && ( */}
          {/*   <CheckIcon sx={checkIconStyle} color={(isChecked(task) && "success") || "secondary"} fontSize="large" /> */}
          {/* )} */}
          <Stack sx={{ pt: 1.5 }} spacing={0.3}>
            {csTitle && <AffiliationInfo parentTypeTitle={t(LOCALES.CONSTRUCTION_SITE.SHORT)} title={csTitle} />}
            {isHasAffiliationAreaOrJob && (
              <AffiliationInfo
                parentTypeTitle={t(getForeignTypeNameLocale(task.foreignType))}
                /* eslint-disable-next-line @typescript-eslint/no-extra-non-null-assertion */
                title={task.foreignTitle!!} // task.foreignTitle is in the isHasAffiliationAreaOrJob
              />
            )}
          </Stack>
          <DatesAndAvatar task={task} assignee={assignee} />
        </Box>
        {exportMode && <TaskMask selected={isSelected(task.id)} />}
      </Card>
    </Fade>
  );
};

const cardStyles: (exportMode: boolean) => SxProps<Theme> = exportMode => ({
  position: "relative",
  minHeight: "130px",
  backgroundColor: "secondary.light",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  alignItems: "stretch",
  cursor: "pointer",
  borderRadius: "10px",
  textTransform: "none",
  width: "100%",
  transition: "all 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms!important",
  "& .MuiTypography-root": { textAlign: "left" },
  "&:hover": { bgcolor: "rgba(255, 255, 255, 0.2)" },
});

const cardHeaderStyles: SxProps<Theme> = {
  position: "relative",
  p: TASK_CARD_PADDING,
  backgroundColor: "#303030",
  width: "100%",
  height: 71,
};

const cardBodyStyles: SxProps<Theme> = {
  position: "relative",
  display: "flex",
  flexFlow: "column",
  justifyContent: "space-between",
  height: 126,
  p: TASK_CARD_PADDING,
  backgroundColor: "#3A3A3A",
};

const actionsAndChipStyles: SxProps<Theme> = {
  position: "absolute",
  top: -15,
  left: 0,
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  pl: TASK_CARD_PADDING,
  pr: TASK_CARD_PADDING,
  width: "100%",
};

const taskNameDisabledStyles: SxProps<Theme> = {
  "& .MuiInputBase-root.MuiInput-root": {
    cursor: "pointer",
    pointerEvents: "none",
  },
  "& .MuiInputBase-input.MuiInput-input": {
    cursor: "pointer",
    pointerEvents: "none",
  },
};

const taskNameActiveStyles: SxProps<Theme> = {
  "& .MuiInputBase-root.MuiInput-root": {
    cursor: "text",
    pointerEvents: "auto",
  },
  "& .MuiInputBase-input.MuiInput-input": {
    cursor: "text",
    pointerEvents: "auto",
  },
};

export default observer(TaskCard);
