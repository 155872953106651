import { useRootStore } from "hooks/useRootStore";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

import { IFile } from "stores/fileStore/types";

const DownloadButton: FC<{ file: IFile }> = ({ file }) => {
  const { t } = useTranslation();
  const { processingStore, fileStore } = useRootStore();

  const { processing } = processingStore;

  return (
    <Button
      sx={btnStyles}
      color="secondary"
      onClick={() =>
        processing &&
        fileStore.download(
          file.subsystem,
          file.storageProvider,
          processing.id,
          processing.acl,
          processing.projectId,
          file.storageMeta.key
        )
      }>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <FileDownloadOutlinedIcon fontSize="small" />
        <Typography sx={textStyles} variant="caption">
          {t("Files:Download")}
        </Typography>
      </Box>
    </Button>
  );
};

const btnStyles = {
  color: "text.primary",
  textTransform: "none",
  height: "100%",
  flex: "1",
  textAlign: "center",
  ml: "-24px",
  pl: "24px",
  zIndex: 10,
  borderRadius: "0 5px 5px 0",
  "& .MuiTypography-root": {
    transition: "color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
  },
  "& .MuiSvgIcon-root": {
    transition: "color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
  },
  "&:hover": {
    "& .MuiTypography-root": {
      color: "#ADADAD",
    },
    "& .MuiSvgIcon-root": {
      color: "#ADADAD",
    },
  },
} as const;

const textStyles = {
  ml: "6px",
  letterSpacing: "normal",
};

export default DownloadButton;
