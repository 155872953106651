import { FileExt, FileName, FileType } from "stores/fileStore/types";

import { CSVValidator } from "helpers/CSVValidator";
import { getFileExt } from "helpers/functions";
import { FileStore } from "stores/fileStore";
import RootStore from "stores/rootStore";

const acceptedFiles = (files: FileList, fileType: FileType): File[] => {
  return Array.from(files).filter(file => {
    const ext = getFileExt(file)?.toLocaleLowerCase();
    return (
      (fileType.name === FileName.IMAGE && (ext === FileExt.jpg || ext === FileExt.jpeg)) ||
      (fileType.name === FileName.CONFIDENCE &&
        file.name.toLocaleUpperCase().includes(FileName.CONFIDENCE) &&
        ext === FileExt.tiff) ||
      (fileType.name === FileName.DEPTHMAP &&
        file.name.toLocaleUpperCase().includes(FileName.DEPTHMAP) &&
        ext === FileExt.tiff)
    );
  });
};

export const handleInputFiles = async (
  e: React.ChangeEvent<HTMLInputElement>,
  fileType: FileType,
  isFolder: boolean,
  fileStore: FileStore,
  rootStore: RootStore
) => {
  if (fileType.name === FileName.GCP) {
    CSVValidator(e.target.files!, rootStore);
  } else {
    if (isFolder) {
      fileStore.setFilesFromInputs(acceptedFiles(e.target.files!, fileType), fileType);
    } else fileStore.setFilesFromInputs(Array.from(e.target.files!), fileType);
  }
};
