import { ProcessType, TaskForeignType } from "stores/taskStore/types";

import { LOCALES } from "constants/locales";

export const getProcessTypeLocale = (processType: ProcessType): string => {
  return processTypesLocales[processType];
};

export const getForeignTypeNameLocale = (foreignType: TaskForeignType): string => {
  return foreignTypeNameLocales[foreignType];
};

const { PROCESS_TYPE } = LOCALES.TASKS;
const processTypesLocales: Record<ProcessType, string> = {
  APPROVAL: PROCESS_TYPE.APPROVAL,
  CONDITION_ASSESSMENT: PROCESS_TYPE.CONDITION_ASSESSMENT,
  CONSTRUCTION_REPORT: PROCESS_TYPE.CONSTRUCTION_REPORT,
  DEFECT_MANAGEMENT: PROCESS_TYPE.DEFECT_MANAGEMENT,
  INCOMING_INSPECTION: PROCESS_TYPE.INCOMING_INSPECTION,
  MEASUREMENT_ACCOUNTING: PROCESS_TYPE.MEASUREMENT_ACCOUNTING,
} as const;

const foreignTypeNameLocales: Record<TaskForeignType, string> = {
  AREA: LOCALES.AREA.AREA,
  CONSTRUCTION_SITE: LOCALES.CONSTRUCTION_SITE.SHORT,
  JOB: LOCALES.JOB.JOB,
} as const;
