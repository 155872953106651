import { useRootStore } from "hooks/useRootStore";
import React, { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Box, SxProps } from "@mui/material";
import { observer } from "mobx-react-lite";

import { DisplayOptions } from "common/DisplayOptions";
import { Loader } from "common/Loader";
import { NoDataMessage } from "common/NoDataMessage";

import ConfirmDeletion from "./ConfirmDeletion";
import CreateButton from "./CreateButton";
import EditTitle from "./EditTitle";
import List from "./List";

interface ProcessingsProps {
  jobId: string;
  isLoading: boolean;
}

const Processings: FC<ProcessingsProps> = ({ jobId, isLoading }) => {
  const { t } = useTranslation();
  const { processingStore, uiStore } = useRootStore();

  const { processings, processing, editableProcessing, deleteId } = processingStore;
  const openModal = (jobId: string) => {
    if (editableProcessing?.id) {
      uiStore.openModal(<EditTitle jobId={jobId} />);
    }
    if (deleteId) {
      uiStore.openModal(<ConfirmDeletion jobId={jobId} processingId={deleteId} />);
    }
  };

  useEffect(() => {
    openModal(jobId);
  }, [processing?.id, editableProcessing?.id, deleteId, jobId]);

  return (
    <Box sx={wrapperSx}>
      <DisplayOptions>
        <CreateButton />
      </DisplayOptions>
      {isLoading ? (
        <Loader />
      ) : processings.length ? (
        <List />
      ) : (
        <NoDataMessage message={t("Processings:NoProcessings")} />
      )}
    </Box>
  );
};

const wrapperSx: SxProps = {
  width: "100%",
};

export default observer(Processings);
