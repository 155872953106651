import React, { useMemo } from "react";
import { Breadcrumbs as MBreadcrumbs } from "@mui/material";
import { Enum } from "types";

import Breadcrumb, { BreadcrumbActions } from "./Breadcrumb";
import { getExistingSteps } from "./helpers";
import { ExistingStep } from "./types";

const SEPARATOR = "|";

interface BreadcrumbsProps<T extends Enum> {
  steps: Array<ExistingStep<T>>;
}

function Breadcrumbs<T extends Enum>({
  steps,
  setStepName,
  selectedStepName,
}: BreadcrumbsProps<T> & BreadcrumbActions<T>) {
  const breadcrumbs = useMemo(
    () =>
      getExistingSteps(steps).map(s => (
        <Breadcrumb key={s.stepName} step={s} setStepName={setStepName} selectedStepName={selectedStepName} />
      )),
    [steps, setStepName, selectedStepName]
  );

  return <MBreadcrumbs separator={SEPARATOR}>{breadcrumbs}</MBreadcrumbs>;
}

export default Breadcrumbs;
