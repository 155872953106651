import { useRootStore } from "hooks/useRootStore";;
import React, { CSSProperties } from "react";
import Joyride from "react-joyride";
import { observer } from "mobx-react-lite";

import { onboardingStore } from "stores/onboardingStore";

import { getAppTourSteps } from "components/Onboarding/steps";

import StartTourModal from "./StartTourModal";
import Tooltip from "./Tooltip";

const AppTour = () => {
  const { constructionSiteStore } = useRootStore();

  const { isRun, stepIndex } = onboardingStore;

  return (
    <>
      <StartTourModal />
      <Joyride
        run={isRun}
        stepIndex={stepIndex ?? undefined}
        steps={getAppTourSteps(constructionSiteStore)}
        continuous
        beaconComponent={() => <></>}
        tooltipComponent={Tooltip}
        disableCloseOnEsc
        disableOverlayClose
        styles={{
          overlay: overlayStyles,
          spotlight: { zIndex: 3000 },
          options: {
            arrowColor: "transparent",
          },
        }}
      />
    </>
  );
};

const overlayStyles: CSSProperties = {
  backgroundColor: "rgba(255, 255, 255, .3)",
};

export default observer(AppTour);
