import React, { FC, useCallback, useState } from "react";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import Box from "@mui/material/Box";
import Fab from "@mui/material/Fab";
import IconButton from "@mui/material/IconButton";
import Zoom from "@mui/material/Zoom";

import { IProcessing } from "stores/managerService/processingStore/types";

import { LOCALES } from "constants/locales";
import { useRootStore } from "hooks/useRootStore";

interface ActionButtonsProps {
  processing: IProcessing;
  iconStyles: Object;
}

const ActionButtons: FC<ActionButtonsProps> = ({ processing, iconStyles }) => {
  const {
    processingStore: { setEditableProcessing, setDeleteId, generateShareProcessingResultsLink },
    uiStore: { setSnackbarOpen },
  } = useRootStore();

  const [actionButtons, setActionButtons] = useState("");
  const [scrollTop, setScrollTop] = useState(0);

  const handleCopyShareProcessingLink = useCallback(async () => {
    const shareToken = generateShareProcessingResultsLink(processing.projectId, processing.tokenRead);
    await navigator.clipboard.writeText(shareToken);
    setSnackbarOpen(LOCALES.GENERAL.SUCCESSFULLY_COPIED, "success");
  }, [generateShareProcessingResultsLink, processing.projectId, processing.tokenRead, setSnackbarOpen]);

  return (
    <Box sx={{ position: "relative" }}>
      <IconButton
        sx={iconStyles}
        onClick={() => {
          setActionButtons(processing.id);
          setScrollTop(document.getElementById("processings")!.scrollTop);
        }}>
        <MoreHorizIcon fontSize="small" />
      </IconButton>
      <Box
        sx={buttonsWrapperStyles(actionButtons === processing.id, scrollTop)}
        onMouseLeave={() => setActionButtons("")}>
        <Zoom in={actionButtons === processing.id}>
          <Fab
            size="small"
            aria-label="edit"
            sx={{ mb: 1, color: "background.default" }}
            onClick={handleCopyShareProcessingLink}>
            <ContentCopyOutlinedIcon fontSize="small" />
          </Fab>
        </Zoom>
        <Zoom in={actionButtons === processing.id} style={{ transitionDelay: "100ms" }}>
          <Fab
            size="small"
            aria-label="edit"
            sx={{ mb: 1, color: "background.default" }}
            onClick={() => setEditableProcessing(processing)}>
            <EditOutlinedIcon fontSize="small" />
          </Fab>
        </Zoom>
        <Zoom in={actionButtons === processing.id} style={{ transitionDelay: "200ms" }}>
          <Fab
            size="small"
            aria-label="delete"
            sx={{ color: "background.default" }}
            onClick={() => setDeleteId(processing.id)}>
            <DeleteOutlinedIcon />
          </Fab>
        </Zoom>
      </Box>
    </Box>
  );
};

const buttonsWrapperStyles = (selected: boolean, scrollTop: number) => {
  return {
    pl: selected ? 5 : 0,
    ml: selected ? 0 : 5,
    zIndex: selected ? 10 : -1,
    transition: "z-index 0.24s",
    mt: `calc(-86px - ${scrollTop}px)`,
    position: "fixed",
    display: "flex",
    flexDirection: "column",
  } as const;
};

export default ActionButtons;
