import React, { createRef, FC, useCallback, useLayoutEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import Box from "@mui/material/Box";
import Fade from "@mui/material/Fade";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { format } from "date-fns";
import { observer } from "mobx-react-lite";

import { SEARCH_PARAMS_TASK_ID_KEY } from "stores/mapStore/constants";
import { Task } from "stores/taskStore/types";

import { cardMaxHeightProps, hoverStyles, thumbStyles } from "constants/styles";
import { useFormatDate } from "hooks/useFormatDate";
import { useRootStore } from "hooks/useRootStore";

const List: FC<{ tasks: Task[] }> = ({ tasks }) => {
  const { t } = useTranslation();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, setSearchParam] = useSearchParams();

  const { teamStore, taskStore } = useRootStore();
  const formatDueDate = useFormatDate(format);

  const { csPermissions } = teamStore;
  const ref = createRef<HTMLDivElement>();
  const [isOverflow, setIsOverflow] = useState(false);

  useLayoutEffect(() => {
    const node = ref.current;

    if (node && node.clientHeight < node.scrollHeight) {
      setIsOverflow(true);
    } else {
      setIsOverflow(false);
    }
  }, [ref]);

  const handleOpenTask = useCallback(
    (taskId: string) => {
      setSearchParam({ [SEARCH_PARAMS_TASK_ID_KEY]: taskId });
    },
    [setSearchParam]
  );

  return (
    <Box
      sx={{
        ...containerStyles,
        ...(isOverflow && { pr: 1, MozPaddingEnd: "0" }),
      }}
      ref={ref}>
      {tasks.map(task => (
        <Fade in key={task.id}>
          <Paper sx={itemStyles} onClick={() => handleOpenTask(task.id)}>
            <Box sx={hoverStyles()} />
            <Typography variant="body1" component="div" fontWeight={600}>
              {task.title}
            </Typography>
            {task.dueDate && (
              <Typography
                variant="caption"
                component="div"
                fontWeight={300}
                sx={{ mt: 0.5, letterSpacing: "normal", color: "#ADADAD" }}>
                {t("Tasks:DueDate") + ": " + formatDueDate(task.dueDate, "d MMMM")}
              </Typography>
            )}
            <IconButton
              sx={iconStyles}
              onClick={e => {
                e.stopPropagation();
                taskStore.deleteTask(task.foreignId, task.id);
              }}
              disabled={!csPermissions.creating}>
              <DeleteOutlinedIcon />
            </IconButton>
          </Paper>
        </Fade>
      ))}
    </Box>
  );
};

export const containerStyles = {
  mt: 1.5,
  ...cardMaxHeightProps(),
  ...thumbStyles,
};

const itemStyles = {
  p: 1.5,
  position: "relative",
  minHeight: "75px",
  bgcolor: "background.paper",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  mb: 0.5,
  "&:last-of-type": {
    mb: 0,
  },
  "& .MuiTypography-body1": {
    transition: "color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
  },
};

const iconStyles = {
  ml: 0.5,
  color: "text.primary",
  bgcolor: "background.default",
  "& .MuiSvgIcon-root": {
    transition: "color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
  },
  "&:hover": {
    bgcolor: "secondary.main",
    "& .MuiSvgIcon-root": { color: "primary.main" },
  },
};

export default observer(List);
