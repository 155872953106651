import React, { FC } from "react";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { Box, IconButton, IconButtonProps, SxProps, Theme } from "@mui/material";

interface CloseIconButton {
  zeroHeight?: boolean;
  icon?: React.ReactNode;
}

const CloseIconButton: FC<CloseIconButton & IconButtonProps> = ({ zeroHeight, icon, ...props }) => {
  return (
    <Box sx={getCloseButtonContainerStyles(!!zeroHeight)}>
      <IconButton sx={buttonStyles} {...props}>
        {icon ?? <CloseRoundedIcon />}
      </IconButton>
    </Box>
  );
};

const getCloseButtonContainerStyles: (zeroHeight: boolean) => SxProps<Theme> = zeroHeight => ({
  textAlign: "right",
  height: zeroHeight ? 0 : "auto",
});

const buttonStyles: SxProps<Theme> = {
  color: "primary.contrastText",
  "&:hover": {
    color: "primary.main",
  },
};

export default CloseIconButton;
