import React, { FC, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import Button from "@mui/material/Button";
import Fade from "@mui/material/Fade";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { observer } from "mobx-react-lite";

import { ErrorI18KeyAndOptions } from "stores/managerService/helpers/checkGraphics";
import { onboardingStore } from "stores/onboardingStore";
import { useRootStore } from "hooks/useRootStore";

import { OnboardingSelector } from "components/Onboarding/steps/types";


import CreatePopupForm from "./CreatePopupForm";

const CreateMode: FC = () => {
  const { t } = useTranslation();
  const { mapStore, constructionSiteStore, uiStore } = useRootStore();

  const { _graphicsLayer } = mapStore;
  const { checkConstructionSiteElements } = constructionSiteStore;
  const { isOnboardingRunning } = onboardingStore;

  const [error, setError] = useState<ErrorI18KeyAndOptions>();

  const handleSubmit = useCallback(() => {
    const errorMessageAndOptions = checkConstructionSiteElements();
    setError(errorMessageAndOptions);
    if (errorMessageAndOptions) {
      return;
    }
    uiStore.openModal(<CreatePopupForm />);
  }, [_graphicsLayer.graphics, uiStore.openModal, isOnboardingRunning, onboardingStore.nextStep]);

  const handleCancel = () => {
    setError(undefined);
    constructionSiteStore.cancelCreatingConstructionSite();
  };

  return (
    <Stack direction={"column"} sx={containerStyles} spacing={1}>
      <Fade in>
        <Paper id={OnboardingSelector.START_CREATING_CONSTRUCTION_SITE} sx={wrapperStyles}>
          <Typography variant="body1">{t("ConstructionSite:DetermineLocation")}</Typography>
          {error && (
            <Typography variant="caption" color="error" sx={{ mt: 0.5, mb: 0.25 }} component="div">
              {error && t(...error)}
            </Typography>
          )}
          <Button variant="contained" sx={{ mt: 1.75, mr: 1 }} onClick={handleSubmit}>
            {t("General:Save")}
          </Button>
          <Button
            variant="contained"
            color="secondary"
            sx={{ mt: 1.75 }}
            onClick={handleCancel}
            disabled={isOnboardingRunning}
          >
            {t("General:Cancel")}
          </Button>
        </Paper>
      </Fade>
    </Stack>
  );
};

const wrapperStyles = {
  bgcolor: "background.default",
  boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.2)",
  px: 2,
  py: 2.5,
};

const containerStyles = {
  position: "absolute",
  bottom: 0,
  left: 0,
  // bottom: 0,
  width: "350px",
} as const;

export default observer(CreateMode);
