import { useRootStore } from "hooks/useRootStore";;
import React, { FC } from "react";
import { observer } from "mobx-react-lite";



const PrivateComponent: FC<{ component: FC }> = ({ component: Component }) => {
  const {
    authStore: { cognitoUser },
  } = useRootStore();

  return <>{cognitoUser && <Component />}</>;
};

export default observer(PrivateComponent);
