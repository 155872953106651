import { Box, Button, Paper, Typography } from "@mui/material";
import { LoadingIndicatorOverlay } from "common/LoadingIndicatorOverlay";
import { getHoveredTextStyles, thumbStyles } from "constants/styles";
import { validateFixPointCsv } from "helpers/CSVValidator";
import { copyCoordinatesToClipboard, getDateString, getFileExt, getInputAccept } from "helpers/functions";
import { useFetchWithLoading, useRootStore } from "hooks";
import { observer } from "mobx-react-lite";
import { ChangeEvent, MouseEvent, useState } from "react";
import { useTranslation } from "react-i18next";
import { FileExt } from "stores/fileStore/types";
import { PointTypes } from "stores/fixPointStore/types";
import ContentCopyRoundedIcon from "@mui/icons-material/ContentCopyRounded";
import DeleteOutlineRoundedIcon from "@mui/icons-material/DeleteOutlineRounded";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { NoDataMessage } from "common/NoDataMessage";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

function CoordinateDisplay({ label, value }: { label: string; value: number }) {
  return (
    <Typography variant="body2">
      {label}: {value.toFixed(3)}
    </Typography>
  );
}

export const FixPointsModal = observer(() => {
  const {
    fixPointStore: { fixPoints, createFixPoint, deleteFixPoint },
    uiStore: { setConfirmModalOpen, closeLastModal, setSnackbarOpen },
    uiStore,
  } = useRootStore();
  const { t } = useTranslation();
  const [invokeCreateFixPoint, isLoading] = useFetchWithLoading(createFixPoint);
  const [invokeDeleteFixPoint, isFixPointRemoving] = useFetchWithLoading(deleteFixPoint);
  const [openErrorDialog, setOpenErrorDialog] = useState(false);

  const handleClick = (e: MouseEvent<HTMLInputElement>) => {
    e.currentTarget.value = "";
  };

  const handleInputFiles = (e: ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;

    if (files) {
      const file = files[0];
      validateFixPointCsv(file)
        .then(fixPoints => {
          return fixPoints.map(p => ({ ...p, type: PointTypes.FIX_POINT }));
        })
        .then(fixPoints => {
          invokeCreateFixPoint(fixPoints);
        })
        .catch(() => {
          setOpenErrorDialog(true);
        });
    }
  };

  return (
    <Box>
      <Dialog
        open={openErrorDialog}
        onClose={() => setOpenErrorDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">{t("Measurements:InvalidFileFormat")}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">{t("Measurements:InvalidFixPointCSV")}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenErrorDialog(false)} color="primary" autoFocus>
            {t("Close")}
          </Button>
        </DialogActions>
      </Dialog>
      {(isLoading || isFixPointRemoving) && <LoadingIndicatorOverlay />}
      <Typography textAlign="center" variant="body1">
        {t("ConstructionSite:DefineFixPoints")}
      </Typography>
      <CloseRoundedIcon
        onClick={closeLastModal}
        sx={{ ...getHoveredTextStyles(), position: "absolute", top: 20, right: 20 }}
      />
      <Button
        component="label"
        size="small"
        color="secondary"
        variant="contained"
        fullWidth
        sx={{ flexShrink: 0, mt: 1, mb: 1 }}>
        <input
          type="file"
          accept={getInputAccept(FileExt.csv)}
          hidden
          onClick={handleClick}
          onChange={handleInputFiles}
        />
        <Typography variant="caption">+ {t("Measurements:AddNewPoint")}</Typography>
      </Button>
      <Box sx={{ ...thumbStyles }} height={300} paddingRight={1}>
        {fixPoints.length ? (
          fixPoints.map(fp => {
            return (
              <Paper key={fp.id} sx={{ mb: 1, p: 1 }}>
                <Box sx={containerStyles}>
                  <Box display="flex" alignItems="center" justifyContent="space-between" flex="1">
                    <Typography variant="body1">{fp.name}</Typography>
                    <Typography variant="body2" sx={{ color: "text.secondary" }}>
                      {t("Measurements:Date", { createdAt: getDateString(fp.createdAt) })}
                    </Typography>
                  </Box>
                  <Box flex="0.1" display="flex" justifyContent="flex-end" alignItems="center">
                    <DeleteOutlineRoundedIcon
                      onClick={async () =>
                        setConfirmModalOpen({
                          onConfirm: async () => await invokeDeleteFixPoint(fp.id),
                          children: (
                            <Typography textAlign="center">{t("General:ConfirmDelete", { name: fp.name })}</Typography>
                          ),
                        })
                      }
                      fontSize="small"
                      sx={getHoveredTextStyles()}
                    />
                  </Box>
                </Box>
                <Box display="flex" justifyContent="space-between" alignItems="center" paddingTop={0.5} flex="1">
                  <Box display="flex" alignItems="center" justifyContent="space-between" flex="1">
                    <CoordinateDisplay label={t("Measurements:X")} value={fp.x} />
                    <CoordinateDisplay label={t("Measurements:Y")} value={fp.y} />
                    <CoordinateDisplay label={t("Measurements:Z")} value={fp.z} />
                  </Box>
                  <Box flex="0.2" display="flex" justifyContent="flex-end" alignItems="center">
                    <ContentCopyRoundedIcon
                      onClick={async e => {
                        const { x, y, z } = fp;
                        const dataToCopy = {
                          x,
                          y,
                          z,
                        };
                        await copyCoordinatesToClipboard(e, dataToCopy, uiStore);
                      }}
                      fontSize={"small"}
                      sx={getHoveredTextStyles()}
                    />
                  </Box>
                </Box>
              </Paper>
            );
          })
        ) : (
          <NoDataMessage />
        )}
      </Box>
    </Box>
  );
});

const containerStyles = {
  display: "flex",
  borderWidth: 1,
  borderColor: "secondary.light",
  borderBottomStyle: "solid",
  pb: 0.5,
  flex: 1,
};
