import { useRootStore } from "hooks/useRootStore";;
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import Typography from "@mui/material/Typography";
import { observer } from "mobx-react-lite";

import { onboardingStore } from "stores/onboardingStore";



import { OnboardingSelector } from "../../Onboarding/steps/types";

const CreateTextButton: FC = () => {
  const { t } = useTranslation();
  const { constructionSiteStore } = useRootStore();

  const { isOnboardingRunning } = onboardingStore;
  const handleCreate = () => {
    constructionSiteStore.startCreatingConstructionSite();
    if (isOnboardingRunning) {
      onboardingStore.nextStep();
    }
  };

  return (
    <Typography
      id={OnboardingSelector.CREATE_NEW_CONSTRUCTION_SITE_BUTTON}
      fontWeight={500}
      sx={styles}
      onClick={handleCreate}
    >
      {t("ConstructionSite:CreateConstructionSite")}
    </Typography>
  );
};

const styles = {
  color: "text.disabled",
  ml: 2.5,
  width: "100%",
  minWidth: "200px",
  cursor: "default",
  transition: "color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
  "&:hover": { cursor: "pointer", color: "primary.main" },
};

export default observer(CreateTextButton);
